import React, { useState, useEffect } from "react";
import { 
  Box, 
  Typography, 
  TextField, 
  Autocomplete, 
  Chip, 
  Card, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  IconButton,
  Tooltip,
  Paper
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

// Import data with try/catch to handle potential parsing issues
let materialRiskData = [];
try {
  materialRiskData = require("../../data/material_risk.json");
} catch (error) {
  console.error("Error loading material risk data:", error);
  materialRiskData = [];
}

const MaterialRiskGrid = () => {
  // State for search and filters
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueMaterials, setUniqueMaterials] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [displayMode, setDisplayMode] = useState("grid"); // 'grid' or 'card'
  const [riskFilter, setRiskFilter] = useState("all"); // 'all', 'childLabor', 'forcedLabor', 'forcedChildLabor'

  // Extract unique materials and countries for filters
  useEffect(() => {
    if (Array.isArray(materialRiskData) && materialRiskData.length > 0) {
      const materials = [...new Set(materialRiskData.map(item => item.Good))].sort();
      const countries = [...new Set(materialRiskData.map(item => item["Country/Area"]))].sort();
      
      setUniqueMaterials(materials);
      setUniqueCountries(countries);
      setFilteredData(materialRiskData);
    } else {
      // Handle empty or invalid data
      setUniqueMaterials([]);
      setUniqueCountries([]);
      setFilteredData([]);
    }
  }, []);

  // Filter data based on search term and selected filters
  useEffect(() => {
    if (!Array.isArray(materialRiskData) || materialRiskData.length === 0) {
      setFilteredData([]);
      return;
    }
    
    let result = materialRiskData;
    
    // Filter by material
    if (selectedMaterial) {
      result = result.filter(item => item.Good === selectedMaterial);
    }
    
    // Filter by country
    if (selectedCountry) {
      result = result.filter(item => item["Country/Area"] === selectedCountry);
    }
    
    // Filter by search term (search in both material and country)
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(
        item => 
          item.Good.toLowerCase().includes(term) || 
          item["Country/Area"].toLowerCase().includes(term)
      );
    }
    
    // Filter by risk type
    if (riskFilter !== "all") {
      switch(riskFilter) {
        case "childLabor":
          result = result.filter(item => item["Child Labor"] === "X");
          break;
        case "forcedLabor":
          result = result.filter(item => item["Forced Labor"] === "X");
          break;
        case "forcedChildLabor":
          result = result.filter(item => item["Forced Child Labor"] === "X");
          break;
        default:
          break;
      }
    }
    
    setFilteredData(result);
  }, [searchTerm, selectedMaterial, selectedCountry, riskFilter]);

  // Clear all filters
  const clearFilters = () => {
    setSearchTerm("");
    setSelectedMaterial(null);
    setSelectedCountry(null);
    setRiskFilter("all");
  };

  // Generate risk indicator chips for a data item
  const getRiskIndicators = (item) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {item["Child Labor"] === "X" && (
          <Tooltip title="Child Labor Risk">
            <Chip 
              icon={<ChildCareIcon />} 
              label="Child Labor" 
              size="small" 
              sx={{ 
                bgcolor: "#FFECEB", 
                color: "#B42318",
                "& .MuiChip-icon": { color: "#B42318" }
              }} 
            />
          </Tooltip>
        )}
        {item["Forced Labor"] === "X" && (
          <Tooltip title="Forced Labor Risk">
            <Chip 
              icon={<AccessibilityNewIcon />} 
              label="Forced Labor" 
              size="small" 
              sx={{ 
                bgcolor: "#FEF0C7", 
                color: "#B54708",
                "& .MuiChip-icon": { color: "#B54708" }
              }} 
            />
          </Tooltip>
        )}
        {item["Forced Child Labor"] === "X" && (
          <Tooltip title="Forced Child Labor Risk">
            <Chip 
              icon={<PriorityHighIcon />} 
              label="Forced Child Labor" 
              size="small" 
              sx={{ 
                bgcolor: "#F04438", 
                color: "white",
                "& .MuiChip-icon": { color: "white" }
              }} 
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  // Card view renderer for material risk data
  const renderCardView = () => {
    return (
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {filteredData.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card 
              elevation={2} 
              sx={{ 
                p: 2, 
                height: '100%',
                borderLeft: (theme) => 
                  item["Forced Child Labor"] === "X" ? 
                    `4px solid ${theme.palette.error.main}` : 
                    item["Forced Labor"] === "X" ? 
                      `4px solid ${theme.palette.warning.main}` : 
                      item["Child Labor"] === "X" ? 
                        `4px solid ${theme.palette.error.light}` : 
                        `4px solid ${theme.palette.grey[300]}`
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: "#101828" }}>
                  {item.Good}
                </Typography>
                {(item["Child Labor"] === "X" || item["Forced Labor"] === "X" || item["Forced Child Labor"] === "X") && (
                  <Tooltip title="Risk Indicators Present">
                    <WarningAmberIcon color="warning" />
                  </Tooltip>
                )}
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: "#667085", 
                  mb: 2,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Box 
                  component="span" 
                  sx={{ 
                    width: 12, 
                    height: 12, 
                    borderRadius: '50%', 
                    bgcolor: '#6172F3', 
                    display: 'inline-block',
                    mr: 1
                  }}
                />
                {item["Country/Area"]}
              </Typography>
              <Box sx={{ mt: 1 }}>
                {getRiskIndicators(item)}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Data grid columns for table view
  const columns = [
    {
      field: "Good",
      headerName: "Material/Product",
      flex: 2,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            fontWeight: "600",
            color: "#101828",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "Country/Area",
      headerName: "Country",
      flex: 1.5,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: "#667085",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "riskIndicators",
      headerName: "Risk Indicators",
      flex: 3,
      renderCell: (params) => getRiskIndicators(params.row),
    },
  ];

  return (
    <Box sx={{ width: "100%", padding: "12px 0px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
          alignItems: "center"
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: "#101828",
          }}
        >
          Material/Product Risk Analysis
        </Typography>
        
        <Box sx={{ display: "flex", gap: 2 }}>
          <Tooltip title={displayMode === "grid" ? "Switch to Card View" : "Switch to Grid View"}>
            <IconButton 
              onClick={() => setDisplayMode(displayMode === "grid" ? "card" : "grid")}
              sx={{ color: '#6172F3' }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Clear all filters">
            <IconButton 
              onClick={clearFilters}
              disabled={!searchTerm && !selectedMaterial && !selectedCountry && riskFilter === "all"}
              sx={{ color: '#6172F3' }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" sx={{ color: "#475467", mb: 2, display: "flex", alignItems: "center" }}>
          
          Data source: <Box component="a" href="https://www.dol.gov/agencies/ilab/better-trade-tool" target="_blank" rel="noopener noreferrer" sx={{ ml: 0.5, color: "#6172F3", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>U.S. Department of Labor's Bureau of International Labor Affairs (ILAB) Better Trade Tool</Box>
        </Typography>
      </Box>
      
      <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #EAECF0', borderRadius: '12px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Search materials or countries..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "#667085", mr: 1 }} />,
              }}
              size="small"
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={selectedMaterial}
              onChange={(event, newValue) => {
                setSelectedMaterial(newValue);
              }}
              options={uniqueMaterials}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Material" size="small" />
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={selectedCountry}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
              }}
              options={uniqueCountries}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Country" size="small" />
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Risk Type</InputLabel>
              <Select
                value={riskFilter}
                label="Risk Type"
                onChange={(e) => setRiskFilter(e.target.value)}
              >
                <MenuItem value="all">All Risks</MenuItem>
                <MenuItem value="childLabor">Child Labor</MenuItem>
                <MenuItem value="forcedLabor">Forced Labor</MenuItem>
                <MenuItem value="forcedChildLabor">Forced Child Labor</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      
      <Box>
        <Typography variant="body2" sx={{ mb: 2, color: "#667085" }}>
          {filteredData.length === 0 
            ? "No materials match your search criteria" 
            : `Showing ${filteredData.length} of ${materialRiskData.length} materials with risk indicators`}
        </Typography>
        
        {displayMode === "grid" ? (
          <Box
            sx={{
              height: "calc(100vh - 380px)",
              width: "100%",
            }}
          >
            <DataGridPro
              rows={filteredData.map((item, index) => ({ ...item, id: index }))}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50]}
              disableSelectionOnClick
              disableColumnMenu
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              sx={{
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                border: "none",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F9FAFB",
                  borderBottom: "1px solid #EAECF0",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#FFFFFF",
                },
              }}
            />
          </Box>
        ) : (
          renderCardView()
        )}
      </Box>
    </Box>
  );
};

export default MaterialRiskGrid;
