import React from "react";
import { Divider, Grid, Box } from "@mui/material";

import AuditReportTabs from "../components/AuditDetails/auditReportTabs.component";
import AuditReportLeftSideNavigationBar from "../components/AuditDetails/auditReportDetailsLeftSideNavigationBar.component";
import AuditReport from "../components/AuditDetails/auditReport.component";
import AuditReportDetailsAppbar from "../components/AuditDetails/auditReportDetailsAppbar.component";
import { useSelector } from "react-redux";
import {
  selectAuditIssueDetails,
  selectAuditReportPageTab,
} from "../selectors/audit.selector";
import AuditReportFactoryInfo from "../components/AuditDetails/auditReportFactoryInfo.component";
import EditAuditReport from "../components/AuditDetails/EditModals/editAuditReport.component";
import {
  selectEditModeForAuditState,
  selectEditModeState,
} from "../selectors/misc.selector";

const AuditReportDetails = ({ handleDrawerClose }) => {
  const auditReportTabValue = useSelector(selectAuditReportPageTab);
  const data = useSelector(selectAuditIssueDetails);
  const selectedEditState = useSelector(selectEditModeForAuditState);
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}>
        <AuditReportDetailsAppbar handleDrawerClose={handleDrawerClose} />
        {/* <Divider flexItem style={{ width: "98%", margin: "1px 16px" }} /> */}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          overflow: "hidden", // Prevent double scrollbars
        }}
      >
        {/* Left Navigation */}
        <Box
          sx={{
            width: "23%",
            overflow: "auto",
            borderRight: "1px solid #E0E0E0",
            height: "100%",
          }}
        >
          <AuditReportTabs />
          {auditReportTabValue === "issues" ? (
            <AuditReportLeftSideNavigationBar />
          ) : (
            <AuditReportFactoryInfo />
          )}
        </Box>

        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            height: "100%",
          }}
        >
          {selectedEditState ? (
            <EditAuditReport data={data} />
          ) : (
            <AuditReport />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AuditReportDetails;
