import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { uploadFactoryFile } from "../../reducers/factory.reducer";
import { selectFilesUploadedIds } from "../../selectors/misc.selector";
import { setFilesUploadedIds } from "../../reducers/misc.reducer";
import { Snackbar, Box } from "@mui/material";

const FacilityDocumentDrop = ({
  field,
  fieldKey,
  organisationId,
  supplierId,
  facilityId,
  onClose,
  onUploadSuccess,
}) => {
  const [extFiles, setExtFiles] = React.useState([]);
  const [imageSrc, setImageSrc] = React.useState(undefined);
  const [videoSrc, setVideoSrc] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const dispatch = useDispatch();
  const uploadProgress = useSelector((state) => state.factory.uploadProgress);
  const uploadStatus = useSelector((state) => state.factory.uploadStatus);

  const filesUploadedIds = useSelector(selectFilesUploadedIds);

  React.useEffect(() => {
    if (Object.keys(filesUploadedIds).length > 0) {
      let successfulIds = Object.keys(filesUploadedIds).filter(
        (x) => filesUploadedIds[x] === true
      );
      setMessage(
        `${successfulIds.length} ${
          successfulIds.length > 1 ? "files" : "file"
        } uploaded successfully, ${
          successfulIds.length > 1 ? "they have" : "it has"
        } been removed from the dropbox.`
      );
      setOpen(true);
      setExtFiles(extFiles.filter((x) => !filesUploadedIds[x.id]));
      dispatch(setFilesUploadedIds({}));
    }
  }, [filesUploadedIds]);

  const updateFiles = (incommingFiles) => {
    console.log("incoming files", incommingFiles);
    setExtFiles(incommingFiles);
  };

  const filesWithProgress = extFiles.map((file) => ({
    ...file,
    uploadProgress: uploadProgress[file.id] || file.uploadProgress || 0,
    uploadStatus: uploadStatus[file.id] || file.uploadStatus || undefined,
  }));

  const onDelete = (id) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };

  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };

  // const handleStart = (filesToUpload) => {
  //     console.log("Starting file upload process");
  //     console.log("Files to upload:", filesToUpload);

  //     dispatch(uploadFactoryFile({
  //         organisationId,
  //         supplierId,
  //         factoryId: facilityId,
  //         fieldKey,
  //         file: filesToUpload
  //     }));

  //     console.log("Closing the modal after starting the upload");
  //     onClose();
  // };
  const handleStart = (filesToUpload) => {
    console.log("Starting file upload process");
    console.log("Files to upload:", filesToUpload);

    const updatedFiles = filesToUpload.map((file) => ({
      ...file,
      uploadStatus: "uploading",
      uploadProgress: 0,
    }));

    setExtFiles(updatedFiles);

    // dispatch(
    //   uploadFactoryFile({
    //     organisationId,
    //     supplierId,
    //     factoryId: facilityId,
    //     fieldKey,
    //     file: filesToUpload,
    //     onSuccess: (uploadedFiles) => {
    //       // Add callback
    //       // Show success message
    //       setMessage(
    //         `${uploadedFiles.length} ${
    //           uploadedFiles.length > 1 ? "files" : "file"
    //         } uploaded successfully`
    //       );
    //       setOpen(true);

    //       // Notify parent component
    //       onUploadSuccess?.(uploadedFiles);

    //       // Close dialog after delay
    //       setTimeout(() => {
    //         onClose?.();
    //       }, 2000);
    //     },
    //   })
    // );
  };

  const handleFinish = (uploadedFiles) => {
    console.log("Upload finished", uploadedFiles);
  };

  const handleAbort = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };

  const handleCancel = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Dropzone
          onChange={updateFiles}
          minHeight="400px"
          value={extFiles}
          label="Drag'n drop files here or click to browse"
          onUploadStart={handleStart}
          onUploadFinish={handleFinish}
          actionButtons={{
            position: "after",
            abortButton: {},
            deleteButton: {},
            uploadButton: {
              onClick: () => {
                handleStart(extFiles);
              },
            },
          }}
        >
          {extFiles.map((file) => (
            <FileMosaic
              {...file}
              key={file.id}
              onDelete={onDelete}
              onSee={handleSee}
              onWatch={handleWatch}
              onAbort={handleAbort}
              onCancel={handleCancel}
              uploadStatus={file.uploadStatus}
              uploadProgress={file.uploadProgress}
              resultOnTooltip
              alwaysActive
              preview
              info
            />
          ))}
        </Dropzone>
        <FullScreen
          open={imageSrc !== undefined}
          onClose={() => setImageSrc(undefined)}
        >
          <ImagePreview src={imageSrc} />
        </FullScreen>
        <FullScreen
          open={videoSrc !== undefined}
          onClose={() => setVideoSrc(undefined)}
        >
          <VideoPreview src={videoSrc} autoPlay controls />
        </FullScreen>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
        />
      </Box>
    </>
  );
};

export default FacilityDocumentDrop;
