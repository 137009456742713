import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
} from "@mui/material";

import { ComponentStyles, FacilityStyles } from "../../styles";
import { useTenant } from "../../context/TenantContext";

const FactoryIssuesCard = ({ latestAuditData }) => {
  // const issuesData = useSelector(selectFactoryIssuesData);
  // const [filteredIssues, setFilteredIssues] = useState([]);
  // const [isSorted, setIsSorted] = useState(false);

  // useEffect(() => {
  //   setFilteredIssues(issuesData.detailedIssues);
  // }, [issuesData]);

  // const handleSort = () => {
  //   const sortedData = [...filteredIssues].sort((a, b) => {
  //     if (isSorted) {
  //       return a.category.localeCompare(b.category);
  //     } else {
  //       return b.category.localeCompare(a.category);
  //     }
  //   });
  //   setFilteredIssues(sortedData);
  //   setIsSorted(!isSorted);
  // };

  // const handleFilter = (key) => {
  //   const filteredData = issuesData.detailedIssues.filter(
  //     (issue) => issue[key] > 0
  //   );
  //   setFilteredIssues(filteredData);
  // };

  // 4imprint frontend calculation for finding summary
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;
  const auditData = latestAuditData?.metadata?.capDetails;

  let issueSummary = [
    { category: "Management Systems", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Health & Safety", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Working Hours", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Wages & Benefits", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Business Ethics", zt: 0, critical: 0, major: 0, minor: 0 },
    {
      category: "Employment, Discipline, & Grievances",
      zt: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    {
      category: "Discrimination & Harassment",
      zt: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    {
      category: "Freedom of Association & Collective Bargaining",
      zt: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    {
      category: "Young & Underage Workers",
      zt: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    { category: "Forced Labor", zt: 0, critical: 0, major: 0, minor: 0 },
    {
      category: "Sub-contracting & Homework",
      zt: 0,
      critical: 0,
      major: 0,
      minor: 0,
    },
    { category: "Environment", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Other", zt: 0, critical: 0, major: 0, minor: 0 },
    { category: "Labor Exploitation", zt: 0, critical: 0, major: 0, minor: 0 },
    {
      category: "Health & Safety",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Environmental",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Business Ethics",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety, and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety & Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Compensation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Hours of Work",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Transparency & Business Integrity",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Unsure",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Employment Relationship",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HSE",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ENV",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HOW",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Freedom of Association",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Nondiscrimination",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "C",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health and Safety",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Code Commitment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Employer Regulation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Code Communication",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "CC - Code of Conduct",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HOW - Hours of Work",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ER - Employment Relationship",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "C - Compensation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HSE - Health, Safety and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ENV - Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "TRAN - Transparency",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ND - Nondiscrimination",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "RCI - Remediation & Continuous Improvement",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "SUB - Subcontractors",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "H/A - Harassment or Abuse",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "FL - Forced Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "FOA - Freedom of Association and Collective Bargaining",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "CL - Child Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Management Systems",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
  ];
  auditData?.forEach((issue) => {
    const categoryIndex = issueSummary.findIndex(
      (item) => item.category === issue.type
    );
    if (categoryIndex !== -1) {
      switch (issue.severity.toLowerCase()) {
        case "zt":
          issueSummary[categoryIndex].zt++;
          break;
        case "critical":
          issueSummary[categoryIndex].critical++;
          break;
        case "major":
          issueSummary[categoryIndex].major++;
          break;
        case "minor":
          issueSummary[categoryIndex].minor++;
          break;
        default:
          break;
      }
    }
  });

  if (
    factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard?.org ===
    "reformation"
  ) {
    issueSummary = auditData.reduce((acc, issue) => {
      if (!acc.some((item) => item.category === issue.subType)) {
        acc.push({
          category: issue.subType,
          zt: 0,
          critical: 0,
          major: 0,
          moderate: 0,
          minor: 0,
        });
      }
      return acc;
    }, []);
    auditData.forEach((issue) => {
      if (issue && issue.subType && issue.severity) {
        const categoryIndex = issueSummary.findIndex(
          (item) => item.category === issue.subType
        );
        if (categoryIndex !== -1) {
          switch (issue.severity.toLowerCase()) {
            case "zt":
              issueSummary[categoryIndex].zt++;
              break;
            case "critical":
              issueSummary[categoryIndex].critical++;
              break;
            case "major":
              issueSummary[categoryIndex].major++;
              break;
            case "moderate":
              issueSummary[categoryIndex].moderate++;
              break;
            case "minor":
              issueSummary[categoryIndex].minor++;
              break;
            default:
              break;
          }
        }
      } else {
        console.warn("Invalid issue object:", issue); // Log invalid issue objects
      }
    });
  }

  // Calculate issue summary from issue summary

  // Filter out categories with no issues
  const filteredIssueSummary = issueSummary.filter(
    (category) =>
      category.zt > 0 ||
      category.critical > 0 ||
      category.major > 0 ||
      category.moderate > 0 ||
      category.minor > 0
  );

  // Calculate total counts
  const totalCounts = filteredIssueSummary.reduce(
    (acc, category) => {
      acc.totalIssues +=
        category.zt +
        category.critical +
        category.major +
        category?.moderate +
        category.minor;
      acc.totalZtIssues += category.zt;
      acc.totalCriticalIssues += category.critical;
      acc.totalMajorIssues += category.major;
      acc.totalModerateIssues += category.moderate;
      acc.totalMinorIssues += category.minor;
      return acc;
    },
    {
      totalIssues: 0,
      totalZtIssues: 0,
      totalCriticalIssues: 0,
      totalMajorIssues: 0,
      totalMinorIssues: 0,
      totalModerateIssues: 0,
    }
  );

  return (
    <Paper elevation={1} sx={FacilityStyles.issuesTablePaper}>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        {auditData?.auditor}
      </Typography>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        {
          factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard
            ?.title
        }
      </Typography>
      <Divider sx={{ my: "12px" }} />
      <Box sx={{ height: 296, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90px", // Fixed width
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography></Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  borderBottom: "2px solid #3538CD",
                  borderRight: "2px dotted #3538CD",
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Total
                    </Typography>
                  </Box>
                  <Box>{totalCounts.totalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#B42318",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>ZT</span>
                  <Box>{totalCounts.totalZtIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#FF692E",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Critical</span>
                  <Box>{totalCounts.totalCriticalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#EAAA08",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Major</span>
                  <Box>{totalCounts.totalMajorIssues}</Box>
                </Box>
              </TableCell>
              {factoryStatusSection?.detailsFromMostRecentAuditSection
                ?.issuesCard?.org === "reformation" && (
                <TableCell
                  sx={{
                    ...FacilityStyles.issuesTableCell,
                    color: "#EAAA08",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>Moderate</span>
                    <Box>{totalCounts.totalModerateIssues}</Box>
                  </Box>
                </TableCell>
              )}
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#667085",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Minor</span>
                  <Box>{totalCounts.totalMinorIssues}</Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issueSummary.map((issue, index) => {
              const issueCount =
                issue.zt + issue.critical + issue.major + issue.minor;
              return issueCount > 0 ? (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      width: "90px", // Fixed width
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "normal",

                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    {issue.category.length > 20 ? (
                      <span>{issue.category}</span>
                    ) : (
                      issue.category
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issueCount ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      borderRight: "2px dotted #3538CD",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issueCount}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.zt ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.zt}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.critical ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.critical}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.major ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.major}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.minor ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.minor}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default FactoryIssuesCard;
