import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Card,
  Tooltip,
  Chip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierData } from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectFacilityPageTab,
  selectFactoryData,
} from "../selectors/factory.selector";
import FactoryInformation from "../components/FacilityDetails/factoryInformation.component";

import FactoryComplianceStatus from "../components/FacilityDetails/factoryComplianceStatus.component";
import FactoryAuditList from "../components/FacilityDetails/factoryAuditList.component";
import { getFactoryById } from "../reducers/factory.reducer";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import AdditionalFactoryInformation from "../components/FacilityDetails/facilityAdditionalInformation.component";
import { ComponentStyles, PageStyles } from "../styles";

import { getListOfAllAudits } from "../reducers/audit.reducer";
import { getSupplierById } from "../reducers/supplier.reducer";
import CommentSection from "../components/commentSection.component";
import { useTenant } from "../context/TenantContext";

import FactoryDataTab from "../tenants/reformation/factoryDataTab.component";
import EnvironmentalManagementDataTab from "../tenants/reformation/environmentalManagementDataTab.component";
import DocumentationAndCertificationDataTab from "../tenants/reformation/docsAndCertsTab.component";
import FactoryTabs from "../components/FacilityDetails/factoryTabs.component";
import FactoryEnvironmentalEmissionsData from "../tenants/reformation/factoryEnvironmentalEmissionsData.component";
import FactoryQuestionnaires from "../tenants/4imprint/questionnaire/factoryQuestionnaire.component";

const FactoryDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { factoryConfig } = useTenant();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const selectedFactoryData = useSelector(selectFactoryData);

  const supplierData = useSelector(selectSupplierData);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    try {
      if (!selectedFactoryData || selectedFactoryData.id !== params.factoryId) {
        dispatch(
          getFactoryById({
            supplierId: params.supplierId,
            factoryId: params.factoryId,
          })
        );
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [params.factoryId, params.supplierId, dispatch]);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);

  const selectedTab = useSelector(selectFacilityPageTab);

  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  }, []);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedFactoryData?.name}
                {factoryConfig?.factoryDetailsTitleSection?.tier &&
                  selectedFactoryData?.metadata?.tier && (
                    <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                      {selectedFactoryData?.metadata?.tier
                        ?.toString()
                        .includes("Tier")
                        ? selectedFactoryData?.metadata?.tier
                        : `Tier ${selectedFactoryData?.metadata?.tier}`}
                    </Box>
                  )}
                {factoryConfig?.factoryDetailsTitleSection?.status &&
                  selectedFactoryData?.metadata?.status === "inactive" && (
                    <Box
                      sx={{
                        ...PageStyles.tierAndPrivateLabel,
                        bgcolor: "#EAAA08",
                      }}
                      size="small"
                      variant="outlined"
                    >
                      Inactive Site
                    </Box>
                  )}
                {factoryConfig?.factoryDetailsTitleSection?.privateLabel &&
                  selectedFactoryData?.metadata?.privateLabel === "Yes" && (
                    <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                      Private Label
                    </Box>
                  )}
                {factoryConfig?.factoryDetailsTitleSection?.factoryId &&
                  selectedFactoryData?.metadata?.factoryId && (
                    <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                      Factory ID: {selectedFactoryData?.metadata?.factoryId}
                    </Box>
                  )}
              </Typography>
            </Grid>
            {factoryConfig?.editMode?.toggle && (
              <Grid item>
                <EditToggleSwitch />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <FactoryInformation
              isLoading={isLoading}
              error={error}
              factoryData={selectedFactoryData}
            />
          </Grid>

          {factoryConfig?.commentSection?.showConfidentialBusinessInfo && (
            <Grid
              item
              xs={
                factoryConfig?.commentSection?.confidentialBusinessInfo?.width
                  .xs
              }
              md={
                factoryConfig?.commentSection?.confidentialBusinessInfo?.width
                  .md
              }
            >
              <ConfidentialBusinessInformation
                title={
                  factoryConfig?.commentSection?.confidentialBusinessInfo?.title
                }
                confidential={
                  factoryConfig?.commentSection?.confidentialBusinessInfo
                    ?.confidential
                }
                type={
                  factoryConfig?.commentSection?.confidentialBusinessInfo?.type
                }
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={
              factoryConfig?.commentSection?.showConfidentialBusinessInfo
                ? 8
                : 12
            }
          >
            <Card sx={ComponentStyles.commentSectionCard}>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2, ml: 2, mr: 2 }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={ComponentStyles.commentSectionTitle}
                  >
                    {factoryConfig?.commentSection?.title}
                  </Typography>
                  {factoryConfig?.commentSection?.showBetaChip && (
                    <Tooltip title="The internal note is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                      <Chip
                        label="Beta"
                        size="small"
                        sx={ComponentStyles.betaChip}
                      />
                    </Tooltip>
                  )}
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ mt: 0, mr: 4 }}
                >
                  {factoryConfig?.commentSection?.fields.map(
                    ({ field, label, layout, width }) => (
                      <Grid item xs={width.xs} md={width.md} key={field}>
                        {label && (
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ fontWeight: 400, fontSize: 14, ml: 2, mb: 1 }}
                          >
                            {label}
                          </Typography>
                        )}
                        <CommentSection
                          source={factoryConfig?.commentSection?.source}
                          field={field}
                          layout={layout}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AdditionalFactoryInformation />
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <FactoryTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <FactoryComplianceStatus />
                  ) : selectedTab === "audits" ? (
                    <FactoryAuditList />
                  ) : selectedTab === "data" ? (
                    <FactoryDataTab />
                  ) : selectedTab === "environmental" ? (
                    <EnvironmentalManagementDataTab />
                  ) : selectedTab === "documentation" ? (
                    <DocumentationAndCertificationDataTab />
                  ) : selectedTab === "environmentEmissions" ? (
                    <FactoryEnvironmentalEmissionsData />
                  ) : selectedTab === "questionnaires" ? (
                    <FactoryQuestionnaires factoryId={params.factoryId} />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FactoryDetails;
