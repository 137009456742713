import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  questionnaires: [],
  error: null,
  selectedQuestionnaireId: null,
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    getQuestionnaires: (state) => {
      state.loading = true;
      state.error = null;
    },
    setQuestionnaires: (state, action) => {
      state.questionnaires = action.payload;
      state.loading = false;
    },
    setQuestionnaireError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    selectQuestionnaire: (state, action) => {
      state.selectedQuestionnaireId = action.payload;
    },
    clearSelectedQuestionnaire: (state) => {
      state.selectedQuestionnaireId = null;
    },
    linkResponseToFactory: (state) => {
      state.linkingResponse = true;
      state.linkingSuccess = false;
      state.linkingError = null;
    },
    linkResponseToFactorySuccess: (state) => {
      state.linkingResponse = false;
      state.linkingSuccess = true;
    },
    linkResponseToFactoryFailure: (state, action) => {
      state.linkingResponse = false;
      state.linkingError = action.payload;
    },
    resetLinkingState: (state) => {
      state.linkingResponse = false;
      state.linkingSuccess = false;
      state.linkingError = null;
    },
    getFileSignedUrlRequest: (state, action) => {
      state.loadingSignedUrls[action.payload] = true;
      state.signedUrlErrors[action.payload] = null;
    },
    getFileSignedUrlSuccess: (state, action) => {
      state.loadingSignedUrls[action.payload.fileKey] = false;
      state.signedUrls[action.payload.fileKey] = action.payload.signedUrl;
    },
    getFileSignedUrlFailure: (state, action) => {
      state.loadingSignedUrls[action.payload.fileKey] = false;
      state.signedUrlErrors[action.payload.fileKey] = action.payload.error;
    },
    clearFileSignedUrls: (state) => {
      state.signedUrls = {};
      state.loadingSignedUrls = {};
      state.signedUrlErrors = {};
    },
  },
});

export const {
  getQuestionnaires,
  setQuestionnaires,
  setQuestionnaireError,
  selectQuestionnaire,
  clearSelectedQuestionnaire,
  linkResponseToFactory,
  linkResponseToFactorySuccess,
  linkResponseToFactoryFailure,
  resetLinkingState,
  getFileSignedUrlRequest,
  getFileSignedUrlSuccess,
  getFileSignedUrlFailure,
  clearFileSignedUrls,
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
