import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";
const initialState = {
  editMode: false,
};

const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {
    toggleEditMode: (state, action) => {
      state.editMode = !state.editMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});
export const { toggleEditMode } = miscSlice.actions;

export default miscSlice.reducer;
