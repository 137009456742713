import { Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const SupplierAssociatedFactoryLinks = ({ factories, tier, supplierId }) => {
  return (
    <Box component="ul" sx={{ padding: 0, margin: 0, listStyle: "none" }}>
      {factories && factories.length > 0 ? (
        factories.map((factory) => (
          <Link
            key={factory.factoryId}
            to={`/suppliers/${supplierId}/factory/${factory.factoryId}`}
            style={{
              color: "#6172F3",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            {factory.name}
            <img
              style={{ paddingLeft: 8 }}
              src="/ne_arrow.svg"
              alt="arrow icon"
            />
          </Link>
        ))
      ) : (
        <Box>No Tier {tier} facilities found</Box>
      )}
    </Box>
  );
};

export default SupplierAssociatedFactoryLinks;
