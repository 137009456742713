import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  Box,
  Divider,
  Card,
  Tooltip,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierNameFromId,
  selectSupplierData,
  selectSupplierPageTab,
} from "../selectors/supplier.selector";
import { useTenant } from "../context/TenantContext";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import SupplierInformation from "../components/SupplierDetails/supplierInformation.component";
import InternalInformation from "../components/SupplierDetails/internalInformation.component";
import SupplierTabs from "../components/SupplierDetails/supplierTabs.component";

import { getSupplierById } from "../reducers/supplier.reducer";

import LeftSideNavbar from "../components/leftSideNavbar.component";

import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";

import SupplierSupplyChainOverview from "../components/SupplierDetails/supplierSupplyChainOverview.component";
import SupplierFactoryList from "../components/SupplierDetails/supplierFactoryList.component";
import CommentSection from "../components/commentSection.component";
import { PageStyles } from "../styles";
import SupplierEnvironmental from "../tenants/4imprint/supplierEnvironmental.component";
import SupplierData from "../tenants/reformation/supplierData.component";
import SupplierDocumentationAndCertifications from "../tenants/reformation/supplierDocumentationAndCertifications.component";

const SupplierDetails = () => {
  const { supplierConfig } = useTenant();

  const params = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );
  const supplierData = useSelector(selectSupplierData);
  const selectedTab = useSelector(selectSupplierPageTab);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);

  useEffect(() => {
    const loadSupplierData = async () => {
      if (!supplierData || supplierData.id !== params.supplierId) {
        setIsLoading(true);
        setError(null);
        try {
          await dispatch(getSupplierById(params.supplierId));
        } catch (err) {
          console.error("Error loading supplier data:", err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadSupplierData();
  }, [params.supplierId, dispatch]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {supplierData?.name || supplierName || "-"}
                {supplierData?.metadata?.status === "inactive" && (
                  <Box
                    sx={{
                      ...PageStyles.tierAndPrivateLabel,
                      bgcolor: "#EAAA08",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Inactive Supplier
                  </Box>
                )}
                {supplierData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
              </Typography>
            </Grid>
            {supplierConfig?.editMode?.toggle && (
              <Grid item>
                <EditToggleSwitch />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <SupplierInformation />
          </Grid>
          {/* Internal Information */}
          {supplierConfig.detailsPage.internalInformation && (
            <Grid item xs={12}>
              <InternalInformation />
            </Grid>
          )}
          {supplierConfig.detailsPage.showConfidentialBusinessInfo && (
            <Grid item xs={12} md={4}>
              <ConfidentialBusinessInformation
                title="Confidential Business Information"
                confidential={true}
                type={"supplier"}
              />
            </Grid>
          )}
          <Grid
            item
            xs={
              supplierConfig.detailsPage.showConfidentialBusinessInfo ? 8 : 12
            }
          >
            <Card
              sx={{
                p: 1,
                pt: 3,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                border: "1px solid rgba(234, 236, 240, 1)",
                borderRadius: "12px",
                height: "100%", // Ensure the card takes up the full height
                margin: "auto",
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2, ml: 2, mr: 2 }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                    }}
                  >
                    Internal Notes
                  </Typography>
                  <Tooltip title="The internal note is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                    <Chip
                      label="Beta"
                      size="small"
                      sx={{
                        backgroundColor: "#4C5ED9",
                        color: "white",
                        fontWeight: 500,
                        fontSize: "12px",
                        height: "24px",
                        ml: 1,
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mt: 0, mr: 2 }}
                >
                  <Grid item xs={11.5}>
                    <CommentSection source="supplier"></CommentSection>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <SupplierTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "supplyChainOverview" ? (
                    <SupplierSupplyChainOverview />
                  ) : selectedTab === "environmental" ? (
                    <SupplierEnvironmental />
                  ) : selectedTab === "factoryList" ? (
                    <SupplierFactoryList />
                  ) : selectedTab === "supplierData" ? (
                    <SupplierData />
                  ) : selectedTab === "documentation&Certifications" ? (
                    <SupplierDocumentationAndCertifications />
                  ) : (
                    <SupplierFactoryList />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SupplierDetails;
