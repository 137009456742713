import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Link,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CloudUpload } from "@mui/icons-material";
import moment from "moment";
import _ from "lodash";
import { handleWriteValue } from "../../utils/lib";
import CountryStateSelect from "./countryStateSelect.component";
import { deleteFactoryFileStart } from "../../reducers/factory.reducer";

import DocumentUpload from "./facilityDocumentDrop.component";

const RenderFormFields = ({
  field,
  value,
  handleChange,
  formData,
  signedUrls,
  dispatch,
  handleFileClick,
  languages,
  factoryData,
  uploadDialogOpen,
  setUploadDialogOpen,
  isReadOnly = false,
  isSupplierLevel = false,
}) => {
  // Add state for file delete confirmation
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  // Read-only props to be applied to text fields
  const readOnlyProps = isReadOnly
    ? {
        InputProps: {
          readOnly: true,
          disableunderline: "true",
          sx: {
            cursor: "default",
            "&:hover": {
              cursor: "default",
              backgroundColor: "transparent",
            },
            "& .MuiInputBase-input": {
              cursor: "default",
              "&:focus": {
                backgroundColor: "transparent",
                cursor: "default",
              },
            },
            "& fieldset": {
              borderColor: "#e0e0e0 !important",
              "&:hover": {
                borderColor: "#e0e0e0 !important",
              },
            },
          },
        },
        sx: {
          pointerEvents: "none",
          backgroundColor: "transparent",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "#e0e0e0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#e0e0e0",
            },
          },
        },
      }
    : {};

  if (field.dbMapping === "irss.percentByFacility") {
    const percentByCustomer = parseFloat(
      _.get(formData, "irss.percentByCustomer", "0")
    );
    value = !isNaN(percentByCustomer)
      ? (100 - percentByCustomer).toString()
      : "-";
  }

  switch (field.fieldType) {
    case "string":
    case "number":
      return (
        <TextField
          fullWidth
          size="small"
          label={field.fieldLabel}
          name={field.dbMapping}
          value={value !== undefined && value !== null ? value : ""}
          onChange={
            !isReadOnly
              ? (e) => {
                  const inputValue = e.target.value;
                  const finalValue =
                    field.fieldType === "number" && inputValue !== ""
                      ? Number(inputValue)
                      : inputValue;
                  handleWriteValue(handleChange, field.dbMapping, finalValue);
                }
              : undefined
          }
          type={field.fieldType === "number" ? "number" : "text"}
          margin="dense"
          variant="outlined"
          {...readOnlyProps}
        />
      );

    case "dropdown":
      if (field.isCountrySelect || field.dependsOn === "metadata.country") {
        return (
          <CountryStateSelect
            field={field}
            value={value}
            handleChange={handleChange}
            formData={formData}
            isReadOnly={isReadOnly}
          />
        );
      }
    case "dropdown multi":
      if (isReadOnly) {
        return (
          <TextField
            fullWidth
            size="small"
            label={field.fieldLabel}
            value={Array.isArray(value) ? value.join(", ") : value || ""}
            {...readOnlyProps}
            margin="dense"
            variant="outlined"
          />
        );
      }

      if (field.fieldType === "dropdown multi") {
        let arrayValue = Array.isArray(value)
          ? value.filter((v) => typeof v === "string")
          : typeof value === "string"
          ? value
              .split(",")
              .map((v) => v.trim())
              .filter(Boolean)
          : [];

        return (
          <FormControl fullWidth size="small" margin="dense">
            <Autocomplete
              multiple
              freeSolo
              options={field.dropdownOptions}
              value={arrayValue}
              onChange={(event, newValue) => {
                handleWriteValue(
                  handleChange,
                  field.dbMapping,
                  newValue || null
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={field.fieldLabel}
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
            />
          </FormControl>
        );
      } else {
        let dropdownValue = value !== undefined ? value : null;
        if (Array.isArray(dropdownValue) && dropdownValue.length === 1) {
          dropdownValue = dropdownValue[0];
        }
        return (
          <FormControl fullWidth size="small" margin="dense">
            <Autocomplete
              freeSolo
              options={field.dropdownOptions.filter(
                (option) => typeof option === "string"
              )}
              value={dropdownValue}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : ""
              }
              onChange={(event, newValue) => {
                handleWriteValue(
                  handleChange,
                  field.dbMapping,
                  newValue || null
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={field.fieldLabel}
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </FormControl>
        );
      }

    case "bool":
      if (isReadOnly) {
        return (
          <TextField
            fullWidth
            size="small"
            label={field.fieldLabel}
            value={value ? "Yes" : "No"}
            {...readOnlyProps}
            margin="dense"
            variant="outlined"
          />
        );
      }
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={value === true}
              onChange={(e) =>
                handleWriteValue(
                  handleChange,
                  field.dbMapping,
                  e.target.checked
                )
              }
              name={field.dbMapping}
              sx={{ pt: 2 }}
            />
          }
          label={field.fieldLabel}
        />
      );

    case "date":
      if (isReadOnly) {
        return (
          <TextField
            fullWidth
            size="small"
            label={field.fieldLabel}
            value={value || ""}
            {...readOnlyProps}
            margin="dense"
            variant="outlined"
          />
        );
      }
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={field.fieldLabel}
            value={value ? moment.utc(value) : null}
            onChange={(newValue) => {
              const formattedDate = newValue
                ? newValue.format("YYYY-MM-DD")
                : null;
              handleWriteValue(handleChange, field.dbMapping, formattedDate);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" margin="dense" fullWidth />
            )}
          />
        </LocalizationProvider>
      );

    case "file":
      const effectiveDbMapping = Array.isArray(field.dbMapping)
        ? field.dbMapping.find((key) => {
            const value = _.get(formData, key);
            return value !== null && value !== undefined;
          }) || field.dbMapping[0]
        : field.dbMapping;

      const currentFiles = Array.isArray(value) ? value : value ? [value] : [];

      const renderFileLink = (file) => {
        const truncateText = (text, maxLength) =>
          text.length <= maxLength
            ? text
            : text.substring(0, maxLength) + "...";

        return (
          <Box
            key={file.key}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                handleFileClick(file.key);
              }}
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
                color: "primary.main",
                "&:hover": {
                  textDecoration: "underline",
                },
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "90%",
              }}
              title={file.name}
            >
              {file.name ? truncateText(file.name, 25) : "View File"}
            </Link>
            {!isReadOnly && (
              <Button
                size="small"
                color="error"
                onClick={() => {
                  // Dispatch the delete action to the backend
                  // dispatch(
                  //   deleteFactoryFileStart({
                  //     organisationId: factoryData.organisationId,
                  //     supplierId: factoryData.supplierId,
                  //     factoryId: factoryData._id || factoryData.factoryId,
                  //     fileUrl: 'test_mvp/' + file.key,
                  //     fieldKey: effectiveDbMapping,
                  //   })
                  // );
                  // Open confirmation dialog instead of deleting immediately
                  setFileToDelete(file);
                  setDeleteDialogOpen(true);

                  // Update local state to remove the file from UI immediately
                  // const updatedFiles = currentFiles.filter(
                  //   (f) => f.key !== file.key
                  // );
                  // handleWriteValue(
                  //   handleChange,
                  //   effectiveDbMapping,
                  //   updatedFiles
                  // );
                }}
                sx={{ minWidth: "auto", p: 0.5 }}
              >
                ✕
              </Button>
            )}
          </Box>
        );
      };

      // Add a function to handle the confirmed deletion
      const handleConfirmDelete = () => {
        if (fileToDelete) {
          // Dispatch the delete action to the backend
          dispatch(
            deleteFactoryFileStart({
              organisationId: factoryData.organisationId,
              supplierId: factoryData.supplierId,
              factoryId: factoryData._id || factoryData.factoryId,
              fileUrl: "test_mvp/" + fileToDelete.key,
              fieldKey: effectiveDbMapping,
            })
          );

          // Update local state to remove the file from UI immediately
          const updatedFiles = currentFiles.filter(
            (f) => f.key !== fileToDelete.key
          );
          handleWriteValue(handleChange, effectiveDbMapping, updatedFiles);

          // Close the dialog
          setDeleteDialogOpen(false);
          setFileToDelete(null);
        }
      };

      return (
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
          <FormControl fullWidth size="small" margin="dense">
            <InputLabel shrink sx={{ backgroundColor: "white", px: 0.5 }}>
              {field.fieldLabel}
            </InputLabel>
            <Box
              component="div"
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                p: 1.5,
                minHeight: "40px",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {currentFiles.length > 0 ? (
                  currentFiles.map(renderFileLink)
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontStyle: "italic",
                    }}
                  >
                    No file uploaded
                  </Typography>
                )}
              </Box>
            </Box>
          </FormControl>
          {!isReadOnly && (
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 2, minWidth: "auto", p: 1 }}
              onClick={() =>
                setUploadDialogOpen({ open: true, fieldKey: field.dbMapping })
              }
            >
              <CloudUpload />
            </Button>
          )}
          {!isReadOnly && (
            <Dialog
              open={
                uploadDialogOpen.open &&
                uploadDialogOpen.fieldKey === field.dbMapping
              }
              onClose={() =>
                setUploadDialogOpen({ open: false, fieldKey: null })
              }
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>Upload Document</DialogTitle>
              <DialogContent>
                <DocumentUpload
                  field={field.fieldLabel}
                  fieldKey={effectiveDbMapping}
                  organisationId={factoryData?.organisationId}
                  supplierId={factoryData?.supplierId}
                  facilityId={factoryData?._id || factoryData?.factoryId}
                  isSupplierLevel={isSupplierLevel}
                  onClose={() =>
                    setUploadDialogOpen({ open: false, fieldKey: null })
                  }
                  onUploadSuccess={(uploadedFiles) => {
                    const updatedFiles = [...currentFiles, ...uploadedFiles];
                    handleWriteValue(
                      handleChange,
                      effectiveDbMapping,
                      updatedFiles
                    );
                  }}
                />
              </DialogContent>
            </Dialog>
          )}

          {/* Add Delete Confirmation Dialog */}
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this file?
              </DialogContentText>
              {fileToDelete && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "#f5f5f5",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                  }}
                >
                  <ListItemText primary={fileToDelete.name || "File"} />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleConfirmDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );

    case "date-month":
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            views={["year", "month"]}
            label={field.fieldLabel}
            value={value ? moment.utc(value) : null}
            onChange={(newValue) => {
              const formattedDate = newValue
                ? newValue.format("YYYY-MM")
                : null;
              handleWriteValue(handleChange, field.dbMapping, formattedDate);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                margin="dense"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      );

    case "none":
      return null;

    default:
      return null;
  }
};

export default RenderFormFields;
