import { createSelector } from "@reduxjs/toolkit";

const selectSupplier = (state) => state?.supplier || {};

export const selectSupplierList = createSelector(
  [selectSupplier],
  (supplier) => supplier?.suppliers || []
);

export const selectSearchResultsSupplierList = createSelector(
  [selectSupplier],
  (supplier) => supplier?.searchResults || []
);

export const selectLimitOffset = createSelector(
  [selectSupplier],
  (supplier) => {
    return { limit: supplier?.limit || 0, offset: supplier?.offset || 0 };
  }
);

export const selectSupplierCache = createSelector(
  [selectSupplier, (_, page) => page],
  (supplier, page) => {
    return supplier?.supplierCache?.[page] || null;
  }
);

export const selectTotalCount = createSelector([selectSupplier], (supplier) =>
  supplier?.count ? Math.ceil(supplier.count / 8) : 1
);

export const selectSearchCount = createSelector(
  [selectSupplier],
  (supplier) => supplier?.searchCount || 0
);

export const selectSupplierPageTab = createSelector(
  [selectSupplier],
  (supplier) => supplier?.supplierPageTab || null
);

export const selectSupplierData = createSelector(
  [selectSupplier],
  (supplier) => supplier?.supplierData?.[0] || null
);

export const selectSupplierTableUIConfig = createSelector(
  [selectSupplier],
  (supplier) => supplier?.ui || null
);

export const selectSupplierNameFromId = createSelector(
  [selectSupplier, (_, id) => id],
  (supplier, id) => {
    if (id && id !== "") {
      const suppliers = supplier?.suppliers || [];
      const supplierName = suppliers.filter(
        (supplier) => supplier?.supplierId?.toString() === id?.toString()
      );
      if (supplierName?.length > 0) {
        return supplierName[0]?.name || null;
      }
      const supplierData = supplier?.supplierData || [];
      if (supplierData?.length > 0) {
        return supplierData[0]?.name || null;
      }
    }
    return null;
  }
);

export const selectSupplierConfidentialInformation = createSelector(
  [selectSupplier],
  (supplier) => {
    const supplierData = supplier?.supplierData || [];
    if (supplierData?.length > 0) {
      const supplierMetadata = supplierData[0]?.metadata || {};
      return [
        [
          {
            heading: "Product Categories",
            value:
              !supplierMetadata.productCategories ||
              supplierMetadata.productCategories === ""
                ? "-"
                : supplierMetadata.productCategories,
            xs: 6,
          },
          {
            heading: "Top Product Group",
            value:
              !supplierMetadata.topProduct || supplierMetadata.topProduct === ""
                ? "-"
                : supplierMetadata.topProduct,
            xs: 6,
          },
        ],
        [
          {
            heading: "Spend",
            value:
              !supplierMetadata.spend || supplierMetadata.spend === ""
                ? "-"
                : `$${parseFloat(supplierMetadata.spend).toFixed(2)}`,
            xs: 6,
          },
        ],
        [
          {
            heading: "% of business from Saks",
            value:
              !supplierMetadata.percentageOfBusinessFrom4imprint ||
              supplierMetadata.percentageOfBusinessFrom4imprint === ""
                ? "-"
                : supplierMetadata.percentageOfBusinessFrom4imprint,
            xs: 6,
          },
        ],
        // [
        //   {
        //     heading: "4imprint Internal Note",
        //     value:
        //       !supplierMetadata.note ||
        //       supplierMetadata.note === ""
        //         ? "-"
        //         : supplierMetadata.note,
        //     xs: 12,
        //   },
        // ],
      ];
    }
    return [];
  }
);

export const selectSupplierConfidentialInformationReformation = createSelector(
  [selectSupplier],
  (supplier) => {
    return supplier.supplierData?.metadata?.internalStatusNotes || "";
  }
);

export const selectSupplierRemediationStatusData = createSelector(
  [selectSupplier],
  (supplier) => {
    const supplierData = supplier?.supplierData || [];
    if (supplierData?.length > 0) {
      const supplierMetadata = supplierData[0]?.metadata || {};
      const factoryData = supplierData[0]?.factoryData || {};
      const data = [
        {
          tier: 1,
          cards: [
            {
              title: "CAPs",
              details: [
                {
                  title: "Past Due",
                  value: factoryData?.pastDueCAPCount || "-",
                  key: "pastDue",
                },
                {
                  title: "Open",
                  value: factoryData?.openCAPCount || "-",
                  key: "openDue",
                },
              ],
            },
            {
              title: "Issues",
              details: [
                {
                  title: "ZT Issues",
                  value:
                    factoryData?.ztCount == null || factoryData?.ztCount == "-"
                      ? "-"
                      : factoryData?.ztCount > 0
                      ? factoryData?.ztCount
                      : 0,
                  key: "zt",
                },
                {
                  title: "Critical Issues",
                  value:
                    factoryData?.criticalCount == null ||
                    factoryData?.criticalCount == "-"
                      ? "-"
                      : factoryData?.criticalCount > 0
                      ? factoryData?.criticalCount
                      : 0,
                  key: "critical",
                },
              ],
            },
            {
              title: "Audits Due",
              details: [
                {
                  title: "Past Due",
                  value: supplierMetadata?.pastDue || "-",
                  key: "pastDue",
                },
                {
                  title: "In 30 days",
                  value: supplierMetadata?.in30 || "-",
                  key: "in30",
                },
                // {
                //   title: "In 60 days",
                //   value: supplierMetadata?.in60 || "-",
                //   key: "in60",
                // },
              ],
            },
          ],
        },
        {
          tier: 2,
          cards: [
            {
              title: "CAPs",
              details: [
                {
                  title: "Past Due",
                  value: supplierMetadata?.pastDue || "-",
                  key: "pastDue",
                },
                {
                  title: "Open",
                  value: supplierMetadata?.openDue || "-",
                  key: "openDue",
                },
              ],
            },
            {
              title: "Issues",
              details: [
                { title: "ZT Issues", value: "0", key: "zt" }, // TODO: Tech debt, remove hard coding
                { title: "Critical Issues", value: "0", key: "critical" }, // TODO: Tech debt, remove hard coding
              ],
            },
            {
              title: "Audits Due",
              details: [
                {
                  title: "Past Due",
                  value: supplierMetadata?.pastDue || "-",
                  key: "pastDue",
                },
                {
                  title: "In 30 days",
                  value: supplierMetadata?.in30 || "-",
                  key: "in30",
                },
                // {
                //   title: "In 60 days",
                //   value: supplierMetadata?.in60 || "-",
                //   key: "in60",
                // },
              ],
            },
          ],
        },
      ];
      return data;
    }
    return [];
  }
);

export const selectSupplierResources = createSelector(
  [selectSupplier],
  (supplier) => {
    return (
      supplier?.supplierData?.[0]?.resources?.reduce((acc, file) => {
        if (!acc[file.section]) {
          acc[file.section] = [];
        }
        acc[file.section].push(file);
        return acc;
      }, {}) || []
    );
  }
);

export const selectSupplierDropdownData = createSelector(
  [selectSupplier],
  (supplierState) => {
    return supplierState.suppliers.map((supplier) => ({
      supplierId: supplier?.supplierId,
      name: supplier?.name,
      facilityCount: supplier?.metadata?.facilityCount || 0, // Assuming facilityCount is in metadata
    }));
  }
);

// Add this new selector for SAQ suppliers
export const selectSAQSuppliers = (state) => state.supplier.saqSuppliers || [];
