import React from "react";
import { Box, Tabs, Tab, Tooltip, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectAuditPageTab } from "../../selectors/audit.selector";
import { setAuditPageTab } from "../../reducers/audit.reducer";
import { useTenant } from "../../context/TenantContext";

const AuditTabs = () => {
  const dispatch = useDispatch();
  const { auditConfig } = useTenant();

  const handleChange = (event, newValue) => {
    dispatch(setAuditPageTab(newValue));
  };
  const value = useSelector(selectAuditPageTab);
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1, px: 4 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        {auditConfig?.tabs.map(
          (tab) =>
            tab.display &&
            (tab.tooltipIdentifier ? (
              <Tab
                value={tab.tab}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>{tab.label}</span>

                    <Tooltip title={tab.tooltipIdentifier}>
                      <Chip
                        label="Beta"
                        size="small"
                        sx={{
                          backgroundColor: "#4C5ED9",
                          color: "white",
                          fontWeight: 500,
                          fontSize: "12px",
                          height: "24px",
                          ml: 1,
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textTransform: "none" }}
              />
            ) : (
              <Tab
                value={tab.tab}
                label={tab.label}
                sx={{ textTransform: "none" }}
              />
            ))
        )}
        {/* <Tab
          value="issues"
          label="Findings & CAP"
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="document"
          label="Supporting Documents"
          sx={{ textTransform: "none" }}
        />
        <Tab value="export" label="Export" sx={{ textTransform: "none" }} /> */}
      </Tabs>
    </Box>
  );
};

export default AuditTabs;
