import { useMemo, useEffect } from "react";
import { debounce } from "lodash";

/**
 * A hook to create a stable debounced callback function.
 * @param {Function} callback - The function you want to debounce.
 * @param {number} delay - Debounce time in ms.
 * @param {Array} deps - Dependency array.
 * @returns {Function} A debounced version of your callback.
 */
export const useDebouncedCallback = (callback, delay, deps = []) => {
  const debounced = useMemo(
    () => debounce(callback, delay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, delay, ...deps]
  );

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return debounced;
};
