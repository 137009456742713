import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateSupplierData } from "../../../reducers/supplier.reducer";

const SupplierInformationEditModal = ({
  open = false,
  onClose,
  supplierData,
}) => {
  const initData = {
    contactNote: getValidData(
      supplierData?.metadata?.contactPerson?.contactNote
    ),
    note: getValidData(supplierData?.metadata?.note),
  };
  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [supplierData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateSupplierData({
          supplierId: supplierData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900, // Increased width for the modal
          p: 6, // Increased padding for spaciousness
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 3,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography
            id="edit-supplier-modal"
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: 18,
              color: "#344054",
              mb: 4,
            }}
          >
            Edit Supplier Information
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Contact Information"
                name="contactNote"
                variant="outlined"
                value={formData.contactNote}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={6}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="StitchFix Note"
                name="note"
                variant="outlined"
                value={formData.note}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={8} // Slightly larger for more text
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default SupplierInformationEditModal;
