import React from "react";
import { Box, Grid, Divider } from "@mui/material";

import CapManagementDetailsLeftSideNavigationBar from "./capManagementDetailsLeftSideNavigationBar.component";
import CapManagementForm from "./capManagementForm.component";
import CapManagementDetailsAppBar from "./capManagementDetailsAppBar.component";

const CapManagementDetails = ({ handleDrawerClose }) => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* Fixed Header */}
      <Box sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}>
        <CapManagementDetailsAppBar handleDrawerClose={handleDrawerClose} />
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          overflow: "hidden", // Prevent double scrollbars
        }}
      >
        {/* Left Navigation */}
        <Box
          sx={{
            width: "23%",
            overflow: "auto",
            borderRight: "1px solid #E0E0E0",
            height: "100%",
          }}
        >
          <CapManagementDetailsLeftSideNavigationBar />
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            height: "100%",
          }}
        >
          <CapManagementForm />
        </Box>
      </Box>
    </Box>
  );
};

export default CapManagementDetails;
