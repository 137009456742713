import React, { useState } from "react";
import { Box, Typography, Button, Chip, Drawer, Skeleton } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useTenant } from "../context/TenantContext";

import { selectFactoryList } from "../selectors/factory.selector";
import { selectOrganisationId } from "../selectors/login.selector";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import CreateFacilityForm from "./createFacility.component";
import { AuditStyles } from "../styles";
import moment from "moment";
import { selectLoadingState } from "../selectors/misc.selector";

import { copyToClipboard } from "../utils/lib";

const FactoryTableDataGrid = ({
  displayInactiveFactories = false,
  navigateToFactoryDetails,
  getActionNeededData,
  organisationId,
}) => {
  const { factoryConfig, nomenclature } = useTenant();
  const location = useLocation();

  let factoriesList = useSelector(selectFactoryList);

  const isLoading = useSelector(selectLoadingState);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (
    factoryConfig?.dataGrid?.filterDataConditions?.filterConditionsAvailable
  ) {
    factoriesList = factoriesList.filter((factory) => {
      // Filter out hidden factories
      if (factory?.metadata?.entityType === "hide") {
        return false;
      }
      if (location.pathname === "/factories") {
        return factory?.metadata?.entityType !== "upstream";
      } else if (location.pathname === "/upstreamfactories") {
        return factory?.metadata?.entityType === "upstream";
      }
      return true; // Include all factories if mode is neither CMA nor upstream
    });
  }

  let rows = factoryConfig?.dataGrid?.onloadFilterCondition
    ? factoriesList.filter((factory) => {
        return !factory?.tier
          ?.toLowerCase()
          .includes(factoryConfig?.dataGrid?.onloadFilterCondition);
      })
    : factoriesList;

  rows = rows.map((factory) => {
    const actionNeededData = getActionNeededData(factory);
    const actionAlertTrigger = actionNeededData.triggers?.overallTriggered
      ? "Yes"
      : "No";

    return {
      id: factory?.factoryId,
      factoryName: factory?.name,
      factoryIdFromMetadata: factory?.factoryIdFromMetadata || "-",
      country: factory?.location || "-",
      tier: factory?.tier || "-",
      supplierId: factory?.supplierId || "-",
      supplierName: factory?.supplierName || "-",
      supplierIdFromMetadata: factory?.supplierIdFromMetadata || "-",
      complianceStatus: factory?.metadata?.complianceStatus || "-",
      businessIntensity:
        factory?.metadata?.processInfo?.businessIntensity || "Not Available",
      actionAlert: factory?.metadata?.actionAlert || "-",
      status: factory?.status || "-",
      productionStatus:
        factory?.metadata?.processInfo?.productionContractType || "-",
      vendorRating: factory?.metadata?.vendorRating || "Unrated",
      actionAlertTrigger: actionAlertTrigger,
      lastAuditDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate
        ? moment(
            factory.metadata.socialAssessmentInfo.lastAssessmentDate
          ).format("MMM. DD, YYYY")
        : "-",
      confirmedNextAuditDate: factory?.metadata?.socialAssessmentInfo
        ?.confirmedAuditDate
        ? moment(
            factory.metadata.socialAssessmentInfo.confirmedAuditDate
          ).format("MMM. DD, YYYY")
        : "-",
      factoryApprovalDate: factory?.metadata?.factoryApprovalDate
        ? moment(factory.metadata.factoryApprovalDate).format("YYYY-MM-DD")
        : "N/A",
      nextReportDate: factory?.metadata?.auditCadence?.nextAuditDate
        ? moment(factory.metadata.auditCadence.nextAuditDate).format(
            "YYYY-MM-DD"
          )
        : factory?.metadata?.nextReportDate
        ? moment(factory.metadata.nextReportDate).format("YYYY-MM-DD")
        : "N/A",
      brand: factory?.metadata?.productCategories || "",
      business: factory?.businessUnit || "",
      riskScore: factory?.riskScore,
      accountManager: factory?.productSupport?.name,
      region: factory?.metadata?.region,
      parentAccount: factory?.supplierName,

      inherentScore: factory?.inherentRisk || "",
      enhancedScore: factory?.auditScore ?? "",
      factoryStatusGore:
        factory?.metadata?.status === null || factory?.metadata?.status === ""
          ? "active"
          : factory?.metadata?.status,
      supplyChainStatus: factory?.metadata?.supplyChainStatus || "-",
    };
  });

  const columns = factoryConfig?.dataGrid?.columns?.map((col) => {
    if (col?.renderCell === "statusChip") {
      return {
        ...col,
        renderCell: (params) => {
          // Handle empty or missing values
          if (!params.value || params.value === "Unknown") {
            return (
              <Chip
                label="Deactivated"
                sx={{
                  borderRadius: "16px",
                  fontWeight: 500,
                  fontSize: 12,
                  backgroundColor: "#F2F4F7", // Gray for deactivated/empty
                  color: "#667085", // Gray text
                }}
              />
            );
          }
          
          // Check for both compliant and active statuses
          const status = params.value?.toLowerCase() || "";
          const isCompliant = status === "compliant";
          const isActive = status === "active";
          
          // Set colors based on status
          let backgroundColor = "#FEF0C7"; // Default yellow for non-compliant/non-active
          let textColor = "#B54708"; // Default amber text
          
          if (isCompliant || isActive) {
            backgroundColor = "#E6F4EA"; // Green for compliant/active
            textColor = "#1E8E3E"; // Green text
          }
          
          return (
            <Chip
              label={params.value}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor: backgroundColor,
                color: textColor,
              }}
            />
          );
        },
      };
    } else if (col?.renderCell === "statusChipRoots") {
      return {
        ...col,
        renderCell: (params) => {
          let backgroundColor = "#FCE8E8"; // Default red for inactive
          let textColor = "#D32F2F";

          const status = params.value?.toLowerCase() || "";

          if (status === "active") {
            backgroundColor = "#E6F4EA"; // Light green
            textColor = "#1E8E3E";
          } else if (status === "onboarding" || status === "Onboarding") {
            backgroundColor = "#FFF8E1"; // Light yellow
            textColor = "#F9A825";
          }
          return (
            <Chip
              label={params.value || "Inactive"}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor,
                color: textColor,
                textTransform: "capitalize",
              }}
            />
          );
        },
      };
    } else if (col?.renderCell === "alertChip") {
      return {
        ...col,
        renderCell: (params) => (
          <Chip
            label={params.value}
            sx={{
              borderRadius: "16px",
              fontWeight: 500,
              fontSize: 12,
              backgroundColor: params.value === "Yes" ? "#FECDCA" : "#F2F4F7",
              color: params.value === "Yes" ? "#B42318" : "#667085",
            }}
          />
        ),
      };
    } else if (col?.renderCell === "details") {
      return {
        ...col,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => {
              navigateToFactoryDetails(params.row.supplierId, params.row.id);
            }}
            sx={{
              backgroundColor: "#6172F3",
              color: "white",
              "&:hover": {
                backgroundColor: "#5667e2",
                fontWeight: "bold",
              },
              textTransform: "none",
              minWidth: "79px",
              height: "40px",
              borderRadius: "8px",
            }}
            size="small"
          >
            Details
          </Button>
        ),
      };
    } else if (col?.renderCell === "ratingChip") {
      return {
        ...col,
        renderCell: (params) => {
          const rating = params.value?.toLowerCase();
          let backgroundColor;
          let textColor = "#D32F2F";
          switch (rating) {
            case "green":
              backgroundColor = AuditStyles.auditRatingColors.green;
              break;
            case "yellow":
              backgroundColor = AuditStyles.auditRatingColors.yellow;
              break;
            case "red":
              backgroundColor = AuditStyles.auditRatingColors.red;
              break;
            case "orange":
              backgroundColor = AuditStyles.auditRatingColors.orange;
              break;
            case "satisfactory":
              backgroundColor = "#E6F4EA"; // Light green
              textColor = "#1E8E3E";
              break;
            case "needs major improvement":
              backgroundColor = "#FEF3E6"; // Light orange
              textColor = "#C65102";
              break;
            case "needs improvement":
              backgroundColor = "#FFF8E1"; // Light yellow
              textColor = "#F9A825";
              break;
            case "critical issues":
              backgroundColor = "#FCE8E8"; // Light red
              textColor = "#D32F2F";
              break;
            default:
              backgroundColor = "transparent";
          }
          return (
            <Box
              sx={{
                display: "inline-block",
                padding: "4px 8px",
                borderRadius: "4px",
                backgroundColor: backgroundColor,
                color: textColor,
              }}
            >
              {params.value || "-"}
            </Box>
          );
        },
      };
    } else if (col?.renderCell === "vendorRating") {
      return {
        ...col,
        renderCell: (params) => {
          const rating = params.value?.toLowerCase();
          let backgroundColor;
          if (rating?.includes("green")) {
            backgroundColor = AuditStyles.auditRatingColors.green;
          } else if (rating?.includes("yellow")) {
            backgroundColor = AuditStyles.auditRatingColors.yellow;
          } else if (rating?.includes("red")) {
            backgroundColor = AuditStyles.auditRatingColors.red;
          } else if (rating?.includes("orange")) {
            backgroundColor = AuditStyles.auditRatingColors.orange;
          } else {
            backgroundColor = "transparent";
          }
          return (
            <Box
              sx={{
                display: "inline-block",
                padding: "4px 8px",
                borderRadius: "4px",
                backgroundColor: backgroundColor,
                color: "black",
              }}
            >
              {params.value || "-"}
            </Box>
          );
        },
      };
    } else if (col?.renderCell === "actionAlertTrigger") {
      return {
        ...col,
        renderCell: (params) => {
          // Conditionally render Chip if value is "Yes" or "No"
          if (params.value === "Yes" || params.value === "No") {
            return (
              <Chip
                label={params.value}
                sx={{
                  borderRadius: "16px",
                  fontWeight: 500,
                  fontSize: 12,
                  backgroundColor:
                    params.value === "Yes" ? "#FECDCA" : "#F2F4F7",
                  color: params.value === "Yes" ? "#B42318" : "default",
                }}
              />
            );
          } else {
            // Render plain text for other values
            return <span>{params.value}</span>;
          }
        },
      };
    } else if (col?.renderCell === "businessIntensity") {
      return {
        ...col,
        renderCell: (params) => {
          let backgroundColor = "#F2F4F7"; // Default background
          let textColor = "#667085"; // Default text color
          const value = params.value || "Not Available";

          return (
            <Chip
              label={value}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor,
                color: textColor,
                textTransform: "capitalize",
              }}
            />
          );
        },
      };
    } else if (col?.renderCell === "ratingChipRoots") {
      return {
        ...col,
        renderCell: (params) => {
          const rating = params.value?.toLowerCase();
          let backgroundColor;
          let textColor = "#D32F2F";
          switch (rating) {
            case "green":
              backgroundColor = AuditStyles.auditRatingColors.green;
              break;
            case "yellow":
              backgroundColor = AuditStyles.auditRatingColors.yellow;
              break;
            case "red":
              backgroundColor = AuditStyles.auditRatingColors.red;
              break;
            case "orange":
              backgroundColor = AuditStyles.auditRatingColors.orange;
              break;
            case "satisfactory":
              backgroundColor = "#E6F4EA"; // Light green
              textColor = "#1E8E3E";
              break;
            case "needs major improvement":
              backgroundColor = "#FEF3E6"; // Light orange
              textColor = "#C65102";
              break;
            case "needs improvement":
              backgroundColor = "#FFF8E1"; // Light yellow
              textColor = "#F9A825";
              break;
            case "critical issues":
              backgroundColor = "#FCE8E8"; // Light red
              textColor = "#D32F2F";
              break;
            default:
              backgroundColor = "transparent";
          }
          return (
            <Chip
              label={params.value || "Inactive"}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor,
                color: textColor,
                textTransform: "capitalize",
              }}
            />
          );
        },
      };
    } else if (col?.renderCell === "actionAlert") {
      return {
        ...col,
        renderCell: (params) => {
          // Conditionally render Chip if value is "Yes" or "No"
          if (params.value === "Yes" || params.value === "No") {
            return (
              <Chip
                label={params.value}
                sx={{
                  borderRadius: "16px",
                  fontWeight: 500,
                  fontSize: 12,
                  backgroundColor:
                    params.value === "Yes" ? "#FECDCA" : "#F2F4F7",
                  color: params.value === "Yes" ? "#B42318" : "default",
                }}
              />
            );
          } else {
            // Render plain text for other values
            return <span>{params.value}</span>;
          }
        },
      };
    }
    return col;
  });

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ fontSize: 20, fontWeight: 600 }}>
            {nomenclature.factory.fieldNamePlural}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: 14, fontWeight: 400 }}
          >
            Manage your {nomenclature.factory.fieldNamePlural} here
          </Typography>
        </Box>
        <div>
          {factoryConfig?.buttons?.presourcingForm?.display && (
            <Button
              variant="outlined"
              onClick={() => copyToClipboard(organisationId, true)}
              sx={{
                color: "#6172F3",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#5667e2",
                },
                textTransform: "none",
                width: "230px",
                height: "40px",
                borderRadius: "8px",
                mr: "24px",
              }}
            >
              {factoryConfig?.buttons?.presourcingForm?.label}
              <img
                style={{ paddingLeft: 8 }}
                src={factoryConfig?.buttons?.presourcingForm?.icon}
                alt="arrow icon"
              />
            </Button>
          )}
          {factoryConfig?.buttons?.createNewFactory?.display && (
            <Button
              variant="outlined"
              onClick={() => setIsDrawerOpen(true)}
              sx={{
                color: "#6172F3",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#5667e2",
                },
                textTransform: "none",
                width: "180px",
                height: "40px",
                borderRadius: "8px",
                mr: "24px",
              }}
            >
              {factoryConfig?.buttons?.createNewFactory?.label}
            </Button>
          )}
        </div>
      </Box>

      <div style={{ width: "80vw", padding: "12px 0px" }}>
        <div
          style={{
            height: "calc(100vh - 250px)",
            width: "100%",
            overflowX: "auto",
          }}
        >
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
            <DataGridPro
              rows={rows}
              slots={{ toolbar: GridToolbar }}
              initialState={
                displayInactiveFactories
                  ? factoryConfig?.dataGrid?.inactiveFactoryTableInitialState
                  : factoryConfig?.dataGrid?.factoryTableInitialState
              }
              columns={columns}
              disableColumnSelector
              disableDensitySelector
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                borderRadius: "8px",
                padding: "16px",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                  cursor: factoryConfig?.dataGrid?.onRowClick
                    ?.navigateToFactoryDetails
                    ? "pointer"
                    : "default",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f0f0f0", // Light gray background
                  color: "#333", // Dark text color
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader": {
                  padding: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
              onRowClick={(params) => {
                if (
                  factoryConfig?.dataGrid?.onRowClick?.navigateToFactoryDetails
                ) {
                  return navigateToFactoryDetails(
                    params.row.supplierId,
                    params.row.id
                  );
                }
              }}
            />
          )}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            width: "40%",
          },
        }}
      >
        <CreateFacilityForm onClose={() => setIsDrawerOpen(false)} />
      </Drawer>
    </Box>
  );
};

export default FactoryTableDataGrid;
