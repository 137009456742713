import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { Country, State } from "country-state-city";

const CountryStateSelect = ({
  field,
  value,
  handleChange,
  formData,
  isReadOnly,
}) => {
  const [stateOptions, setStateOptions] = useState([]);

  // Add read-only styling similar to other form fields
  const readOnlyProps = isReadOnly
    ? {
        InputProps: {
          readOnly: true,
          disableunderline: "true",
          sx: {
            cursor: "default",
            "&:hover": {
              cursor: "default",
              backgroundColor: "transparent",
            },
            "& .MuiInputBase-input": {
              cursor: "default",
              "&:focus": {
                backgroundColor: "transparent",
                cursor: "default",
              },
            },
            "& fieldset": {
              borderColor: "#e0e0e0 !important",
              "&:hover": {
                borderColor: "#e0e0e0 !important",
              },
            },
          },
        },
        sx: {
          pointerEvents: "none",
          backgroundColor: "transparent",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "#e0e0e0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#e0e0e0",
            },
          },
        },
      }
    : {};

  useEffect(() => {
    // Get the parent path and field paths dynamically
    const parentPath = field.dependsOn?.split(".").slice(0, -1).join(".");
    const countryPath = field.isCountrySelect
      ? field.dbMapping
      : `${parentPath}.country`;
    const statePath = field.isCountrySelect
      ? `${parentPath}.state`
      : field.dbMapping;

    // Update state options when country changes
    if (!field.isCountrySelect) {
      const selectedCountryName = getValue(formData, countryPath);
      if (selectedCountryName) {
        const selectedCountry = Country.getAllCountries().find(
          (country) => country.name === selectedCountryName
        );
        if (selectedCountry) {
          const states = State.getStatesOfCountry(selectedCountry.isoCode);
          const options = states.map((state) => ({
            label: state.name,
            value: state.name,
          }));
          setStateOptions(options);

          // Validate current state selection
          const currentState = getValue(formData, statePath);
          const currentStateValid = states.some(
            (state) => state.name === currentState
          );

          if (!currentStateValid && currentState) {
            handleChange(statePath, null);
          }
        } else {
          setStateOptions([]);
          handleChange(statePath, null);
        }
      } else {
        setStateOptions([]);
        handleChange(statePath, null);
      }
    }
  }, [field, formData, handleChange]);

  // Helper function to get nested object value
  const getValue = (obj, path) => {
    if (!path || !obj) return null;
    return path.split(".").reduce((acc, part) => acc?.[part], obj);
  };

  const currentValue = value
    ? {
        label: value,
        value: value,
      }
    : null;

  const handleSelectionChange = (event, newValue) => {
    const newSelection = newValue?.label || null;
    const parentPath = field.dbMapping.split(".").slice(0, -1).join(".");

    if (field.isCountrySelect) {
      // Update country and clear corresponding state
      handleChange(field.dbMapping, newSelection);
      handleChange(`${parentPath}.state`, null);
    } else {
      // Update state only
      handleChange(field.dbMapping, newSelection);
    }
  };

  // Get parent path for checking if country is selected
  const getParentCountryPath = () => {
    const parts = field.dbMapping.split(".");
    parts.pop();
    return [...parts, "country"].join(".");
  };

  const getDropdownOptions = () => {
    if (field.isCountrySelect) {
      return Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.name,
      }));
    }
    return stateOptions;
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={getDropdownOptions()}
      value={currentValue}
      onChange={handleSelectionChange}
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.fieldLabel}
          variant="outlined"
          margin="dense"
          error={field.error}
          helperText={field.helperText}
          {...readOnlyProps}
        />
      )}
      disabled={
        !field.isCountrySelect && !getValue(formData, getParentCountryPath())
      }
      disableClearable={isReadOnly}
      forcePopupIcon={!isReadOnly}
      sx={{
        ...(isReadOnly && {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#e0e0e0",
            },
            "&:hover fieldset": {
              borderColor: "#e0e0e0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#e0e0e0",
            },
          },
        }),
      }}
    />
  );
};

export default CountryStateSelect;
