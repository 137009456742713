import React, { useState, useEffect } from 'react';
import { 
  Grid, 
  Box, 
  Typography, 
  TextField, 
  Autocomplete,
  Paper,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
  Divider,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CountryRiskCard from './countryRiskCard.component';
import countryRiskScores from '../../data/country_risk_scores.json';

const CountryRiskGrid = () => {
  const [selectedCountries, setSelectedCountries] = useState([
    'Vietnam', 
    'India', 
    'United States of America',
    'Eritrea'
  ]);
  const [searchValue, setSearchValue] = useState(null);
  const [showDataSources, setShowDataSources] = useState(true);
  const [openSourcesDialog, setOpenSourcesDialog] = useState(false);

  // Get all available country names from the data
  const allCountries = countryRiskScores.map(country => country.Country);

  // Filter country data for selected countries
  const filteredCountries = countryRiskScores.filter(country => 
    selectedCountries.includes(country.Country)
  );

  // Handle country selection
  const handleCountryChange = (event, newValue) => {
    if (newValue && !selectedCountries.includes(newValue)) {
      setSelectedCountries([...selectedCountries, newValue]);
      setSearchValue(null);
    }
  };

  // Handle removing a country
  const removeCountry = (countryName) => {
    setSelectedCountries(selectedCountries.filter(name => name !== countryName));
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
          Country Risk Scores
        </Typography>
        
        <Typography variant="body1" sx={{ color: '#667085', mb: 1 }}>
          Explore risk scores for countries based on labor rights, corruption, bribery, and forced labor metrics.
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 0.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            <Typography variant="body2" sx={{ color: '#667085', fontSize: '0.85rem' }}>
              Data sources:
            </Typography>
            
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
              <Box component="a" href="https://www.ituc-csi.org/" target="_blank" rel="noopener noreferrer" sx={{ color: '#6172F3', fontSize: '0.85rem', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                ITUC (Labor Rights)
              </Box>
              <Typography variant="body2" sx={{ color: '#667085', fontSize: '0.85rem' }}>•</Typography>
              
              <Box component="a" href="https://www.worldbank.org/" target="_blank" rel="noopener noreferrer" sx={{ color: '#6172F3', fontSize: '0.85rem', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                World Bank (Corruption)
              </Box>
              <Typography variant="body2" sx={{ color: '#667085', fontSize: '0.85rem' }}>•</Typography>
              
              <Box component="a" href="https://www.traceinternational.org/" target="_blank" rel="noopener noreferrer" sx={{ color: '#6172F3', fontSize: '0.85rem', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                TRACE Matrix (Bribery)
              </Box>
              <Typography variant="body2" sx={{ color: '#667085', fontSize: '0.85rem' }}>•</Typography>
              
              <Box component="a" href="https://www.walkfree.org/" target="_blank" rel="noopener noreferrer" sx={{ color: '#6172F3', fontSize: '0.85rem', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                Walk Free/ILO (Forced Labor)
              </Box>
            </Box>
          </Box>
          
          <Button 
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
            size="small"
            onClick={() => setOpenSourcesDialog(true)}
            sx={{ 
              textTransform: 'none',
              color: '#6172F3',
              fontSize: '0.85rem',
              minWidth: 'auto',
              py: 0.5
            }}
          >
            Request more sources
          </Button>
          
          {/* Dialog for requesting more data sources */}
          <Dialog
            open={openSourcesDialog}
            onClose={() => setOpenSourcesDialog(false)}
            aria-labelledby="sources-dialog-title"
            aria-describedby="sources-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="sources-dialog-title" sx={{ fontWeight: 600 }}>
              Additional Data Sources Available
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="sources-dialog-description">
                We have dozens more sources available for comprehensive risk assessment, including:
              </DialogContentText>
              <List sx={{ mt: 1 }}>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Box sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#6172F3' }} />
                  </ListItemIcon>
                  <ListItemText primary="Additional labor rights indicators from various NGOs" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Box sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#6172F3' }} />
                  </ListItemIcon>
                  <ListItemText primary="Environmental impact and compliance data" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Box sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#6172F3' }} />
                  </ListItemIcon>
                  <ListItemText primary="Industry-specific risk indicators" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Box sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#6172F3' }} />
                  </ListItemIcon>
                  <ListItemText primary="Geopolitical stability metrics" />
                </ListItem>
              </List>
              <DialogContentText sx={{ mt: 2 }}>
                Please reach out to our support team to add more data sources to your risk assessment dashboard.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Button 
                onClick={() => setOpenSourcesDialog(false)} 
                sx={{ 
                  textTransform: 'none',
                  color: '#6172F3'
                }}
              >
                Close
              </Button>
              <Button 
                variant="contained"
                onClick={() => setOpenSourcesDialog(false)}
                sx={{ 
                  textTransform: 'none',
                  bgcolor: '#6172F3',
                  '&:hover': {
                    bgcolor: '#4B5EE4'
                  }
                }}
              >
                Contact Support
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>

      <Paper
        sx={{
          p: 2,
          mb: 3,
          borderRadius: "8px",
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Autocomplete
          value={searchValue}
          onChange={handleCountryChange}
          options={allCountries.filter(country => !selectedCountries.includes(country))}
          sx={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search for a country"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Paper>

      <Grid container spacing={3}>
        {filteredCountries.map((country) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={country.Country}>
            <Box
              sx={{
                position: 'relative',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                  backgroundColor: '#F2F4F7',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: '#E4E7EC'
                  }
                }}
                onClick={() => removeCountry(country.Country)}
              >
                <Typography sx={{ fontSize: '16px', lineHeight: 1 }}>×</Typography>
              </Box>
              <CountryRiskCard country={country} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CountryRiskGrid;
