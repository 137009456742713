import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
} from "@mui/material";

import { ComponentStyles, FacilityStyles } from "../../styles";

const FactoryIssuesCardRoots = ({ latestAuditData }) => {
  // This is business logic that may be specific to Roots
  // Frontend calculation for finding summary based on specific categorization and severity levels
  const auditData = latestAuditData?.metadata?.capDetails || [];
  const findingsData = latestAuditData?.metadata?.findings;

  const issueSummary = [
    {
      category: "Health & Safety",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Environmental",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Business Ethics",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety, and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety & Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Compensation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Hours of Work",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Transparency & Business Integrity",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health, Safety and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Unsure",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Employment Relationship",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HSE",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ENV",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HOW",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Freedom of Association",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Nondiscrimination",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "C",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Health and Safety",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Code Commitment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Employer Regulation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Code Communication",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "CC - Code of Conduct",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HOW - Hours of Work",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ER - Employment Relationship",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "C - Compensation",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "HSE - Health, Safety and Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ENV - Environment",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "TRAN - Transparency",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "ND - Nondiscrimination",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "RCI - Remediation & Continuous Improvement",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "SUB - Subcontractors",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "H/A - Harassment or Abuse",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "FL - Forced Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "FOA - Freedom of Association and Collective Bargaining",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "CL - Child Labor",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
    {
      category: "Management Systems",
      zt: 0,
      critical: 0,
      major: 0,
      moderate: 0,
      minor: 0,
    },
  ];

  // Calculate issue summary with safe access
  if (auditData && auditData.length > 0) {
    auditData.forEach((issue) => {
      const categoryIndex = issueSummary.findIndex(
        (item) => item.category === issue.type
      );
      // console.log("categoryIndex", categoryIndex);
      if (categoryIndex !== -1) {
        switch (issue.severity?.toLowerCase()) {
          case "zt":
            issueSummary[categoryIndex].zt++;
            break;
          case "critical":
            issueSummary[categoryIndex].critical++;
            break;
          case "major":
            issueSummary[categoryIndex].major++;
            break;
          case "moderate":
            issueSummary[categoryIndex].moderate++;
            break;
          case "minor":
            issueSummary[categoryIndex].minor++;
            break;
          default:
            break;
        }
      }
    });
  }

  // If all counts are zero, check metadata findings
  const allCountsZero =
    !auditData ||
    auditData.length === 0 ||
    !issueSummary.some(
      (category) =>
        category.zt > 0 ||
        category.critical > 0 ||
        category.major > 0 ||
        category.moderate > 0 ||
        category.minor > 0
    );

  if (allCountsZero && findingsData) {
    // Add findings to the "Other" category since we don't have specific categorization
    const otherIndex = issueSummary.findIndex(
      (item) => item.category === "Other"
    );
    if (otherIndex !== -1) {
      // Handle Critical ZT findings if categories are specified
      if (findingsData.criticalZTFindingsCategories) {
        const categories = findingsData.criticalZTFindingsCategories
          .split(",")
          .map((cat) => cat.trim());
        categories.forEach((category) => {
          const categoryIndex = issueSummary.findIndex(
            (item) => item.category.toLowerCase() === category.toLowerCase()
          );
          if (categoryIndex !== -1) {
            issueSummary[categoryIndex].zt++;
          }
        });
      }

      // Regular findings go to "Other" category
      issueSummary[otherIndex].critical = findingsData.criticalFindings || 0;
      issueSummary[otherIndex].major = findingsData.majorFindings || 0;
      issueSummary[otherIndex].moderate = findingsData.moderateFindings || 0;
      issueSummary[otherIndex].minor = findingsData.minorFindings || 0;
    }
  }

  // Filter out categories with no issues
  const filteredIssueSummary = issueSummary.filter(
    (category) =>
      category.zt > 0 ||
      category.critical > 0 ||
      category.major > 0 ||
      category.moderate > 0 ||
      category.minor > 0
  );

  // Calculate total counts
  const totalCounts = filteredIssueSummary.reduce(
    (acc, category) => {
      acc.totalIssues =
        findingsData?.totalFindings ||
        category.zt +
          category.critical +
          category.major +
          category.moderate +
          category.minor;
      acc.totalZtIssues += category.zt;
      acc.totalCriticalIssues += category.critical;
      acc.totalMajorIssues += category.major;
      acc.totalModerateIssues += category.moderate;
      acc.totalMinorIssues += category.minor;
      return acc;
    },
    {
      totalIssues: 0,
      totalZtIssues: 0,
      totalCriticalIssues: 0,
      totalMajorIssues: 0,
      totalModerateIssues: 0,
      totalMinorIssues: 0,
    }
  );

  return (
    <Paper elevation={1} sx={FacilityStyles.issuesTablePaper}>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        {auditData?.auditor}
      </Typography>
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 700,
          color: "#344054",
          marginBottom: "10px",
        }}
      >
        Findings
      </Typography>
      <Divider sx={{ my: "12px" }} />
      <Box sx={{ height: 282, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "90px", // Fixed width
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography></Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  borderBottom: "2px solid #3538CD",
                  borderRight: "2px dotted #3538CD",
                  pb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Total
                    </Typography>
                  </Box>
                  <Box>{totalCounts.totalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#B42318",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>ZT</span>
                  <Box>{totalCounts.totalZtIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#FF692E",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Critical</span>
                  <Box>{totalCounts.totalCriticalIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#EAAA08",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Major</span>
                  <Box>{totalCounts.totalMajorIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#F79009",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Moderate</span>
                  <Box>{totalCounts.totalModerateIssues}</Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...FacilityStyles.issuesTableCell,
                  color: "#667085",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>Minor</span>
                  <Box>{totalCounts.totalMinorIssues}</Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredIssueSummary.map((issue, index) => {
              const issueCount =
                issue.zt +
                issue.critical +
                issue.major +
                issue.moderate +
                issue.minor;
              return issueCount > 0 ? (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      width: "90px", // Fixed width
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "normal",

                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    {issue.category.length > 20 ? (
                      <span>{issue.category}</span>
                    ) : (
                      issue.category
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issueCount ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      borderRight: "2px dotted #3538CD",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issueCount}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.zt ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.zt}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.critical ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.critical}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.major ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.major}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.moderate ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.moderate}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.minor ? "#45464E" : "#D0D5DD",
                      borderBottom: "none",
                      pb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {issue.minor}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default FactoryIssuesCardRoots;
