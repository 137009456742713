import moment from "moment";
import _ from "lodash";

export const isBrand = true;
export const getValidData = (data) => {
  return data === "-" || data == null ? "" : data;
};

export const checkInternalEmail = (email) => {
  return email.includes("esger") || email.includes("elm");
};

export const calculateAuditDueDataData = (timestamp) => {
  const currentDate = moment();
  const inputDate = moment(timestamp);
  const nextAuditDueDate = inputDate.clone().add(2, "years");
  const isPastDue = nextAuditDueDate.isBefore(currentDate);
  const daysUntilNextAudit = nextAuditDueDate.diff(currentDate, "days");

  return {
    lastAuditDate: inputDate.format("MMM. D, YYYY"),
    nextAuditDueDate: nextAuditDueDate.format("MMM. D, YYYY"),
    isPastDue,
    daysUntilNextAudit: isPastDue
      ? -Math.abs(daysUntilNextAudit)
      : daysUntilNextAudit,
  };
};

export const convertStatusToNormal = (status) => {
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const computeDueInDays = (date) => {
  return moment(date).diff(moment(), "days");
};

export const deepCloneWithDates = (obj) => {
  return _.cloneDeepWith(obj, (value) => {
    if (moment.isMoment(value)) {
      return value.format("YYYY-MM-DD");
    } else if (value instanceof Date) {
      return moment(value).format("YYYY-MM-DD");
    }
  });
};

export const getChangedPaths = (newObj, oldObj, basePath = "") => {
  let changes = {};

  Object.keys(newObj).forEach((key) => {
    const newValue = newObj[key];
    const oldValue = oldObj ? oldObj[key] : undefined;
    const path = basePath ? `${basePath}.${key}` : key;

    if (_.isEqual(newValue, oldValue)) {
      // Values are the same, no change detected
      return;
    }

    if (_.isObject(newValue) && newValue !== null && !Array.isArray(newValue)) {
      // Recursively find changes in nested objects
      const nestedChanges = getChangedPaths(newValue, oldValue, path);
      changes = { ...changes, ...nestedChanges };
    } else {
      // Direct value change
      changes[path] = newValue;
    }
  });

  return changes;
};

export const getValue = (obj, path, defaultValue = "") => {
  if (Array.isArray(path)) {
    for (const p of path) {
      const value = _.get(obj, p);
      if (
        value !== undefined &&
        value !== null &&
        !(typeof value === "object" && Object.keys(value).length === 0)
      ) {
        return value;
      }
    }
    return defaultValue;
  }

  const value = _.get(obj, path);

  if (value === undefined || value === null) {
    return defaultValue;
  } else if (
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length === 0
  ) {
    return defaultValue;
  } else if (Array.isArray(value) && value.length === 0) {
    return defaultValue;
  } else {
    return value;
  }
};

export function handleWriteValue(handleChange, dbMapping, newVal) {
  console.log("handleWriteValue", dbMapping, newVal);
  if (Array.isArray(dbMapping) && dbMapping.length > 0) {
    // Write only to the first path in the array
    handleChange(dbMapping[0], newVal);
  } else {
    // Single-string path scenario
    handleChange(dbMapping, newVal);
  }
}

export function getTooltipContent(field, formData) {
  if (!field.tooltip?.enabled) return null;

  const fieldValues = {};
  (field.tooltip.relatedFields || []).forEach((fieldPath) => {
    fieldValues[fieldPath] = _.get(formData, fieldPath, "N/A");
  });

  return field.tooltip.formatter ? field.tooltip.formatter(fieldValues) : null;
}

export const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const copyToClipboard = (
  organisationId,
  forFactory = false,
  forSupplier = false
) => {
  let url = "";
  if (forFactory) {
    url =
      "https://q-auditsense-1086719075712.us-central1.run.app/" +
      organisationId;
  } else if (forSupplier) {
    url =
      "https://q-auditsense-ref-supplier-1086719075712.us-central1.run.app/" +
      organisationId;
  }
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert("URL copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};
