import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { updateAuditData } from "../../reducers/audit.reducer";

import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Typography, TextField } from "@mui/material";
import { getValidData } from "../../utils/lib";
import { debounce } from "lodash";
import { ComponentStyles } from "../../styles";
import {
  selectAuditIssueDetails,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import { selectSaveStatus } from "../../selectors/misc.selector";
import { setSaveStatus } from "../../reducers/misc.reducer";

const InternalNote = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);

  const selectedIssueId = useSelector(selectSelectedAuditIssueId);

  const debouncedSaveNote = useCallback(
    debounce((newNote, issueId) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus]);
  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId]);
  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);
    debouncedSaveNote(newValue, selectedIssueId);
  };

  return (
    <TextField
      fullWidth
      multiline
      variant="outlined"
      value={note}
      onChange={handleNoteChange}
      helperText={
        saveStatus === "saving" ? (
          <Typography
            sx={{
              color: "error.main",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Saving...
          </Typography>
        ) : saveStatus === "saved" ? (
          <Box sx={ComponentStyles.alignBoxItemsCenter}>
            <Typography
              sx={{
                color: "success.main",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Saved
            </Typography>
            <CheckCircleOutline
              color="success"
              sx={{ ml: 1 }}
              fontSize="small"
            />
          </Box>
        ) : (
          ""
        )
      }
      FormHelperTextProps={{
        sx: {
          ml: 0, // Adjust the left margin as needed
        },
      }}
      inputProps={{
        style: {
          WebkitTextFillColor: "#45464E",
          color: "#45464E",
        },
      }}
    />
  );
};

export default InternalNote;
