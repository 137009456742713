import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Chip,
  Card,
  CardContent,
  Divider,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { FacilityStyles } from "../../../styles";
import { getQuestionnaires } from "../../../reducers/questionnaire.reducer";
import {
  selectQuestionnaires,
  selectQuestionnaireLoading,
  selectQuestionnaireError,
} from "../../../selectors/questionnaire.selector";
import QuestionnaireDetails from "./questionnaireDetails.component";

const FactoryQuestionnaires = ({ factoryId }) => {
  const dispatch = useDispatch();
  const questionnaires = useSelector(selectQuestionnaires);
  const loading = useSelector(selectQuestionnaireLoading);
  const error = useSelector(selectQuestionnaireError);

  // State for tracking expanded accordions and modal
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [filteredResponses, setFilteredResponses] = useState([]);

  // Fetch questionnaires on component mount
  useEffect(() => {
    dispatch(getQuestionnaires());
  }, [dispatch]);

  const getNestedValue = (obj, path) => {
    if (!obj || !path) return null;

    return path.split(".").reduce((prev, curr) => {
      return prev && prev[curr] !== undefined ? prev[curr] : null;
    }, obj);
  };

  const getMetadataValue = (response, sourceField) => {
    if (sourceField === "fullName") {
      return (
        `${response.response?.firstName || ""} ${
          response.response?.lastName || ""
        }`.trim() || "N/A"
      );
    }

    const value = getNestedValue(response.response, sourceField);
    return value || "N/A";
  };

  // Filter responses by facility and organize them
  useEffect(() => {
    if (questionnaires && factoryId) {
      // Process all questionnaires to extract responses for this facility
      const facilityResponses = [];

      questionnaires?.forEach((questionnaire) => {
        const matchingResponses = questionnaire.responses?.filter(
          (response) =>
            response.response?.factoryId === factoryId ||
            response.response?.factoryId === factoryId
        );

        if (matchingResponses && matchingResponses.length > 0) {
          // Sort responses by date (newest first)
          const sortedResponses = [...matchingResponses].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA; // Descending order (newest first)
          });

          // Group responses by questionnaire
          facilityResponses.push({
            questionnaire,
            responses: sortedResponses,
          });
        }
      });

      // Set expanded state for the latest response of each questionnaire
      setFilteredResponses(facilityResponses);

      if (
        facilityResponses.length > 0 &&
        facilityResponses[0].responses.length > 0
      ) {
        // Set the first (newest) questionnaire's response ID as expanded
        setExpandedAccordion(facilityResponses[0].responses[0]._id);
      }
    }
  }, [questionnaires, factoryId]);

  // Format date helper
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM dd, yyyy");
    } catch (e) {
      return "Invalid Date";
    }
  };

  // Handle accordion expansion toggle
  const handleAccordionChange = (responseId) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? responseId : null);
  };

  // View full response details
  const viewFullResponse = (response, questionnaire) => {
    setSelectedResponse(response);
    setSelectedQuestionnaire(questionnaire);
    setDetailsOpen(true);
  };

  // Close details modal
  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  // Extract highlight data from response (customize based on your rules)
  const getResponseHighlights = (response, questionnaire) => {
    const highlights = [];

    if (!response.response || !questionnaire) return highlights;

    // Helper function to evaluate highlight conditions
    const evaluateCondition = (condition, responseData) => {
      try {
        // Create a function that takes responseData as parameter and evaluates the condition
        // Note: the parameter name must match what's used in the condition
        return new Function(
          "response",
          `return response.response.${condition}`
        )(response);
      } catch (error) {
        console.error("Error evaluating highlight condition:", error);
        return false;
      }
    };

    // Helper function to extract question data from questionnaire structure
    const extractQuestions = (steps) => {
      const questions = [];

      if (!steps) return questions;

      steps.forEach((step) => {
        if (step.subsections) {
          step.subsections.forEach((subsection) => {
            if (subsection.rows) {
              subsection.rows.forEach((row) => {
                if (row.columns) {
                  row.columns.forEach((column) => {
                    if (column.questions) {
                      questions.push(...column.questions);
                    }
                  });
                }
              });
            }
          });
        }
      });

      return questions;
    };

    // Parse schema if needed or use as is if already parsed
    let schemaSteps;
    try {
      // Try to parse the schema if it's a string
      schemaSteps =
        typeof questionnaire.schema === "string"
          ? JSON.parse(questionnaire.schema).steps
          : questionnaire.schema.steps;
    } catch (error) {
      console.error("Error parsing questionnaire schema:", error);
      return highlights;
    }

    // Get all questions from the questionnaire
    const allQuestions = extractQuestions(schemaSteps);

    // Evaluate highlight conditions for each question
    allQuestions.forEach((question) => {
      if (question.highlightCondition) {
        // Get the field path (e.g., "fireSafetyEquipmentInstalled.response")
        const fieldPath = question.field;

        // If no field path is defined, skip this question
        if (!fieldPath) return;

        // Evaluate the highlight condition against response.response
        const shouldHighlight = evaluateCondition(
          question.highlightCondition,
          response.response
        );

        if (shouldHighlight) {
          // Get field name without .response suffix for explanation field
          const baseField = fieldPath.endsWith(".response")
            ? fieldPath.substring(0, fieldPath.length - 9)
            : fieldPath;

          const explanationField = `${baseField}.notes`;

          highlights.push({
            question: question.label || "Unlabeled Question",
            response: getNestedValue(response.response, fieldPath) || "N/A",
            explanation:
              getNestedValue(response.response, explanationField) || "-",
          });
        }
      }
    });

    return highlights;
  };

  // Render meta data section - customize based on your questionnaire structure
  const renderMetaData = (response) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" color="textSecondary">
            Respondent
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            {response.response?.firstName || "N/A"}{" "}
            {response.response?.lastName || ""}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" color="textSecondary">
            Site
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            {response.response?.factoryName || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" color="textSecondary">
            Email
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            {response.response?.email || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="caption" color="textSecondary">
            Phone
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            {response.response?.phone || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {filteredResponses.length > 0 ? (
        filteredResponses?.map((item) => (
          <Box key={item.questionnaire._id} sx={{ mb: 4 }}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
              {item.questionnaire.title || "Untitled Questionnaire"}
            </Typography>

            {item?.responses?.map((response, index) => (
              <Accordion
                key={response._id}
                expanded={expandedAccordion === response._id}
                onChange={handleAccordionChange(response._id)}
                sx={{
                  mb: 2,
                  borderRadius: "12px !important",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
                  border: "1px solid #E5E7EB",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    minHeight: "80px",
                    padding: "24px 32px",
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                      width: "100%",
                    },
                    "&.Mui-expanded": {
                      position: "relative",
                      minHeight: "80px",
                      margin: 0,
                      padding: "24px 32px",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "32px",
                        right: "32px",
                        height: "1px",
                        backgroundColor: "#E5E7EB",
                      },
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ mb: 0.5 }}
                      >
                        Date
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="700"
                        sx={{ color: "#1543E5" }}
                      >
                        {formatDate(response.createdAt)}
                      </Typography>
                    </Box>

                    {/* <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '40px', alignSelf: 'center' }} />

                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ mb: 0.5 }}
                      >
                        Respondent
                      </Typography>
                      <Typography variant="body2" fontWeight="medium">
                        {response.response?.firstName || "N/A"}{" "}
                        {response.response?.lastName || ""}
                      </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ mb: 0.5 }}
                      >
                        MetaData 2
                      </Typography>
                      <Typography variant="body2" fontWeight="medium">
                        {response.response?.factoryId || "N/A"}
                      </Typography>
                    </Box> */}

                    {/* Only show divider if there are metadata fields */}
                    {item.questionnaire.metaField1 && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 2, height: "40px", alignSelf: "center" }}
                      />
                    )}

                    {/* Dynamic metadata fields */}
                    {item.questionnaire.metaField1 && (
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ mb: 0.5 }}
                        >
                          {item.questionnaire.metaField1.title || "Field 1"}
                        </Typography>
                        <Typography variant="body2" fontWeight="medium">
                          {getMetadataValue(
                            response,
                            item.questionnaire.metaField1.sourceField
                          )}
                        </Typography>
                      </Box>
                    )}

                    {item.questionnaire.metaField2 && (
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ mb: 0.5 }}
                        >
                          {item.questionnaire.metaField2.title || "Field 2"}
                        </Typography>
                        <Typography variant="body2" fontWeight="medium">
                          {getMetadataValue(
                            response,
                            item.questionnaire.metaField2.sourceField
                          )}
                        </Typography>
                      </Box>
                    )}

                    {item.questionnaire.metaField3 && (
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ mb: 0.5 }}
                        >
                          {item.questionnaire.metaField3.title || "Field 3"}
                        </Typography>
                        <Typography variant="body2" fontWeight="medium">
                          {getMetadataValue(
                            response,
                            item.questionnaire.metaField3.sourceField
                          )}
                        </Typography>
                      </Box>
                    )}

                    <Box>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          mx: 2,
                          borderRadius: "24px",
                          textTransform: "none",
                          color: "#111927",
                          borderColor: "#E5E7EB",
                          "&:hover": {
                            borderColor: "#D1D5DB",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          viewFullResponse(response, item.questionnaire);
                        }}
                      >
                        Full Response
                      </Button>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails sx={{ px: 4, pt: 3, pb: 0 }}>
                  <Typography variant="subtitle2" fontWeight="700" gutterBottom>
                    Points of Concert from Risk Questionnaire
                  </Typography>

                  <Card
                    sx={{
                      borderRadius: "12px",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent sx={{ p: 0 }}>
                      {getResponseHighlights(response, item.questionnaire).map(
                        (highlight, idx) => (
                          <React.Fragment key={idx}>
                            <Box sx={{ pr: 2, py: 3 }}>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={1}
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    pt: 1,
                                    pr: 1,
                                    pl: 1,
                                    pb: 0,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "50%",
                                      border: "1.5px solid #E5E7EB",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      fontWeight="medium"
                                    >
                                      {idx + 1}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={11}>
                                  <Typography
                                    variant="body2"
                                    fontWeight="medium"
                                    sx={{ mb: 2 }}
                                  >
                                    {highlight.question}
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={2}>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{ mb: 0.5 }}
                                      >
                                        Response
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        {highlight.response}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{ mb: 0.5 }}
                                      >
                                        Explanation
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        {highlight.explanation}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                            {idx <
                              getResponseHighlights(response).length - 1 && (
                              <Divider sx={{ ml: "7%" }} />
                            )}
                          </React.Fragment>
                        )
                      )}

                      {getResponseHighlights(response, item.questionnaire)
                        .length === 0 && (
                        <Box sx={{ p: 3, textAlign: "center" }}>
                          <Typography color="textSecondary">
                            No highlights available for this response
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ))
      ) : (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Typography color="textSecondary">
            No questionnaire responses found for this facility
          </Typography>
        </Box>
      )}

      {/* Reuse QuestionnaireDetails modal for viewing full responses */}
      <QuestionnaireDetails
        open={detailsOpen}
        handleClose={handleCloseDetails}
        response={selectedResponse}
        questionnaire={selectedQuestionnaire}
      />
    </Box>
  );
};

export default FactoryQuestionnaires;
