import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { useTenant } from "../../context/TenantContext";

const FactoryDetails = () => {
  const { auditConfig } = useTenant();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);

  const dataToIdentifierMapping = {
    factoryName: selectedFactoryData?.name || "-",
    address: selectedFactoryData?.metadata?.address || "-",
    auditEndDate: selectedAuditData?.metadata?.auditEndDate || "-",
    auditorName: selectedAuditData?.auditor || "-",
    auditFirm: selectedAuditData?.metadata?.auditFirm || "-",
    auditScheme: selectedAuditData?.metadata?.auditType || "-",
    auditScore: selectedAuditData?.risk?.auditScore || "-",
  };

  return (
    <List sx={{ width: "100%", pl: 3, pr: 1 }}>
      {auditConfig?.findingDetailsSection?.leftSideNavigation?.tabsSection?.info?.fields?.map(
        (component) => (
          <ListItem key={component.identifier}>
            <ListItemText
              primary={component.fieldName}
              secondary={dataToIdentifierMapping[component.identifier]}
              primaryTypographyProps={{
                style: {
                  color: "#475467",
                  fontSize: 16,
                  fontWeight: 700,
                },
              }}
              secondaryTypographyProps={{
                style: {
                  color: "#45464E",
                  fontSize: 16,
                  fontWeight: 400,
                },
              }}
            />
          </ListItem>
        )
      )}
    </List>
  );
};

export default FactoryDetails;
