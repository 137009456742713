import { createSelector } from "@reduxjs/toolkit";

const selectUser = (state) => state.user;

export const selectUsers = createSelector([selectUser], (user) => user.users);

export const selectUserLoading = createSelector(
  [selectUser],
  (user) => user.loading
);

export const selectUserError = createSelector(
  [selectUser],
  (user) => user.error
);
