import React from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";

import CustomChipLabel from "../AuditDetails/customChipLabel.component";
import { AuditStyles, ComponentStyles } from "../../styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTenant } from "../../context/TenantContext";

const RecommendationsList = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);

const AuditCapDetailsStatusOpen = ({ data, currentStep }) => {
  const { auditConfig } = useTenant();

  const fieldsToIdentifierMapping = {
    rootCause: data?.rootCause ? String(data.rootCause).split("\n") : [],
    immediateCAPPlan: data?.capRecommendations?.immediateCAP
      ? String(data?.capRecommendations?.immediateCAP).split("\n")
      : [],
    longTermPreventativeActionPlan: data?.capRecommendations
      ?.longTermPreventativeAction
      ? String(data?.capRecommendations?.longTermPreventativeAction).split("\n")
      : [],
    accountablePerson:
      data?.responsiblePerson ||
      (data?.status === "SITE_ADDING_ACTION_PLAN" ||
      data?.status === "SITE_ADDED_ACTION_PLAN"
        ? `${auditConfig?.nomenclature?.factory?.fieldNameSingular} filling this section`
        : `To be filled by ${auditConfig?.nomenclature?.factory?.fieldNameSingular}`),
    targetDeadline: data?.targetDeadline || "-",
    rootCauseResponse:
      data?.rootCauseResponse ||
      (data?.status === "SITE_ADDING_ACTION_PLAN" ||
      data?.status === "SITE_ADDED_ACTION_PLAN"
        ? `${auditConfig?.nomenclature?.factory?.fieldNameSingular} filling this section`
        : `To be filled by ${auditConfig?.nomenclature?.factory?.fieldNameSingular}`),
    immediateCAP:
      data?.immediateCorrectiveActionPlanFacilityInput ||
      (data?.status === "SITE_ADDING_ACTION_PLAN" ||
      data?.status === "SITE_ADDED_ACTION_PLAN"
        ? `${auditConfig?.nomenclature?.factory?.fieldNameSingular} filling this section`
        : `To be filled by ${auditConfig?.nomenclature?.factory?.fieldNameSingular}`),
    longTermPreventativeAction:
      data?.longTermPreventativeActionPlanFacilityInput ||
      (data?.status === "SITE_ADDING_ACTION_PLAN" ||
      data?.status === "SITE_ADDED_ACTION_PLAN"
        ? `${auditConfig?.nomenclature?.factory?.fieldNameSingular} filling this section`
        : `To be filled by ${auditConfig?.nomenclature?.factory?.fieldNameSingular}`),
    actualCompletionDate: data?.completionDate || "-",
    verificationNote: data?.verificationNote || "-",
    documentationRequirements: data?.documentationRequirements || "-",
    manufacturerComment:
      data?.manufacturerComment ||
      `To be filled by ${auditConfig?.nomenclature?.factory?.fieldNameSingular}`,
  };

  return (
    <Grid container spacing={2}>
      {/* CAP Recommendation Section */}
      <Grid
        item
        xs={
          !auditConfig?.findingDetailsSection?.components?.factoryComments
            ? currentStep === 1
              ? 12
              : 6
            : 6
        }
        sx={{ pr: 4 }}
      >
        <Typography
          sx={
            AuditStyles.auditFindingDetailsCapRecommendationAndFactoryCommentsHeader
          }
        >
          {
            auditConfig?.findingDetailsSection?.fields
              ?.capRecommendationSectionHeader?.fieldName
          }
        </Typography>

        {auditConfig?.findingDetailsSection?.fields?.capRecommendationFields?.map(
          (field) => (
            <AiGeneratedField label={field.fieldName}>
              <RecommendationsList
                title=""
                items={fieldsToIdentifierMapping[field.identifier]}
              />
            </AiGeneratedField>
          )
        )}
      </Grid>
      {auditConfig?.findingDetailsSection?.components?.factoryComments && (
        <Grid item xs={6}>
          <Typography
            sx={
              AuditStyles.auditFindingDetailsCapRecommendationAndFactoryCommentsHeader
            }
          >
            {
              auditConfig?.findingDetailsSection?.fields
                ?.factoryCommentsSectionHeader?.fieldName
            }
          </Typography>

          <Grid container spacing={1}>
            {auditConfig?.findingDetailsSection?.fields?.factoryCommentsFields?.map(
              (field) => (
                <Grid item xs={field.width}>
                  <Typography
                    sx={
                      AuditStyles.auditFindingDetailsFactoryCommentsFieldTitle
                    }
                  >
                    {field.fieldName}
                  </Typography>
                  <Typography
                    sx={
                      AuditStyles.auditFindingDetailsFactoryCommentsFieldValues
                    }
                  >
                    {fieldsToIdentifierMapping[field.identifier]}
                  </Typography>
                </Grid>
              )
            )}
          </Grid>

          <Typography
            sx={
              AuditStyles.auditFindingDetailsCapRecommendationAndFactoryCommentsHeader
            }
          >
            {
              auditConfig?.findingDetailsSection?.fields?.capUpdateSectionHeader
                ?.fieldName
            }
          </Typography>

          <Grid container spacing={2}>
            {auditConfig?.findingDetailsSection?.fields?.capUpdateFields?.map(
              (field) => (
                <Grid item xs={field.width}>
                  <Typography
                    sx={
                      AuditStyles.auditFindingDetailsFactoryCommentsFieldTitle
                    }
                  >
                    {field.fieldName}
                  </Typography>

                  {field.identifier === "capUpdate" ? (
                    <Box sx={ComponentStyles.alignBoxItemsStart}>
                      <CustomChipLabel
                        cap={data.statusChipLabel}
                        capLabel={data.status}
                      />
                      <Box sx={{ minWidth: "8px" }}></Box>
                      {data.status &&
                        !data.status.includes("Closed") &&
                        data.timeline && (
                          <CustomChipLabel
                            cap={"default"}
                            capLabel={data.timeline}
                          />
                        )}
                    </Box>
                  ) : (
                    <Typography
                      sx={
                        AuditStyles.auditFindingDetailsFactoryCommentsFieldValues
                      }
                    >
                      {fieldsToIdentifierMapping[field.identifier]}
                    </Typography>
                  )}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AuditCapDetailsStatusOpen;
