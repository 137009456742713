import React, { useState } from "react";
import { Box, Button, Modal, Card, Grid } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ListOfAuditDocuments from "./listOfAuditDocuments.component";
import AuditDocumentDrop from "./auditDocumentDrop.component";
import { useTenant } from "../../context/TenantContext";

const AuditSupportingDocuments = ({
  organisationId,
  supplierId,
  factoryId,
  auditId,
}) => {
  const { auditConfig } = useTenant();
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = useState("supporting-documents");
  const [type, setType] = useState("supporting-document");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUploadClick = (modeValue, fileTypeValue) => {
    setMode(modeValue);
    setType(fileTypeValue);
    handleOpen();
  };
  const UploadButton = ({ label, mode, fileType }) => (
    <Button
      variant="outlined"
      onClick={() => handleUploadClick(mode, fileType)}
      startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
      sx={{
        color: "#3538CD",
        fontSize: "14px",
        fontWeight: 600,
        padding: "8px 12px",
        borderRadius: "8px",
        border: "1px solid #A4BCFD",
        background: "#FFF",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        textTransform: "none",
        minWidth: "150px", // Ensures buttons have a consistent width
      }}
    >
      {label}
    </Button>
  );
  return (
    <>
      {auditConfig?.tabsSection?.documents?.upload?.display && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(234, 236, 240, 0.5)",
            padding: "8px 16px",
            borderRadius: "8px",
            mb: 2,
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#344054",
              borderRadius: "8px",
              padding: "10px 14px",
              border: "1px solid var(--Gray-3000, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "none",
            }}
            onClick={handleOpen}
            startIcon={<UploadFileIcon />}
          >
            Upload Document
          </Button>
        </Box>
      )}
      <ListOfAuditDocuments includeType="document" />

      {/* Subsection 2: SDS */}
      {auditConfig?.tabsSection?.documents?.supplementalDataSheet?.display && (
        <Box sx={{ mb: 0, ml: 2 }}>
          <Grid container alignItems="flex-start" spacing={0}>
            {/* Left Side: Header and Document List */}
            <Grid item xs={12} sm={6}>
              <ListOfAuditDocuments
                includeType={
                  auditConfig?.tabsSection?.documents?.supplementalDataSheet
                    ?.includeType
                }
                header={
                  auditConfig?.tabsSection?.documents?.supplementalDataSheet
                    ?.label
                }
              />
            </Grid>

            {/* Right Side: Upload Button */}
            <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
              <UploadButton
                label={
                  auditConfig?.tabsSection?.documents?.supplementalDataSheet
                    ?.button
                }
                mode={
                  auditConfig?.tabsSection?.documents?.supplementalDataSheet
                    ?.mode
                }
                fileType={
                  auditConfig?.tabsSection?.documents?.supplementalDataSheet
                    ?.fileType
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Subsection 3: Photo Form */}
      {auditConfig?.tabsSection?.documents?.photoForm?.display && (
        <Box sx={{ mb: 0, ml: 2 }}>
          <Grid container alignItems="flex-start" spacing={0}>
            {/* Left Side: Header and Document List */}
            <Grid item xs={12} sm={6}>
              <ListOfAuditDocuments
                includeType={
                  auditConfig?.tabsSection?.documents?.photoForm?.includeType
                }
                header={auditConfig?.tabsSection?.documents?.photoForm?.label}
              />
            </Grid>

            {/* Right Side: Upload Button */}
            <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
              <UploadButton
                label={auditConfig?.tabsSection?.documents?.photoForm?.button}
                mode={auditConfig?.tabsSection?.documents?.photoForm?.mode}
                fileType={
                  auditConfig?.tabsSection?.documents?.photoForm?.fileType
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Subsection 4: Worker Survey (WSS) */}
      {auditConfig?.tabsSection?.documents?.workerSurvey?.display && (
        <Box sx={{ mb: 0, ml: 2 }}>
          <Grid container alignItems="flex-start" spacing={0}>
            {/* Left Side: Header and Document List */}
            <Grid item xs={12} sm={6}>
              <ListOfAuditDocuments
                includeType={
                  auditConfig?.tabsSection?.documents?.workerSurvey?.includeType
                }
                header={
                  auditConfig?.tabsSection?.documents?.workerSurvey?.label
                }
              />
            </Grid>

            {/* Right Side: Upload Button */}
            <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
              <UploadButton
                label={
                  auditConfig?.tabsSection?.documents?.workerSurvey?.button
                }
                mode={auditConfig?.tabsSection?.documents?.workerSurvey?.mode}
                fileType={
                  auditConfig?.tabsSection?.documents?.workerSurvey?.fileType
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Subsection 5: CAP */}
      {auditConfig?.tabsSection?.documents?.capForRegrade?.display && (
        <Box sx={{ mb: 0, ml: 2 }}>
          <Grid container alignItems="flex-start" spacing={0}>
            {/* Left Side: Header and Document List */}
            <Grid item xs={12} sm={6}>
              <ListOfAuditDocuments
                includeType={
                  auditConfig?.tabsSection?.documents?.capForRegrade
                    ?.includeType
                }
                header={
                  auditConfig?.tabsSection?.documents?.capForRegrade?.label
                }
              />
            </Grid>

            {/* Right Side: Upload Button */}
            <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
              <UploadButton
                label={
                  auditConfig?.tabsSection?.documents?.capForRegrade?.button
                }
                mode={auditConfig?.tabsSection?.documents?.capForRegrade?.mode}
                fileType={
                  auditConfig?.tabsSection?.documents?.capForRegrade?.fileType
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Subsection 6: Other */}
      {auditConfig?.tabsSection?.documents?.other?.display && (
        <Box sx={{ mb: 0, ml: 2 }}>
          <Grid container alignItems="flex-start" spacing={0}>
            {/* Left Side: Header and Document List */}
            <Grid item xs={12} sm={6}>
              <ListOfAuditDocuments
                includeType={
                  auditConfig?.tabsSection?.documents?.other?.includeType
                }
                header={auditConfig?.tabsSection?.documents?.other?.label}
              />
            </Grid>

            {/* Right Side: Upload Button */}
            <Grid item xs={12} sm={4} sx={{ mt: 3 }}>
              <UploadButton
                label={auditConfig?.tabsSection?.documents?.other?.button}
                mode={auditConfig?.tabsSection?.documents?.other?.mode}
                fileType={auditConfig?.tabsSection?.documents?.other?.fileType}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            organisationId={organisationId}
            supplierId={supplierId}
            factoryId={factoryId}
            auditId={auditId}
            onClose={handleClose}
            mode="something"
          />
        </Card>
      </Modal>
    </>
  );
};

export default AuditSupportingDocuments;
