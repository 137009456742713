import { all, put, select, takeLatest } from "redux-saga/effects";

import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import {
  getListOfAllAudits,
  getAuditById,
  setAuditData,
  setListOfAllAudits,
  updateAuditData,
  setAuditIssueCAPData,
} from "../reducers/audit.reducer";
import { logout } from "../reducers/login.reducer";

function* getListOfAuditsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { limit, offset, supplierId } = action.payload;
  const requestURL = API.AUDITS.LIST.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = { limit, offset };

  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(
          setListOfAllAudits({
            audits: response.data.results.audits,
            auditCount: response.data.results.auditCount,
          })
        );
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getAuditByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.GET_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));

        yield put(setAuditIssueCAPData(response.data.results.capDetails));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateAuditDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.UPDATE_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ...action.payload.changes,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* watchAuditSaga() {
  yield takeLatest(getListOfAllAudits.type, getListOfAuditsWorker);
}

function* watchAuditByIdSaga() {
  yield takeLatest(getAuditById.type, getAuditByIdWorker);
}

function* watchUpdateAuditDataSaga() {
  yield takeLatest(updateAuditData.type, updateAuditDataWorker);
}
export default function* rootSaga() {
  yield all([
    watchAuditSaga(),
    watchAuditByIdSaga(),
    watchUpdateAuditDataSaga(),
  ]);
}
