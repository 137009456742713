import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@mui/material";
import { ComponentStyles } from "../styles";

const TwoRowCard = ({ title, row1, row2, row3 }) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        background: "#FFF",
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table sx={{ flex: 1, alignItems: "flex-start" }}>
        <TableBody>
          <TableRow
            sx={{ verticalAlign: "top", "& > td": { pt: 0.5, pr: 0, pl: 0 } }}
          >
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                {row1.label1}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {row1.value1}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                {row1.label2}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {row1.value2}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow
            sx={{ verticalAlign: "top", "& > td": { pt: 0.5, pr: 0, pl: 0 } }}
          >
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                {row2.label1}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {row2.value1}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                {row2.label2}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {row2.value2}
              </Typography>
            </TableCell>
          </TableRow>
          {row3 && (
            <TableRow
              sx={{ verticalAlign: "top", "& > td": { pt: 0.5, pr: 0, pl: 0 } }}
            >
              <TableCell sx={{ borderBottom: "none", py: 0 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  {row3.label1}
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {row3.value1}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none", py: 0 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  {row3.label2}
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {row3.value2}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TwoRowCard;
