import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import {
  selectFactoryListBySupplierId,
} from "../../selectors/factory.selector";
import ListCard from "../listCard.component";

const FacilityComplianceList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [tierFilter, setTierFilter] = useState("");

  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#D92D20";
      case "critical":
        return "#FF692E";
      case "major":
        return "#FDB022";
      default:
        return "#45464E";
    }
  };

  const facilities = useSelector((state) =>
    selectFactoryListBySupplierId(state, params.supplierId)
  ).filter(facility => 
    tierFilter === "" || facility?.metadata?.tier === tierFilter
  );

  const navigateToFacilityDetails = (facilityId) => {
    dispatch(
      getFactoryById({ supplierId: params.supplierId, factoryId: facilityId })
    );
    navigate(`${location.pathname}/factory/${facilityId}`);
  };

  useEffect(() => {
    dispatch(
      getListOfAllFactories({
        limit: 100,
        offset: 0,
        supplierId: params.supplierId,
        supplierFactoryList: true,
      })
    );
  }, [dispatch, params.supplierId]);

  return (
    <Box mt={2}>
      <FormControl fullWidth>
        <InputLabel id="tier-filter-label">Filter by Tier</InputLabel>
        <Select
          labelId="tier-filter-label"
          value={tierFilter}
          label="Filter by Tier"
          onChange={(e) => setTierFilter(e.target.value)}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={"1"}>Tier 1</MenuItem>
          <MenuItem value={"2"}>Tier 2</MenuItem>
        </Select>
      </FormControl>
      {facilities.length > 0 &&
        facilities.map((facility) => (
          <Paper
            key={facility?.factoryId}
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              overflowY: "scroll",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            onClick={() => {
              navigateToFacilityDetails(facility?.factoryId);
            }}
          >
            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
              <Grid container sx={{ pr: 4, alignItems: "center" }}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 18,
                      color: "#000",
                      display: "inline-block",
                      mr: 2,
                    }}
                  >
                    {facility?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <ListCard data={facility} type="factory" />
          </Paper>
        ))}
    </Box>
  );
};

export default FacilityComplianceList;
