import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { selectEditModeState } from "../../selectors/misc.selector";

import { ComponentStyles, FacilityStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTenant } from "../../context/TenantContext";

import { capitalizeWords } from "../../utils/lib";
import FactoryAssociatedSupplierLinks from "../../tenants/stitchfix/factoryAssociatedSupplierLinks.component";
import FactoryStatusCard from "../../tenants/reformation/factoryStatusCard.component";
import FactoryIRSSCard from "../../tenants/reformation/factoryIRSSCard.component";
import FactoryLatestAuditInfo from "../../tenants/reformation/factoryLatestAuditInfo.component";
import FactoryInformationDrawer from "./factoryInformationDrawer.component";
import EditFactoryInformation from "./EditModals/editFactoryInformation.component";
import FactoryTierMapDrawer from "./factoryTierMapDrawer.component";
const FactoryInformation = ({ isLoading, error, factoryData }) => {
  const params = useParams();
  const { factoryConfig } = useTenant();

  const [isEditingInternal, setIsEditingInternal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  const editMode = useSelector(selectEditModeState);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No factory data available.</div>;
  }
  const factoryDataKeyValuePairs = {
    supplierName: supplierName || "-",
    tier: factoryData?.metadata?.tier
      ? typeof factoryData.metadata.tier === "number"
        ? factoryData.metadata.tier
        : factoryData.metadata.tier.split(" ")[1]
      : "-",
    factoryId: factoryData.metadata?.factoryId || "-",
    factoryType: factoryData?.metadata?.processInfo?.supplierType || "-",
    streetAddress: factoryData?.metadata?.streetAddress || "-",
    factoryCompleteAddress: capitalizeWords(factoryData?.metadata?.address),
    status: factoryData?.metadata?.status || "-",
    address: factoryData?.metadata?.addressLine1 || "-",
    zipCode: factoryData?.metadata?.zipCode || "-",
    factorySizeToolTip: `Orginal data from audit is: ${
      factoryData?.latestAudit?.metadata?.facilityData?.site_size || "-"
    }`,
    factorySize: factoryData?.latestAudit?.metadata?.facilityData
      ? `${factoryData.latestAudit.metadata.facilityData.standardized_site_size} ft²`
      : "-",
    factoryIdFromMetadata: factoryData?.metadata?.factoryId || "-",
    // reporting: factoryData?.metadata?.reportingCheck === true ? "Yes" : "No",
    reporting: factoryData?.metadata?.doNotReportCheck === true ? "Yes" : "No",
    domesticOrInternational:
      factoryData?.metadata?.domesticOrInternational || "-",
    deMinimisCheck: factoryData?.metadata?.deMinimis?.deMinimisCheck || "-",
    deMinimisYear: factoryData?.metadata?.deMinimis?.deMinimisYear || "-",
    subcontractorUsed:
      factoryData?.metadata?.processInfo?.productionContractType?.includes(
        "Subcontractor"
      )
        ? "Yes"
        : "No",
    subcontractorDetails:
      factoryData?.metadata?.processInfo?.subcontractorDetails || "-",

    contactName:
      factoryData?.metadata?.primaryFacilityContactPerson?.name || "-",
    contactPosition:
      factoryData?.metadata?.primaryFacilityContactPerson?.position || "-",
    contactPersonPhone: factoryData?.metadata?.contactPerson?.phone || "-",
    contactPersonEmail: factoryData?.metadata?.contactPerson?.email || "-",
    contactPersonPosition:
      factoryData?.metadata?.contactPerson?.position || "-",
    contactPhone:
      factoryData?.metadata?.primaryFacilityContactPerson?.phone || "-",
    contactEmail:
      factoryData?.metadata?.primaryFacilityContactPerson?.email || "-",
    contactPerson:
      factoryData?.metadata?.contactPerson?.name ||
      factoryData?.metadata?.contactPerson ||
      "-",
    agentName: factoryData?.metadata?.agentContact?.name || "N/A",
    agentEmail: factoryData?.metadata?.agentContact?.email || "-",
    agentPhone: factoryData?.metadata?.agentContact?.phone || "-",
    agentAddress: factoryData?.metadata?.agentContact?.address || "-",

    supplyChainContact: factoryData?.metadata?.supplyChainContact || "-",
    cityStateCountry: `${factoryData?.metadata?.city || "-"}, ${
      factoryData?.metadata?.stateProvince || "-"
    }, ${factoryData?.metadata?.country || "-"}`,
    lastSocialAssessmentPayer:
      factoryData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer ||
      "-",
    lastSocialAssessmentType:
      factoryData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType ||
      "-",
    lastSocialAssessmentDate:
      factoryData?.metadata?.socialAssessmentInfo?.lastAssessmentDate || "-",
    lastSocialAssessmentFirm:
      factoryData?.metadata?.socialAssessmentInfo?.lastAssessmentFirm || "-",
    nextVisitType:
      factoryData?.metadata?.socialAssessmentInfo?.nextVisitType || "-",
    nextVisitDate:
      factoryData?.metadata?.socialAssessmentInfo?.nextVisitDate || "-",
    nextVisitServiceProvider:
      factoryData?.metadata?.socialAssessmentInfo?.nextVisitServiceProvider ||
      "-",
    agreementType: factoryData?.metadata?.agreementType || "N/A",
    accountManager: factoryData?.metadata?.productSupport?.name || "N/A",
    city: factoryData?.metadata?.city || "N/A",
    country: factoryData?.metadata?.country || "N/A",
    reporting: factoryData?.metadata?.reportingCheck === true ? "Yes" : "No",
    domesticOrInternational:
      factoryData?.metadata?.domesticOrInternational || "-",
    primatyFactoryContactPhone:
      factoryData?.metadata?.primaryFacilityContactPerson?.phone || "-",
    primaryFactoryContactEmail:
      factoryData?.metadata?.primaryFacilityContactPerson?.email || "-",
  };

  return (
    <Box sx={FacilityStyles.factoryInformationContainerBox}>
      <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
        <Grid item>
          <Typography sx={FacilityStyles.headerOfTheCard}>
            {factoryConfig?.generalInformation?.sectionTitle}
          </Typography>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}
        </Grid>
        <Grid item>
          {factoryConfig?.generalInformation?.components?.moreInfo?.display && (
            <Button
              variant="outlined"
              onClick={toggleDrawer(true)}
              sx={FacilityStyles.moreInforAndTierMapButton}
            >
              More Information
            </Button>
          )}

          {factoryConfig?.generalInformation?.components?.tierMap?.display && (
            <Button
              variant="outlined"
              onClick={toggleTierMapDrawer(true)}
              sx={{
                ...FacilityStyles.moreInforAndTierMapButton,
                width: "100px",
                mr: "-12px",
              }}
            >
              Tier Map
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {Object.entries(factoryConfig?.generalInformation?.fields)
          .filter(([_, value]) => value.display)
          .map(([field, value]) => {
            if (value.isLink) {
              return (
                <Grid item xs={value.width}>
                  <Typography sx={ComponentStyles.fieldName}>
                    {value.fieldName}
                  </Typography>
                  <FactoryAssociatedSupplierLinks
                    factoryData={factoryData}
                    isLoadingSuppliers={isLoading}
                  />
                </Grid>
              );
            }
            return (
              <Grid item xs={value.width}>
                <Box display="flex" alignItems="center">
                  <Typography sx={ComponentStyles.fieldName}>
                    {value.fieldName}
                  </Typography>
                  {field === "factorySize" && value.tooltipIdentifier && (
                    <Tooltip
                      title={factoryDataKeyValuePairs[value.tooltipIdentifier]}
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={ComponentStyles.tooltipInfoIcon}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryDataKeyValuePairs[field]}
                </Typography>
              </Grid>
            );
          })}
      </Grid>
      {factoryConfig?.generalInformation?.components?.factoryStatus
        ?.display && <FactoryStatusCard factoryData={factoryData} />}
      {factoryConfig?.generalInformation?.components?.irssCard?.display && (
        <FactoryIRSSCard factoryData={factoryData} />
      )}
      {factoryConfig?.generalInformation?.components?.latestAuditInfo
        ?.display && (
        <Grid item xs={12} md={12}>
          <FactoryLatestAuditInfo factoryData={factoryData} />
        </Grid>
      )}

      <FactoryInformationDrawer
        factoryData={factoryData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <EditFactoryInformation
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        internalData={{ ...factoryData, supplierName }}
      />
      {/* 4imprint Specific */}
      <FactoryTierMapDrawer
        factoryData={factoryData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </Box>
  );
};

export default FactoryInformation;
