import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";
import { ComponentStyles, PageStyles } from "../styles";

import { calculateAuditDueDataData } from "../utils/lib";
import { useTenant } from "../context/TenantContext";

const ListCard = ({ data = {}, type }) => {
  const { factoryConfig, supplierConfig } = useTenant();

  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#B42318";
      case "critical":
        return "#FF692E";
      case "major":
        return "#EAAA08";
      default:
        return "#667085";
    }
  };

  const auditDueData = calculateAuditDueDataData(
    type === "factory"
      ? data?.lastAuditDateTimestamp
      : data?.facilityData?.audit_end
  );

  if (data.state === "in_progress") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontSize: "12px", color: "#667085" }}>
          The documents has been uploaded successfully. You will be notified
          once the AI result is ready to be reviewed.
        </Typography>
      </Box>
    );
  }
  const displayAuditRating = (rating) => {
    return rating !== null && rating !== undefined ? rating : "-";
  };
  return (
    <Box sx={{ position: "relative" }}>
      {(factoryConfig?.tabsSection?.auditList?.tags?.isHistoricalAudit ||
        factoryConfig?.tabsSection?.auditList?.tags?.irss) && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: -50,
            right: 0,
            gap: 0, // Add some space between the tags
          }}
        >
          {factoryConfig?.tabsSection?.auditList?.tags?.isHistoricalAudit &&
            data?.isHistoricalAudit && (
              <Box
                sx={{
                  ...PageStyles.tierAndPrivateLabel,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "bold",
                    color: "#6566db",
                    fontSize: "0.875rem",
                  }}
                >
                  {data?.issueDetails &&
                  Object.keys(data?.issueDetails).length > 0
                    ? data?.historicalAuditProcessedFromScratch
                      ? "Historical Audit (processed by Elm as no historical CAP)"
                      : "Historical Audit (digitized by Elm)"
                    : "Historical Audit (unprocessed)"}
                </Typography>
              </Box>
            )}
          {factoryConfig?.tabsSection?.auditList?.tags?.irss &&
            data.irss?.irssCheck === "Yes" && (
              <Box
                sx={{
                  ...PageStyles.tierAndPrivateLabel,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "bold",
                    color: "#6566db",
                    fontSize: "0.875rem",
                  }}
                >
                  IRSS
                </Typography>
              </Box>
            )}
        </Box>
      )}
      <Table sx={{ p: 0, m: 0 }}>
        <TableBody>
          <TableRow sx={{ borderBottom: "none" }}>
            {type === "factory" &&
              supplierConfig?.tabsSection?.factoryList?.listCardFields?.country
                ?.display && (
                <TableCell
                  sx={{
                    minWidth: "100px",
                    borderBottom: "none",
                    pl: 0,
                    py: 0,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Typography sx={ComponentStyles.fieldName}>
                    {supplierConfig?.tabsSection?.factoryList?.listCardFields
                      ?.country?.fieldName || "Country"}
                  </Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {data.location || "-"}
                  </Typography>
                </TableCell>
              )}
            <TableCell
              sx={{
                width: "135px",
                minWidth: "135px",
                borderBottom: "none",
                py: 0,
                pl: type === "factory" ? 2 : 0,
                pr: 0,
              }}
            >
              <Typography sx={ComponentStyles.fieldName}>
                {type === "factory" ? "Last Audit Date" : "Audit Date"}
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {auditDueData?.lastAuditDate &&
                auditDueData?.lastAuditDate !== "Invalid date"
                  ? auditDueData?.lastAuditDate
                  : data?.lastAuditDate
                  ? moment(data?.lastAuditDate).format("MMM. DD, YYYY")
                  : "-"}
              </Typography>
            </TableCell>
            {type === "factory" &&
              supplierConfig?.tabsSection?.factoryList?.listCardFields
                ?.nextAuditDueIn?.display && (
                <TableCell
                  sx={{
                    width: "150px",
                    minWidth: "150px",
                    borderBottom: "none",
                    py: 0,
                    pr: 0,
                  }}
                >
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldName,
                      px: 0,
                    }}
                  >
                    Next Audit Due In
                  </Typography>
                  <Typography
                    sx={ComponentStyles.fieldValue}
                    color={issuesColorScheme(data.status)}
                  >
                    {supplierConfig?.tabsSection?.factoryList?.listCardFields
                      ?.nextAuditDueIn?.identifier ===
                    "nextAuditDueInReformation"
                      ? data.nextReportDate
                        ? `${moment(data.nextReportDate).diff(
                            moment(),
                            "days"
                          )} Days`
                        : data.auditType
                      : `${
                          isNaN(auditDueData?.daysUntilNextAudit)
                            ? 0
                            : auditDueData?.daysUntilNextAudit
                        } days`}
                  </Typography>
                </TableCell>
              )}
            {type !== "factory" &&
              factoryConfig?.tabsSection?.auditList?.listCardFields?.auditType
                ?.display && (
                <TableCell
                  sx={{
                    width: "150px",
                    minWidth: "150px",
                    borderBottom: "none",
                    py: 0,
                    pr: 0,
                  }}
                >
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldName,
                      px: 0,
                    }}
                  >
                    {type === "factory"
                      ? "Next Audit Due In"
                      : factoryConfig?.tabsSection?.auditList?.listCardFields
                          ?.auditType?.fieldName || "Audit Scheme"}
                  </Typography>
                  <Typography
                    sx={ComponentStyles.fieldValue}
                    color={issuesColorScheme(data.status)}
                  >
                    {type === "factory"
                      ? `${auditDueData?.daysUntilNextAudit} days`
                      : data?.facilityData?.auditing_standard
                      ? data.facilityData.auditing_standard
                      : data?.auditType
                      ? data?.auditType
                      : "-"}
                  </Typography>
                </TableCell>
              )}

            {type !== "factory" &&
              factoryConfig?.tabsSection?.auditList?.listCardFields?.auditFirm
                ?.display && (
                <TableCell
                  sx={{
                    width: "120px",
                    minWidth: "120px",
                    borderBottom: "none",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    py: 0,
                    pr: 0,
                  }}
                >
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldName,
                      px: 0,
                    }}
                  >
                    {factoryConfig?.tabsSection?.auditList?.listCardFields
                      ?.auditFirm?.fieldName || "Audit Firm"}
                  </Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {data?.facilityData?.auditing_firm ||
                      data?.auditFirm ||
                      "-"}
                  </Typography>
                </TableCell>
              )}
            {factoryConfig?.tabsSection?.auditList?.listCardFields?.auditScore
              ?.display && (
              <TableCell
                sx={{
                  width: "120px",
                  minWidth: "120px",
                  borderBottom: "none",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  py: 0,
                  pr: 0,
                }}
              >
                <Typography
                  sx={{
                    ...ComponentStyles.fieldName,
                    px: 0,
                  }}
                >
                  {factoryConfig?.tabsSection?.auditList?.listCardFields
                    ?.auditScore?.fieldName || "Audit Score"}
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryConfig?.tabsSection?.auditList?.listCardFields
                    ?.auditScore?.isReformation
                    ? displayAuditRating(data.auditRating)
                    : factoryConfig?.tabsSection?.auditList?.listCardFields
                        ?.auditScore?.isRoots
                    ? data?.metadata?.facilityData?.audit_score === 100 ||
                      !data?.metadata?.facilityData?.audit_score
                      ? data?.metadata?.facilityData?.audit_score ||
                        data?.auditRating
                      : data?.metadata?.facilityData?.audit_score
                    : data.auditScore || "-"}
                </Typography>
              </TableCell>
            )}
            {factoryConfig?.tabsSection?.auditList?.listCardFields?.findings
              ?.display ? (
              <TableCell
                sx={{
                  borderBottom: "none",
                  py: 0,
                  pr: 0,
                  minWidth: "90px",
                  width: "90px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8B8D97",
                  }}
                >
                  Findings
                </Typography>
                <Typography
                  style={{ color: "45464E", fontSize: 16, fontWeight: "500" }}
                >
                  {data.issueDetails?.total ||
                    [
                      parseInt(data.ztCount || 0, 10),
                      parseInt(data.criticalCount || 0, 10),
                      parseInt(data.majorCount || 0, 10),
                      parseInt(data.minorCount || 0, 10),
                    ]
                      .reduce((acc, count) => acc + count, 0)
                      .toString() ||
                    "-"}
                </Typography>
              </TableCell>
            ) : (
              <span style={{ width: "20px", display: "inline-block" }}></span>
            )}
            <TableCell
              sx={{
                borderBottom: "none",
                p: 0,
                ml: 2,
                pr: 2,
                width: "240px",
              }}
            >
              <Table
                sx={{
                  borderBottom: "none",
                  p: 0,
                  m: 0,
                }}
              >
                <TableBody>
                  <TableRow sx={{ borderBottom: "none", p: 0, m: 0 }}>
                    {Object.entries(data.severityCategories || {}).map(
                      ([severityCategory, color], index) => (
                        <TableCell
                          key={index}
                          sx={{
                            p: 0,
                            m: 0,
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: 16,
                              color: "#8B8D97",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {severityCategory}
                          </Typography>
                          <Box
                            sx={{
                              width: "80px",

                              backgroundColor:
                                data.issueDetails?.[severityCategory] &&
                                (data.issueDetails[severityCategory] !== 0 ||
                                  data.issueDetails[severityCategory] !== "0" ||
                                  data.issueDetails[severityCategory] !== "-")
                                  ? color
                                  : "#FFFFFF",
                              color:
                                data.issueDetails?.[severityCategory] &&
                                (data.issueDetails[severityCategory] !== 0 ||
                                  data.issueDetails[severityCategory] !== "0" ||
                                  data.issueDetails[severityCategory] !== "-")
                                  ? "#FFFFFF"
                                  : "#E0E0E0",

                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 500,
                              fontSize: 16,
                              border:
                                data.issueDetails?.[severityCategory] &&
                                data.issueDetails[severityCategory] !== 0 &&
                                data.issueDetails[severityCategory] !== "0" &&
                                data.issueDetails[severityCategory] !== "-"
                                  ? `1px solid ${color}`
                                  : "1px solid #E0E0E0",
                              borderLeft:
                                index !== 0 ? "none" : "1px solid #E0E0E0",
                            }}
                          >
                            {data.issueDetails?.[severityCategory] ?? "0"}
                          </Box>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>

            {factoryConfig?.tabsSection?.auditList?.listCardFields?.openCAP
              ?.display && (
              <TableCell
                sx={{
                  minWidth: "120px",
                  borderBottom: "none",
                  py: 0,
                  pr: 0,
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <Typography sx={ComponentStyles.fieldName}>
                  {factoryConfig?.tabsSection?.auditList?.listCardFields
                    ?.openCAP?.fieldName || "Open CAP"}
                </Typography>
                <Box
                  variant="contained"
                  sx={{
                    borderRadius: "6px",
                    background: !factoryConfig.tabsSection.auditList
                      .listCardFields.openCAP.backgroundColor
                      ? "#EAECF0"
                      : data?.issueDetails?.Open > 0
                      ? "#EAAA08"
                      : "#EAECF0",
                    border: "1px solid var(--Gray-300, #D0D5DD)",
                    padding: "3px 8px",
                    color: !factoryConfig.tabsSection.auditList.listCardFields
                      ?.openCAP?.color
                      ? "#667085"
                      : data?.issueDetails?.Open > 0
                      ? "#FFFFFF"
                      : issuesColorScheme(data.status),
                    fontSize: "12px",
                    fontWeight: 500,
                    width: "fit-content",
                  }}
                >
                  {!data?.issueDetails?.Open ||
                  data?.issueDetails?.Open === 0 ||
                  data?.issueDetails?.Open === "0" ||
                  data?.issueDetails?.Open === "-"
                    ? "No"
                    : "Yes"}
                </Box>
              </TableCell>
            )}

            {factoryConfig?.tabsSection?.auditList?.listCardFields?.pastDueCAP
              ?.display && (
              <TableCell
                sx={{ minWidth: "150px", borderBottom: "none", py: 0 }}
              >
                <Typography sx={ComponentStyles.fieldName}>
                  {factoryConfig?.tabsSection?.auditList?.listCardFields
                    ?.pastDueCAP?.fieldName || "Past Due CAP"}
                </Typography>
                <Box
                  variant="contained"
                  sx={{
                    borderRadius: "6px",
                    background: !factoryConfig.tabsSection.auditList
                      .listCardFields.pastDueCAP.backgroundColor
                      ? "#EAECF0"
                      : data?.issueDetails?.PastDue > 0
                      ? "#B42318"
                      : "#EAECF0",
                    border: "1px solid var(--Gray-300, #D0D5DD)",
                    padding: "3px 8px",
                    color: !factoryConfig.tabsSection.auditList.listCardFields
                      .pastDueCAP.color
                      ? "#667085"
                      : data?.issueDetails?.PastDue > 0
                      ? "white"
                      : issuesColorScheme(data.status),
                    fontSize: "12px",
                    fontWeight: 500,
                    width: "fit-content",
                  }}
                >
                  {data?.issueDetails?.PastDue > 0 ? "Yes" : "No"}
                </Box>
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default ListCard;
