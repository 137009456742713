import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  IconButton,
  Divider,
  Grid,
  Paper,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Autocomplete,
  TextField,
  Alert,
  Chip,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { alpha } from "@mui/material/styles";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryList } from "../../../selectors/factory.selector";
import { getListOfAllFactories } from "../../../reducers/factory.reducer";
import {
  selectSignedUrls,
  selectIsLoadingSignedUrl,
} from "../../../selectors/questionnaire.selector";
import {
  linkResponseToFactory,
  resetLinkingState,
  getFileSignedUrlRequest,
  clearFileSignedUrls,
} from "../../../reducers/questionnaire.reducer";

// Color palette - easier to maintain and update
const colors = {
  primary: "#4A56E2", // Slightly adjusted main color
  primaryLight: "rgba(74, 86, 226, 0.08)",
  primaryDark: "#3A46B4",
  text: {
    primary: "#1A1D1F",
    secondary: "#5E6670",
    tertiary: "#84878D",
  },
  background: {
    paper: "#FFFFFF",
    default: "#F5F7FA",
    secondary: "#F9FAFB",
  },
  border: "#E4E7EC",
  divider: "#F0F2F5",
  success: "#34B53A",
  error: "#F04438",
};

const QuestionnaireDetails = ({
  open,
  handleClose,
  response,
  questionnaire,
  onLinkToFactory,
}) => {
  const [loading, setLoading] = useState(true);
  const [mappedResponses, setMappedResponses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState(null);

  const dispatch = useDispatch();
  const factories = useSelector(selectFactoryList);
  const linkingResponse = useSelector(
    (state) => state.questionnaire.linkingResponse
  );
  const linkingSuccess = useSelector(
    (state) => state.questionnaire.linkingSuccess
  );
  const linkingError = useSelector((state) => state.questionnaire.linkingError);
  const signedUrls = useSelector(selectSignedUrls);

  // Format date helper
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM dd, yyyy");
    } catch (e) {
      return "Invalid Date";
    }
  };

  // Load factories when the component mounts
  useEffect(() => {
    if (open) {
      dispatch(getListOfAllFactories({ limit: 999, offset: 0 }));
    }
  }, [open, dispatch]);

  useEffect(() => {
    // Reset selectedFactory when the dialog opens/closes or when response changes
    if (!open) {
      setSelectedFactory(null);
      dispatch(clearFileSignedUrls());
      return;
    }

    // Find and set the factory if response is linked to one
    if (response?.response?.factoryId && factories.length > 0) {
      const linkedFactory = factories.find(
        (f) => f.factoryId === response.response.factoryId
      );

      if (linkedFactory) {
        setSelectedFactory(linkedFactory);
      } else {
        setSelectedFactory(null); // Reset if no match found
      }
    } else {
      setSelectedFactory(null); // Reset if no factoryId
    }
  }, [open, response, factories]);

  const getSignedUrlForFile = (fileKey) => {
    if (fileKey && !signedUrls[fileKey]) {
      dispatch(getFileSignedUrlRequest(fileKey));
    }
    return signedUrls[fileKey];
  };

  const getFileUrl = (file) => {
    if (!file) return "#";

    // If we have a signed URL for this file, use it
    if (file.key && signedUrls[file.key]) {
      return signedUrls[file.key];
    }

    // If we don't have a signed URL but do have a key, request one
    if (file.key && !signedUrls[file.key]) {
      dispatch(getFileSignedUrlRequest(file.key));
    }

    console.log("File:", file);
    console.log("Signed URLs:", signedUrls);

    // Fallback to the direct URL or a placeholder
    return file.url || "#";
  };

  const handleLinkToFactory = () => {
    if (!selectedFactory || !response) return;

    dispatch(
      linkResponseToFactory({
        responseId: response._id,
        factoryId: selectedFactory.factoryId,
      })
    );

    // Clear success message after a delay
    if (linkingSuccess) {
      setTimeout(() => {
        dispatch(resetLinkingState());
      }, 3000);
    }
  };

  // Parse schema to extract questions and structure
  const parseSchema = (schemaString) => {
    try {
      return typeof schemaString === "string"
        ? JSON.parse(schemaString)
        : schemaString;
    } catch (error) {
      console.error("Error parsing schema:", error);
      return null;
    }
  };

  // Extract all questions from schema with their full path information
  const extractQuestionsFromSchema = (schema) => {
    const result = [];
    let questionIndex = 1;

    if (!schema || !schema.steps) return result;

    schema.steps.forEach((step) => {
      if (!step.subsections) return;

      step.subsections.forEach((subsection) => {
        // Add section header
        result.push({
          isSection: true,
          sectionTitle: subsection.title || "Untitled Section",
          stepTitle: step.title || "Untitled Step",
        });

        if (!subsection.rows) return;

        subsection.rows.forEach((row) => {
          if (!row.columns) return;

          row.columns.forEach((column) => {
            if (!column.questions) return;

            column.questions.forEach((question) => {
              if (question.field) {
                result.push({
                  questionIndex: questionIndex++,
                  field: question.field,
                  questionText:
                    question.label || formatFieldToReadable(question.field),
                  questionType: question.type,
                  required: question.required || false,
                  tooltip: question.tooltip,
                  subsection: subsection.title,
                  section: step.title,
                  options: question.options,
                });
              }
            });
          });
        });
      });
    });

    return result;
  };

  // Process and map responses to questions when the component mounts or response changes
  useEffect(() => {
    if (!open || !response || !questionnaire) {
      return;
    }

    setLoading(true);

    try {
      // Parse the schema if it exists
      let parsedSchema = null;
      if (questionnaire.schema) {
        parsedSchema = parseSchema(questionnaire.schema);
        const extractedSections = extractSectionsFromSchema(parsedSchema);
        setSections(extractedSections);
      }

      // Extract questions from schema and map responses
      const extractedQuestions = parsedSchema
        ? extractQuestionsFromSchema(parsedSchema)
        : [];

      if (extractedQuestions.length > 0) {
        // Map responses to extracted questions
        const responseData = response.response || {};
        const mappedData = extractedQuestions.map((item) => {
          // Skip section headers
          if (item.isSection) return item;

          // Get response value for this field
          const fieldPath = item.field.split(".");
          let responseValue = responseData;

          // Navigate through the response object based on field path
          for (const part of fieldPath) {
            if (responseValue && responseValue[part] !== undefined) {
              responseValue = responseValue[part];
            } else {
              responseValue = undefined;
              break;
            }
          }

          return {
            ...item,
            answer: formatResponseValue(responseValue, item.questionType),
            notes: getNotesIfAvailable(responseValue),
            _rawValue:
              item.questionType === "fileUpload" ? responseValue : undefined,
          };
        });

        setMappedResponses(mappedData);
      } else {
        // Fallback for when no schema is available
        const fallbackMappedData = mapResponseWithoutSchema(response.response);
        setMappedResponses(fallbackMappedData);
      }

      // Set the current factory if the response is already linked
      if (response?.factoryId) {
        const linkedFactory = factories.find(
          (f) => f.factoryId === response.factoryId
        );
        if (linkedFactory) {
          setSelectedFactory(linkedFactory);
        }
      }
    } catch (error) {
      console.error("Error mapping responses to questions:", error);
    } finally {
      setLoading(false);
    }
  }, [open, response, questionnaire, factories]);

  // Map response when no schema is available
  const mapResponseWithoutSchema = (responseData) => {
    if (!responseData) return [];

    const result = [];
    const skipFields = ["initialized", "_id"];

    Object.entries(responseData).forEach(([key, value], index) => {
      if (!skipFields.includes(key)) {
        result.push({
          questionIndex: index + 1,
          field: key,
          questionText: formatFieldToReadable(key),
          answer: formatResponseValue(value),
          notes: getNotesIfAvailable(value),
        });
      }
    });

    return result;
  };

  // Extract sections from schema
  const extractSectionsFromSchema = (schema) => {
    if (!schema || !schema.steps) {
      return [];
    }

    return schema.steps.flatMap((step) => {
      // Make sure subsections exist before mapping
      if (!step.subsections || !Array.isArray(step.subsections)) {
        return [];
      }

      return step.subsections.map((subsection) => ({
        title: subsection.title || "Untitled Section",
        stepTitle: step.title || "Untitled Step",
      }));
    });
  };

  // Converts camelCase to Title Case with spaces
  const formatFieldToReadable = (field) => {
    return field
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  // Check if the answer has notes
  const getNotesIfAvailable = (value) => {
    if (value && typeof value === "object" && value.notes) {
      return value.notes;
    }
    return null;
  };

  // Format response value based on structure and type
  const formatResponseValue = (value, type) => {
    if (value === null || value === undefined) {
      return "Not answered";
    }

    try {
      // If value is an object with a response property (common in the sample)
      if (typeof value === "object" && value.response !== undefined) {
        return formatSimpleValue(value.response, type);
      }

      // Otherwise format the value directly
      return formatSimpleValue(value, type);
    } catch (error) {
      console.error("Error formatting response value:", error);
      return "Error displaying response";
    }
  };

  // Format a simple value based on its type
  const formatSimpleValue = (value, type) => {
    if (value === null || value === undefined) {
      return "Not answered";
    }

    switch (type) {
      case "boolean":
        if (typeof value === "boolean") {
          return value ? "Yes" : "No";
        } else if (typeof value === "string") {
          return value === "Yes" || value === "true" || value === "1"
            ? "Yes"
            : "No";
        }
        return String(value);
      case "multiselect":
        return Array.isArray(value) ? value.join(", ") : String(value);
      case "date":
        return formatDate(value);
      case "fileUpload":
        if (!value || !value.fileUpload || value.fileUpload.length === 0)
          return "No files uploaded";

        if (value.fileUpload && Array.isArray(value.fileUpload)) {
          if (value.fileUpload.length === 1) {
            // For a single file, just show the name
            return value.fileUpload[0].name;
          } else {
            // For multiple files
            return `${value.fileUpload.length} files uploaded`;
          }
        }

        return "File uploaded";
      case "radio":
      case "select":
        // If we have options available, try to map the value to its label
        if (value.options) {
          const option = value.options.find((opt) => opt.value === value);
          return option ? option.label : String(value);
        }
        return String(value);
      default:
        return String(value);
    }
  };

  // Render a question and its answer
  const renderQuestionAnswer = (item, index) => {
    // console.log(item);
    if (item.isSection) {
      return (
        <Box key={`section-${index}`} sx={{ mt: 5, mb: 3 }}>
          {item.sectionTitle != "Untitled Section" ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontSize: 18,
                  color: colors.text.primary,
                  mb: 1,
                }}
              >
                {item.sectionTitle}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: colors.text.secondary,
                  fontSize: "14px",
                  fontWeight: 500,
                  mb: 1,
                  display: "block",
                }}
              >
                {item.stepTitle}
              </Typography>
              <Divider sx={{ mt: 1, mb: 2, backgroundColor: colors.divider }} />
            </>
          ) : (
            <></>
          )}
        </Box>
      );
    }

    return (
      <Box
        key={item.field || index}
        sx={{
          mb: 3.5,
          pt: 1,
          pb: 3.5,
          pl: 4,
          borderBottom:
            index !== mappedResponses.length - 1
              ? `1px solid ${colors.divider}`
              : "none",
          "&:last-child": {
            borderBottom: "none",
            pb: 1,
          },
        }}
      >
        <Grid container spacing={{ xs: 3, sm: 12 }}>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                color: colors.text.secondary,
                mb: 0.5,
              }}
            >
              {item.questionText}
              {item.required && (
                <Box component="span" sx={{ color: colors.error, ml: 0.5 }}>
                  *
                </Box>
              )}
            </Typography>
            {item.tooltip && (
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  color: colors.text.tertiary,
                  mt: 0.5,
                  fontStyle: "italic",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: 1.5,
                }}
              >
                {item.tooltip}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color:
                  item.answer === "Not answered"
                    ? colors.text.tertiary
                    : colors.text.primary,
                wordBreak: "break-word",
                lineHeight: 1.6,
                backgroundColor:
                  item.answer === "Not answered"
                    ? "rgba(0,0,0,0.03)"
                    : "transparent",
                padding: item.answer === "Not answered" ? 1 : 0,
                borderRadius: 1,
              }}
            >
              {item.questionType != "fileUpload" ? item.answer : ""}
            </Typography>

            {item.notes && (
              <Accordion
                sx={{
                  mt: 2,
                  boxShadow: "none",
                  backgroundColor: colors.background.secondary,
                  borderRadius: "8px",
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{ fontSize: "1rem", color: colors.primary }}
                    />
                  }
                  sx={{
                    p: 1,
                    minHeight: "36px",
                    "&.Mui-expanded": {
                      minHeight: "36px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: colors.primary,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Show notes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: "8px",
                      backgroundColor: colors.background.default,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: colors.text.secondary,
                        fontWeight: 400,
                        lineHeight: 1.6,
                      }}
                    >
                      {item.notes}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            {item.questionType === "fileUpload" && (
              <Box sx={{ mt: 0 }}>
                {item._rawValue &&
                item._rawValue.fileUpload &&
                item._rawValue.fileUpload.length > 0 ? (
                  // Files exist - render buttons for all files (single or multiple)
                  <Stack direction="column" spacing={1}>
                    {item._rawValue.fileUpload.map((file, idx) => (
                      <Button
                        key={idx}
                        startIcon={<AttachFileIcon />}
                        component="a"
                        href={getFileUrl(file)}
                        target="_blank"
                        sx={{
                          textTransform: "none",
                          color: colors.primary,
                          backgroundColor: colors.primaryLight,
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: alpha(colors.primary, 0.15),
                          },
                          fontSize: "14px",
                          fontWeight: 500,
                          justifyContent: "flex-start",
                          width: "fit-content",
                          mb:
                            idx < item._rawValue.fileUpload.length - 1 ? 1 : 0,
                        }}
                      >
                        {file.name || `File ${idx + 1}`}
                      </Button>
                    ))}
                  </Stack>
                ) : (
                  // No files available
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: colors.text.tertiary,
                      fontStyle: "italic",
                    }}
                  >
                    No files uploaded
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
          maxHeight: "95vh",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.08)",
          border: `1px solid ${colors.border}`,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              backgroundColor: colors.primaryLight,
              borderRadius: "12px",
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 2,
            }}
          >
            <AssignmentIcon sx={{ color: colors.primary, fontSize: 22 }} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              fontSize: 20,
              color: colors.text.primary,
            }}
          >
            Questionnaire Response
          </Typography>
        </Box>
        <IconButton
          edge="end"
          onClick={handleClose}
          aria-label="close"
          sx={{
            color: colors.text.tertiary,
            "&:hover": {
              backgroundColor: colors.background.default,
            },
          }}
        >
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </DialogTitle>

      <Divider sx={{ backgroundColor: colors.divider }} />

      <DialogContent sx={{ p: 0, backgroundColor: colors.background.default }}>
        <Container maxWidth="xl" sx={{ py: 4, px: { xs: 3, md: 4 } }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 8,
              }}
            >
              <CircularProgress sx={{ color: colors.primary }} />
            </Box>
          ) : (
            <>
              {/* Response Overview */}
              <Box sx={{ mb: 4 }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    backgroundColor: colors.background.paper,
                    borderRadius: "16px",
                    border: `1px solid ${colors.border}`,
                    mb: 4,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <AssignmentIcon
                          sx={{ color: colors.primary, mr: 2, fontSize: 22 }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: colors.text.tertiary,
                              mb: 1,
                            }}
                          >
                            Questionnaire
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: colors.text.primary,
                            }}
                          >
                            {questionnaire?.title || "Untitled Questionnaire"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <CalendarTodayIcon
                          sx={{ color: colors.primary, mr: 2, fontSize: 22 }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: colors.text.tertiary,
                              mb: 1,
                            }}
                          >
                            Submission Date
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: colors.text.primary,
                            }}
                          >
                            {formatDate(response?.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <PersonIcon
                          sx={{ color: colors.primary, mr: 2, fontSize: 22 }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: colors.text.tertiary,
                              mb: 1,
                            }}
                          >
                            Respondent
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: colors.text.primary,
                            }}
                          >
                            {response?.response?.firstName || "N/A"}{" "}
                            {response?.response?.lastName || ""}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <BusinessIcon
                          sx={{ color: colors.primary, mr: 2, fontSize: 22 }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: colors.text.tertiary,
                              mb: 1,
                            }}
                          >
                            Site
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: colors.text.primary,
                            }}
                          >
                            {response?.response?.factoryName || "N/A"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>

                {/* Site Linking Box */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    backgroundColor: colors.background.paper,
                    borderRadius: "12px",
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <LinkIcon
                      sx={{ color: colors.primary, mr: 1.5, fontSize: 20 }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: colors.text.primary,
                      }}
                    >
                      Assign Response to Site
                    </Typography>
                  </Box>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={8}>
                      <Autocomplete
                        value={selectedFactory}
                        onChange={(event, newValue) => {
                          setSelectedFactory(newValue);
                        }}
                        options={factories}
                        getOptionLabel={(option) =>
                          `${option.name} ${
                            option.factoryIdFromMetadata
                              ? `(${option.factoryIdFromMetadata})`
                              : ""
                          }`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Site"
                            variant="outlined"
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "10px",
                                borderColor: colors.border,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant={response?.factoryId ? "outlined" : "contained"}
                        startIcon={<LinkIcon />}
                        onClick={handleLinkToFactory}
                        disabled={
                          !selectedFactory ||
                          response?.factoryId === selectedFactory?.factoryId
                        }
                        sx={{
                          ...(response?.response?.factoryId
                            ? {
                                color: colors.primary,
                                borderColor: colors.primary,
                                border: "1px solid",
                                backgroundColor: "transparent",
                                "&:hover": {
                                  backgroundColor: colors.primaryLight,
                                  borderColor: colors.primaryDark,
                                },
                              }
                            : {
                                backgroundColor: colors.primary,
                                "&:hover": {
                                  backgroundColor: colors.primaryDark,
                                  boxShadow:
                                    "0 4px 12px rgba(74, 86, 226, 0.2)",
                                },
                                border: "1px solid",
                              }),
                          borderRadius: "10px",
                          textTransform: "none",
                          px: 3,
                          py: 1.5,
                          fontSize: 15,
                          fontWeight: 600,
                          height: "56px",
                          width: "100%",
                          boxShadow: "none",
                        }}
                      >
                        {response?.response?.factoryId
                          ? "Reassign response to Site"
                          : "Assign response to Site"}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Alerts for linking status */}
                  {linkingSuccess && (
                    <Alert
                      severity="success"
                      variant="outlined"
                      icon={false}
                      sx={{
                        mt: 2,
                        borderRadius: "10px",
                        borderColor: colors.success,
                        color: colors.success,
                        "& .MuiAlert-message": {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            backgroundColor: "rgba(52, 181, 58, 0.1)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              backgroundColor: colors.success,
                            }}
                          />
                        </Box>
                        <Typography sx={{ fontWeight: 500 }}>
                          Response successfully linked to{" "}
                          {selectedFactory?.name}
                        </Typography>
                      </Box>
                    </Alert>
                  )}

                  {linkingError && (
                    <Alert
                      severity="error"
                      variant="outlined"
                      sx={{
                        mt: 2,
                        borderRadius: "10px",
                        borderColor: colors.error,
                      }}
                    >
                      {linkingError}
                    </Alert>
                  )}
                </Paper>
              </Box>

              {/* Responses */}
              <Box sx={{ mb: 4 }}>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: colors.background.paper,
                    borderRadius: "12px",
                    border: `1px solid ${colors.border}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      p: 3,
                      borderBottom: `1px solid ${colors.border}`,
                      mx: 30,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 18,
                        color: colors.text.primary,
                      }}
                    >
                      Responses
                    </Typography>
                  </Box>

                  <Box sx={{ pl: 5 }}>
                    {mappedResponses.length > 0 ? (
                      <Box>
                        {mappedResponses.map((item, index) =>
                          renderQuestionAnswer(item, index)
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          textAlign: "center",
                          py: 5,
                        }}
                      >
                        <Typography
                          sx={{ color: colors.text.secondary, fontSize: 16 }}
                        >
                          No answers found for this questionnaire.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>
            </>
          )}
        </Container>
      </DialogContent>

      <Divider sx={{ backgroundColor: colors.divider }} />

      <DialogActions sx={{ p: 3, backgroundColor: colors.background.paper }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="large"
          sx={{
            color: colors.primary,
            borderColor: colors.primary,
            borderRadius: "10px",
            textTransform: "none",
            paddingLeft: 3,
            paddingRight: 3,
            fontSize: 15,
            fontWeight: 600,
            "&:hover": {
              borderColor: colors.primaryDark,
              backgroundColor: colors.primaryLight,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionnaireDetails;
