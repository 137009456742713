import React from "react";
import { Typography, Grid } from "@mui/material";

import TwoLineCard from "../../components/twoLineCard.component";
import { FacilityStyles } from "../../styles";
import moment from "moment";
import { useTenant } from "../../context/TenantContext";

const FactoryAuditScheduling = ({ selectedFactory }) => {
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;

  const formatDate = (dateStr) => {
    if (dateStr === "N/A") return "N/A";
    return moment(dateStr).format("MM/DD/YY");
  };

  const isInactive = selectedFactory?.metadata?.status === "inactive";

  const socialAssessmentInfo =
    selectedFactory?.metadata?.socialAssessmentInfo || {};

  const availableData = {
    lastAssessmentDate: formatDate(socialAssessmentInfo.lastAssessmentDate),
    nextVisitDate: formatDate(socialAssessmentInfo.nextVisitDate),
    nextConfirmedAuditDate: formatDate(
      selectedFactory?.metadata?.confirmedNextAuditDate || "N/A"
    ),
    nextAuditDueDate: formatDate(
      selectedFactory?.auditCadence?.nextAuditDate || "N/A"
    ),
    isPastDue: moment(selectedFactory?.auditCadence?.nextAuditDate).isBefore(
      moment(),
      "day"
    ),
  };
  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        {factoryStatusSection.auditSchedulingSection.title}
      </Typography>
      <Grid container spacing={4}>
        {factoryStatusSection?.auditSchedulingSection?.sections?.filter(
          section => section.display
        ).map(
          (section) => (
            <Grid item xs={6} key={section.key}>
              <TwoLineCard
                title={section.fieldName}
                nextAuditDate={availableData[section.key]}
                textColor={
                  section.key === "lastAssessmentDate" &&
                  !isInactive &&
                  availableData?.isPastDue
                    ? section.color
                    : "inherit"
                }
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default FactoryAuditScheduling;
