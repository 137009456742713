import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Chip,
  IconButton,
  Collapse,
  Button,
  alpha,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { format } from "date-fns";
import QuestionnaireDetails from "./questionnaireDetails.component";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryList } from "../../../selectors/factory.selector";
import { getListOfAllFactories } from "../../../reducers/factory.reducer";

// Shared styles
const styles = {
  chip: {
    fontWeight: 600,
    borderRadius: "16px",
    fontSize: "13px",
  },
  text: {
    fontSize: "14px",
    color: "#475467",
  },
  iconText: {
    fontSize: "13px",
    color: "#667085",
    fontWeight: 500,
  },
  cellAlignment: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
};

const QuestionnaireAccordion = ({ questionnaire }) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const factories = useSelector(selectFactoryList);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    dispatch(getListOfAllFactories({ limit: 999, offset: 0 }));
  }, [dispatch]);

  // Format the date if it exists
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "MMM dd, yyyy");
    } catch (e) {
      return "Invalid Date";
    }
  };

  const toggleExpanded = () => setExpanded(!expanded);

  const viewFullResponse = (response) => {
    setSelectedResponse(response);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => setDetailsOpen(false);

  const handleCopyLink = (e) => {
    e.stopPropagation();
    const formLink = `${
      process.env.REACT_APP_QUESTIONNAIRE_BASE_URL ||
      "https://questionnaire.elm-ai.com"
    }/${questionnaire._id}`;

    navigator.clipboard
      .writeText(formLink)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Define columns for DataGrid
  const columns = [
    {
      field: "site",
      headerName: "Site",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={styles.cellAlignment}>
          <Typography sx={styles.text}>{params.value || "N/A"}</Typography>
        </Box>
      ),
    },
    {
      field: "submissionDate",
      headerName: "Submission Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ ...styles.cellAlignment }}>
          <Typography sx={styles.text}>{params.value || ""}</Typography>
        </Box>
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <Box sx={styles.cellAlignment}>
          <Typography sx={styles.text}>{params.value || ""}</Typography>
        </Box>
      ),
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <Box sx={styles.cellAlignment}>
          <Typography sx={styles.text}>{params.value || ""}</Typography>
        </Box>
      ),
    },
    {
      field: "siteMapping",
      headerName: "Site Mapping",
      flex: 1,
      minWidth: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          // Find the factory in factories list by factoryId
          const assignedFactory = factories?.find(
            (factory) => factory.factoryId === params.value
          );

          if (assignedFactory) {
            // Create link to factory details
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="text"
                  component={Link} // Assuming you have Link from react-router-dom
                  to={`/suppliers/${assignedFactory.supplierId}/factory/${assignedFactory.factoryId}`}
                  target="_blank"
                  sx={{
                    color: "#4C5ED9",
                    textDecoration: "none",
                    fontWeight: 600,
                    fontSize: "13px",
                    "&:hover": {
                      backgroundColor: alpha("#4C5ED9", 0.05),
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {assignedFactory.name}
                  <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
                </Button>
              </Box>
            );
          }

          // Fallback if factory not found but ID exists
          return (
            <Chip
              icon={
                <CheckCircleIcon style={{ fontSize: 16, color: "inherit" }} />
              }
              label="Mapped"
              size="small"
              sx={{
                ...styles.chip,
                backgroundColor: alpha("#4C5ED9", 0.1),
                color: "#4C5ED9",
                "& .MuiChip-icon": {
                  color: "#4C5ED9",
                },
              }}
            />
          );
        } else {
          // Not mapped case
          return (
            <Chip
              icon={<CancelIcon style={{ fontSize: 16, color: "inherit" }} />}
              label="Not mapped"
              size="small"
              sx={{
                ...styles.chip,
                backgroundColor: alpha("#667085", 0.1),
                color: "#667085",
                "& .MuiChip-icon": {
                  color: "#667085",
                },
              }}
            />
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            viewFullResponse(params.row.originalResponse);
          }}
          sx={{
            borderColor: "#6172F3",
            color: "#6172F3",
            "&:hover": {
              backgroundColor: alpha("#6172F3", 0.05),
              borderColor: "#6172F3",
            },
            textTransform: "none",
            height: "34px",
            borderRadius: "8px",
            fontWeight: 500,
            fontSize: "13px",
            boxShadow: "none",
            transition: "all 0.2s ease-in-out",
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  // Prepare the data for DataGrid
  const rows =
    questionnaire.responses?.map((response, index) => ({
      id: index,
      site: response.response.factoryName,
      submissionDate: formatDate(response.createdAt),
      firstName: response.response.firstName,
      lastName: response.response.lastName,
      siteMapping: response.response.factoryId,
      originalResponse: response,
    })) || [];

  const hasResponses =
    questionnaire.responses && questionnaire.responses.length > 0;

  return (
    <>
      <Card
        sx={{
          mb: 3,
          borderRadius: "12px",
          border: "1px solid #EAECF0",
          boxShadow: expanded
            ? "0px 4px 12px rgba(16, 24, 40, 0.08)"
            : "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          overflow: "hidden",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0px 4px 12px rgba(16, 24, 40, 0.08)",
          },
        }}
      >
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
          <Box
            onClick={toggleExpanded}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2.5,
              borderBottom: expanded ? "1px solid #EAECF0" : "none",
              cursor: "pointer",
              backgroundColor: "#FFF",
              transition: "background-color 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: alpha("#6172F3", 0.03),
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                color: "#344054",
              }}
            >
              {questionnaire.title || "Untitled Questionnaire"}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                <Button
                  component="a"
                  href={`${
                    process.env.REACT_APP_QUESTIONNAIRE_BASE_URL ||
                    "https://questionnaire.elm-ai.com"
                  }/${questionnaire._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<LinkIcon sx={{ fontSize: 16 }} />}
                  sx={{
                    textTransform: "none",
                    fontSize: "13px",
                    color: "#4C5ED9",
                    fontWeight: 600,
                    mr: 1.5,
                    "&:hover": {
                      backgroundColor: alpha("#4C5ED9", 0.05),
                    },
                    padding: "4px 10px",
                    borderRadius: "6px",
                    height: "28px",
                  }}
                >
                  View Form
                </Button>

                <Button
                  onClick={handleCopyLink}
                  startIcon={<ContentCopyIcon sx={{ fontSize: 16 }} />}
                  sx={{
                    textTransform: "none",
                    fontSize: "13px",
                    color: "#4C5ED9",
                    fontWeight: 600,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: alpha("#4C5ED9", 0.05),
                    },
                    padding: "4px 10px",
                    borderRadius: "6px",
                    height: "28px",
                  }}
                >
                  Click to copy link
                </Button>

                <Chip
                  label={`${
                    questionnaire.responses?.length || 0
                  } Responses Submitted`}
                  size="small"
                  sx={{
                    ...styles.chip,
                    mr: 3,
                    backgroundColor: alpha("#667085", 0.1),
                    color: "#667085",
                    height: "28px",
                    px: 1,
                    border: "none",
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarTodayIcon
                    sx={{ color: "#667085", fontSize: 16, mr: 0.7 }}
                  />
                  <Typography sx={styles.iconText}>
                    Created: {formatDate(questionnaire.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpanded();
                }}
                sx={{
                  color: "#667085",
                  backgroundColor: alpha("#6172F3", 0.05),
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    backgroundColor: alpha("#6172F3", 0.1),
                    transform: "scale(1.05)",
                  },
                }}
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </Box>

          <Collapse in={expanded} timeout={300}>
            <Box sx={{ p: 0 }}>
              {hasResponses ? (
                <Paper
                  elevation={0}
                  sx={{
                    boxShadow: "none",
                    m: 0,
                    height: 450,
                    width: "100%",
                    borderRadius: "0",
                    overflow: "hidden",
                    border: "none",
                    px: 4,
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableColumnMenu
                    autoHeight={false}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "submissionDate", sort: "desc" }],
                      },
                    }}
                    sx={{
                      border: "none",
                      height: "100%",
                      "& .MuiDataGrid-cell": {
                        padding: "0 16px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    isRowSelectable={() => false}
                  />
                </Paper>
              ) : (
                <Box
                  p={4}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AssignmentIcon
                    sx={{ fontSize: 42, color: alpha("#6172F3", 0.5), mb: 1.5 }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "#344054", fontWeight: 600, mb: 0.5 }}
                  >
                    No Responses Yet
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#667085", maxWidth: "380px" }}
                  >
                    No responses have been submitted for this questionnaire.
                    Responses will appear here once they are received.
                  </Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Form link copied!
        </Alert>
      </Snackbar>

      <QuestionnaireDetails
        open={detailsOpen}
        handleClose={handleCloseDetails}
        response={selectedResponse}
        questionnaire={questionnaire}
      />
    </>
  );
};

export default QuestionnaireAccordion;
