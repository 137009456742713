import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Select,
  MenuButton,
  Menu,
  MenuItem,
  MenuItemText,
  Listbox,
  FormControl,
  InputLabel,
} from "@mui/material";
import CustomChipLabel from "./../customChipLabel.component";
import { useSelector } from "react-redux";
import { selectAuditIssueDetails } from "../../../selectors/audit.selector";

const RecommendationsList = ({ title, items }) => (
  <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={`- ${item}`}
            sx={{
              color: "var(--text-primary, rgba(0, 0, 0, 0.90))",
              fontSize: "16px",
              fontWeight: 400,
            }}
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);
const EditAuditReport = () => {
  const data = useSelector(selectAuditIssueDetails);
  const [category, setCategory] = React.useState("");
  const [timeFrame, setTimeFrame] = React.useState("");

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const handleChangeOfTimeFrame = (event) => {
    setTimeFrame(event.target.value);
  };
  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ p: 2 }}>
        {/* Header Section */}
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
          }}
          gutterBottom
        >
          Issue
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data.issueTitle}
        </Typography>

        {/* Details Section */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Type
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.type}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Subcategory
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.subType}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Repeated Issue
            </Typography>

            <Chip
              label={data.repeatedIssue}
              sx={{
                fontSize: "14px",
                color: "#344054",
                fontWeight: 500,
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                background: "#FFF",
                padding: "4px 10px",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Issue Detail
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.issueDetail}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Audit Code or Legal Reference
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.auditCode}
            </Typography>
          </Grid>
        </Grid>

        {/* Finding Category */}
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Finding Category
            </Typography>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.category}
                defaultValue={data.category}
                label=""
                onChange={handleChange}
              >
                <MenuItem value={"ZT"}>ZT</MenuItem>
                <MenuItem value={"Major"}>Major</MenuItem>
                <MenuItem value={"Minor"}>Minor</MenuItem>
                <MenuItem value={"Moderate"}>Moderate</MenuItem>
                <MenuItem value={"Observation"}>Observation</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Timeframe */}
          <Grid item xs={2}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Timeframe
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-timeframe-select-label"
                id="demo-timeframe-select"
                value={data.timeframe}
                defaultValue={data.timeframe}
                label=""
                onChange={handleChangeOfTimeFrame}
              >
                <MenuItem value={"30 days"}>30 days</MenuItem>
                <MenuItem value={"60 days"}>60 days</MenuItem>
                <MenuItem value={"90 days"}>90 days</MenuItem>
                <MenuItem value={"120 days"}>120 days</MenuItem>
                <MenuItem value={"180 days"}>180 days</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Rating Explanation */}
          <Grid item xs={7}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Rating Explanation
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.ratingExplanation}
            </Typography>
          </Grid>

          {/* Internal Note */}
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Internal Note
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={data.note}
              inputProps={{
                style: {
                  WebkitTextFillColor: "#45464E",
                  color: "#45464E",
                },
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* CAP Recommendation Section */}
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
              }}
            >
              CAP Recommendations
            </Typography>
            <RecommendationsList
              title="Immediate Corrective Action Plan"
              items={
                data?.capRecommendations?.immediateCAP
                  ? data?.capRecommendations?.immediateCAP
                  : []
              }
            />
            <RecommendationsList
              title="Long Term Preventative Action"
              items={
                data?.capRecommendations?.longTermCAP
                  ? data?.capRecommendations?.longTermCAP
                  : []
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
              }}
            >
              Factory Comment
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={8} sx={{ my: 3 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Accountable Person
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {data.responsiblePerson}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ my: 3 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Target Deadline
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {data.targetDeadline && data.timeline !== ""
                    ? data.targetDeadline
                    : "-"}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Manufacturer Comment
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.manufacturerComment || "-"}
            </Typography>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                my: 3,
              }}
            >
              CAP Progress
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Status
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CustomChipLabel
                    cap={data.statusChipLabel}
                    capLabel={data.status}
                  />
                  <Box sx={{ minWidth: "8px" }}></Box>
                  {data.timeline && (
                    <CustomChipLabel cap={"default"} capLabel={data.timeline} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Completion Date
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {data.completionDate || "-"}
                </Typography>
              </Grid>
            </Grid>

            

            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mt: 3,
              }}
            >
              Verification Note
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
                mb: 3,
              }}
            >
              {data.verificationNote || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by {data.lastUpdatedBy} - {data.lastUpdatedDate}
        </Typography>
      </Box>
    </Container>
  );
};

export default EditAuditReport;
