import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  selectAuditPageTab,
  selectAuditReportPageTab,
} from "../../selectors/audit.selector";
import {
  setAuditPageTab,
  setAuditReportPageTab,
} from "../../reducers/audit.reducer";
import { useTenant } from "../../context/TenantContext";
const AuditReportTabs = () => {
  const { auditConfig } = useTenant();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setAuditReportPageTab(newValue));
  };
  const value = useSelector(selectAuditReportPageTab);
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        {auditConfig?.findingDetailsSection?.leftSideNavigation?.tabs?.map(
          (tab) =>
            tab.display && (
              <Tab
                key={`${tab.tab}-${tab.label}`}
                value={tab.tab}
                label={tab.label}
                sx={{ textTransform: "none" }}
              />
            )
        )}
      </Tabs>
    </Box>
  );
};

export default AuditReportTabs;
