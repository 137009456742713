import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Box } from "@mui/material";

import { getListOfAllSuppliers } from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";

import { selectSupplierList } from "../../selectors/supplier.selector";

import { useTenant } from "../../context/TenantContext";

const SupplierInsightsMetaDataGrid = () => {
  const { insightsConfig } = useTenant();
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);

  const rows = suppliersList.map((supplier) => {
    return {
      id: supplier?.supplierId ?? "",
      associatedSupplierId: supplier?.metadata?.associatedSupplierId ?? "",
      supplierIdFromMetadata: supplier?.metadata?.uniqueSupplierId ?? "",
      supplierName: supplier?.name ?? "",
      supplierAlias: supplier?.metadata?.alias ?? "",
      supplierAddress: supplier?.metadata?.companyAddress ?? "",
      contactName: supplier?.metadata?.contactPerson?.name ?? "",
      contactPosition: supplier?.metadata?.contactPerson?.position ?? "",
      contactPhone: supplier?.metadata?.contactPerson?.email ?? "",
      productCategories: supplier?.metadata?.productCategories ?? "",
      productSubcategories: supplier?.metadata?.productSubcategories ?? "",
      topProductGroup: supplier?.metadata?.topProduct ?? "",
      privateLabel: supplier?.metadata?.privateLabel === "Yes" ? "Yes" : "No",
      country: supplier?.country ?? "",
      spend: supplier?.metadata?.spend ?? "",
      percentageOfBusiness: supplier?.metadata?.percentageOfBusiness ?? "",

      vendorStatus: supplier?.metadata?.vendorStatus ?? "",
      contactPersonNote: supplier?.metadata?.contactPerson?.contactNote ?? "",
      note: supplier?.metadata?.note ?? [],
      streetAddress: supplier?.metadata?.streetAddress ?? "",
      city: supplier?.metadata?.city ?? "",
      stateProvince: supplier?.metadata?.stateProvince ?? "",
      zipCode: supplier?.metadata?.zipCode ?? "",
      spend2023: supplier?.metadata?.spend
        ? Number(Number(supplier.metadata.spend).toFixed(2))
        : "",
      formattedSpend2023: supplier?.metadata?.spend
        ? `$${Number(supplier.metadata.spend).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`
        : "",
      spend2024: supplier?.metadata?.spend2024
        ? Number(Number(supplier.metadata.spend2024).toFixed(2))
        : "",
      formattedSpend2024: supplier?.metadata?.spend2024
        ? `$${Number(supplier.metadata.spend2024).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}`
        : "",
      status: supplier?.status ?? "",
      totalSites: supplier?.factoryData?.totalFactories ?? "",
      totalActiveSites: supplier?.factoryData?.totalActiveFactories ?? "",
      envTotalSqFt:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general?.sqFt ??
        "",
      envTotalEmissions:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general
          ?.mtCO2e ?? "",
      envEmissionsIntensity:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general
          ?.mtCO2ePerSqFt ?? "",
      envCarbonNeutral:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general
          ?.isCarbonNeutral ?? "",
      envIsVerified:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general
          ?.isVerified ?? "",
      envValidationOrg:
        supplier?.metadata?.environmental?.["2023SelfReport"]?.general
          ?.validationOrganization ?? "",
    };
  });

  const columns = insightsConfig?.supplierDataGrid?.columns?.map((col) => {
    if (col.renderCell === "status") {
      return {
        ...col,
        valueFormatter: (params) =>
          params
            ? params.charAt(0).toUpperCase() + params.slice(1).toLowerCase()
            : "‑",
        renderCell: (params) =>
          params.value
            ? params.value.charAt(0).toUpperCase() +
              params.value.slice(1).toLowerCase()
            : "-",
      };
    } else if (col.renderCell === "envCarbonNeutral") {
      return {
        ...col,
        renderCell: (params) =>
          params.value === true ? "Yes" : params.value === false ? "No" : "",
      };
    } else if (col.renderCell === "envIsVerified") {
      return {
        ...col,
        renderCell: (params) =>
          params.value === true ? "Yes" : params.value === false ? "No" : "",
      };
    }
    return col;
  });

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  useEffect(() => {
    dispatch(getListOfAllSuppliers({ page: 1, limit: 999, offset: 0 }));
  }, [dispatch]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 250px)",
        width: "100%",
        padding: "24px 80px",
      }}
    >
      <DataGrid
        {...dataGridFormat}
        disableDensitySelector
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default SupplierInsightsMetaDataGrid;
