import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  Chip,
  Autocomplete,
} from "@mui/material";

import { ComponentStyles, FacilityStyles } from "../../../styles";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const EditModalSwitchCases = ({
  field,
  value,
  formData,
  setFormData,
  addContact,
  handleChange,
  initData,
  suppliers,
  countries,
  supplierName,
  additionalContact = false,
  contact,
  index,
  handleContactChange,
}) => {
  switch (true) {
    case field && field === "supplierName":
      return (
        <Grid item xs={4}>
          <Typography>
            <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: "#3538CD",
                display: "flex",
                textDecoration: "none",
              }}
            >
              {supplierName}
            </Typography>
          </Typography>
        </Grid>
      );
    case field && field === "suppliers" && value?.isDropdown:
      return (
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="supplier-select"
            options={suppliers?.map((supplier) => ({
              value: supplier.supplierId,
              label: supplier.name,
            }))}
            value={formData.supplierIds
              .map((id) => {
                const supplier = suppliers.find((s) => s.supplierId === id);
                return supplier
                  ? {
                      value: supplier.supplierId,
                      label: supplier.name,
                    }
                  : null;
              })
              .filter(Boolean)}
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                supplierIds: newValue.map((option) => option.value),
              });
            }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Suppliers"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.value}
                  label={option.label}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor:
                      option.value === initData?.supplierId
                        ? "#4caf50"
                        : undefined,
                    color:
                      option.value === initData?.supplierId
                        ? "white"
                        : undefined,
                  }}
                />
              ))
            }
          />
        </Grid>
      );
    case field && field === "country" && value?.isDropdown:
      return (
        <Grid item xs={value.width}>
          <TextField
            select
            fullWidth
            label="Country"
            name="country"
            variant="outlined"
            value={formData.country}
            onChange={handleChange}
            margin="normal"
            SelectProps={{
              native: true,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px",
                },
              },
            }}
          >
            <option value="">Select a country</option>
            {countries?.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </TextField>
        </Grid>
      );
    case field && field === "country" && value?.isDropdown:
      return (
        <Grid item xs={value.width}>
          <TextField
            select
            fullWidth
            label={value.fieldName}
            name={field}
            variant="outlined"
            value={formData[field]}
            onChange={handleChange}
            margin="normal"
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px", // Set the border-radius here
                },
              },
            }}
          >
            {value?.options?.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </TextField>
        </Grid>
      );
    case field && field === "country" && value?.isDate:
      return (
        <Grid item xs={value.width}>
          <TextField
            fullWidth
            label={value.fieldName}
            name={field}
            type="date"
            variant="outlined"
            value={formData[field]}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px", // Set the border-radius here
                },
              },
            }}
          />
        </Grid>
      );
    case field && field?.includes("divider"):
      return (
        <Grid item xs={value.width}>
          <Divider sx={{ mt: 3, mb: 3, width: "100%" }} />
        </Grid>
      );
    case field && field === "addNewContactButton":
      return (
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={addContact}
            sx={{
              color: "#585aeb",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(88, 90, 235, 0.04)",
              },
            }}
          >
            Add Another Contact
          </Button>
        </Grid>
      );
    case field && field === "spend" && value?.identifier === "spend":
      return (
        <Grid item xs={value.width}>
          <TextField
            fullWidth
            label="Spend"
            name="spend"
            variant="outlined"
            value={formData.spend || 0}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, "");
              setFormData({
                ...formData,
                spend: value,
              });
            }}
            margin="normal"
            InputProps={{
              startAdornment: <span>$</span>,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px",
                },
              },
            }}
          />
        </Grid>
      );
    case field && additionalContact:
      return (
        <>
          <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Contact Person {index + 1}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Contact Person"
                value={contact.contactPersonName}
                onChange={(e) =>
                  handleContactChange(
                    index,
                    "contactPersonName",
                    e.target.value
                  )
                }
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Position"
                value={contact.contactPersonPosition}
                onChange={(e) =>
                  handleContactChange(
                    index,
                    "contactPersonPosition",
                    e.target.value
                  )
                }
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone"
                value={contact.contactPersonPhone}
                onChange={(e) =>
                  handleContactChange(
                    index,
                    "contactPersonPhone",
                    e.target.value
                  )
                }
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                value={contact.contactPersonEmail}
                onChange={(e) =>
                  handleContactChange(
                    index,
                    "contactPersonEmail",
                    e.target.value
                  )
                }
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, mb: 3 }} />
        </>
      );
    default:
      return (
        <Grid item xs={value?.width}>
          <TextField
            fullWidth
            label={value?.fieldName}
            name={field}
            variant="outlined"
            value={formData[field]}
            onChange={handleChange}
            margin="normal"
            rows={value?.rows ? value?.rows : 1}
            multiline={value?.rows ? true : false}
            disabled={value?.isDisabled}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "8px", // Set the border-radius here
                },
              },
            }}
            helperText={
              <Typography
                variant="body2"
                sx={{ color: "#667085", fontSize: "14px" }}
              >
                {value?.label}
              </Typography>
            }
          />
        </Grid>
      );
  }
};

export default EditModalSwitchCases;
