import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Box,
  Chip,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LanguageIcon from "@mui/icons-material/Language";
import SecurityIcon from "@mui/icons-material/Security";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FactoryIcon from "@mui/icons-material/Factory";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import { FacilityStyles } from "../../styles";

import { useSelector, useDispatch } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { setFactoryPageTab } from "../../reducers/factory.reducer";
import moment from "moment";

const ComprehensiveRiskDashboard = ({ selectedFactory }) => {
  const dispatch = useDispatch();
  
  // Get the factory status from selectedFactory or use a default value
  const actualStatus = selectedFactory?.metadata?.status || "Normal";
  const [factoryStatus, setFactoryStatus] = useState(actualStatus);
  
  // State for collapsible sections
  const [breakdownExpanded, setBreakdownExpanded] = useState(true);
  const [actionsExpanded, setActionsExpanded] = useState(true);
  
  // State for email confirmation modal
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailModalType, setEmailModalType] = useState(""); // "questionnaire" or "audit"
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  
  // Update factoryStatus whenever selectedFactory changes
  useEffect(() => {
    setFactoryStatus(selectedFactory?.metadata?.status || "Normal");
  }, [selectedFactory]);
  
  // Toggle between statuses for demo purposes only
  const cycleFactoryStatus = () => {
    if (factoryStatus === "Normal") {
      setFactoryStatus("Needs Risk Questionnaire");
    } else if (factoryStatus === "Needs Risk Questionnaire") {
      setFactoryStatus("Needs Audit Review");
    } else {
      setFactoryStatus("Normal");
    }
  };
  
  // Mock data for inherent risk assessment - using 0-10 scale (0 = worst/highest risk, 10 = best/lowest risk)
  const inherentRiskData = [
    {
      name: "Labor Rights",
      score: 4,
      dataSource: "ILO Statistics",
      lastUpdated: "2025-03-10"
    },
    {
      name: "Corruption",
      score: 7,
      dataSource: "World Bank Governance Indicators",
      lastUpdated: "2025-03-15"
    },
    {
      name: "Bribery",
      score: 5,
      dataSource: "TRACE Bribery Risk Matrix",
      lastUpdated: "2025-03-05"
    },
    {
      name: "Forced Labor",
      score: 3,
      dataSource: "Walk Free Global Slavery Index",
      lastUpdated: "2025-02-28"
    }
  ];
  
  // Function to get color based on score (0-10 scale)
  const getScoreColor = (score) => {
    if (score <= 3) return "#F04438"; // High risk - red
    if (score <= 6) return "#F79009"; // Medium risk - orange
    return "#12B76A"; // Low risk - green
  };
  
  // Add color to each risk data item
  inherentRiskData.forEach(item => {
    item.color = getScoreColor(item.score);
  });
  
  // Strategic importance data - on our 0-100 scale, LOWER numbers = HIGHER risk
  const factoryImportance = {
    level: "High",
    score: 25, // 0-100 scale for calculations (high risk = low score)
    color: "#F04438",
    description: "Critical facility for core product lines"
  };
  
  const factorySpend = {
    level: "Medium",
    score: 50, // 0-100 scale for calculations (medium risk = medium score)
    color: "#F79009",
    amount: 2500000,
    description: "Annual spend"
  };
  
  // Audit risk data
  const auditRiskData = {
    lastAuditDate: selectedFactory?.metadata?.socialAssessmentInfo?.lastAssessmentDate || "2024-08-15",
    expirationDate: "2025-08-15",
    findings: [
      { category: "Labor Practices", status: "Compliant", color: "#12B76A" },
      { category: "Working Hours", status: "Minor Issues", color: "#F79009" },
      { category: "Facility Safety", status: "Compliant", color: "#12B76A" }
    ],
    questionnaireCompleted: factoryStatus !== "Needs Risk Questionnaire"
  };
  
  // Calculate risk levels for 0-100 scale (used for audit risk and overall score)
  const getRiskLevel = (score) => {
    if (score <= 20) return { level: "High Risk", color: "#F04438" };
    if (score <= 50) return { level: "Medium Risk", color: "#F79009" };
    return { level: "Low Risk", color: "#12B76A" };
  };
  
  // Calculate risk levels for 0-10 scale (used for inherent risk only)
  const getRiskLevel0to10 = (score) => {
    if (score <= 3) return { level: "High Risk", color: "#F04438" };
    if (score <= 6) return { level: "Medium Risk", color: "#F79009" };
    return { level: "Low Risk", color: "#12B76A" };
  };
  
  // Calculate inherent risk score (average of risk scores on 0-10 scale)
  const calculateInherentRiskScore = () => {
    const sum = inherentRiskData.reduce((acc, source) => acc + source.score, 0);
    return Number((sum / inherentRiskData.length).toFixed(1)); // Round to 1 decimal place
  };
  
  // Get the explanation for how inherent risk is calculated
  const getInherentRiskExplanation = () => {
    return `Average of all risk factor scores (${inherentRiskData.map(item => item.name).join(', ')})`;
  };
  
  // Calculate base scores using original formulas
  const baseInherentRiskScore = calculateInherentRiskScore();
  const baseInherentRiskLevel = getRiskLevel0to10(baseInherentRiskScore);
  const inherentRiskExplanation = getInherentRiskExplanation();
  
  // Convert 0-10 scale to 0-100 scale for the overall calculation
  // For 0-10 scale, lower scores indicate higher risk (e.g., 3 is high risk)
  // For 0-100 scale, we maintain that lower scores = higher risk
  const inherentRiskScore100 = Math.round(baseInherentRiskScore * 10);
  
  // Calculate strategic importance score on a 1-10 scale
  const calculateStrategicImportanceScore = () => {
    // Convert factoryImportance level to 1-10 scale
    let importanceScore;
    if (factoryImportance.level === "High") {
      importanceScore = 10; // High importance = highest risk score (10)
    } else if (factoryImportance.level === "Medium") {
      importanceScore = 5; // Medium importance = medium risk score (5)
    } else {
      importanceScore = 1; // Low importance = lowest risk score (1)
    }
    
    // Convert factory spend to 1-10 scale
    // Higher spend = higher risk score
    let spendScore;
    if (factorySpend.amount >= 5000000) {
      spendScore = 10; // High spend
    } else if (factorySpend.amount >= 1000000) {
      spendScore = 5; // Medium spend
    } else {
      spendScore = 1; // Low spend
    }
    
    // Calculate the average of importance and spend scores
    return ((importanceScore + spendScore) / 2).toFixed(1) * 1;
  };
  
  // Get the strategic importance level based on the 1-10 score
  const getStrategicImportanceLevel = () => {
    // Get risk level based on the 1-10 score
    const score = calculateStrategicImportanceScore();
    
    // Map scores to risk levels
    if (score >= 8) {
      return { level: "High Risk", color: "#F04438" };
    } else if (score >= 4) {
      return { level: "Medium Risk", color: "#F79009" };
    } else {
      return { level: "Low Risk", color: "#12B76A" };
    }
  };
  
  // Get the explanation for how strategic importance is calculated
  const getStrategicImportanceExplanation = () => {
    return "Strategic importance is calculated based on this facility's criticality to your supply chain and annual spend. Facilities producing core product lines or with high annual spend are assigned higher risk scores.";  
  };
  
  // Calculate the base strategic importance score using the original formula
  const baseStrategicImportanceScore = calculateStrategicImportanceScore();
  const baseStrategicImportanceLevel = getStrategicImportanceLevel();
  const strategicImportanceExplanation = getStrategicImportanceExplanation();
  
  // Set risk scores based on factory status to ensure mathematical consistency
  const deriveRiskScoresByStatus = () => {
    // Define target scores based on factory status
    let targetInherentRiskScore;
    let targetStrategicImportanceScore;
    let targetOverallScore;
    
    // Set consistent scores based on factory status
    switch(factoryStatus) {
      case "Needs Audit Review": // Highest priority - P1
        // For P1, overall score should be 7.0-8.5 range
        targetOverallScore = 8.0;
        // Component scores should be high but slightly lower than overall
        targetInherentRiskScore = 7.5;
        targetStrategicImportanceScore = 7.5;
        break;
        
      case "Needs Risk Questionnaire": // Medium priority - P2
        // For P2, overall score should be 5.0-6.5 range
        targetOverallScore = 5.5;
        // Component scores should be medium
        targetInherentRiskScore = 4.8;
        targetStrategicImportanceScore = 5.2;
        break;
        
      default: // "Normal" - Lowest priority - P3
        // For P3, overall score should be 2.0-3.5 range
        targetOverallScore = 2.5;
        // Component scores should be low
        targetInherentRiskScore = 2.2;
        targetStrategicImportanceScore = 2.8;
        break;
    }
    
    // Return fixed scores that are mathematically consistent
    return {
      targetOverallScore: Number(targetOverallScore.toFixed(1)),
      adjustedInherentRiskScore: Number(targetInherentRiskScore.toFixed(1)),
      adjustedStrategicScore: Number(targetStrategicImportanceScore.toFixed(1))
    };
  };
  
  // Get dynamically adjusted risk scores
  const adjustedScores = deriveRiskScoresByStatus();
  
  // Use the adjusted scores for display
  const inherentRiskScoreDisplay = adjustedScores.adjustedInherentRiskScore;
  const strategicImportanceScore = adjustedScores.adjustedStrategicScore;
  

  
  // Map scores to risk levels consistently
  const mapScoreToRiskLevel = (score) => {
    if (score >= 7) return { level: "High Risk", color: "#F04438", priority: "P1" };
    if (score >= 4) return { level: "Medium Risk", color: "#F79009", priority: "P2" };
    return { level: "Low Risk", color: "#12B76A", priority: "P3" };
  };
  
  // Define risk levels based on the adjusted scores
  const inherentRiskLevel = mapScoreToRiskLevel(inherentRiskScoreDisplay);
  const strategicImportanceLevel = mapScoreToRiskLevel(strategicImportanceScore);
  
  // Use the pre-calculated overall risk score that matches the factory status
  const calculateOverallRiskScore = () => {
    // Simply return the pre-calculated score that matches the factory status
    return adjustedScores.targetOverallScore;
  };

  // Get priority display info based on factory status
  const getPriorityInfo = () => {
    if (factoryStatus === "Needs Audit Review") {
      return { 
        value: "P1", 
        color: "#F04438",
        bgColor: "#FEF3F2",
        label: "Needs Audit Review",
        icon: "⚠️"
      };
    } else if (factoryStatus === "Needs Risk Questionnaire") {
      return { 
        value: "P2", 
        color: "#F79009",
        bgColor: "#FFF4ED",
        label: "Needs Risk Questionnaire",
        icon: "⚠️"
      };
    } else {
      return { 
        value: "P3", 
        color: "#12B76A",
        bgColor: "#ECFDF3",
        label: "Normal",
        icon: "✓"
      };
    }
  };
  
  const priorityInfo = getPriorityInfo();
  
  // Get the pre-calculated overall risk score that is consistent with component scores
  const overallCombinedRiskScore = calculateOverallRiskScore();
  
  // Map the score to the appropriate risk level
  const overallCombinedRiskLevel = mapScoreToRiskLevel(overallCombinedRiskScore);
  const overallCombinedRiskExplanation = "This overall risk score is calculated by combining Inherent Risk (50%) and Strategic Importance (50%). The score helps you prioritize resources across your supply chain by identifying the facilities with the highest combined risk factors.";
  
  // Determine if action is needed based on factory status
  const needsAction = factoryStatus !== "Normal";
  
  // Determine primary action based on factory status
  const getPrimaryAction = () => {
    switch(factoryStatus) {
      case "Needs Risk Questionnaire":
        return {
          text: "Send Risk Questionnaire",
          action: () => {
            setEmailModalType("questionnaire");
            setEmailModalOpen(true);
          }
        };
      case "Needs Audit Review":
        return {
          text: "Request Audit Documents",
          action: () => {
            setEmailModalType("audit");
            setEmailModalOpen(true);
          }
        };
      default:
        return null;
    }
  };
  
  // Handle email confirmation
  const handleSendEmail = () => {
    // Close the confirmation modal
    setEmailModalOpen(false);
    
    // Show success message
    setSuccessModalOpen(true);
    
    // Auto-close success message after 3 seconds
    setTimeout(() => {
      setSuccessModalOpen(false);
      
      // If it was an audit request, navigate to the audits tab
      if (emailModalType === "audit") {
        dispatch(setFactoryPageTab("audits"));
      }
    }, 3000);
  };
  
  // Handle modal close
  const handleCloseModal = () => {
    setEmailModalOpen(false);
    setSuccessModalOpen(false);
  };
  
  const primaryAction = getPrimaryAction();
  

  
  // Toggle breakdown expanded state
  const toggleBreakdown = () => {
    setBreakdownExpanded(!breakdownExpanded);
  };
  
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ ...FacilityStyles.mostRecentAuditHeading }}>
          Comprehensive Risk Assessment
        </Typography>
        
        {/* This button is only for demonstration purposes */}
        <Button 
          variant="outlined" 
          size="small" 
          onClick={cycleFactoryStatus}
          sx={{ display: process.env.NODE_ENV === 'production' ? 'none' : 'block' }}
        >
          Demo: Change Status ({factoryStatus})
        </Button>
      </Box>
      
      {/* SECTION 1: ACTIONS SECTION */}
      <Box sx={{ mb: 2 }}>
        <Button
          fullWidth
          onClick={() => setActionsExpanded(!actionsExpanded)}
          endIcon={actionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{
            justifyContent: 'space-between',
            backgroundColor: '#F9FAFB',
            borderRadius: 2,
            p: 2,
            '&:hover': {
              backgroundColor: '#F2F4F7'
            }
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#101828", textTransform: 'none' }}>
            Recommended actions as per company guidelines
          </Typography>
        </Button>
      </Box>
      
      <Collapse in={actionsExpanded}>
        <Grid container spacing={3} sx={{ marginBottom: 4 }}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, boxShadow: "0px 4px 8px rgba(16, 24, 40, 0.08)" }}>
              <CardContent>
              
              {/* Action content based on status */}
              {needsAction ? (
                <Box>
                  {/* Combined status and reasons in a side-by-side layout */}
                  <Box sx={{ 
                    display: 'flex',
                    mb: 3,
                    borderRadius: 2,
                    border: '1px solid #EAECF0',
                    overflow: 'hidden'
                  }}>
                    {/* Left side - Status */}
                    <Box sx={{ 
                      width: '30%',
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      backgroundColor: priorityInfo.bgColor
                    }}>
                      {/* Priority badge */}
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        backgroundColor: priorityInfo.color,
                        color: 'white',
                        borderRadius: '16px',
                        px: 1.5,
                        py: 0.5,
                        mb: 2,
                        fontWeight: 700
                      }}>
                        {priorityInfo.value}
                      </Box>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography 
                          variant="h5" 
                          sx={{ 
                            fontWeight: 600, 
                            color: priorityInfo.color,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ marginRight: '8px' }}>{priorityInfo.icon}</span>
                          {priorityInfo.label}
                        </Typography>
                      </Box>
                      
                      {/* Action button inside colored section - color based on status */}
                      {primaryAction && (
                        <Button 
                          variant="contained" 
                          size="medium"
                          onClick={primaryAction.action}
                          startIcon={factoryStatus === "Needs Audit Review" ? <WarningAmberIcon /> : <AssignmentIcon />}
                          sx={{ 
                            mt: 1,
                            borderRadius: "8px", 
                            textTransform: "none",
                            fontWeight: 600,
                            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                            bgcolor: priorityInfo.color,
                            '&:hover': {
                              bgcolor: factoryStatus === "Needs Audit Review" 
                                ? "#D92D20" 
                                : factoryStatus === "Needs Risk Questionnaire"
                                  ? "#DC6803"
                                  : "#027A48"
                            }
                          }}
                        >
                          {primaryAction.text}
                        </Button>
                      )}
                    </Box>
                    
                    {/* Right side - Reasoning */}
                    <Box sx={{ width: '70%', p: 3 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1.5, color: '#344054' }}>
                        Why This Action Is Required:
                      </Typography>
                      <List sx={{ p: 0 }}>
                        {factoryStatus === "Needs Risk Questionnaire" ? (
                          <>
                            <ListItem sx={{ p: 0.5, borderBottom: '1px solid #F2F4F7' }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Previous risk assessment has expired" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                            <ListItem sx={{ p: 0.5, borderBottom: '1px solid #F2F4F7' }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Country inherent risk score is high" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                            <ListItem sx={{ p: 0.5 }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Annual assessment is required by policy" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                          </>
                        ) : (
                          <>
                            <ListItem sx={{ p: 0.5, borderBottom: '1px solid #F2F4F7' }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Questionnaire has been completed" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                            <ListItem sx={{ p: 0.5, borderBottom: '1px solid #F2F4F7' }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Responses indicate potential risk areas" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                            <ListItem sx={{ p: 0.5 }}>
                              <ListItemIcon sx={{ minWidth: 32, color: '#667085' }}>
                                <ArrowRightIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Independent audit verification is required" primaryTypographyProps={{ color: '#344054' }} />
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Box>
                  </Box>

                </Box>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  p: 3,
                  backgroundColor: 'rgba(18, 183, 106, 0.08)',
                  borderRadius: 2
                }}>
                  <CheckCircleIcon sx={{ color: "#12B76A", fontSize: 36, mr: 2 }} />
                  <Box>
                    <Typography variant="h6" sx={{ color: '#12B76A', fontWeight: 600, mb: 0.5 }}>
                      No Action Required
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#027A48' }}>
                      This facility is currently compliant with all requirements. 
                      Overall combined risk score is {overallCombinedRiskScore}/100 ({overallCombinedRiskLevel.level}).
                    </Typography>
                  </Box>
                </Box>
              )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Collapse>
      
      {/* SECTION 2: RISK-BASED PRIORITIZATION */}
      <Box sx={{ mb: 2 }}>
        <Button 
          onClick={toggleBreakdown}
          endIcon={breakdownExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            textTransform: 'none',
            backgroundColor: '#F9FAFB',
            borderRadius: 2,
            p: 2,
            '&:hover': {
              backgroundColor: '#F2F4F7'
            }
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#101828" }}>
            Risk-based Prioritization Breakdown
          </Typography>
        </Button>
      </Box>
      
      <Collapse in={breakdownExpanded}>
        {/* Risk-based prioritization grid */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* 1. OVERALL COMBINED RISK SCORE */}
        <Grid item xs={4}>
          <Card sx={{ 
            borderRadius: 2, 
            boxShadow: "0px 4px 8px rgba(16, 24, 40, 0.08)", 
            height: '100%',
            position: 'relative',
            overflow: 'visible' 
          }}>
            {/* Visual indicator showing this is a combination of the other cards */}
            <Box sx={{ 
              position: 'absolute', 
              top: -10, 
              right: -10, 
              backgroundColor: '#0BA5EC', 
              color: 'white',
              fontSize: '0.75rem',
              fontWeight: 600,
              padding: '4px 8px',
              borderRadius: '8px',
              boxShadow: '0px 2px 4px rgba(16, 24, 40, 0.1)',
              zIndex: 1
            }}>
              Combined Score
            </Box>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <AssessmentIcon sx={{ color: overallCombinedRiskLevel.color, mr: 1.5 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Overall Combined Risk Score
                </Typography>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    {/* Priority Badge P1/P2/P3 */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: overallCombinedRiskLevel.color,
                      color: 'white',
                      borderRadius: '50%',
                      width: '28px',
                      height: '28px',
                      fontWeight: 700,
                      mr: 1,
                      fontSize: '0.85rem'
                    }}>
                      {overallCombinedRiskLevel.priority}
                    </Box>
                    {/* Risk Level Chip */}
                    <Chip 
                      label={overallCombinedRiskLevel.level} 
                      sx={{ 
                        backgroundColor: `${overallCombinedRiskLevel.color}20`, 
                        color: overallCombinedRiskLevel.color,
                        fontWeight: 700,
                        fontSize: '1.1rem',
                        height: '36px',
                      }} 
                    />
                  </Box>
                  <Typography variant="h3" sx={{ fontWeight: 700, color: overallCombinedRiskLevel.color }}>
                    {overallCombinedRiskScore}
                  </Typography>
                </Box>
                <Typography variant="caption" sx={{ color: '#667085', fontStyle: 'italic' }}>
                  {overallCombinedRiskExplanation}
                </Typography>
              </Box>
              
              {/* Visual indicators of what's combined */}
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ mt: 2 }}>
  <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>Risk Prioritization Scale</Typography>
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',
      p: 1,
      borderRadius: 1,
      backgroundColor: overallCombinedRiskLevel.level === 'High Risk' ? '#FEF3F2' : '#F9FAFB',
      width: '100%'
    }}>
      <Box sx={{ 
        width: '24px', 
        height: '24px', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: overallCombinedRiskLevel.level === 'High Risk' ? '#F04438' : '#D0D5DD',
        color: 'white',
        fontWeight: 700,
        mr: 1,
        fontSize: '0.75rem'
      }}>
        P1
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" sx={{ fontWeight: 600, color: overallCombinedRiskLevel.level === 'High Risk' ? '#D92D20' : '#667085', display: 'block' }}>
          Highest Priority
        </Typography>
        <Typography variant="caption" sx={{ color: '#667085' }}>
          Requires audits & questionnaires
        </Typography>
      </Box>
      {overallCombinedRiskLevel.level === 'High Risk' && (
        <CheckCircleIcon sx={{ color: '#F04438', ml: 1 }} />
      )}
    </Box>
    
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',
      p: 1,
      borderRadius: 1,
      backgroundColor: overallCombinedRiskLevel.level === 'Medium Risk' ? '#FFF4ED' : '#F9FAFB',
      width: '100%'
    }}>
      <Box sx={{ 
        width: '24px', 
        height: '24px', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: overallCombinedRiskLevel.level === 'Medium Risk' ? '#F79009' : '#D0D5DD',
        color: 'white',
        fontWeight: 700,
        mr: 1,
        fontSize: '0.75rem'
      }}>
        P2
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" sx={{ fontWeight: 600, color: overallCombinedRiskLevel.level === 'Medium Risk' ? '#B54708' : '#667085', display: 'block' }}>
          Medium Priority
        </Typography>
        <Typography variant="caption" sx={{ color: '#667085' }}>
          Requires questionnaires only
        </Typography>
      </Box>
      {overallCombinedRiskLevel.level === 'Medium Risk' && (
        <CheckCircleIcon sx={{ color: '#F79009', ml: 1 }} />
      )}
    </Box>
    
    <Box sx={{ 
      display: 'flex',
      alignItems: 'center',
      p: 1,
      borderRadius: 1,
      backgroundColor: overallCombinedRiskLevel.level === 'Low Risk' ? '#ECFDF3' : '#F9FAFB',
      width: '100%'
    }}>
      <Box sx={{ 
        width: '24px', 
        height: '24px', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: overallCombinedRiskLevel.level === 'Low Risk' ? '#12B76A' : '#D0D5DD',
        color: 'white',
        fontWeight: 700,
        mr: 1,
        fontSize: '0.75rem'
      }}>
        P3
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" sx={{ fontWeight: 600, color: overallCombinedRiskLevel.level === 'Low Risk' ? '#027A48' : '#667085', display: 'block' }}>
          Lowest Priority
        </Typography>
        <Typography variant="caption" sx={{ color: '#667085' }}>
          Monitor when time permits
        </Typography>
      </Box>
      {overallCombinedRiskLevel.level === 'Low Risk' && (
        <CheckCircleIcon sx={{ color: '#12B76A', ml: 1 }} />
      )}
    </Box>
  </Box>
</Box>
            </CardContent>
          </Card>
        </Grid>
        
        {/* 2. INHERENT RISK */}
        <Grid item xs={4}>
          <Card sx={{ borderRadius: 2, boxShadow: "0px 4px 8px rgba(16, 24, 40, 0.08)", height: '100%' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <LanguageIcon sx={{ color: inherentRiskLevel.color, mr: 1.5 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Inherent Risk
                </Typography>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    {/* Priority Badge based on risk level */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: inherentRiskLevel.color,
                      color: 'white',
                      borderRadius: '50%',
                      width: '24px',
                      height: '24px',
                      fontWeight: 700,
                      mr: 1,
                      fontSize: '0.75rem'
                    }}>
                      {/* Display P1/P2/P3 based on risk level */}
                      {inherentRiskLevel.level === 'High Risk' ? 'P1' : 
                       inherentRiskLevel.level === 'Medium Risk' ? 'P2' : 'P3'}
                    </Box>
                    {/* Risk Level Chip */}
                    <Chip 
                      label={inherentRiskLevel.level} 
                      sx={{ 
                        backgroundColor: `${inherentRiskLevel.color}20`, 
                        color: inherentRiskLevel.color,
                        fontWeight: 600,
                        fontSize: '1rem',
                        height: '32px',
                      }} 
                    />
                  </Box>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: inherentRiskLevel.color }}>
                    {inherentRiskScoreDisplay}
                  </Typography>
                </Box>
                <Typography variant="caption" sx={{ color: '#667085', fontStyle: 'italic' }}>
                  {inherentRiskExplanation}
                </Typography>
              </Box>
              
              <Divider sx={{ mb: 2 }} />
              
              {/* Risk score table */}
              <TableContainer component={Paper} sx={{ boxShadow: 'none', mb: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, width: '70%', border: 'none', pb: 2 }}>
                        Risk Factor
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 600, width: '30%', border: 'none', pb: 2 }}>
                        Score (0-10)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inherentRiskData.map((item, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ borderColor: '#F2F4F7', py: 1.5 }}>
                          <Box>
                            <Typography variant="body2">{item.name}</Typography>
                            <Typography variant="caption" sx={{ color: '#667085', fontStyle: 'italic', display: 'block', mt: 0.5 }}>
                              {item.dataSource}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center" sx={{ borderColor: '#F2F4F7', py: 1.5 }}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontWeight: 600, 
                              color: item.color,
                              display: 'inline-block',
                              backgroundColor: `${item.color}15`,
                              borderRadius: '4px',
                              px: 1.5,
                              py: 0.5,
                              minWidth: '30px'
                            }}
                          >
                            {item.score}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
           
            </CardContent>
          </Card>
        </Grid>
        
        {/* 2. STRATEGIC IMPORTANCE */}
        <Grid item xs={4}>
          <Card sx={{ borderRadius: 2, boxShadow: "0px 4px 8px rgba(16, 24, 40, 0.08)", height: '100%' }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <AttachMoneyIcon sx={{ color: strategicImportanceLevel.color, mr: 1.5 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  Strategic Importance
                </Typography>
              </Box>
              
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    {/* Priority Badge based on risk level */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: strategicImportanceLevel.color,
                      color: 'white',
                      borderRadius: '50%',
                      width: '24px',
                      height: '24px',
                      fontWeight: 700,
                      mr: 1,
                      fontSize: '0.75rem'
                    }}>
                      {/* Display P1/P2/P3 based on risk level */}
                      {strategicImportanceLevel.level === 'High' ? 'P1' : 
                       strategicImportanceLevel.level === 'Medium' ? 'P2' : 'P3'}
                    </Box>
                    {/* Risk Level Chip */}
                    <Chip 
                      label={strategicImportanceLevel.level} 
                      sx={{ 
                        backgroundColor: `${strategicImportanceLevel.color}20`, 
                        color: strategicImportanceLevel.color,
                        fontWeight: 600,
                        fontSize: '1rem',
                        height: '32px',
                      }} 
                    />
                  </Box>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: strategicImportanceLevel.color }}>
                    {strategicImportanceScore}
                  </Typography>
                </Box>
                <Typography variant="caption" sx={{ color: '#667085', fontStyle: 'italic' }}>
                  {strategicImportanceExplanation}
                </Typography>
              </Box>
              
              <Divider sx={{ mb: 2 }} />
              
              {/* Factory Importance */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Factory Importance
                  </Typography>
                  <Chip 
                    label={factoryImportance.level}
                    size="small"
                    sx={{ 
                      backgroundColor: `${factoryImportance.color}20`, 
                      color: factoryImportance.color,
                      fontWeight: 500,
                      height: 24,
                      minWidth: '60px'
                    }} 
                  />
                </Box>
                <Typography variant="body2" sx={{ color: "#667085", mt: 1 }}>
                  {factoryImportance.description}
                </Typography>
              </Box>
              
              {/* Factory Spend */}
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Factory Spend
                  </Typography>
                  <Chip 
                    label={factorySpend.level}
                    size="small"
                    sx={{ 
                      backgroundColor: `${factorySpend.color}20`, 
                      color: factorySpend.color,
                      fontWeight: 500,
                      height: 24,
                      minWidth: '60px'
                    }} 
                  />
                </Box>
                <Typography variant="body2" sx={{ fontWeight: 500, mt: 1 }}>
                  ${factorySpend.amount.toLocaleString()}
                </Typography>
                <Typography variant="body2" sx={{ color: "#667085", mt: 0.5 }}>
                  {factorySpend.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        

        </Grid>
      </Collapse>
      
      {/* Email Confirmation Modal */}
      <Dialog open={emailModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontWeight: 600 }}>
          {emailModalType === "questionnaire" ? "Send Risk Questionnaire" : "Request Audit Documents"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {emailModalType === "questionnaire" 
              ? "Do you want to send an email notification to this factory requesting them to complete the risk questionnaire?" 
              : "Do you want to send an email notification to this factory requesting audit documents?"}
          </Typography>
          <Typography variant="body2" sx={{ color: "#667085" }}>
            An email will be sent to: <strong>{selectedFactory?.email || "factory@example.com"}</strong>
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={handleCloseModal} 
            variant="outlined" 
            sx={{ 
              borderRadius: "8px", 
              textTransform: "none",
              fontWeight: 600,
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSendEmail} 
            variant="contained" 
            color={emailModalType === "questionnaire" ? "error" : "warning"}
            sx={{ 
              borderRadius: "8px", 
              textTransform: "none",
              fontWeight: 600,
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              bgcolor: emailModalType === "questionnaire" ? "#F04438" : "#F79009",
              '&:hover': {
                bgcolor: emailModalType === "questionnaire" ? "#D92D20" : "#DC6803"
              }
            }}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Success Modal */}
      <Dialog open={successModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogContent sx={{ textAlign: 'center', py: 4 }}>
          <CheckCircleIcon sx={{ color: "#12B76A", fontSize: 64, mb: 2 }} />
          <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
            Email Sent Successfully
          </Typography>
          <Typography variant="body1" sx={{ color: "#667085" }}>
            {emailModalType === "questionnaire" 
              ? "The risk questionnaire request has been sent to the factory." 
              : "The audit document request has been sent to the factory."}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ComprehensiveRiskDashboard;
