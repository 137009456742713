import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectAuditPageTab } from "../../selectors/audit.selector";
import { setAuditPageTab } from "../../reducers/audit.reducer";
import { setDueAuctionTabs } from "../../reducers/dueAction.reducer";
import { selectDueActionTabs } from "../../selectors/dueAction.selector";
import { useTenant } from "../../context/TenantContext";

const DueActionTabs = () => {
  const dispatch = useDispatch();
  const { dueActionConfig } = useTenant();
  const handleChange = (event, newValue) => {
    dispatch(setDueAuctionTabs(newValue));
  };
  const value = useSelector(selectDueActionTabs);
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1, px: 4, mt: 2 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        {dueActionConfig?.tabs?.map(
          (tab) =>
            tab.display && (
              <Tab
                key={tab.tab}
                value={tab.tab}
                label={tab.label}
                sx={{ textTransform: "none" }}
              />
            )
        )}
      </Tabs>
    </Box>
  );
};

export default DueActionTabs;
