import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { ComponentStyles } from "../../styles";
import FacilityTierMap from "../FacilityDetails/factoryTierMap.component";
import { useTenant } from "../../context/TenantContext";

const SupplierInformationDrawer = ({
  supplierData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const { supplierConfig } = useTenant();
  const params = useParams();

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 600, padding: 4, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            {supplierConfig?.generalInformation?.components?.moreInfo?.label}
          </Typography>

          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                Supplier Alias
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {supplierData?.metadata?.alias || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                Associated Supplier ID
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {typeof supplierData?.metadata?.associatedSupplierId ===
                "string"
                  ? supplierData?.metadata?.associatedSupplierId.replace(
                      /\n/g,
                      ", "
                    )
                  : supplierData?.metadata?.associatedSupplierId || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Additional Confidential Business Information
          </Typography>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                Product Subcategories
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {typeof supplierData?.metadata?.productSubcategories ===
                "string"
                  ? supplierData?.metadata?.productSubcategories.replace(
                      /\n/g,
                      ", "
                    )
                  : supplierData?.metadata?.productSubcategories || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {supplierConfig?.generalInformation?.components?.moreInfo?.fields
          ?.multipleContacts?.display &&
          Array.isArray(supplierData?.metadata?.additionalContacts) && (
            <>
              <Divider sx={{ mt: 3, mb: 3 }} />
              {supplierData?.metadata?.additionalContacts?.map(
                (contact, index) => (
                  <>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {supplierConfig?.generalInformation?.components
                          ?.moreInfo?.fields?.multipleContacts?.label ||
                          "Contact Person"}{" "}
                        {index + 1}
                      </Typography>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="space-between"
                      >
                        <Grid item xs={6} sx={{ pr: 3 }}>
                          <Typography sx={ComponentStyles.fieldName}>
                            Name
                          </Typography>
                          <Typography
                            sx={{
                              ...ComponentStyles.fieldValue,
                              wordBreak: "break-word",
                            }}
                          >
                            {contact?.contactPersonName || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ pr: 3 }}>
                          <Typography sx={ComponentStyles.fieldName}>
                            Position
                          </Typography>
                          <Typography
                            sx={{
                              ...ComponentStyles.fieldValue,
                              wordBreak: "break-word",
                            }}
                          >
                            {contact?.contactPersonPosition || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ pr: 3 }}>
                          <Typography sx={ComponentStyles.fieldName}>
                            Phone
                          </Typography>
                          <Typography
                            sx={{
                              ...ComponentStyles.fieldValue,
                              wordBreak: "break-word",
                            }}
                          >
                            {contact?.contactPersonPhone || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ pr: 3 }}>
                          <Typography sx={ComponentStyles.fieldName}>
                            Email
                          </Typography>
                          <Typography
                            sx={{
                              ...ComponentStyles.fieldValue,
                              wordBreak: "break-word",
                            }}
                          >
                            {contact?.contactPersonEmail || "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                  </>
                )
              )}
            </>
          )}
      </Box>
    </Drawer>
  );
};

export default SupplierInformationDrawer;
