import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  fetchSAQFactories,
  getListOfAllFactories,
  deleteSAQFacility,
} from "../../reducers/factory.reducer";
import { getListOfAllSuppliers } from "../../reducers/supplier.reducer";
import { selectSAQFactories } from "../../selectors/factory.selector";
import { selectFactoryDropdownData } from "../../selectors/factory.selector";
import { selectSupplierDropdownData } from "../../selectors/supplier.selector";
import { selectOrganisationId } from "../../selectors/login.selector";
import QuestionnaireDetailView from "./questionnaireDetailedView.component";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { loading as setLoading } from "../../reducers/misc.reducer";
import { selectLoadingState } from "../../selectors/misc.selector";

const QuestionnaireTable = () => {
  const dispatch = useDispatch();
  const saqFactories = useSelector(selectSAQFactories) || [];
  const factoryDropdownData = useSelector(selectFactoryDropdownData) || [];
  const supplierDropdownData = useSelector(selectSupplierDropdownData) || [];

  const organisationId = useSelector(selectOrganisationId);
  const [selectedSAQ, setSelectedSAQ] = useState(null);
  const [rows, setRows] = useState([]);

  const isLoading = useSelector(selectLoadingState);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    factoryId: null,
  });

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(fetchSAQFactories());
    dispatch(
      getListOfAllFactories({
        limit: 10,
        offset: 0,
        page: 1,
        search: "",
        supplierId: null,
        supplierFactoryList: false,
      })
    );
    dispatch(
      getListOfAllSuppliers({ page: 1, limit: 10, offset: 0, organisationId })
    );
  }, [dispatch]);

  useEffect(() => {
    if (saqFactories && saqFactories.length > 0) {
      dispatch(setLoading(false));
    }
  }, [saqFactories, dispatch]);

  useEffect(() => {
    // When saqFactories changes, update the rows
    const updatedRows = saqFactories.map((factory) => ({
      id: factory.factoryId,
      createdAt: factory.createdAt,
      name: factory.name,
      country: factory.metadata?.country,
      supplierName: factory.metadata?.supplierName,
      tier: factory.metadata?.tier,
      assignedToFacility: factory.assignedToFactory,
      supplyChainContact: factory.metadata?.supplyChainContact,
      supplyChainContactEmail: factory.metadata?.supplyChainContactEmail,
      supplyChainTeam: factory.metadata?.supplyChainTeam,
      onboardingStatus: factory.metadata?.onboardingStatus,
      ndaUploaded: factory.metadata?.nda?.fileUpload?.length > 0,
      spgUploaded: factory.metadata?.spgAor?.fileUpload?.length > 0,
      wccUploaded: factory.metadata?.wcc?.fileUpload?.length > 0,
      mraAuditUploaded:
        factory.metadata?.socialAssessmentInfo?.auditForMRA?.fileUpload
          ?.length > 0,
      mraCAPUploaded:
        factory.metadata?.socialAssessmentInfo?.capForMRA?.fileUpload?.length >
        0,
      domesticOrInternational:
        factory?.metadata?.country == "United States"
          ? "Domestic"
          : "International",
      garmentRegistrationUploaded:
        factory?.metadata?.country == "United States" &&
        factory.metadata?.businessLicenseInfo?.fileUpload?.length > 0,
      businessLicenseUploaded:
        factory?.metadata?.country != "United States" &&
        factory.metadata?.businessLicenseInfo?.fileUpload?.length > 0,
      saqContent: "",
    }));

    // Update the rows state
    setRows(updatedRows);
    // dispatch(setLoading(false));
  }, [saqFactories]);

  const DeleteConfirmationDialog = () => {
    const facilityToDelete = saqFactories.find(
      (f) => f.factoryId === deleteConfirmation.factoryId
    );

    const documentStatus = [
      {
        label:
          facilityToDelete?.metadata?.country == "United States"
            ? "Garment Registration"
            : "Business License",
        uploaded:
          facilityToDelete?.metadata?.businessLicenseInfo?.fileUpload?.length >
          0,
      },
      {
        label: "NDA",
        uploaded: facilityToDelete?.metadata?.nda?.fileUpload?.length > 0,
      },
      {
        label: "SPG",
        uploaded: facilityToDelete?.metadata?.spgAor?.fileUpload?.length > 0,
      },
      {
        label: "WCC",
        uploaded: facilityToDelete?.metadata?.wcc?.fileUpload?.length > 0,
      },
    ];

    return (
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, factoryId: null })}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            fontSize: "1.125rem",
            fontWeight: 600,
            color: "#101828",
            borderBottom: "1px solid #EAECF0",
            pb: 2,
          }}
        >
          Confirm Delete
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText sx={{ color: "#344054", mb: 3 }}>
            Are you sure you want to delete this presourcing form? This action
            cannot be undone.
          </DialogContentText>
          <Box
            sx={{
              p: 3,
              bgcolor: "#F9FAFB",
              border: "1px solid #EAECF0",
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.875rem",
                color: "#101828",
                fontWeight: 600,
                mb: 2,
              }}
            >
              Facility Details
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Time Received
                </Typography>
                <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                  {facilityToDelete?.createdAt
                    ? moment(facilityToDelete.createdAt).format(
                        "MMM DD, YYYY, h:mm A"
                      )
                    : "-"}
                </Typography>
              </Box>

              {/* Existing facility details */}
              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Facility Name
                </Typography>
                <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                  {facilityToDelete?.name || "-"}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Supplier Name
                </Typography>
                <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                  {facilityToDelete?.metadata?.supplierName || "-"}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Location
                </Typography>
                <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                  {facilityToDelete?.metadata?.country || "-"}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Contact
                </Typography>
                <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                  {facilityToDelete?.metadata?.supplyChainContact || "-"}
                  {facilityToDelete?.metadata?.supplyChainContactEmail
                    ? ` (${facilityToDelete.metadata.supplyChainContactEmail})`
                    : ""}
                </Typography>
              </Box>

              {/* Document upload status */}
              <Box>
                <Typography
                  sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                >
                  Documents Uploaded
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {documentStatus.map((doc) => (
                    <Chip
                      key={doc.label}
                      label={doc.label}
                      size="small"
                      icon={
                        doc.uploaded ? (
                          <CheckCircleIcon sx={{ fontSize: "16px" }} />
                        ) : (
                          <CancelIcon sx={{ fontSize: "16px" }} />
                        )
                      }
                      sx={{
                        backgroundColor: doc.uploaded ? "#ECFDF3" : "#FEF3F2",
                        color: doc.uploaded ? "#027A48" : "#B42318",
                        "& .MuiChip-icon": {
                          color: doc.uploaded ? "#12B76A" : "#F04438",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: "1px solid #EAECF0" }}>
          <Button
            onClick={() =>
              setDeleteConfirmation({ open: false, factoryId: null })
            }
            sx={{
              color: "#344054",
              fontSize: "0.875rem",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#F2F4F7",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(
                deleteSAQFacility({ factoryId: deleteConfirmation.factoryId })
              );
              setDeleteConfirmation({ open: false, factoryId: null });
            }}
            sx={{
              backgroundColor: "#D92D20",
              color: "white",
              fontSize: "0.875rem",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#B42318",
              },
            }}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Time Received",
      width: 200,
      renderCell: (params) => {
        // Use moment to format the date
        return moment(params.value).format("MMM DD, YYYY, h:mm A"); // e.g., "Oct 16, 2024"
      },
    },
    { field: "name", headerName: "Facility Name", width: 200 }, // Increased column width
    { field: "supplierName", headerName: "Supplier Name", width: 150 },
    { field: "country", headerName: "Facility Country", width: 150 },
    {
      field: "supplyChainTeam",
      headerName: "Supply Chain Team",
      width: 150,
    },
    {
      field: "supplyChainContact",
      headerName: "Supply Chain Contact",
      width: 180,
    },
    {
      field: "onboardingStatus",
      headerName: "Onboarding Status",
      width: 150,
    },
    {
      field: "garmentRegistrationUploaded",
      headerName: "G.R.",
      width: 50,
      renderCell: (params) => {
        // Only show checkmark for US facilities
        if (params.row.domesticOrInternational !== "Domestic") {
          return "-";
        }
        return params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        );
      },
      headerTooltip: "Garment Registration (US facilities only)",
    },
    {
      field: "wccUploaded",
      headerName: "WCC",
      width: 60,
      renderCell: (params) => {
        // Only show checkmark for US facilities
        if (params.row.domesticOrInternational !== "Domestic") {
          return "-";
        }
        return params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        );
      },
      headerTooltip: "Garment Registration (US facilities only)",
    },
    {
      field: "businessLicenseUploaded",
      headerName: "B.L.",
      width: 50,
      renderCell: (params) => {
        // Only show checkmark for international facilities
        if (params.row.domesticOrInternational !== "International") {
          return "-";
        }
        return params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        );
      },
      headerTooltip: "Business License (International facilities only)",
    },
    {
      field: "ndaUploaded",
      headerName: "NDA",
      width: 50,
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        ),
    },
    {
      field: "spgUploaded",
      headerName: "SPG",
      width: 50,
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        ),
    },
    {
      field: "mraAuditUploaded",
      headerName: "MRA Audit",
      width: 90,
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        ),
      headerTooltip: "Most Recent Audit",
    },
    {
      field: "mraCAPUploaded",
      headerName: "MRA CAP",
      width: 90,
      renderCell: (params) =>
        params.value ? (
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <CancelIcon sx={{ color: "#d32f2f" }} />
        ),
      headerTooltip: "Most Recent Corrective Action Plan",
    },
    { field: "tier", headerName: "Facility Tier", width: 120 },
    {
      field: "assignedToFacility",
      headerName: "Assigned to Facility",
      width: 220,
      renderCell: (params) => {
        // Find the factory in factoryDropdownData by factory ID
        const assignedFactory = factoryDropdownData.find(
          (factory) => factory.factoryId === params.value
        );
        // Construct the link if the factory exists
        if (assignedFactory) {
          const supplierId = assignedFactory.supplierId; // Ensure you have access to the supplierId
          const factoryId = assignedFactory.factoryId;
          const link = `/suppliers/${supplierId}/factory/${factoryId}`;

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#4C5ED9",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                {assignedFactory.name}
              </a>
              <img
                style={{ paddingLeft: 8 }}
                src="/ne_arrow.svg"
                alt="arrow icon"
              />
            </Box>
          );
        }

        return "-";
      },
    },
    {
      field: "saqContent",
      headerName: "Presourcing Form Content",
      width: 320,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#4C5ED9",
              color: "white",
              width: "75px",
              borderRadius: "8px",
              padding: "8px 16px",
              fontWeight: 500,
              fontSize: "0.875rem",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#3B4BBA",
              },
            }}
            onClick={() => {
              const fullFactoryData = saqFactories.find(
                (factory) => factory.factoryId === params.row.id
              );
              setSelectedSAQ(fullFactoryData);
            }}
          >
            View
          </Button>
          {!params.row.assignedToFacility && (
            <Button
              variant="outlined"
              sx={{
                borderColor: "#D92D20",
                width: "75px",
                color: "#D92D20",
                borderRadius: "8px",
                padding: "8px 16px",
                fontWeight: 500,
                fontSize: "0.875rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FEE4E2",
                  borderColor: "#D92D20",
                },
              }}
              onClick={() => {
                setDeleteConfirmation({
                  open: true,
                  factoryId: params.row.id,
                });
              }}
            >
              Delete
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box sx={{ height: 500, width: "100%" }}>
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={5}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#F9FAFB", // Light gray for header background
                color: "#667085", // Text color for header
                fontWeight: "800", // Bold text
                fontSize: "0.875rem",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "0.875rem",
                color: "#101828",
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
            }}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
            sortModel={[
              {
                field: "createdAt", // Use the field that represents "Time Received"
                sort: "desc", // or 'desc' for descending order
              },
            ]}
          />
        )}
      </Box>
      <DeleteConfirmationDialog />
      {selectedSAQ && (
        <QuestionnaireDetailView
          open={Boolean(selectedSAQ)}
          onClose={() => setSelectedSAQ(null)}
          saqData={selectedSAQ}
          factoryDropdownData={factoryDropdownData}
          supplierDropdownData={supplierDropdownData}
          organisationId={organisationId}
        />
      )}
    </Box>
  );
};

export default QuestionnaireTable;
