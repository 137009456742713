import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Divider,
  Tooltip,
  Chip,
} from "@mui/material";
import LeftSideNavbar from "../components/leftSideNavbar.component";

import { useSelector } from "react-redux";
import { selectOrganisationId } from "../selectors/login.selector";
import SupplierQuestionnaireTable from "../tenants/reformation/supplierQuestionnaireTable.component";
import QuestionnaireTable from "../tenants/reformation/questionnaireTable.component";

const PreSourcingForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const organisationId = useSelector(selectOrganisationId);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const copyToClipboard = (formType) => {
    let url;
    if (formType === "facility") {
      url =
        "https://q-auditsense-1086719075712.us-central1.run.app/" +
        organisationId;
    } else {
      // For supplier form
      url =
        "https://q-auditsense-ref-supplier-1086719075712.us-central1.run.app/" +
        organisationId;
    }
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        {/* Common Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: 24, fontWeight: 600, color: "#101828" }}
            >
              Presourcing Form Responses
            </Typography>
            <Tooltip title="The pre-sourcing form is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
              <Chip
                label="Beta"
                size="small"
                sx={{
                  backgroundColor: "#4C5ED9",
                  color: "white",
                  fontWeight: 500,
                  fontSize: "12px",
                  height: "24px",
                }}
              />
            </Tooltip>
          </Box>

          {/* Copy to Clipboard button changes based on active tab */}
          <Button
            variant="outlined"
            onClick={() =>
              copyToClipboard(activeTab === 0 ? "facility" : "supplier")
            }
            sx={{
              color: "#6172F3",
              borderColor: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "230px",
              height: "40px",
              borderRadius: "8px",
              fontWeight: 500,
              mr: "48px",
            }}
          >
            {activeTab === 0 ? "Facility" : "Supplier"} Presourcing Form
            <img
              style={{ paddingLeft: 8 }}
              src="/ne_arrow.svg"
              alt="arrow icon"
            />
          </Button>
        </Box>

        <Typography
          variant="body2"
          sx={{ fontSize: 14, color: "#667085", fontWeight: 400, mb: 2 }}
        >
          Review Presourcing Form responses here
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            mb: 2,
            borderBottom: 1,
            borderColor: "divider",
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 500,
              color: "#667085",
              "&.Mui-selected": {
                color: "#6172F3",
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#6172F3",
            },
          }}
        >
          <Tab label="Facility Responses" />
          <Tab label="Supplier Responses" />
        </Tabs>

        {/* Tables */}
        <Box sx={{ flex: 1, display: activeTab === 0 ? "block" : "none" }}>
          <QuestionnaireTable hideHeader={true} />
        </Box>

        <Box sx={{ flex: 1, display: activeTab === 1 ? "block" : "none" }}>
          <SupplierQuestionnaireTable hideHeader={true} />
        </Box>
      </Box>
    </Box>
  );
};

export default PreSourcingForm;
