import { useState } from "react";
import { Grid, TextField, Button, Box, AppBar } from "@mui/material";
import { Toolbar, Typography, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";

import { createSupplier } from "../reducers/supplier.reducer";
import { useTenant } from "../context/TenantContext";

const CreateSupplierForm = ({ handleDrawerClose }) => {
  const { nomenclature } = useTenant();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});

  const fields = {
    required: {
      title: "Required General Vendor Information",
      fields: [
        {
          name: "name",
          label: "Vendor Name",
          type: "text",
          required: true,
          layout: { xs: 12, sm: 6, rowBreak: true },
        },
      ],
    },
    optional: {
      general: {
        title: "Additional General Vendor Information",
        fields: [
          {
            name: "status",
            label: "Vendor Status",
            type: "select",
            options: ["active", "inactive"],
            layout: { xs: 12, sm: 4 },
          },
          {
            name: "companyAddress",
            label: "Company Address",
            type: "text",
            layout: { xs: 12, sm: 8, rowBreak: true },
          },
          {
            name: "uniqueSupplierId",
            label: "Supplier ID",
            type: "text",
            layout: { xs: 12, sm: 6 },
          },
          {
            name: "associatedSupplierId",
            label: "Associated Supplier ID",
            type: "text",
            layout: { xs: 12, sm: 6, rowBreak: true },
          },
          {
            name: "alias",
            label: "Supplier Alias",
            type: "text",
            layout: { xs: 12, sm: 6, rowBreak: true },
          },
          // Contact Person Information
          {
            name: "contactPerson.name",
            label: "Contact Person Name",
            type: "text",
            layout: { xs: 12, sm: 3 },
          },
          {
            name: "contactPerson.position",
            label: "Contact Position",
            type: "text",
            layout: { xs: 12, sm: 3 },
          },
          {
            name: "contactPerson.email",
            label: "Contact Email",
            type: "email",
            layout: { xs: 12, sm: 3 },
          },
          {
            name: "contactPerson.phone",
            label: "Contact Phone",
            type: "text",
            layout: { xs: 12, sm: 3, rowBreak: true },
          },
        ],
      },
      confidential: {
        title: "Confidential Business Information",
        fields: [
          {
            name: "productCategories",
            label: "Product Categories",
            type: "text",
            layout: { xs: 12, sm: 6 },
          },
          {
            name: "topProduct",
            label: "Top Product Group",
            type: "text",
            layout: { xs: 12, sm: 6, rowBreak: true },
          },
          {
            name: "productSubcategories",
            label: "Product Subcategories",
            type: "text",
            layout: { xs: 12, sm: 6 },
          },
          {
            name: "productProduced",
            label: "Products Produced",
            type: "text",
            layout: { xs: 12, sm: 6, rowBreak: true },
          },
          {
            name: "spend",
            label: "Spend",
            type: "text",
            layout: { xs: 12, sm: 6 },
          },
          {
            name: "percentageOfBusinessFrom4imprint",
            label: "% of business from Saks",
            type: "text",
            layout: { xs: 12, sm: 6, rowBreak: true },
          },
          {
            name: "privateLabel",
            label: "Private Label",
            type: "select",
            options: ["Yes", "No"],
            layout: { xs: 12, sm: 6 },
          },
        ],
      },
    },
  };

  const handleInputChange = (fieldName) => (event) => {
    setFormValues({
      ...formValues,
      [fieldName]: event.target.value,
    });
  };

  const handleDateChange = (fieldName) => (date) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: date ? date.format("YYYY-MM-DD") : null,
    }));
  };

  const handleSubmit = () => {
    // Check required fields
    const requiredFields = fields.required.fields;
    const missingFields = requiredFields.filter(
      (field) => !formValues[field.name]
    );

    if (missingFields.length > 0) {
      // You can use your preferred notification system here
      alert(
        "Please fill out all required fields: " +
          missingFields.map((f) => f.label).join(", ")
      );
      return;
    }

    dispatch(createSupplier(formValues));
    handleDrawerClose();
  };

  const renderFormFields = () => {
    const renderSection = (fields, title) => {
      let currentRow = [];
      const rows = [];

      fields.forEach((field) => {
        currentRow.push(field);
        if (field.layout?.rowBreak) {
          rows.push([...currentRow]);
          currentRow = [];
        }
      });

      if (currentRow.length > 0) {
        rows.push(currentRow);
      }

      return (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ mt: 3, mb: 2, fontSize: 16, fontWeight: 600 }}
            >
              {title}
            </Typography>
          </Grid>
          {rows.map((row, rowIndex) => (
            <Grid container item spacing={2} key={`row-${rowIndex}`}>
              {row.map((field) => (
                <Grid
                  item
                  xs={field.layout?.xs || 12}
                  sm={field.layout?.sm || 3}
                  key={field.name}
                >
                  {field.type === "date" ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label={field.label}
                        value={
                          formValues[field.name]
                            ? moment(formValues[field.name])
                            : null
                        }
                        onChange={handleDateChange(field.name)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            required: field.required,
                            variant: "outlined",
                            sx: {
                              width: "100%",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  ) : field.type === "select" ? (
                    <TextField
                      select
                      fullWidth
                      label={field.label}
                      value={formValues[field.name] || ""}
                      onChange={handleInputChange(field.name)}
                      required={field.required}
                      variant="outlined"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value=""></option>
                      {field.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      fullWidth
                      label={field.label}
                      type={field.type}
                      value={formValues[field.name] || ""}
                      onChange={handleInputChange(field.name)}
                      required={field.required}
                      variant="outlined"
                      multiline={field.type === "textarea"}
                      rows={field.type === "textarea" ? 4 : 1}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          ))}
        </>
      );
    };

    return (
      <>
        {renderSection(fields.required.fields, fields.required.title)}
        {renderSection(
          fields.optional.general.fields,
          fields.optional.general.title
        )}
      </>
    );
  };

  return (
    <Grid container sx={{ padding: 3 }}>
      <Box
        sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1000, mb: 2 }}
      >
        <AppBar
          color="inherit"
          position="static"
          sx={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            borderBottom: "1px solid #E0E0E0",
          }}
          elevation={0}
        >
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  sx={{ alignItems: "flex-start", display: "flex" }}
                  onClick={handleDrawerClose}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    paddingTop: "15px",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {`Create New ${nomenclature.supplier.fieldNameSingular}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    backgroundColor: "#6172F3",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5667e2",
                      fontWeight: "bold",
                    },
                    textTransform: "none",
                    minWidth: "50px",
                    height: "40px",
                    borderRadius: "8px",
                  }}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          maxWidth: "700px",
          margin: "0 auto",
        }}
      >
        {renderFormFields()}
      </Grid>
    </Grid>
  );
};

export default CreateSupplierForm;
