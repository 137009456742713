import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControlLabel,
  Divider,
  IconButton,
  Link,
  Popover,
  Switch,
  Checkbox,
  Modal,
  Card,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WarningIcon from "@mui/icons-material/Warning";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as AuditStyles from "../../../styles/audit.style";

import { useSelector, useDispatch } from "react-redux";
import {
  selectCAPManagementIssueDetails,
  selectSelectedCAPManagementIssueId,
  selectAuditData,
  selectAuditIssueCAPDataGroupedByType,
} from "../../../selectors/audit.selector";
import moment from "moment";

import {
  updateIssueData,
  updateAuditData,
  setSelectedCAPManagementIssueId,
} from "../../../reducers/audit.reducer";
import { useParams } from "react-router-dom";

import CustomChipLabel from "../../../components/AuditDetails/customChipLabel.component";
import AuditDocumentDrop from "../../../components/AuditDetails/auditDocumentDrop.component";
import { isBrand } from "../../../utils/lib";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";

const CapManagementForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectCAPManagementIssueDetails);
  const selectedIssueId = useSelector(selectSelectedCAPManagementIssueId);
  const auditData = useSelector(selectAuditData);
  const issuesDataGroupedByType = useSelector(
    selectAuditIssueCAPDataGroupedByType
  );
  const formRef = React.useRef(null);
  const [isUserbrand, setIsUserbrand] = useState(isBrand);

  const INITIAL_DATA = {
    findingId: data?.clientCodeTitle || "",
    category: data?.type || "",
    subcategory: data?.subType || "",
    severity: data?.severity || "",
    remediation_timeline: data?.timeline || "",
    finding: data?.issueSummary || "",
    findingDetails: data?.issueDetail || "",
    auditCode: data?.auditBasis || "",
    responsiblePerson: data?.actionPlan?.responsiblePerson || "",
    rootCause: data?.actionPlan?.rootCause || "",
    rootCauseExplanation: data?.actionPlan?.rootCauseAnalysis || "",
    immediateAction: data?.actionPlan?.immediateCorrectiveAction || "",
    immediateActionDate: data?.actionPlan?.deadlineForImmediateActionPlan
      ? moment(data.actionPlan.deadlineForImmediateActionPlan)
      : null,
    longTermAction: data?.actionPlan?.longTermPreventativeAction || "",
    longTermActionDate: data?.actionPlan?.deadlineForLongTermPreventativeAction
      ? moment(data.actionPlan.deadlineForLongTermPreventativeAction)
      : null,
    isCAPcompletelyFilledInByFactory:
      data?.actionPlan?.isCAPcompletelyFilledInByFactory || false,
    brandResponse: data?.brandResponse?.capNotefromBrand || "",
    capApproval: data?.brandResponse?.capApprovalfromBrand ? "Yes" : "No",
    proofDescription: data?.actionPlan?.textCAPProof || "",
    isProofCompleted: data?.actionPlan?.capClosedClaimedBySupplier || false,
    status: data?.status || "Open",
    isSupplierFillinCAP: data?.capStatus?.isSupplierFillinCAP || false,
    isBrandApproveCAP: data?.capStatus?.isBrandApproveCAP || false,
    isSupplierProvideCapProof:
      data?.capStatus?.isSupplierProvideCapProof || false,
    isBrandApproveCAPProof: data?.capStatus?.isBrandApproveCAPProof || false,
    proofApproval: data?.brandResponse?.proofApprovalfromBrand ? "Yes" : "No",
    brandProofResponse: data?.brandResponse?.proofNotefromBrand || "",
    capClosedDate: data?.capClosedDate ? moment(data.capClosedDate) : null,
    ciDueDate: data?.ciDueDate ? moment(data.ciDueDate) : null,
  };

  const [formData, setFormData] = useState(INITIAL_DATA);
  const [anchorEl, setAnchorEl] = useState(null);

  // Add function to determine edit control state
  const getEditControlState = useCallback(() => {
    if (auditData?.metadata?.capManagedByAuditFirm && isUserbrand) {
      return true;
    }

    // If user is brand, edit control is false
    if (isUserbrand) {
      return false;
    }
    // If CAP is managed by audit firm, edit control is false
    if (auditData?.metadata?.capManagedByAuditFirm && !isUserbrand) {
      return false;
    }

    // Otherwise, supplier can edit when not brand user and not managed by audit firm
    return true;
  }, [isUserbrand, auditData?.metadata?.capManagedByAuditFirm]);

  const [supplierSectionEditControl, setSupplierSectionEditControl] = useState(
    getEditControlState()
  );
  const [proofSectionEditControl, setProofSectionEditControl] = useState(
    getEditControlState()
  );

  // Determine if CAP fields should be locked due to brand approval
  const isCAPLocked = !isUserbrand && formData.capApproval === "Yes";

  // Determine if Proof fields should be locked due to brand approval
  const isProofLocked = !isUserbrand && formData.proofApproval === "Yes";

  // Update edit controls when relevant state changes
  useEffect(() => {
    const newEditState = getEditControlState();
    setSupplierSectionEditControl(newEditState);
    setProofSectionEditControl(newEditState);
  }, [
    isUserbrand,
    auditData?.metadata?.capManagedByAuditFirm,
    getEditControlState,
  ]);

  const [uploadProofOpen, setUploadProofOpen] = useState(false);
  const handleUploadProofOpen = () => {
    setUploadProofOpen(true);
  };

  const handleUploadProofClose = () => {
    setUploadProofOpen(false);
  };

  // Update form data when API data changes
  React.useEffect(() => {
    if (data) {
      const newFormData = {
        responsiblePerson: data?.actionPlan?.responsiblePerson || "",
        rootCause: data?.actionPlan?.rootCause || "",
        rootCauseExplanation: data?.actionPlan?.rootCauseAnalysis || "",
        immediateAction: data?.actionPlan?.immediateCorrectiveAction || "",
        immediateActionDate: data?.actionPlan?.deadlineForImmediateActionPlan
          ? moment(data.actionPlan.deadlineForImmediateActionPlan)
          : null,
        longTermAction: data?.actionPlan?.longTermPreventativeAction || "",
        longTermActionDate: data?.actionPlan
          ?.deadlineForLongTermPreventativeAction
          ? moment(data.actionPlan.deadlineForLongTermPreventativeAction)
          : null,
        isCAPcompletelyFilledInByFactory:
          data?.actionPlan?.isCAPcompletelyFilledInByFactory || false,
        brandResponse: data?.brandResponse?.capNotefromBrand || "",
        capApproval: data?.brandResponse?.capApprovalfromBrand ? "Yes" : "No",
        proofDescription: data?.actionPlan?.textCAPProof || "",
        isProofCompleted: data?.actionPlan?.capClosedClaimedBySupplier || false,
        status: data?.status || "Open",
        isSupplierFillinCAP: data?.capStatus?.isSupplierFillinCAP || false,
        isBrandApproveCAP: data?.capStatus?.isBrandApproveCAP || false,
        isSupplierProvideCapProof:
          data?.capStatus?.isSupplierProvideCapProof || false,
        isBrandApproveCAPProof:
          data?.capStatus?.isBrandApproveCAPProof || false,
        proofApproval: data?.brandResponse?.proofApprovalfromBrand
          ? "Yes"
          : "No",
        brandProofResponse: data?.brandResponse?.proofNotefromBrand || "",
        capClosedDate: data?.capClosedDate ? moment(data.capClosedDate) : null,
        ciDueDate: data?.ciDueDate ? moment(data.ciDueDate) : null,
      };
      setFormData(newFormData);
    }
  }, [data]);

  // Modify useEffect to scroll the component
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollTop = 0;
    }
  }, [selectedIssueId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const checkIncompleteFields = () => {
    const incompleteFields = [];
    if (!formData.responsiblePerson)
      incompleteFields.push("Responsible Person");
    if (!formData.rootCause) incompleteFields.push("Root Cause Analysis");
    if (!formData.rootCauseExplanation)
      incompleteFields.push("Root Cause Explanation");
    if (!formData.immediateAction)
      incompleteFields.push("Immediate Corrective Action Plan");
    if (!formData.immediateActionDate)
      incompleteFields.push("Target Completion Date For Immediate Action");
    if (!formData.longTermAction)
      incompleteFields.push("Long Term Preventative Action Plan");
    if (!formData.longTermActionDate)
      incompleteFields.push("Target Completion Date For Long Term Action");
    if (formData.isCAPcompletelyFilledInByFactory !== true)
      incompleteFields.push("Is the CAP completed and ready to be reviewed");
    return incompleteFields;
  };

  const handleBrandCapApproval = (e) => {
    const value = e.target.value;
    const boolValue = value === "Yes";
    setFormData((prev) => ({
      ...prev,
      capApproval: value,
    }));
    debouncedSaveBrandCapApproval(boolValue, selectedIssueId);
  };

  const handleBrandProofApproval = (e) => {
    const value = e.target.value;
    const boolValue = value === "Yes";
    setFormData((prev) => ({
      ...prev,
      proofApproval: value,
    }));
    debouncedSaveBrandProofApproval(boolValue, selectedIssueId);
  };

  const debouncedSaveBrandCapApproval = useDebouncedCallback(
    (approval, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "brandResponse.capApprovalfromBrand": approval,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveBrandCapNote = useDebouncedCallback(
    (note, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "brandResponse.capNotefromBrand": note,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveBrandProofApproval = useDebouncedCallback(
    (approval, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "brandResponse.proofApprovalfromBrand": approval,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveBrandProofNote = useDebouncedCallback(
    (note, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "brandResponse.proofNotefromBrand": note,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveResponsiblePerson = useDebouncedCallback(
    (newResponsiblePerson, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.responsiblePerson": newResponsiblePerson },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveStatus = useDebouncedCallback(
    (value, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { status: value },
          issueId,
        })
      );

      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsStatus: value },
          issueId,
        })
      );
    },
    1000, // debounce delay
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveRootCause = useDebouncedCallback(
    (value, id) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCause": value },
          issueId: id,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveRootCauseExplanation = useDebouncedCallback(
    (newExplanation, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.rootCauseAnalysis": newExplanation },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveImmediateAction = useDebouncedCallback(
    (newAction, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.immediateCorrectiveAction": newAction },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveImmediateActionDate = useDebouncedCallback(
    (newDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.deadlineForImmediateActionPlan": newDate },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveLongTermAction = useDebouncedCallback(
    (newAction, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.longTermPreventativeAction": newAction },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveLongTermActionDate = useDebouncedCallback(
    (newDate, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.deadlineForLongTermPreventativeAction": newDate,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveCapCompleted = useDebouncedCallback(
    (isCompleted, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: {
            "actionPlan.isCAPcompletelyFilledInByFactory": isCompleted,
          },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveProofDescription = useDebouncedCallback(
    (newProofDescription, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.textCAPProof": newProofDescription },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveProofCompleted = useDebouncedCallback(
    (isCompleted, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { "actionPlan.capClosedClaimedBySupplier": isCompleted },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveCapClosedDate = useDebouncedCallback(
    (date, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capClosedDate: date },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const debouncedSaveCiDueDate = useDebouncedCallback(
    (date, issueId) => {
      dispatch(
        updateIssueData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { ciDueDate: date },
          issueId,
        })
      );
    },
    1000,
    [dispatch, params?.auditId, params?.supplierId, params?.factoryId]
  );

  const handleResponsiblePersonChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, responsiblePerson: newValue }));
    debouncedSaveResponsiblePerson(newValue, selectedIssueId);
  };

  const handleStatusChange = (e) => {
    const newValue = e.target.value;

    // If status is being changed to Closed and there's no capClosedDate, set it to today
    if (newValue === "Closed" && !formData.capClosedDate) {
      const today = moment();
      setFormData((prev) => ({
        ...prev,
        status: newValue,
        capClosedDate: today,
      }));
      // Save both status and capClosedDate
      debouncedSaveStatus(newValue, selectedIssueId);
      debouncedSaveCapClosedDate(today.toISOString(), selectedIssueId);
    } else {
      setFormData((prev) => ({ ...prev, status: newValue }));
      debouncedSaveStatus(newValue, selectedIssueId);
    }
  };

  const handleRootCauseChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, rootCause: newValue }));
    debouncedSaveRootCause(newValue, selectedIssueId);
  };

  const handleRootCauseExplanationChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, rootCauseExplanation: newValue }));
    debouncedSaveRootCauseExplanation(newValue, selectedIssueId);
  };

  const handleImmediateActionChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, immediateAction: newValue }));
    debouncedSaveImmediateAction(newValue, selectedIssueId);
  };

  const handleImmediateActionDateChange = (date) => {
    setFormData((prev) => ({ ...prev, immediateActionDate: date }));
    // Convert to ISO string for MongoDB date format
    const isoDate = date ? date.toISOString() : null;
    debouncedSaveImmediateActionDate(isoDate, selectedIssueId);
  };

  const handleLongTermActionChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, longTermAction: newValue }));
    debouncedSaveLongTermAction(newValue, selectedIssueId);
  };

  const handleLongTermActionDateChange = (date) => {
    setFormData((prev) => ({ ...prev, longTermActionDate: date }));
    // Convert to ISO string for MongoDB date format
    const isoDate = date ? date.toISOString() : null;
    debouncedSaveLongTermActionDate(isoDate, selectedIssueId);
  };

  const handleCapCompletedChange = (e) => {
    const newValue = e.target.checked;
    setFormData((prev) => ({
      ...prev,
      isCAPcompletelyFilledInByFactory: newValue,
    }));
    debouncedSaveCapCompleted(newValue, selectedIssueId);
  };

  const handleProofDescriptionChange = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({ ...prev, proofDescription: newValue }));
    debouncedSaveProofDescription(newValue, selectedIssueId);
  };

  const handleProofCompletedChange = (e) => {
    const newValue = e.target.checked;
    setFormData((prev) => ({
      ...prev,
      isProofCompleted: newValue,
    }));
    debouncedSaveProofCompleted(newValue, selectedIssueId);
  };

  const handleCapClosedDateChange = (date) => {
    setFormData((prev) => ({ ...prev, capClosedDate: date }));
    // Convert to ISO string for MongoDB date format
    const isoDate = date ? date.toISOString() : null;
    debouncedSaveCapClosedDate(isoDate, selectedIssueId);
  };

  const handleCiDueDateChange = (date) => {
    setFormData((prev) => ({ ...prev, ciDueDate: date }));
    // Convert to ISO string for MongoDB date format
    const isoDate = date ? date.toISOString() : null;
    debouncedSaveCiDueDate(isoDate, selectedIssueId);
  };

  const handleNavigation = (direction) => {
    // Flatten all issues into a single array
    const allIssues = Object.values(issuesDataGroupedByType).flat();
    // Find current issue index
    const currentIndex = allIssues.findIndex(
      (issue) => issue.id === selectedIssueId
    );

    if (direction === "next" && currentIndex < allIssues.length - 1) {
      dispatch(setSelectedCAPManagementIssueId(allIssues[currentIndex + 1].id));
    } else if (direction === "last" && currentIndex > 0) {
      dispatch(setSelectedCAPManagementIssueId(allIssues[currentIndex - 1].id));
    }
  };

  // Add function to check navigation state
  const getNavigationState = () => {
    const allIssues = Object.values(issuesDataGroupedByType).flat();
    const currentIndex = allIssues.findIndex(
      (issue) => issue.id === selectedIssueId
    );
    return {
      isFirst: currentIndex === 0,
      isLast: currentIndex === allIssues.length - 1,
    };
  };

  // Get navigation state
  const { isFirst, isLast } = getNavigationState();

  return (
    <Box
      ref={formRef}
      sx={{
        // py: 6,
        px: 10,
        height: "100%",
        overflow: "auto",
      }}
    >
      {/* View Toggle */}
      {isBrand && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            my: 4,
            backgroundColor: "#F9FAFB",
            p: 2,
            borderRadius: "8px",
          }}
        >
          <Typography sx={{ mr: 2, color: "#475467" }}>
            {" "}
            (For testing purpose only) Change View Switch:
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isUserbrand}
                onChange={(e) => setIsUserbrand(e.target.checked)}
                color="primary"
              />
            }
            label={isUserbrand ? "Brand View" : "Supplier View"}
          />
        </Box>
      )}

      {/* Finding Information Section */}
      <Box sx={{ mb: 20, position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,

            zIndex: 10,
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(255, 255, 255, 0.98)",
            transition: "box-shadow 0.3s ease",
            // boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.05)',
            // pb: 2
          }}
        >
          <Typography
            sx={{
              color: "#475467",
              fontSize: 30,
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "38px",
              pt: 2,
              mb: 2,
            }}
          >
            Finding Information
          </Typography>
          <Divider />
        </Box>
        <Box sx={{ pt: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  mb: 1,
                  fontSize: 24,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  lineHeight: "32px",
                }}
              >
                {data?.issueSummary}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>Finding ID</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.clientCodeTitle}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>Category</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.type}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>Subcategory</Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.subType}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>Severity</Typography>
              <CustomChipLabel severity={data?.severity} />
            </Grid>
            <Grid item xs={4}>
              <Typography sx={AuditStyles.fieldTitle}>
                Remediation Timeline
              </Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.timeline}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={AuditStyles.fieldTitle}>
                Finding Details
              </Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.issueDetail}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={AuditStyles.fieldTitle}>
                Audit Code or Legal Reference
              </Typography>
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {data?.auditBasis}
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={4}>
              <Grid item xs={4} sx={{ maxWidth: "120px" }}>
                <Typography sx={AuditStyles.fieldTitle}>CAP Status</Typography>
                {isUserbrand ? (
                  <FormControl sx={{ width: "100%", maxWidth: "200px" }}>
                    <Select
                      value={formData.status}
                      onChange={handleStatusChange}
                      sx={{
                        backgroundColor: "#fff",
                        "& .MuiSelect-select": {
                          color: "#101828",
                        },
                      }}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                      <MenuItem value="CI">CI</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <CustomChipLabel
                    cap={formData.status}
                    capLabel={formData.status}
                  />
                )}
              </Grid>
              {formData.status === "Closed" && (
                <Grid item xs={4}>
                  <Typography sx={AuditStyles.fieldTitle}>
                    Finding Closed Date
                  </Typography>
                  {isUserbrand ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={formData.capClosedDate}
                        onChange={handleCapClosedDateChange}
                        inputFormat="MM/DD/YYYY"
                        fullWidth
                        components={{
                          TextField: (params) => (
                            <TextField
                              {...params}
                              fullWidth
                              sx={{
                                backgroundColor: "#fff",
                                "& .MuiInputBase-input": {
                                  color: "#101828",
                                },
                              }}
                            />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography sx={AuditStyles.auditReportFieldValue}>
                      {formData.capClosedDate
                        ? formData.capClosedDate.format("MM/DD/YYYY")
                        : "-"}
                    </Typography>
                  )}
                </Grid>
              )}
              {formData.status === "CI" && (
                <Grid item xs={4}>
                  <Typography sx={AuditStyles.fieldTitle}>
                    CI Due Date
                  </Typography>
                  {isUserbrand ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={formData.ciDueDate}
                        onChange={handleCiDueDateChange}
                        inputFormat="MM/DD/YYYY"
                        fullWidth
                        components={{
                          TextField: (params) => (
                            <TextField
                              {...params}
                              fullWidth
                              sx={{
                                backgroundColor: "#fff",
                                "& .MuiInputBase-input": {
                                  color: "#101828",
                                },
                              }}
                            />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography sx={AuditStyles.auditReportFieldValue}>
                      {formData.ciDueDate
                        ? formData.ciDueDate.format("MM/DD/YYYY")
                        : "-"}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Fill in CAP Section */}
      <Box sx={{ mb: 20, position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,

            zIndex: 9,
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(255, 255, 255, 0.98)",
            transition: "box-shadow 0.3s ease",
            boxShadow: "0 0px 0px -1px rgba(0, 0, 0, 0.05)",
            // pb: 2
          }}
        >
          <Typography
            sx={{
              color: "#475467",
              fontSize: 30,
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "38px",
              pt: 2,
              mb: 2,
            }}
          >
            Fill in CAP
          </Typography>
          <Typography sx={{ color: "#475467", mb: 3 }}>
            All of your input is saved and shared with 4imprint in real time.
            Each step in the CAP process is marked with a gray check mark if
            further action is required or a green check mark if no further
            action is required.
          </Typography>
          {isCAPLocked && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ECFDF3",
                border: "1px solid #6CE9A6",
                borderRadius: "8px",
                p: 2,
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#027A48",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                This CAP has been approved by 4imprint. Therefore, you are only
                able to view your responses. If you'd like to request changes to
                the approved CAP, please contact 4imprint directly.
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1, color: "#475467" }}>
                Supplier - CAP Entry
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  color:
                    formData.isSupplierFillinCAP === true
                      ? "#039855"
                      : "#D0D5DD",
                  fontSize: "24px",
                  mr: 4,
                }}
              />
            </Box>
            <Button
              onClick={handleClick}
              sx={{
                color:
                  checkIncompleteFields().length > 0 ? "#B54708" : "#039855",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                "&:hover": {
                  background: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              {checkIncompleteFields().length > 0
                ? "Check incomplete fields"
                : "All fields have been completed"}
            </Button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  p: 3,
                  maxWidth: 400,
                  border: "1px solid #EAECF0",
                  boxShadow:
                    "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                  bgcolor: "white",
                  color: "#101828",
                },
              }}
            >
              {checkIncompleteFields().length > 0 ? (
                <>
                  <Typography sx={{ mb: 2, color: "#475467", fontWeight: 600 }}>
                    The following fields need to be completed:
                  </Typography>
                  {checkIncompleteFields().map((field, index) => (
                    <Typography key={index} sx={{ color: "#101828", mb: 1 }}>
                      • {field}
                    </Typography>
                  ))}
                </>
              ) : (
                <Typography sx={{ color: "#039855", fontWeight: 500 }}>
                  All required fields are completed!
                </Typography>
              )}
            </Popover>
            <Box sx={{ display: "flex", alignItems: "center", ml: 10, mr: 4 }}>
              <Typography sx={{ mr: 1, color: "#475467" }}>
                4imprint - CAP Entry Approval
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  color:
                    formData.isBrandApproveCAP === true ? "#039855" : "#D0D5DD",
                  fontSize: "24px",
                }}
              />
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ pt: 4 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sx={{ px: 0 }}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: isUserbrand ? "#F9FAFB" : "transparent",
                  border: isUserbrand ? "2px dashed #EAECF0" : "none",
                  borderRadius: isUserbrand ? "12px" : "0",
                  p: isUserbrand ? 4 : 0,
                  mb: 4,
                }}
              >
                {isUserbrand && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#475467",
                        fontSize: 20,
                        fontFamily: "Inter",
                        fontWeight: 600,
                      }}
                    >
                      Supplier - CAP Entry
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ mr: 1, color: "#475467" }}>
                        Enable Edit Access
                      </Typography>
                      <Switch
                        checked={
                          auditData?.metadata?.capManagedByAuditFirm
                            ? true
                            : supplierSectionEditControl
                        }
                        onChange={(e) =>
                          setSupplierSectionEditControl(e.target.checked)
                        }
                        color="primary"
                        disabled={auditData?.metadata?.capManagedByAuditFirm}
                      />
                    </Box>
                  </Box>
                )}
                {!isUserbrand && auditData?.metadata?.capManagedByAuditFirm && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#FFFDF3",
                      border: "1px solid #F0C000",
                      borderRadius: "8px",
                      p: 2,
                      mb: 4,
                    }}
                  >
                    <WarningIcon sx={{ color: "#B54708", mr: 1 }} />
                    <Typography
                      sx={{
                        color: "#B54708",
                        fontSize: "14px",
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      The CAP is currently being managed by the audit firm, so
                      edit access has been disabled. If you'd like to request
                      changes, please contact 4imprint directly.
                    </Typography>
                  </Box>
                )}
                <Grid container spacing={6}>
                  <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography sx={AuditStyles.fieldTitle}>
                        Responsible Person *
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter name"
                        value={formData.responsiblePerson}
                        onChange={handleResponsiblePersonChange}
                        disabled={!supplierSectionEditControl || isCAPLocked}
                        sx={{
                          mt: 1,
                          backgroundColor:
                            !supplierSectionEditControl || isCAPLocked
                              ? "#F9FAFB"
                              : "#fff",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor:
                              !supplierSectionEditControl || isCAPLocked
                                ? "#667085"
                                : "#101828",
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ display: { xs: "none", md: "none", lg: "block" } }}
                      lg={6}
                    ></Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={AuditStyles.fieldTitle}>
                          Root Cause Analysis *
                        </Typography>
                        <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                          <InfoOutlinedIcon
                            sx={{ color: "#667085", fontSize: 20 }}
                          />
                        </IconButton>
                      </Box>
                      <FormControl fullWidth sx={{ mt: 1 }}>
                        <Select
                          value={formData.rootCause}
                          displayEmpty
                          disabled={!supplierSectionEditControl || isCAPLocked}
                          sx={{
                            backgroundColor:
                              !supplierSectionEditControl || isCAPLocked
                                ? "#F9FAFB"
                                : "#fff",
                            "& .MuiSelect-select": {
                              color: formData.rootCause ? "#101828" : "#667085",
                            },
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor:
                                !supplierSectionEditControl || isCAPLocked
                                  ? "#667085"
                                  : "#101828",
                            },
                          }}
                          onChange={handleRootCauseChange}
                        >
                          <MenuItem value="" disabled>
                            Select an option
                          </MenuItem>
                          <MenuItem value="Inadequate Knowledge or Awareness">
                            Inadequate Knowledge or Awareness
                          </MenuItem>
                          <MenuItem value="Lack of Management Commitment">
                            Lack of Management Commitment
                          </MenuItem>
                          <MenuItem value="Lack of Policies & Procedures">
                            Lack of Policies & Procedures
                          </MenuItem>
                          <MenuItem value="poor_implementation_practices">
                            Poor Implementation Practices
                          </MenuItem>
                          <MenuItem value="Environmental Conditions">
                            Environmental Conditions
                          </MenuItem>
                          <MenuItem value="Resource Constraints">
                            Resource Constraints
                          </MenuItem>
                          <MenuItem value="Inefficient Processes & Procedures">
                            Inefficient Processes & Procedures
                          </MenuItem>
                          <MenuItem value="System Malfunctions">
                            System Malfunctions
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: 400,
                          pl: 1,
                          mt: { xs: 0, md: 0, lg: 5 },
                        }}
                      >
                        If you need help determining a finding's root cause,
                        please refer to:
                      </Typography>

                      <Typography
                        role="link"
                        tabIndex={0}
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: 400,
                          pl: 1,
                          cursor: "pointer",
                          textDecoration: "underline",
                          "&:hover": {
                            color: "#666",
                          },
                          zIndex: 1, // Ensure it's above other elements
                          position: "relative", // Establish a stacking context
                        }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling
                          window.open(
                            "https://storage.googleapis.com/test_mvp_public/4imprint%20CAP%20Guidance.pdf",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            window.open(
                              "https://storage.googleapis.com/test_mvp_public/4imprint%20CAP%20Guidance.pdf",
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }
                        }}
                      >
                        CAP Development Guidance
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography sx={AuditStyles.fieldTitle}>
                        Root Cause Explanation *
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Please explain why you chose the answer above"
                        value={formData.rootCauseExplanation}
                        onChange={handleRootCauseExplanationChange}
                        disabled={!supplierSectionEditControl || isCAPLocked}
                        sx={{
                          backgroundColor:
                            !supplierSectionEditControl || isCAPLocked
                              ? "#F9FAFB"
                              : "#fff",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor:
                              !supplierSectionEditControl || isCAPLocked
                                ? "#667085"
                                : "#101828",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography sx={AuditStyles.fieldTitle}>
                        Immediate Corrective Action Plan *
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter immediate corrective action plan"
                        value={formData.immediateAction}
                        onChange={handleImmediateActionChange}
                        disabled={!supplierSectionEditControl || isCAPLocked}
                        sx={{
                          mt: 1,
                          backgroundColor:
                            !supplierSectionEditControl || isCAPLocked
                              ? "#F9FAFB"
                              : "#fff",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor:
                              !supplierSectionEditControl || isCAPLocked
                                ? "#667085"
                                : "#101828",
                          },
                        }}
                      />
                      <Typography sx={AuditStyles.fieldTitle} mt={3}>
                        Target Completion Date For Immediate Corrective Action
                        Plan *
                      </Typography>
                      <Typography sx={{ color: "#3538CD", mb: 1 }}>
                        Action Must Be Completed By: 11/15/2024
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          value={formData.immediateActionDate}
                          onChange={handleImmediateActionDateChange}
                          disabled={!supplierSectionEditControl || isCAPLocked}
                          inputFormat="MM/DD/YYYY"
                          fullWidth
                          components={{
                            TextField: (params) => (
                              <TextField
                                {...params}
                                fullWidth
                                disabled={
                                  !supplierSectionEditControl || isCAPLocked
                                }
                                sx={{
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#F9FAFB"
                                        : "#fff !important",
                                    width: "100%",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#667085"
                                        : "#101828",
                                    backgroundColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#F9FAFB"
                                        : "#fff !important",
                                    width: "100%",
                                  },
                                }}
                              />
                            ),
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Box sx={{ mt: { xs: 0, md: 0, lg: 4 } }}>
                        <Typography sx={AuditStyles.fieldTitle}>
                          Optional Thought Starter: Immediate Corrective Action
                          Plan
                        </Typography>
                        <Typography sx={{ mt: 1, color: "#475467" }}>
                          {data?.capRecommendations?.immediateCAP || ""}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={8}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Typography sx={AuditStyles.fieldTitle}>
                        Long Term Preventative Action Plan *
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter long term preventative action plan"
                        value={formData.longTermAction}
                        onChange={handleLongTermActionChange}
                        disabled={!supplierSectionEditControl || isCAPLocked}
                        sx={{
                          mt: 1,
                          backgroundColor:
                            !supplierSectionEditControl || isCAPLocked
                              ? "#F9FAFB"
                              : "#fff",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor:
                              !supplierSectionEditControl || isCAPLocked
                                ? "#667085"
                                : "#101828",
                          },
                        }}
                      />
                      <Typography sx={AuditStyles.fieldTitle} mt={3}>
                        Target Completion Date For Long Term Preventative Action
                        Plan *
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          value={formData.longTermActionDate}
                          onChange={handleLongTermActionDateChange}
                          disabled={!supplierSectionEditControl || isCAPLocked}
                          inputFormat="MM/DD/YYYY"
                          components={{
                            TextField: (params) => (
                              <TextField
                                {...params}
                                fullWidth
                                disabled={
                                  !supplierSectionEditControl || isCAPLocked
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#F9FAFB"
                                        : "#fff !important",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#667085"
                                        : "#101828",
                                    backgroundColor:
                                      !supplierSectionEditControl || isCAPLocked
                                        ? "#F9FAFB"
                                        : "#fff !important",
                                  },
                                }}
                              />
                            ),
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Box sx={{ mt: { xs: 0, md: 0, lg: 4 } }}>
                        <Typography sx={AuditStyles.fieldTitle}>
                          Optional Thought Starter: Long Term Preventative
                          Action Plan
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            color: "#475467",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {data?.capRecommendations
                            ?.longTermPreventativeAction || ""}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={AuditStyles.fieldTitle}>
                      Have you finished entering CAP information for this
                      finding, and is it ready for review?
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            formData.isCAPcompletelyFilledInByFactory === true
                          }
                          onChange={handleCapCompletedChange}
                          disabled={!supplierSectionEditControl || isCAPLocked}
                        />
                      }
                      label="Yes"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#F5F6FF",
                  border: "2px dashed #EAECF0",
                  borderRadius: "12px",
                  p: 4,
                  mb: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: 20,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    mb: 4,
                  }}
                >
                  4imprint - CAP Entry Approval
                </Typography>
                {isUserbrand ? (
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>CAP Approval</InputLabel>
                        <Select
                          value={formData.capApproval}
                          onChange={handleBrandCapApproval}
                          sx={{
                            backgroundColor: "#fff",
                          }}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        label="CAP Approval Comments"
                        value={formData.brandResponse}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            brandResponse: e.target.value,
                          });
                          debouncedSaveBrandCapNote(
                            e.target.value,
                            selectedIssueId
                          );
                        }}
                        sx={{
                          backgroundColor: "#fff",
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ color: "#475467", fontWeight: 500, mb: 1 }}
                      >
                        CAP Approval
                      </Typography>
                      <Typography sx={{ color: "#101828" }}>
                        {formData.capApproval === "Yes"
                          ? "Approved"
                          : "Not yet approved"}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        sx={{ color: "#475467", fontWeight: 500, mb: 1 }}
                      >
                        CAP Approval Comments
                      </Typography>
                      <Typography
                        sx={{ color: "#101828", whiteSpace: "pre-wrap" }}
                      >
                        {formData.brandResponse || "No response provided yet"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Provide CAP Closure Proof Section */}
      <Box sx={{ mb: 6, position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,

            zIndex: 8,
            backdropFilter: "blur(8px)",
            backgroundColor: "rgba(255, 255, 255, 0.98)",
            transition: "box-shadow 0.3s ease",
            boxShadow: "0 2px 4px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Typography
            sx={{
              color: "#475467",
              fontSize: 30,
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "38px",
              pt: 2,
              mb: 2,
            }}
          >
            Provide CAP Closure Proof
          </Typography>
          <Typography sx={{ color: "#475467", mb: 3 }}>
            Upload documentation or provide text evidence to demonstrate that
            your corrective actions have been completed.
          </Typography>
          {isProofLocked && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ECFDF3",
                border: "1px solid #6CE9A6",
                borderRadius: "8px",
                p: 2,
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#027A48",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                The proof that has been provided was approved by 4imprint.
                Therefore, you are only able to view your responses. If you'd
                like to request changes to the approved proof, please contact
                4imprint directly.
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1, color: "#475467" }}>
                Supplier - CAP Proof
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  color:
                    formData.isSupplierProvideCapProof === true
                      ? "#039855"
                      : "#D0D5DD",
                  fontSize: "24px",
                  mr: 4,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 1, color: "#475467" }}>
                4imprint - CAP Proof Approval
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  color:
                    formData.isBrandApproveCAPProof === true
                      ? "#039855"
                      : "#D0D5DD",
                  fontSize: "24px",
                }}
              />
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundColor: isUserbrand ? "#F9FAFB" : "transparent",
            border: isUserbrand ? "2px dashed #EAECF0" : "none",
            borderRadius: isUserbrand ? "12px" : "0",
            p: isUserbrand ? 4 : 0,
            my: 4,
          }}
        >
          {isUserbrand && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: 20,
                  fontFamily: "Inter",
                  fontWeight: 600,
                }}
              >
                Supplier - CAP Proof
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ mr: 1, color: "#475467" }}>
                  Enable Edit Access
                </Typography>
                <Switch
                  checked={
                    auditData?.metadata?.capManagedByAuditFirm
                      ? true
                      : proofSectionEditControl
                  }
                  onChange={(e) => setProofSectionEditControl(e.target.checked)}
                  color="primary"
                  disabled={auditData?.metadata?.capManagedByAuditFirm}
                />
              </Box>
            </Box>
          )}
          {!isUserbrand && auditData?.metadata?.capManagedByAuditFirm && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFFDF3",
                border: "1px solid #F0C000",
                borderRadius: "8px",
                p: 2,
                mb: 4,
              }}
            >
              <WarningIcon sx={{ color: "#B54708", mr: 1 }} />
              <Typography
                sx={{
                  color: "#B54708",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                The CAP is currently being managed by the audit firm, so edit
                access has been disabled. If you'd like to request changes,
                please contact 4imprint directly.
              </Typography>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#344054",
                  borderRadius: "8px",
                  padding: "10px 14px",
                  border: "1px solid var(--Gray-3000, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                }}
                onClick={handleUploadProofOpen}
                startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
                disabled={!proofSectionEditControl || isProofLocked}
              >
                Upload Proof *
              </Button>
            </Grid>

            {data?.proofDocuments && data.proofDocuments.length > 0 && (
              <Grid item xs={12}>
                <Typography sx={AuditStyles.fieldTitle}>
                  Uploaded Documents
                </Typography>
                {data.proofDocuments.map((doc, index) => (
                  <Link
                    key={index}
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "block",
                      mt: 1,
                      color: "#3538CD",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {doc.name}
                  </Link>
                ))}
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "16px",
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                and/or provide text proof
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Enter text proof here, if applicable"
                value={formData.proofDescription}
                onChange={handleProofDescriptionChange}
                disabled={!proofSectionEditControl || isProofLocked}
                sx={{
                  backgroundColor:
                    !proofSectionEditControl || isProofLocked
                      ? "#F9FAFB"
                      : "#fff",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor:
                      !proofSectionEditControl || isProofLocked
                        ? "#667085"
                        : "#101828",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={AuditStyles.fieldTitle}>
                Have you finished uploading or entering proof for this finding,
                and is it ready for review? *
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isProofCompleted === true}
                    onChange={handleProofCompletedChange}
                    disabled={!proofSectionEditControl || isProofLocked}
                  />
                }
                label="Yes"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Brand Response for Proof */}
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#F5F6FF",
            border: "2px dashed #EAECF0",
            borderRadius: "12px",
            p: 4,
            mb: 4,
          }}
        >
          <Typography
            sx={{
              color: "#475467",
              fontSize: 20,
              fontFamily: "Inter",
              fontWeight: 600,
              mb: 4,
            }}
          >
            4imprint - CAP Proof Approval
          </Typography>
          {isUserbrand ? (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Proof Approval</InputLabel>
                  <Select
                    value={formData.proofApproval}
                    onChange={handleBrandProofApproval}
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  label="Proof Approval Comments"
                  value={formData.brandProofResponse}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      brandProofResponse: e.target.value,
                    });
                    debouncedSaveBrandProofNote(
                      e.target.value,
                      selectedIssueId
                    );
                  }}
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#475467", fontWeight: 500, mb: 1 }}>
                  Proof Approval
                </Typography>
                <Typography sx={{ color: "#101828" }}>
                  {formData.proofApproval === "Yes"
                    ? "Approved"
                    : "Not yet approved"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: "#475467", fontWeight: 500, mb: 1 }}>
                  Proof Approval Comments
                </Typography>
                <Typography sx={{ color: "#101828", whiteSpace: "pre-wrap" }}>
                  {formData.brandProofResponse || "No response provided yet"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>

      {/* Navigation Buttons */}
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mt: 10, mb: 6 }}
      >
        <Box>
          {!isFirst && (
            <Button
              variant="outlined"
              onClick={() => handleNavigation("last")}
              startIcon={<KeyboardArrowLeftIcon />}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#3538CD",
                borderRadius: "8px",
                padding: "10px 16px",
                border: "1px solid #3538CD",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                textTransform: "none",
                "&:hover": {
                  border: "1px solid #3538CD",
                  background: "rgba(53, 56, 205, 0.04)",
                },
              }}
            >
              Last Finding
            </Button>
          )}
        </Box>
        <Box>
          {!isLast && (
            <Button
              variant="outlined"
              onClick={() => handleNavigation("next")}
              endIcon={<KeyboardArrowRightIcon />}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#3538CD",
                borderRadius: "8px",
                padding: "10px 16px",
                border: "1px solid #3538CD",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                textTransform: "none",
                "&:hover": {
                  border: "1px solid #3538CD",
                  background: "rgba(53, 56, 205, 0.04)",
                },
              }}
            >
              Next Finding
            </Button>
          )}
        </Box>
      </Box>
      <Modal open={uploadProofOpen} onClose={handleUploadProofClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            factoryId={params.factoryId}
            auditId={params?.auditId}
            issueId={selectedIssueId}
            onClose={handleUploadProofClose}
            mode="capProof"
            label="Upload CAP Closure Proof here"
          />
        </Card>
      </Modal>
    </Box>
  );
};

export default CapManagementForm;
