import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

const selectAudit = (state) => state.audit;

const processAuditScore = (audit) => {
  if (!audit.isHistoricalAudit) {
    return audit.risk?.auditRating;
  }
  const colors = ["green", "red", "yellow", "orange"];
  // Check auditRating for color strings
  const auditRating = audit.metadata?.refReportComplianceRating.toLowerCase();
  if (audit.metadata?.refReportComplianceRating) {
    //console.log("auditRating inside", auditRating);
    return audit.metadata?.refReportComplianceRating;
  }
  // Check auditScore for color strings
  const auditScore = audit.metadata?.auditRating.toLowerCase();
  if (colors.some((color) => auditScore.includes(color))) {
    return audit.metadata?.auditRating;
  }
  let numericScore = parseFloat(auditScore);
  if (isNaN(numericScore)) {
    const match = auditScore.match(/(\d+)\//);
    if (match) {
      numericScore = parseFloat(match[1]);
    }
  }
  if (!isNaN(numericScore)) {
    if (numericScore >= 91) {
      return "Green (A)";
    } else if (numericScore >= 71) {
      return "Yellow (B)";
    } else if (numericScore >= 51) {
      return "Orange (C)";
    } else {
      return "Red (D)";
    }
  }
  return audit.auditScore;
};
const processAuditScoreList = (audit) => {
  if (!audit.isHistoricalAudit) {
    return audit.auditScore;
  }
  const colors = ["green", "red", "yellow", "orange"];
  // Check auditRating for color strings
  const auditRating = audit.auditRating.toLowerCase();
  if (colors.some((color) => auditRating.includes(color))) {
    return audit.auditRating;
  }
  // Check auditScore for color strings
  const auditScore = audit.auditScore.toLowerCase();
  if (colors.some((color) => auditScore.includes(color))) {
    return audit.auditScore;
  }
  let numericScore = parseFloat(auditScore);
  if (isNaN(numericScore)) {
    const match = auditScore.match(/(\d+)\//);
    if (match) {
      numericScore = parseFloat(match[1]);
    }
  }
  if (!isNaN(numericScore)) {
    if (numericScore >= 91) {
      return "Green (A)";
    } else if (numericScore >= 71) {
      return "Yellow (B)";
    } else if (numericScore >= 51) {
      return "Orange (C)";
    } else {
      return "Red (D)";
    }
  }
  return audit.auditScore;
};

export const selectAuditList = createSelector(
  [selectAudit],
  (audit) => audit.audits
);

export const selectAuditListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )
      : audit.audits
);

export const selectAuditListForFactoryReformation = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits
          .filter(
            (audit) => audit.factoryId.toString() === factoryId.toString()
          )
          .map((audit) => ({
            ...audit,
            auditScore: processAuditScoreList(audit),
          }))
      : audit.audits.map((audit) => ({
          ...audit,
          auditScore: processAuditScoreList(audit),
        }))
);

export const selectCapListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) => {
    const audits = factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )
      : audit.audits;

    const CapList = audits
      .filter((audit) => audit.state === "active")
      .flatMap(
        (audit) =>
          audit.issueDetails?.capDetails?.map((capDetails) => ({
            auditId: audit.auditId,
            ...capDetails,
          })) || []
      );

    return CapList;
  }
);

export const selectMostRecentAuditIdForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )[0]?.auditId
      : audit.audits[0]?.auditId
);

export const selectAuditNameFromId = createSelector(
  [selectAudit, (_, id) => id],
  (audit, id) => {
    if (id && id !== "" && audit.audits.length > 0) {
      const auditName = audit.audits.filter(
        (audit) => audit.auditId.toString() === id.toString()
      );
      return auditName[0]?.metadata?.auditType;
    } else {
      return null;
    }
  }
);

export const selectAuditData = createSelector(
  [selectAudit],
  (audit) => audit.auditData
);

export const selectAuditDataReformation = createSelector(
  [selectAudit],
  (audit) => {
    if (!audit.auditData) return null;
    return {
      ...audit.auditData,
      auditScore: processAuditScore(audit.auditData),
    };
  }
);
export const selectSelectedAuditIssueId = createSelector(
  [selectAudit],
  (audit) => audit.selectedAuditIssueId
);

export const selectAuditXlsxFile = createSelector([selectAudit], (audit) =>
  audit.auditData?.files?.filter((file) => file.type === "xlsx")
);

export const selectAuditPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditPageTab
);

export const selectAuditReportPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditReportPageTab
);

export const selectAuditIssuesData = createSelector([selectAudit], (audit) => {
  const issueDetails = audit.auditData?.issueDetails?.issueDetails || {};
  const severityCategories =
    audit.auditData?.issueDetails?.severityCategories || {};

  const detailedIssues = Object.keys(severityCategories).map((category) => ({
    category,
    open: issueDetails.open?.[category] || 0,
    pastDue: issueDetails.pastDue?.[category] || 0,
    totalClosed: issueDetails.closed?.[category] || 0,
    color: severityCategories[category],
  }));

  const issuesSummary = {
    open: detailedIssues.reduce((acc, curr) => acc + (curr.open || 0), 0),
    pastDue: detailedIssues.reduce((acc, curr) => acc + (curr.pastDue || 0), 0),
    totalClosed: detailedIssues.reduce(
      (acc, curr) => acc + (curr.totalClosed || 0),
      0
    ),
  };
  return {
    total: detailedIssues.reduce(
      (acc, curr) => acc + ((curr.open || 0) + (curr.totalClosed || 0)),
      0
    ),
    issuesSummary,
    detailedIssues,
  };
});

export const selectAuditIssuesDataReformation = createSelector(
  [selectAudit],
  (audit) => {
    const issueDetails = audit.auditData?.issueDetails?.issueDetails || {};
    const severityCategories =
      audit.auditData?.issueDetails?.severityCategories || {};

    // Create a new object with the desired order, including "Moderate"
    const orderedCategories = {
      ZT: severityCategories["ZT"] || "",
      Critical: severityCategories["Critical"] || "",
      Major: severityCategories["Major"] || "",
      Moderate: "#667085", // Add "Moderate" with the same color as "Minor"
      Minor: severityCategories["Minor"] || "",
      Unsure: "black" || "",
    };

    // Use the new orderedCategories object instead of modifying the original
    const updatedSeverityCategories = { ...orderedCategories };

    const detailedIssues = Object.keys(updatedSeverityCategories).map(
      (category) => ({
        category,
        open: issueDetails.open?.[category] || 0,
        pastDue: issueDetails.pastDue?.[category] || 0,
        totalClosed: issueDetails.closed?.[category] || 0,
        color: updatedSeverityCategories[category],
      })
    );

    const issuesSummary = {
      open: detailedIssues.reduce((acc, curr) => acc + (curr.open || 0), 0),
      pastDue: detailedIssues.reduce(
        (acc, curr) => acc + (curr.pastDue || 0),
        0
      ),
      totalClosed: detailedIssues.reduce(
        (acc, curr) => acc + (curr.totalClosed || 0),
        0
      ),
    };
    return {
      total: detailedIssues.reduce(
        (acc, curr) => acc + ((curr.open || 0) + (curr.totalClosed || 0)),
        0
      ),
      issuesSummary,
      detailedIssues,
    };
  }
);
export const selectAuditIssueCAPData = createSelector(
  [selectAudit],
  (audit) => {
    const capStartDate = moment(
      new Date(
        audit?.auditData?.metadata?.facilityData?.cap_start_date ||
          audit?.auditData?.metadata?.facilityData?.audit_end
      )
    ).isValid()
      ? new Date(
          audit?.auditData?.metadata?.facilityData?.cap_start_date ||
            audit?.auditData?.metadata?.facilityData?.audit_end
        )
      : null;
    const currentDate = new Date();

    return audit?.auditIssueCAPData
      ? Object.values(audit.auditIssueCAPData).map((issue) => {
          const timelineDays = issue.timeline?.match(/\d+/);
          const timelineNumber = timelineDays
            ? parseInt(timelineDays[0], 10)
            : 0;

          const dueInDays = capStartDate
            ? Math.floor(
                (capStartDate.getTime() +
                  timelineNumber * 24 * 60 * 60 * 1000 -
                  currentDate.getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            : null;

          return {
            id: issue?.id,
            clientCodeTitle: issue?.clientCodeTitle,
            issue: issue?.issueSummary,
            issueDetail: issue?.issueDetail,
            auditBasis: issue?.auditBasis,
            type: issue?.type,
            subType: issue?.subType,
            severity: issue?.severity,
            statusChipLabel: issue?.statusChipLabel,
            status: issue?.status,
            timeline: issue?.timeline,
            note: issue?.note || null,
            dueInDays: dueInDays,

            capManagementStatus: issue?.capManagementStatus || {},
            capStatus: issue?.capStatus || {
              isSupplierFillinCAP: false,
              isBrandApproveCAP: false,
              isSupplierProvideCapProof: false,
              is4imprintApproveCAPProof: false,
            },
          };
        })
      : [];
  }
);

export const selectAuditIssueCAPDataReformation = createSelector(
  [selectAudit],
  (audit) => {
    const capStartDate = moment(
      new Date(
        audit?.auditData?.metadata?.capStartDate ||
          audit?.auditData?.metadata?.auditEndDate
      )
    ).isValid()
      ? new Date(
          audit?.auditData?.metadata?.capStartDate ||
            audit?.auditData?.metadata?.auditEndDate
        )
      : null;
    const currentDate = new Date();

    return audit?.auditIssueCAPData
      ? Object.values(audit.auditIssueCAPData).map((issue) => {
          const timelineDays = issue.timeline?.match(/(\d+)\s*months?/);
          const timelineNumber = timelineDays
            ? parseInt(timelineDays[1], 10) * 30
            : 0;
          const dueInDays = capStartDate
            ? Math.floor(
                (capStartDate.getTime() +
                  timelineNumber * 24 * 60 * 60 * 1000 -
                  currentDate.getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            : null;

          return {
            id: issue.id,
            clientCodeTitle: issue.clientCodeTitle,
            issue: issue.issueSummary,
            type: issue.type,
            subType: issue.subType,
            severity: issue.severity,
            statusChipLabel: issue.statusChipLabel,
            status: issue.status,
            timeline: issue.timeline,
            note: issue.note || null,
            dueInDays: dueInDays,
          };
        })
      : [];
  }
);

export const selectAuditIssueCAPDataGroupedByType = createSelector(
  [selectAudit],
  (audit) => {
    if (audit.auditIssueCAPData) {
      const data = Object.values(audit.auditIssueCAPData).reduce(
        (acc, issue) => {
          acc[issue.type] = acc[issue.type] || [];
          acc[issue.type].push({ id: issue.id, issue: issue.issueSummary });
          return acc;
        },
        {}
      );

      return data;
    } else {
      return {};
    }
  }
);

export const selectAuditIssueDetails = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedAuditIssueId;

    return selectedIssueId && audit.auditIssueCAPData[selectedIssueId]
      ? audit.auditIssueCAPData[selectedIssueId]
      : {};
  }
);

export const selectFirstAuditIssueId = createSelector(
  [selectAudit],
  (audit) => {
    return Object.keys(audit.auditIssueCAPData)[0];
  }
);
export const selectListOfAuditsInCapManagement = createSelector(
  [selectAudit],
  (audit) => audit.listOfAuditsInCapManagement
);

export const selectAuditsWhichAreInProgressOfCapManagement = createSelector(
  [selectAudit],
  (audit) => {
    let auditsInProgress = {};
    audit?.listOfAuditsInCapManagement?.forEach((audit) => {
      auditsInProgress[audit.auditId] = true;
    });
    return auditsInProgress;
  }
);

export const selectFactoriesWhichAreInProgressOfCapManagement = createSelector(
  [selectAudit],
  (audit) => {
    let factoriesInProgress = {};
    audit.listOfAuditsInCapManagement.forEach((audit) => {
      factoriesInProgress[audit.factoryId] = true;
    });
    return factoriesInProgress;
  }
);

export const selectNumberOfInProgressCAPs = createSelector(
  [selectAudit],
  (audit) => audit.listOfAuditsInCapManagement?.length
);

export const selectCurrentStepInCapManagement = createSelector(
  [selectAudit],
  (audit) => audit.auditData?.capManagementStatus
);
export const selectAuditIssueCapDataStatus = createSelector(
  [selectAudit],
  (audit) => {
    const auditCAPStatus = audit.auditData?.capManagementStatus;

    let completed = 0;
    let total = Object.keys(audit.auditIssueCAPData).length;

    Object.keys(audit.auditIssueCAPData).forEach((issueId) => {
      if (
        auditCAPStatus === "IN_PROGRESS" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_ACTION_PLAN"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_REVIEWING_PLAN" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "BRAND_APPROVED_PLAN"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVED_PLAN" &&
        (audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_PROOF" ||
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
            "BRAND_APPROVED_PROOF")
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVING_PROOF" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "BRAND_APPROVED_PROOF"
      ) {
        completed++;
      } else if (
        auditCAPStatus === "BRAND_APPROVING_PROOF" &&
        audit.auditIssueCAPData[issueId]?.status?.trim() === "CLOSED"
      ) {
        completed++;
      }
    });
    return {
      completed,
      total,
    };
  }
);

export const selectCaseForSecondSubStepper = createSelector(
  [selectAudit],
  (audit) => {
    const auditCAPStatus = audit.auditData?.capManagementStatus;

    let brandApprovedProof = 0;
    let siteAddedProof = 0;
    let total = Object.keys(audit.auditIssueCAPData).length;

    if (
      auditCAPStatus === "BRAND_APPROVED_PLAN" ||
      auditCAPStatus === "BRAND_REVIEWING_PLAN"
    ) {
      Object.keys(audit.auditIssueCAPData).forEach((issueId) => {
        if (
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
            "BRAND_APPROVED_PROOF" ||
          audit.auditIssueCAPData[issueId]?.status?.trim() === "CLOSED"
        ) {
          brandApprovedProof++;
        } else if (
          audit.auditIssueCAPData[issueId]?.status?.trim() ===
          "SITE_ADDED_PROOF"
        ) {
          siteAddedProof++;
        }
      });
    }
    let showSecondSubStepper = false;
    let showButtonOnCircularProgress = true;
    if (brandApprovedProof > 0) {
      showSecondSubStepper = true;
    }
    if (siteAddedProof > 0 || brandApprovedProof > 0) {
      showButtonOnCircularProgress = false;
    }

    return {
      brandApprovedProof,
      siteAddedProof,
      total,
      showSecondSubStepper,
      showButtonOnCircularProgress,
    };
  }
);

export const selectAuditCAPStatus = createSelector(
  [selectAudit],
  (audit) => audit.auditData?.capManagementStatus
);

export const selectAuditCAPStatusUploadedProof = createSelector(
  [selectAudit],
  (audit) => {
    const issueId = audit.selectedAuditIssueId;
    const issue = audit.auditIssueCAPData[issueId];
    return issue?.status === "SITE_ADDED_PROOF";
  }
);
export const selectAuditCAPUploadedProof = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedAuditIssueId;
    return audit.auditIssueCAPData[selectedIssueId]?.proofDocuments || [];
  }
);

export const selectResources = createSelector([selectAudit], (audit) => {
  return audit.resources;
});

export const selectDueActionIRSSData = createSelector(
  [selectAudit],
  (audit) => audit.dueActionIRSSData || []
);
export const selectAuditQuickbaseData = createSelector(
  [selectAudit],
  (audit) => {
    const metadata = audit.auditData?.metadata || null;

    const isHistoricalAudit = audit.auditData?.isHistoricalAudit;

    const qbInfo = {
      "Supplier Location": metadata?.supplierLocation,
      "Supplier Name": metadata?.supplierName,
      "Facility Name": metadata?.facilityName,
      "Facility Country": metadata?.facilityCountry,
      "Facility City/Region": metadata?.facilityCity,
      "Report Owner and Payee": metadata?.reportOwnerAndPayee,
      "Audit Date From": metadata?.auditStartDate,
      "Audit Date To": metadata?.auditEndDate,
      "Audit Range": metadata?.auditRange,
      "Mutual Recognition Assessment": metadata?.mutualRecognitionAssessment,
      "Assessment Type": metadata?.auditType,
      "Monitoring Firm Name": metadata?.auditFirm,
      Branch: metadata?.auditFirmBranch,
      "Auditors: Primary": metadata?.auditorsPrimary,
      "Auditors: Secondary": metadata?.auditorsSecondary,
      "Assessment Report Ref": metadata?.assessmentReportRef,
      "Audit Announcement": metadata?.auditAnnouncement,
      "Audit Overall Rating/Score": metadata?.auditRating,
      "Ref Report Compliance Rating (if accepted through mutual recognition program)":
        metadata?.refReportComplianceRating,
      "Transparency Status": metadata?.transparencyStatus,
      "Date of Follow Up Assessment (if applicable)":
        metadata?.followUpAssessmentDate,
      "Internal Notes": metadata?.internalNotes,
    };

    return {
      isHistoricalAudit,
      qbInfo,
    };
  }
);

export const selectNonActiveAuditCount = createSelector(
  [selectAudit],
  (audit) => audit.nonActiveAuditCount
);

export const selectSelectedCAPManagementIssueId = createSelector(
  [selectAudit],
  (audit) => audit.selectedCAPManagementIssueId
);

export const selectCAPManagementIssueDetails = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedCAPManagementIssueId;

    return selectedIssueId && audit.auditIssueCAPData[selectedIssueId]
      ? audit.auditIssueCAPData[selectedIssueId]
      : {};
  }
);
