export const editButton = { ml: 1, cursor: "pointer", color: "#585aeb" };
export const alignBoxItemsCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const alignBoxItemsStart = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};
export const typography = { fontWeight: 500, fontSize: "14px" };
export const fieldName = { ...typography, color: "#8B8D97" };
export const fieldValue = { ...typography, color: "#45464E" };

export const headingsName = { ...typography, color: "#344054" };
export const headingsNameBold700 = {
  fontSize: 15,
  color: "#344054",
  fontWeight: 700,
};

export const twoLineThreeLineCard = {
  marginBottom: 24,
  borderRadius: 7,
  border: "1px solid var(--Gray-300, #D0D5DD)",
  background: "#FFF",
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
  minHeight: 182,
};
export const navbarListItemText = {
  color: "#344054",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
};
export const navbarListItemIcon = { maxWidth: 24, height: 24 };
export const logoBox = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  py: 2,
  px: 1,
};

export const logoImage = { maxWidth: 40, height: 40 };
export const tabBody = {
  "& .MuiTab-root": {
    textTransform: "none",
    flexGrow: 1,
    maxWidth: "none",
  },
  "& .Mui-selected": {
    backgroundColor: "rgba(13, 71, 161, 0.04)", // 0D47A1 with 4% opacity
  },
};

export const commentSectionCard = {
  p: 1,
  pt: 3,
  boxShadow:
    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
  border: "1px solid rgba(234, 236, 240, 1)",
  borderRadius: "12px",
  height: "100%", // Ensure the card takes up the full height
  margin: "auto",
};

export const commentSectionTitle = {
  fontWeight: 700,
  fontSize: 16,
  color: "#344054",
  display: "inline-block",
};
export const betaChip = {
  backgroundColor: "#4C5ED9",
  color: "white",
  fontWeight: 500,
  fontSize: "12px",
  height: "24px",
  ml: 1,
};

export const tooltipInfoIcon = {
  marginLeft: "8px",
  color: "#A0A5AD",
  verticalAlign: "middle",
};
