import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
const selectFactory = (state) => state.factory;

export const selectFactoryList = createSelector(
  [selectFactory],
  (factory) => factory.factories
);
export const selectFactoryListBySupplierId = createSelector(
  [selectFactory, (_, id) => id],
  (factory, id) => {
    if (id && id !== "" && factory.factories.length > 0) {
      const factoryList = factory.factories.filter(
        (factory) => factory.supplierId.toString() === id.toString()
      );
      return factoryList;
    } else {
      return [];
    }
  }
);

export const selectFactoryCache = createSelector(
  [selectFactory, (_, page) => page],
  (factory, page) => {
    return factory.factoryCache[page] || null;
  }
);
export const selectTotalCount = createSelector([selectFactory], (factory) =>
  factory.count ? Math.ceil(factory.count / 8) : 1
);
export const selectSearchCount = createSelector(
  [selectFactory],
  (factory) =>
    // factory.searchCount ? Math.ceil(factory.searchCount / 8) : 1
    factory.searchCount
);
export const selectSearchResultsFactoryList = createSelector(
  [selectFactory],
  (factory) => factory.searchResults
);
export const selectFactoryNameFromId = createSelector(
  [selectFactory, (_, id) => id],
  (factory, id) => {
    if (id && id !== "" && factory?.factories?.length > 0) {
      const factoryName = factory.factories.filter(
        (factory) => factory.factoryId?.toString() === id?.toString()
      );
      if (factoryName.length === 0 && factory?.factoryData?.name) {
        return factory.factoryData.name;
      }
      return factoryName[0]?.name;
    } else if (id && id !== "" && factory?.factoryData?.name) {
      return factory.factoryData.name;
    } else {
      return null;
    }
  }
);
export const selectFactoryTierFromId = createSelector(
  [selectFactory, (_, id) => id],
  (factory, id) => {
    if (id && id !== "" && factory?.factories?.length > 0) {
      const factoryName = factory.factories.filter(
        (factory) => factory.factoryId?.toString() === id?.toString()
      );

      return factoryName[0]?.tier;
    } else if (id && id !== "" && factory?.factoryData?.tier) {
      return factory.factoryData.tier;
    } else {
      return null;
    }
  }
);

export const selectFactoryData = createSelector(
  [selectFactory],
  (factory) => factory.factoryData
);

export const selectFacilityPageTab = createSelector(
  [selectFactory],
  (factory) => factory.factoryPageTab
);

export const selectAuditPageFactoryDataTitle = createSelector(
  [selectFactory],
  (factory) =>
    factory?.factoryData?.metadata?.auditType &&
    factory?.factoryData?.metadata?.lastAuditDate
      ? `${factory.factoryData.metadata.auditType} (${moment(
          factory.factoryData.metadata.lastAuditDate
        ).format("MMM. DD, YYYY")})`
      : null
);

export const selectAuditPageFactoryDataTitleReformation = createSelector(
  [selectFactory],
  (factory) =>
    factory.factoryData &&
    factory.factoryData.metadata &&
    factory.factoryData.metadata.auditType &&
    factory.factoryData.metadata.socialAssessmentInfo.lastAssessmentDate
      ? `${factory.factoryData.metadata.auditType} (${moment(
          factory.factoryData.metadata.socialAssessmentInfo.lastAssessmentDate
        ).format("MMM. DD, YYYY")})`
      : null
);
export const selectFactoryConfidentialInformation = createSelector(
  [selectFactory],
  (factory) => {
    const factoryMetaData = factory?.factoryData?.metadata;
    const YoYSpendChange =
      (factoryMetaData?.spend2024 - factoryMetaData?.spend) /
      factoryMetaData?.spend;

    if (factoryMetaData) {
      return [
        [
          {
            heading: "Product Categories",
            value:
              !factoryMetaData?.productCategories ||
              factoryMetaData?.productCategories === ""
                ? "-"
                : factoryMetaData?.productCategories,
            xs: 12,
          },
        ],
        [
          {
            heading: "Products Produced",
            value:
              !factoryMetaData?.productProduced ||
              factoryMetaData?.productProduced === ""
                ? "-"
                : factoryMetaData?.productProduced,
            xs: 12,
          },
        ],
        [
          {
            heading: "Previous Year Spend",
            value:
              !factoryMetaData?.spend || factoryMetaData?.spend === ""
                ? "-"
                : `$${parseFloat(factoryMetaData?.spend).toFixed(2)}`,
            xs: 6,
          },
        ],
        [
          {
            heading: "YoY Spend Change",
            value:
              !YoYSpendChange || YoYSpendChange === ""
                ? "-"
                : `${YoYSpendChange.toFixed(2)}%`,
            xs: 6,
          },
        ],
        // [
        //   {
        //     heading: "Brand Type",
        //     value:
        //       !factoryMetaData.productCategories ||
        //       factoryMetaData.productCategories === ""
        //         ? "-"
        //         : factoryMetaData.productCategories,
        //     xs: 12,
        //   },
        // ],
        // [
        //   {
        //     heading: "Business Unit",
        //     value:
        //       !factoryData.businessUnit || factoryData.businessUnit === ""
        //         ? "-"
        //         : factoryData.businessUnit,
        //     xs: 12,
        //   },
        // ],
        // [
        //   {
        //     heading: "Factory Approval Date",
        //     value:
        //       !factoryMetaData.factoryApprovalDate ||
        //       factoryMetaData.factoryApprovalDate === ""
        //         ? "-"
        //         : moment(factoryMetaData.factoryApprovalDate).format('YYYY-MM-DD'),
        //     xs: 12,
        //   },
        // ],
        // [
        //   {
        //     heading: "Agent",
        //     value:
        //       !factoryMetaData.agent || factoryMetaData.agent === ""
        //         ? "-"
        //         : factoryMetaData.agent,
        //     xs: 12,
        //   },
        // ],
      ];
    } else {
      return [];
    }
  }
);
export const selectFactoryConfidentialInformationStitchfix = createSelector(
  [selectFactory],
  (factory) => {
    const factoryMetaData = factory?.factoryData?.metadata || {};
    const factoryData = factory?.factoryData || {};
    return [
      [
        {
          heading: "Brand Type",
          value:
            !factoryMetaData.productCategories ||
            factoryMetaData.productCategories === ""
              ? "-"
              : factoryMetaData.productCategories,
          xs: 12,
        },
      ],
      [
        {
          heading: "Business Unit",
          value:
            !factoryData.businessUnit || factoryData.businessUnit === ""
              ? "-"
              : factoryData.businessUnit,
          xs: 12,
        },
      ],
      [
        {
          heading: "Factory Approval Date",
          value:
            !factoryMetaData.factoryApprovalDate ||
            factoryMetaData.factoryApprovalDate === ""
              ? "-"
              : moment(factoryMetaData.factoryApprovalDate).format(
                  "YYYY-MM-DD"
                ),
          xs: 12,
        },
      ],
      [
        {
          heading: "Agent",
          value:
            !factoryMetaData.agent || factoryMetaData.agent === ""
              ? "-"
              : factoryMetaData.agent,
          xs: 12,
        },
      ],
    ];
  }
);
export const selectFactoryConfidentialInformationRoots = createSelector(
  [selectFactory],
  (factory) => {
    const factoryMetaData = factory?.factoryData?.metadata;
    if (factoryMetaData) {
      return [
        [
          {
            heading: "Product Categories",
            value:
              !factoryMetaData?.productCategories ||
              factoryMetaData?.productCategories === ""
                ? "-"
                : factoryMetaData?.productCategories,
            xs: 12,
          },
        ],
        [
          {
            heading: "Products Produced",
            value:
              !factoryMetaData?.productProduced ||
              factoryMetaData?.productProduced === ""
                ? "-"
                : factoryMetaData?.productProduced,
            xs: 12,
          },
        ],
        [
          {
            heading: "Spend",
            value:
              !factoryMetaData?.spend || factoryMetaData?.spend === ""
                ? "-"
                : `$${Number(factoryMetaData.spend).toLocaleString("en-US")}`,
            xs: 12,
          },
        ],
      ];
    } else {
      return [];
    }
  }
);

export const selectFactoryIssuesData = createSelector(
  [selectFactory],
  (factory) => {
    const metadata = factory.factoryData ? factory.factoryData.metadata : null;

    const safeAccess = (path, defaultValue = 0) => {
      return path.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
        metadata
      );
    };

    const detailedIssues = [
      {
        category: "Management Systems",
        zt: safeAccess(["issues", "managementSystems", "zt"]),
        critical: safeAccess(["issues", "managementSystems", "critical"]),
        major: safeAccess(["issues", "managementSystems", "major"]),
        minor: safeAccess(["issues", "managementSystems", "minor"]),
      },
      {
        category: "Health & Safety",
        zt: safeAccess(["issues", "healthSafety", "zt"]),
        critical: safeAccess(["issues", "healthSafety", "critical"]),
        major: safeAccess(["issues", "healthSafety", "major"]),
        minor: safeAccess(["issues", "healthSafety", "minor"]),
      },
      {
        category: "Working Hours",
        zt: safeAccess(["issues", "workingHours", "zt"]),
        critical: safeAccess(["issues", "workingHours", "critical"]),
        major: safeAccess(["issues", "workingHours", "major"]),
        minor: safeAccess(["issues", "workingHours", "minor"]),
      },
      {
        category: "Wages & Benefits",
        zt: safeAccess(["issues", "wagesBenefits", "zt"]),
        critical: safeAccess(["issues", "wagesBenefits", "critical"]),
        major: safeAccess(["issues", "wagesBenefits", "major"]),
        minor: safeAccess(["issues", "wagesBenefits", "minor"]),
      },
      {
        category: "Business Ethics",
        zt: safeAccess(["issues", "businessEthics", "zt"]),
        critical: safeAccess(["issues", "businessEthics", "critical"]),
        major: safeAccess(["issues", "businessEthics", "major"]),
        minor: safeAccess(["issues", "businessEthics", "minor"]),
      },
      {
        category: "Employment, Discipline, & Grievances",
        zt: safeAccess(["issues", "employmentDisciplineGrievances", "zt"]),
        critical: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "critical",
        ]),
        major: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "major",
        ]),
        minor: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "minor",
        ]),
      },
      {
        category: "Discrimination & Harassment",
        zt: safeAccess(["issues", "discriminationHarassment", "zt"]),
        critical: safeAccess([
          "issues",
          "discriminationHarassment",
          "critical",
        ]),
        major: safeAccess(["issues", "discriminationHarassment", "major"]),
        minor: safeAccess(["issues", "discriminationHarassment", "minor"]),
      },
      {
        category: "Freedom of Association & Collective Bargaining",
        zt: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "zt",
        ]),
        critical: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "critical",
        ]),
        major: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "major",
        ]),
        minor: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "minor",
        ]),
      },
      {
        category: "Young & Underage Workers",
        zt: safeAccess(["issues", "youngUnderageWorkers", "zt"]),
        critical: safeAccess(["issues", "youngUnderageWorkers", "critical"]),
        major: safeAccess(["issues", "youngUnderageWorkers", "major"]),
        minor: safeAccess(["issues", "youngUnderageWorkers", "minor"]),
      },
      {
        category: "Forced Labor",
        zt: safeAccess(["issues", "forcedLabor", "zt"]),
        critical: safeAccess(["issues", "forcedLabor", "critical"]),
        major: safeAccess(["issues", "forcedLabor", "major"]),
        minor: safeAccess(["issues", "forcedLabor", "minor"]),
      },
      {
        category: "Sub-contracting & Homework",
        zt: safeAccess(["issues", "subContractingHomework", "zt"]),
        critical: safeAccess(["issues", "subContractingHomework", "critical"]),
        major: safeAccess(["issues", "subContractingHomework", "major"]),
        minor: safeAccess(["issues", "subContractingHomework", "minor"]),
      },
      {
        category: "Environment",
        zt: safeAccess(["issues", "environment", "zt"]),
        critical: safeAccess(["issues", "environment", "critical"]),
        major: safeAccess(["issues", "environment", "major"]),
        minor: safeAccess(["issues", "environment", "minor"]),
      },
      {
        category: "Other",
        zt: safeAccess(["issues", "other", "zt"]),
        critical: safeAccess(["issues", "other", "critical"]),
        major: safeAccess(["issues", "other", "major"]),
        minor: safeAccess(["issues", "other", "minor"]),
      },
    ];

    const issuesSummary = {
      zt: detailedIssues.reduce((acc, curr) => acc + (curr.zt || 0), 0),
      critical: detailedIssues.reduce(
        (acc, curr) => acc + (curr.critical || 0),
        0
      ),
      major: detailedIssues.reduce((acc, curr) => acc + (curr.major || 0), 0),
      minor: detailedIssues.reduce((acc, curr) => acc + (curr.minor || 0), 0),
    };
    return {
      totalIssues: Object.values(issuesSummary).reduce(
        (acc, curr) => acc + (curr || 0),
        0
      ),
      issuesSummary,
      detailedIssues,
    };
  }
);
export const selectFactoryIssuesDataReformation = createSelector(
  [selectFactory],
  (factory) => {
    const metadata = factory.factoryData ? factory.factoryData.metadata : null;

    const safeAccess = (path, defaultValue = 0) => {
      return path.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
        metadata
      );
    };

    //TODO
    const detailedIssues = [
      {
        category: "Management Systems",
        zt: safeAccess(["issues", "managementSystems", "zt"]),
        critical: safeAccess(["issues", "managementSystems", "critical"]),
        major: safeAccess(["issues", "managementSystems", "major"]),
        moderate: safeAccess(["issues", "managementSystems", "moderate"]),
        minor: safeAccess(["issues", "managementSystems", "minor"]),
      },
      {
        category: "Health & Safety",
        zt: safeAccess(["issues", "healthSafety", "zt"]),
        critical: safeAccess(["issues", "healthSafety", "critical"]),
        major: safeAccess(["issues", "healthSafety", "major"]),
        moderate: safeAccess(["issues", "healthSafety", "moderate"]),
        minor: safeAccess(["issues", "healthSafety", "minor"]),
      },
      {
        category: "Working Hours",
        zt: safeAccess(["issues", "workingHours", "zt"]),
        critical: safeAccess(["issues", "workingHours", "critical"]),
        major: safeAccess(["issues", "workingHours", "major"]),
        moderate: safeAccess(["issues", "workingHours", "moderate"]),
        minor: safeAccess(["issues", "workingHours", "minor"]),
      },
      {
        category: "Wages & Benefits",
        zt: safeAccess(["issues", "wagesBenefits", "zt"]),
        critical: safeAccess(["issues", "wagesBenefits", "critical"]),
        major: safeAccess(["issues", "wagesBenefits", "major"]),
        moderate: safeAccess(["issues", "wagesBenefits", "moderate"]),
        minor: safeAccess(["issues", "wagesBenefits", "minor"]),
      },
      {
        category: "Business Ethics",
        zt: safeAccess(["issues", "businessEthics", "zt"]),
        critical: safeAccess(["issues", "businessEthics", "critical"]),
        major: safeAccess(["issues", "businessEthics", "major"]),
        moderate: safeAccess(["issues", "businessEthics", "moderate"]),
        minor: safeAccess(["issues", "businessEthics", "minor"]),
      },
      {
        category: "Employment, Discipline, & Grievances",
        zt: safeAccess(["issues", "employmentDisciplineGrievances", "zt"]),
        critical: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "critical",
        ]),
        major: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "major",
        ]),
        moderate: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "moderate",
        ]),
        minor: safeAccess([
          "issues",
          "employmentDisciplineGrievances",
          "minor",
        ]),
      },
      {
        category: "Discrimination & Harassment",
        zt: safeAccess(["issues", "discriminationHarassment", "zt"]),
        critical: safeAccess([
          "issues",
          "discriminationHarassment",
          "critical",
        ]),
        major: safeAccess(["issues", "discriminationHarassment", "major"]),
        moderate: safeAccess([
          "issues",
          "discriminationHarassment",
          "moderate",
        ]),
        minor: safeAccess(["issues", "discriminationHarassment", "minor"]),
      },
      {
        category: "Freedom of Association & Collective Bargaining",
        zt: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "zt",
        ]),
        critical: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "critical",
        ]),
        major: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "major",
        ]),
        moderate: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "moderate",
        ]),
        minor: safeAccess([
          "issues",
          "freedomAssociationCollectiveBargaining",
          "minor",
        ]),
      },
      {
        category: "Young & Underage Workers",
        zt: safeAccess(["issues", "youngUnderageWorkers", "zt"]),
        critical: safeAccess(["issues", "youngUnderageWorkers", "critical"]),
        major: safeAccess(["issues", "youngUnderageWorkers", "major"]),
        moderate: safeAccess(["issues", "youngUnderageWorkers", "moderate"]),
        minor: safeAccess(["issues", "youngUnderageWorkers", "minor"]),
      },
      {
        category: "Forced Labor",
        zt: safeAccess(["issues", "forcedLabor", "zt"]),
        critical: safeAccess(["issues", "forcedLabor", "critical"]),
        major: safeAccess(["issues", "forcedLabor", "major"]),
        moderate: safeAccess(["issues", "forcedLabor", "moderate"]),
        minor: safeAccess(["issues", "forcedLabor", "minor"]),
      },
      {
        category: "Sub-contracting & Homework",
        zt: safeAccess(["issues", "subContractingHomework", "zt"]),
        critical: safeAccess(["issues", "subContractingHomework", "critical"]),
        major: safeAccess(["issues", "subContractingHomework", "major"]),
        moderate: safeAccess(["issues", "subContractingHomework", "moderate"]),
        minor: safeAccess(["issues", "subContractingHomework", "minor"]),
      },
      {
        category: "Environment",
        zt: safeAccess(["issues", "environment", "zt"]),
        critical: safeAccess(["issues", "environment", "critical"]),
        major: safeAccess(["issues", "environment", "major"]),
        moderate: safeAccess(["issues", "environment", "moderate"]),
        minor: safeAccess(["issues", "environment", "minor"]),
      },
      {
        category: "Other",
        zt: safeAccess(["issues", "other", "zt"]),
        critical: safeAccess(["issues", "other", "critical"]),
        major: safeAccess(["issues", "other", "major"]),
        moderate: safeAccess(["issues", "other", "moderate"]),
        minor: safeAccess(["issues", "other", "minor"]),
      },
    ];

    const issuesSummary = {
      zt: detailedIssues.reduce((acc, curr) => acc + (curr.zt || 0), 0),
      critical: detailedIssues.reduce(
        (acc, curr) => acc + (curr.critical || 0),
        0
      ),
      major: detailedIssues.reduce((acc, curr) => acc + (curr.major || 0), 0),
      moderate: detailedIssues.reduce(
        (acc, curr) => acc + (curr.moderate || 0),
        0
      ),
      minor: detailedIssues.reduce((acc, curr) => acc + (curr.minor || 0), 0),
    };
    return {
      totalIssues: Object.values(issuesSummary).reduce(
        (acc, curr) => acc + (curr || 0),
        0
      ),
      issuesSummary,
      detailedIssues,
    };
  }
);

// export const selectAdditionalFactoryInformation = createSelector(
//   [selectFactory],
//   (factory) => {
//     const metadata = factory.factoryData ? factory.factoryData.metadata : null;

//     const safeAccess = (path, defaultValue = "-") => {
//       return path.reduce(
//         (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
//         metadata
//       );
//     };

//     const factoryInfo = [
//       {
//         heading: "Worker Count",
//         values: [
//           { label: "Total", value: safeAccess(["workerCountTotal"]) },
//           { label: "Permanent", value: safeAccess(["workerCountPermanent"]) },
//           { label: "Temporary", value: safeAccess(["workerCountTemporary"]) },
//           { label: "Agency", value: safeAccess(["workerCountAgency"]) },
//         ],
//       },
//       {
//         heading: "Worker Demographics",
//         values: [
//           {
//             label: "Foreign Migrant Workers",
//             value: safeAccess(["foreignMigrantWorkers"]),
//           },
//           {
//             label: "Domestic Migrant Workers",
//             value: safeAccess(["domesticMigrantWorkers"]),
//           },
//           { label: "Young Workers", value: safeAccess(["youngWorkers"]) },
//           { label: "Home Workers", value: safeAccess(["homeWorkers"]) },
//         ],
//       },
//       {
//         heading: "Worker Gender",
//         values: [
//           { label: "Female", value: safeAccess(["workerGenderFemale"]) },
//           { label: "Male", value: safeAccess(["workerGenderMale"]) },
//           { label: "Other", value: safeAccess(["workerGenderOther"]) },
//           { label: "", value: "" },
//         ],
//       },
//       {
//         heading: "Production Seasonality",
//         values: [
//           { label: "Peak", value: safeAccess(["productionSeasonalityPeak"]) },
//           { label: "Low", value: safeAccess(["productionSeasonalityLow"]) },
//         ],
//       },
//     ];
//     return factoryInfo;
//   }
// );

export const selectAdditionalFactoryInformation = createSelector(
  [selectFactory, (_, isReformation = false) => isReformation],
  (factory, isReformation) => {
    let factoryInfo = [];

    let auditProcessingDate = !isReformation
      ? null
      : factory.factoryData
      ? factory.factoryData.latestAudit?.createdAt
      : null;
    const metadata = !isReformation
      ? factory.factoryData?.latestAudit?.metadata
        ? factory.factoryData?.latestAudit?.metadata?.facilityData
        : null
      : factory?.factoryData
      ? factory?.factoryData?.metadata
      : null;
    let auditExists = !isReformation ? false : !!metadata?.dataCitation;
    const safeAccess = (path, defaultValue = "-") => {
      return path.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue),
        metadata
      );
    };
    const safeAccessReformation = (object, path, defaultValue = "-") => {
      if (!Array.isArray(path)) {
        console.error("Path must be an array");
        return defaultValue;
      }

      return path.reduce((acc, key) => {
        if (
          acc !== undefined &&
          acc !== null &&
          acc[key] !== undefined &&
          acc[key] !== null
        ) {
          return acc[key];
        }
        return defaultValue;
      }, object);
    };

    factoryInfo = [
      {
        heading: "Worker Count",
        key: "workerCount",
        values: [
          { label: "Total", value: safeAccess(["worker_count_total"]) },
          {
            label: "Permanent",
            value: safeAccess(["worker_count_permanent"]),
          },
          {
            label: "Temporary",
            value: safeAccess(["worker_count_temporary"]),
          },
          { label: "Agency", value: safeAccess(["worker_count_agency"]) },
        ],
      },
      {
        heading: "Worker Demographics",
        key: "workerDemographics",
        values: [
          {
            label: "Foreign Migrant Workers",
            value: safeAccess(["foreign_migrant_workers"]),
          },
          {
            label: "Domestic Migrant Workers",
            value: safeAccess(["domestic_migrant_workers"]),
          },
          { label: "Young Workers", value: safeAccess(["young_workers"]) },
          { label: "Home Workers", value: safeAccess(["home_workers"]) },
        ],
      },
      {
        heading: "Worker Gender",
        key: "workerGender",
        values: [
          { label: "Female", value: safeAccess(["worker_gender_female"]) },
          { label: "Male", value: safeAccess(["worker_gender_male"]) },
          { label: "Other", value: safeAccess(["worker_gender_other"]) },
          { label: "", value: "" },
        ],
      },
      {
        heading: "Production Seasonality",
        key: "productionSeasonality",
        values: [
          {
            label: "Peak",
            value: safeAccess(["production_seasonality_peak"]),
          },
          { label: "Low", value: safeAccess(["production_seasonality_low"]) },
        ],
      },
      {
        heading: "Wage & Working Hours",
        key: "wageAndWorkingHours",
        values: [
          {
            label: "Country Min Wage",
            value: safeAccess(["productionSeasonalityPeak"]),
          },
          {
            label: "Lowest Wage Paid",
            value: safeAccess(["productionSeasonalityLow"]),
          },
          {
            label: "Highest Weekly Working Hours",
            value: safeAccess(["productionSeasonalityLow"]),
          },
        ],
      },

      // REFORMATION SPECIFIC ++++++++++++++++++

      {
        heading: "Worker Count",
        key: "workerCountReformation",

        values: [
          {
            label: "Total",
            value: safeAccessReformation(metadata, ["workerCountTotal"]),
            color: "normal",
          },
          {
            label: "Permanent",
            value: safeAccessReformation(metadata, ["workerCountPermanent"]),
            color: "normal",
          },
          {
            label: "Temporary",
            value: safeAccessReformation(metadata, ["workerCountTemporary"]),
            color: "normal",
          },
          {
            label: "Agency",
            value: safeAccessReformation(metadata, ["workerCountAgency"]),
            color: "normal",
          },
        ],
      },
      {
        heading: "Worker Demographics",
        key: "workerDemographicsReformation",
        values: [
          {
            label: "Foreign Migrant Workers",
            value: safeAccessReformation(metadata, ["foreignMigrantWorkers"]),
            color: "normal",
          },
          {
            label: "Domestic Migrant Workers",
            value: safeAccessReformation(metadata, ["domesticMigrantWorkers"]),
            color: "normal",
          },
          {
            label: "Young Workers",
            value: safeAccessReformation(metadata, ["youngWorkers"]),
            color: "normal",
          },
          {
            label: "Home Workers",
            value: safeAccessReformation(metadata, ["homeWorkers"]),
            color: "normal",
          }, // Assuming this field is not in audit
        ],
      },
      {
        heading: "Worker Gender",
        key: "workerGenderReformation",
        values: [
          {
            label: "Female",
            value: safeAccessReformation(metadata, ["workerGenderFemale"]),
            color: "normal",
          },
          {
            label: "Male",
            value: safeAccessReformation(metadata, ["workerGenderMale"]),
            color: "normal",
          },
          {
            label: "Other",
            value: safeAccessReformation(metadata, ["workerGenderOther"]),
            color: "normal",
          },
          { label: "", value: "" },
        ],
      },
      {
        heading: "Production Seasonality",
        key: "productionSeasonalityReformation",
        values: [
          {
            label: "Peak",
            value: safeAccessReformation(metadata, [
              "productionSeasonalityPeak",
            ]),
            color: "normal",
          },
          {
            label: "Low",
            value: safeAccessReformation(metadata, [
              "productionSeasonalityLow",
            ]),
            color: "normal",
          },
          { label: "", value: "" },
          { label: "", value: "" },
        ],
      },
      {
        heading: "Dormitory Information",
        key: "dormitoryInformationReformation",
        values: [
          {
            label: "Employee Housing",
            value: safeAccessReformation(metadata, [
              "housing",
              "employeeHousingCheck",
            ]),
            color: "dec12",
          },
          {
            label: "Multiple Tenants",
            value: safeAccessReformation(metadata, [
              "tenants",
              "multipleTenantsCheck",
            ]),
            color: "dec12",
          },
          { label: "", value: "" },
          { label: "", value: "" },
        ],
      },
      {
        heading: "FOA",
        key: "fOA",
        values: [
          {
            label: "Union in Facility",
            value: safeAccessReformation(metadata, [
              "freedomOfAssoc",
              "unionCheck",
            ]),
            color: "dec12",
          },
          {
            label: "CBA in place",
            value: safeAccessReformation(metadata, [
              "freedomOfAssoc",
              "cbaCheck",
            ]),
            color: "dec12",
          },
          {
            label: "Worker Committee",
            value: safeAccessReformation(metadata, [
              "freedomOfAssoc",
              "workerCommitteeCheck",
            ]),
            color: "dec12",
          },
          { label: "", value: "" },
        ],
      },
      {
        heading: "Labor Broker",
        key: "laborBroker",
        values: [
          {
            label: "Labor Agency Used",
            value: safeAccessReformation(metadata, [
              "employeeInfo",
              "workedHiredThroughBrokersOrAgenciesCheck",
            ]),
            color: "dec19",
          },
          {
            label: "Workers Pay Fees to Agency",
            value: safeAccessReformation(metadata, [
              "employeeInfo",
              "agencyWorkersFeesPaidCheck",
            ]),
            color: "jan6",
          },
          {
            label: "Employees retain their documents",
            value: safeAccessReformation(metadata, [
              "employeeInfo",
              "doEmployeesRetainTheirDocuments",
            ]),
            color: "jan6",
          },
        ],
      },
    ];

    return { factoryInfo, auditExists, auditProcessingDate };
  }
);

const computeDueInDays = (date) => moment(date).diff(moment(), "days");
const computeCapDueInDays = (auditEndDate, cap) => {
  const capStartDate = moment(auditEndDate, ["MM/DD/YYYY", "YYYY-MM-DD"], true);
  if (!capStartDate.isValid()) return null;

  const currentDate = moment();
  let timelineNumber = 0;

  if (cap.timeline?.includes("month")) {
    const timelineDays = cap?.timeline.match(/(\d+)\s*months?/);
    timelineNumber = timelineDays ? parseInt(timelineDays[1], 10) * 30 : 0;
  } else {
    const timelineDays = cap?.timeline.match(/\d+/);
    timelineNumber = timelineDays ? parseInt(timelineDays[0], 10) : 0;
  }

  const dueInDays = capStartDate.isValid()
    ? Math.floor(
        capStartDate.add(timelineNumber, "days").diff(currentDate, "days")
      )
    : null;
  //console.log("dueInDays", timelineNumber, auditEndDate, dueInDays);
  return dueInDays;
};

export const selectActionNeededData = createSelector(
  [selectFactory],
  (factory) => {
    const factoryData = factory.factoryData || {};
    const latestAudit = factory.factoryData?.latestAudit || {};
    const metadata = factoryData.metadata || {};
    const capDetails = latestAudit.metadata?.capDetails || [];

    // Certificate Details
    const certificateDetails = {
      wcc: metadata?.wcc || null,
      businessLicenseInfo: metadata?.businessLicenseInfo || null,
    };
    // IRSS
    const rating = latestAudit?.risk?.auditRating || null;
    const irssDetails = metadata?.irss || {};
    // Compute irssEnrollmentTriggered
    let irssEnrollmentTriggered =
      (rating === "Orange (C)" ||
        rating === "Red (D)" ||
        rating === "Orange" ||
        rating === "Red") &&
      irssDetails?.check;
    if (irssDetails?.programEndDate) {
      irssEnrollmentTriggered = false;
    }
    // Compute irssProgramEndTriggered
    let irssProgramEndTriggered = false;
    if (irssDetails?.programEndDate) {
      const daysLeft = computeDueInDays(irssDetails?.programEndDate);
      irssProgramEndTriggered = daysLeft > 0 && daysLeft < 21;
    }

    const upcomingAuditDate =
      metadata.socialAssessmentInfo?.confirmedAuditDate || null; //metadata.socialAssessmentInfo.confirmedAuditDate

    const nextAssessmentWindowDateStart =
      metadata.socialAssessmentInfo?.nextAssessmentWindowDateStart;
    const nextAssessmentWindowDateEnd =
      metadata.socialAssessmentInfo?.nextAssessmentWindowDateEnd;
    const confirmedAuditDate = moment(
      metadata.socialAssessmentInfo?.confirmedAuditDate,
      ["MM/DD/YYYY", "YYYY-MM-DD"],
      true
    );
    const latestAuditDate =
      moment(latestAudit?.metadata?.auditEndDate, [
        "MM/DD/YYYY",
        "YYYY-MM-DD",
      ]) ||
      moment(latestAudit?.metadata?.auditStartDate, [
        "MM/DD/YYYY",
        "YYYY-MM-DD",
      ]) ||
      null;
    //console.log("latestAuditDate", latestAuditDate);
    //console.log("confirmedAuditDate", confirmedAuditDate);

    // Condition 1: Next assessment window date is within 30 days
    const condition1 =
      computeDueInDays(nextAssessmentWindowDateStart) <= 30 &&
      computeDueInDays(nextAssessmentWindowDateStart) > 0;
    // Condition 2: Confirmed audit date is not set
    const condition2 = !confirmedAuditDate.isValid(); // if confirmedAuditDate is not valid, then it is not set
    // Condition 3: Confirmed audit date is outside the assessment window
    const condition3 =
      confirmedAuditDate &&
      nextAssessmentWindowDateStart &&
      nextAssessmentWindowDateEnd &&
      (new Date(confirmedAuditDate) < new Date(nextAssessmentWindowDateStart) ||
        new Date(confirmedAuditDate) > new Date(nextAssessmentWindowDateEnd));
    // Condition 4: Confirmed audit date is >60 days away + no assessment window end date (this is just an extra condition to ensure condition 3 is able to be checked when there is no assessment window end date)
    const condition4 =
      confirmedAuditDate &&
      !nextAssessmentWindowDateEnd &&
      new Date(confirmedAuditDate) >
        new Date().setDate(new Date().getDate() + 60);
    let auditNotConfirmedMessage = null;
    if (condition1) {
      if (condition2) {
        auditNotConfirmedMessage =
          "Next assessment window start date is within 30 days but there is no confirmed audit date";
      } else if (condition3) {
        auditNotConfirmedMessage =
          "Next assessment window start date is within 30 days but confirmed audit date is outside next assessment window";
      } else if (condition4) {
        auditNotConfirmedMessage =
          "Next assessment window start date is within 30 days but confirmed audit date is >60 days away";
      }
    }

    // Compute certificationTriggered
    const wccTriggered =
      computeDueInDays(certificateDetails?.wcc?.wccExpDate) <= 21;
    const licenseTriggered =
      computeDueInDays(
        certificateDetails?.businessLicenseInfo?.licenseExpDate
      ) <= 21;
    const certificationTriggered = wccTriggered || licenseTriggered;

    let auditTriggered =
      computeDueInDays(upcomingAuditDate) <= 5 &&
      computeDueInDays(upcomingAuditDate) > 0;
    let resetAuditTriggered;
    if (!latestAuditDate) {
      resetAuditTriggered = false;
    } else if (new Date(latestAuditDate) >= new Date(confirmedAuditDate)) {
      resetAuditTriggered = true;
    } else {
      resetAuditTriggered = false;
    }
    if (auditTriggered) {
      if (resetAuditTriggered) {
        auditTriggered = false;
      }
    }
    //console.log("latestAuditDate", latestAuditDate);
    //console.log("resetAuditTriggered", resetAuditTriggered);
    const auditNotConfirmedTriggered =
      condition1 && (condition2 || condition3 || condition4);

    const capTriggered = capDetails.some(
      (cap) => computeCapDueInDays(latestAuditDate, cap) <= 0
    ); // TODO: update logic based on Ernest feedback - currently only triggers if overdue

    const overallTriggered =
      irssEnrollmentTriggered ||
      irssProgramEndTriggered ||
      certificationTriggered ||
      auditTriggered ||
      auditNotConfirmedTriggered ||
      capTriggered;

    return {
      capDetails: capDetails,
      certificateDetails: certificateDetails,
      rating: rating,
      irssDetails: irssDetails,
      upcomingAuditDate: upcomingAuditDate,
      nextAssessmentWindowDateStart: nextAssessmentWindowDateStart,
      nextAssessmentWindowDateEnd: nextAssessmentWindowDateEnd,
      triggers: {
        irssEnrollmentTriggered: irssEnrollmentTriggered,
        irssProgramEndTriggered: irssProgramEndTriggered,
        certificationTriggered: certificationTriggered,
        auditTriggered: auditTriggered,
        auditNotConfirmedTriggered: auditNotConfirmedTriggered,
        capTriggered: capTriggered,
        overallTriggered: overallTriggered,
        wccTriggered: wccTriggered,
        licenseTriggered: licenseTriggered,
      },
      auditNotConfirmedMessage: auditNotConfirmedMessage,
    };
  }
);

export const selectSignedUrls = createSelector(
  [selectFactory],
  (factory) => factory.signedUrls || {}
);
export const selectDueActionCerticatesData = createSelector(
  [selectFactory],
  (factory) => factory.dueActionCertificatesData || []
);

export const selectFactoryIRSSData = createSelector(
  [selectFactory],
  (factory) => factory.factoryData?.metadata?.irss || {}
);

export const selectSAQFactories = createSelector(
  [selectFactory],
  (factory) => factory.saqFactories
);
export const selectFactoryDropdownData = createSelector(
  [selectFactory],
  (factoryState) => {
    const liveFactories = new Map();

    factoryState.factories.forEach((factory) => {
      if (factory.factoryStatus === "live") {
        liveFactories.set(factory.factoryId, {
          factoryId: factory.factoryId,
          name: factory.name,
          supplierId: factory.supplierId || "",
        });
      }
    });

    return Array.from(liveFactories.values());
    //   return factoryState.factories
    // .filter(factory => factory.factoryStatus === 'live') // Filter for 'live' status
    // .map(factory => ({
    //   factoryId: factory.factoryId,
    //   name: factory.name,
    //   supplierName: factory.metadata?.supplierName || '',
    //   supplierId: factory.supplierId || '',
    //   factoryStatus: factory.factoryStatus
    // }));
  }
);
export const selectLicenseExpForFactory = createSelector(
  [selectFactory],
  (factory) => {
    const metadata = factory.factoryData ? factory.factoryData.metadata : {};
    return {
      wcc: metadata?.wcc || null,
      businessLicenseInfo: metadata?.businessLicenseInfo || null,
    };
  }
);

export const selectTierCounts = createSelector([selectFactory], (factory) => {
  const factories = factory?.factories || [];
  return {
    tier1: factories.filter((f) => f?.metadata?.tier === "1").length,
    tier2: factories.filter((f) => f?.metadata?.tier === "2").length,
    total: factories.length,
  };
});
