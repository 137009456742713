import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListSubheader,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedCAPManagementIssueId } from "../../../reducers/audit.reducer";
import {
  selectAuditIssueCAPDataGroupedByType,
  selectSelectedCAPManagementIssueId,
} from "../../../selectors/audit.selector";

const CapManagementDetailsLeftSideNavigationBar = () => {
  const dispatch = useDispatch();
  const issuesDataGroupedByType = useSelector(
    selectAuditIssueCAPDataGroupedByType
  );
  const selectedIssueId = useSelector(selectSelectedCAPManagementIssueId);

  const handleIssueSelect = (issueId) => {
    dispatch(setSelectedCAPManagementIssueId(issueId));
  };

  return (
    <Box sx={{ p: 2 }}>
      <List>
        {Object.entries(issuesDataGroupedByType).map(([type, issues]) => (
          <Box key={type} sx={{ mb: 3 }}>
            <ListSubheader
              sx={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 500,
                background: "transparent",
                p: 0,
                mb: 1,
              }}
            >
              {type}
            </ListSubheader>
            {issues.map((issue) => (
              <ListItem key={issue.id}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color:
                          selectedIssueId === issue.id
                            ? "#303F9F"
                            : "rgba(0, 0, 0, 0.70)",
                        ":hover": {
                          fontSize: "14.1px",
                        },
                        fontSize: "14px",
                        fontWeight: 500,
                        letterSpacing: "0.4px",
                      }}
                      onClick={() => handleIssueSelect(issue.id)}
                    >
                      {issue.issue}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default CapManagementDetailsLeftSideNavigationBar;
