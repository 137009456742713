import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  Chip,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateSupplierData } from "../../../reducers/supplier.reducer";
import { ComponentStyles, FacilityStyles } from "../../../styles";
import { useTenant } from "../../../context/TenantContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getListOfAllSuppliers } from "../../../reducers/supplier.reducer";
import { countries } from "../../../utils/countryList";
import {
  selectSupplierList,
  selectSupplierNameFromId,
} from "../../../selectors/supplier.selector";
import EditModalSwitchCases from "./editModalSwitchCases.component";
// import { updateSupplier } from '../../actions/supplierActions'; // Ensure you have this action

const SupplierInformationEditModal = ({
  open = false,
  onClose,
  supplierData,
}) => {
  const { supplierConfig } = useTenant();
  const dispatch = useDispatch();
  const getDefaultDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? new Date().toISOString().split("T")[0]
      : date.toISOString().split("T")[0];
  };
  const initData = {
    supplierId: getValidData(supplierData?.metadata?.uniqueSupplierId),
    streetAddress: getValidData(supplierData?.metadata?.streetAddress),
    city: getValidData(supplierData?.metadata?.city),
    stateProvince: getValidData(supplierData?.metadata?.stateProvince),
    country: getValidData(supplierData?.metadata?.country),
    zipCode: getValidData(supplierData?.metadata?.zipCode),
    facilityCount: getValidData(supplierData?.metadata?.facilityCount),
    contactPersonName: getValidData(
      supplierData?.metadata?.contactPerson?.name
    ),
    contactPersonPosition: getValidData(
      supplierData?.metadata?.contactPerson?.position
    ),
    contactPersonPhone: getValidData(
      supplierData?.metadata?.contactPerson?.phone
    ),
    contactPersonEmail: getValidData(
      supplierData?.metadata?.contactPerson?.email
    ),
    additionalContacts: Array.isArray(
      supplierData?.metadata?.additionalContacts
    )
      ? supplierData?.metadata?.additionalContacts
      : [],
    productCategories: getValidData(supplierData?.metadata?.productCategories),
    topProduct: getValidData(supplierData?.metadata?.topProduct),
    spend: getValidData(supplierData?.metadata?.spend),
    percentageOfBusinessFrom4imprint: getValidData(
      supplierData?.metadata?.percentageOfBusinessFrom4imprint
    ),
    associatedSupplierId: getValidData(
      supplierData?.metadata?.associatedSupplierId
    ),
    alias: getValidData(supplierData?.metadata?.alias),
    productSubcategories: getValidData(
      supplierData?.metadata?.productSubcategories
    ),
    productProduced: getValidData(supplierData?.metadata?.productProduced),
    privateLabel: getValidData(supplierData?.metadata?.privateLabel),
    notes: getValidData(supplierData?.metadata?.note, []),
    name: getValidData(supplierData?.name),
    alias: getValidData(supplierData?.metadata?.alias),
    contactNote: getValidData(
      supplierData?.metadata?.contactPerson?.contactNote
    ),
    status:
      getValidData(supplierData?.metadata?.status) ||
      getValidData(supplierData?.metadata?.vendorStatus) ||
      "Approved",
  };
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, initData?.supplierId)
  );
  const suppliers = useSelector(selectSupplierList);
  useEffect(() => {
    if (!suppliers || suppliers.length === 0) {
      dispatch(
        getListOfAllSuppliers({
          limit: 999,
          offset: 0,
        })
      );
    }
  }, [dispatch]);

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [supplierData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateSupplierData({
          supplierId: supplierData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = [...formData.additionalContacts];
    newContacts[index] = {
      ...newContacts[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  const addContact = () => {
    const newContacts = Array.isArray(formData.additionalContacts)
      ? formData.additionalContacts
      : [];
    setFormData({
      ...formData,
      additionalContacts: [
        ...newContacts,
        {
          contactPersonName: "",
          contactPersonPosition: "",
          contactPersonPhone: "",
          contactPersonEmail: "",
        },
      ],
    });
  };

  const removeContact = (index) => {
    const newContacts = formData.additionalContacts.filter(
      (_, i) => i !== index
    );
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={FacilityStyles.editModalBox}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {supplierConfig?.editMode?.generalInformation?.display && (
              <Grid
                item
                xs={12}
                md={supplierConfig?.editMode?.generalInformation?.width || 7}
              >
                <Card sx={FacilityStyles.editModalCard}>
                  <>
                    {" "}
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: 700,
                            fontSize: 16,
                            color: "#344054",
                            display: "inline-block",
                          }}
                        >
                          {supplierConfig?.editMode?.generalInformation
                            ?.sectionTitle ||
                            "Edit general supplier information"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      {Object.entries(
                        supplierConfig?.editMode?.generalInformation?.fields
                      ).map(([field, value]) => (
                        <EditModalSwitchCases
                          field={field}
                          value={value}
                          formData={formData}
                          setFormData={setFormData}
                          addContact={addContact}
                          handleChange={handleChange}
                          initData={initData}
                          suppliers={suppliers}
                          countries={countries}
                          supplierName={supplierName}
                        />
                      ))}
                    </Grid>
                  </>

                  {supplierConfig?.editMode?.addNewContacts?.display && (
                    <>
                      <Divider sx={{ mt: 3, mb: 3 }} />
                      {formData?.additionalContacts &&
                        formData?.additionalContacts?.map((contact, index) => (
                          <EditModalSwitchCases
                            field={null}
                            value={null}
                            formData={formData}
                            setFormData={setFormData}
                            addContact={addContact}
                            handleChange={handleChange}
                            initData={initData}
                            suppliers={suppliers}
                            countries={countries}
                            supplierName={supplierName}
                            additionalContact={true}
                            contact={contact}
                            index={index}
                            handleContactChange={handleContactChange}
                          />
                        ))}
                      <Grid item xs={12}>
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={addContact}
                          sx={{
                            color: "#585aeb",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "rgba(88, 90, 235, 0.04)",
                            },
                          }}
                        >
                          Add Another Contact
                        </Button>
                      </Grid>
                    </>
                  )}

                  {supplierConfig?.editMode?.operationInformation?.display && (
                    <>
                      {" "}
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#344054",
                              display: "inline-block",
                            }}
                          >
                            Operational information
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        {Object.entries(
                          supplierConfig?.editMode?.operationInformation?.fields
                        ).map(([field, value]) => (
                          <EditModalSwitchCases
                            field={field}
                            value={value}
                            formData={formData}
                            setFormData={setFormData}
                            addContact={addContact}
                            handleChange={handleChange}
                            initData={initData}
                            suppliers={suppliers}
                            countries={countries}
                            supplierName={supplierName}
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                </Card>
              </Grid>
            )}

            {supplierConfig?.editMode?.confidentialInformation?.display && (
              <Grid
                item
                xs={12}
                md={
                  supplierConfig?.editMode?.confidentialInformation?.width || 5
                }
              >
                <Card sx={FacilityStyles.editModalCard}>
                  <>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: 700,
                            fontSize: 16,
                            color: "#344054",
                            display: "inline-block",
                          }}
                        >
                          Edit confidential business information
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      {Object.entries(
                        supplierConfig?.editMode?.confidentialInformation
                          ?.fields
                      ).map(([field, value]) => (
                        <EditModalSwitchCases
                          field={field}
                          value={value}
                          formData={formData}
                          setFormData={setFormData}
                          addContact={addContact}
                          handleChange={handleChange}
                          initData={initData}
                          suppliers={suppliers}
                          countries={countries}
                          supplierName={supplierName}
                        />
                      ))}
                    </Grid>
                  </>

                  {supplierConfig?.editMode?.additionalInformation?.display && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#344054",
                              display: "inline-block",
                            }}
                          >
                            Edit additional information
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        {Object.entries(
                          supplierConfig?.editMode?.additionalInformation
                            ?.fields
                        ).map(([field, value]) => {
                          if (value.isDropdown) {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  select
                                  fullWidth
                                  label={value.fieldName}
                                  name={field}
                                  variant="outlined"
                                  value={formData[field]}
                                  onChange={handleChange}
                                  margin="normal"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                >
                                  {value?.options?.map((option) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                            );
                          } else if (value.isDisabled) {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label="Tier"
                                  name="tier"
                                  variant="outlined"
                                  value={formData.tier}
                                  onChange={handleChange}
                                  margin="normal"
                                  disabled
                                  SelectProps={{
                                    native: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          } else if (value.identifier === "spend") {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label="Spend"
                                  name="spend"
                                  variant="outlined"
                                  value={formData.spend || 0}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setFormData({
                                      ...formData,
                                      spend: value,
                                    });
                                  }}
                                  margin="normal"
                                  InputProps={{
                                    startAdornment: <span>$</span>,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label={value.fieldName}
                                  name={field}
                                  variant="outlined"
                                  value={formData[field]}
                                  onChange={handleChange}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </>
                  )}
                </Card>
              </Grid>
            )}
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default SupplierInformationEditModal;
