import React from "react";
import { Box, Typography, Grid, Card, Button, Divider } from "@mui/material";

import SupplierFactoryRemediationStatus from "./supplierFactoryRemediationStatus.component";
import { selectSupplierData } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { SupplierStyles } from "../../styles";
import { useTenant } from "../../context/TenantContext";
import SupplierSupplyChainFactoryCountTieredBreakdown from "../../tenants/stitchfix/supplierSupplyChainFactoryTieredBreakdown.component";
const SupplierSupplyChainOverview = () => {
  const { supplierConfig, nomenclature } = useTenant();
  const supplierData = useSelector(selectSupplierData);

  return (
    <Box>
      <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 3, mt: 4 }}>
        {nomenclature.factory.fieldNameSingular} Count
      </Typography>
      {supplierConfig?.tabsSection?.supplyChainOverview?.factoryCount && (
        <Card sx={SupplierStyles.supplyChainOverviewCard}>
          <Grid container sx={{ mb: "12px" }}>
            <Grid item xs={4}>
              <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
                Tier 1
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
                Tier 2
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ mt: "12px" }}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "12px",
                  background: "#2D31A6",
                  color: "#fff",
                  fontSize: "36px",
                  fontWeight: 700,
                  padding: "0px 35px",
                }}
              >
                {supplierData?.factoryData?.totalFactories || "-"}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={SupplierStyles.supplyChainOverviewValue}>
                {supplierData?.factoryData?.tier1 || "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={SupplierStyles.supplyChainOverviewValue}>
                {supplierData?.factoryData?.tier2 || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
      {supplierConfig?.tabsSection?.supplyChainOverview
        ?.factoryCountTieredBreakdown && (
        <SupplierSupplyChainFactoryCountTieredBreakdown />
      )}
      {supplierConfig?.tabsSection?.supplyChainOverview
        ?.remediationStatuses && <SupplierFactoryRemediationStatus />}
    </Box>
  );
};

export default SupplierSupplyChainOverview;
