import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";

const initialState = {
  suppliers: [],
  searchResults: [],
  limit: 99, // Defaulting to the higher limit
  offset: 0,
  count: null,
  searchCount: null,
  supplierCache: {},
  supplierPageTab: "supplyChainOverview",
  supplierData: null,
  ui: null,
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    getListOfAllSuppliers: (state) => {},
    getSearchResultOfSuppliers: (state) => {},
    getSupplierById: (state) => {},
    createSupplier: (state, action) => {},

    setListOfAllSuppliers: (state, action) => {
      state.suppliers = action.payload.suppliers
        ? [...state.suppliers, ...action.payload.suppliers]
        : [...state.suppliers];
      state.searchResults = action.payload.searchResults || state.searchResults;
      state.count = action.payload.supplierCount || state.count;
      state.searchCount = action.payload.searchCount || state.searchCount;
      state.ui = action.payload.uiConfig;
    },

    setSuppliersDataCache: (state, action) => {
      state.supplierCache[action.payload.page] = action.payload.results;
    },

    updateSupplierData: (state, action) => {},
    clearSuppliers: (state) => {
      state.suppliers = [];
      state.count = null;
    },

    setSupplierPageTab: (state, action) => {
      state.supplierPageTab = action.payload;
    },

    setSupplierData: (state, action) => {
      state.supplierData = action.payload;
    },

    createNewSupplierFromSAQ: (state, action) => {},
    fetchSAQSuppliers: (state) => {},
    setSAQSuppliers: (state, action) => {
      state.saqSuppliers = action.payload;
    },
    deleteSAQSupplier: (state) => {},
    uploadSupplierFile: (state, action) => {},
    setUploadSupplierFiles: (state, action) => {
      const { fieldKey, files } = action.payload;
      if (state.supplierData) {
        // Ensure the field exists and is an array
        const currentFiles = state.supplierData[fieldKey] || [];
        state.supplierData[fieldKey] = Array.isArray(currentFiles)
          ? [...currentFiles, ...files]
          : files;
      }
    },
    updateUploadProgress: (state, action) => {
      const { fileId, progress } = action.payload;
      state.uploadProgress[fileId] = progress;
    },
    updateUploadStatus: (state, action) => {
      const { fileId, status } = action.payload;
      state.uploadStatus[fileId] = status;
    },
    setSupplierCreationResult: (state, action) => {
      const { success, data, error } = action.payload;
      state.loading = false;

      if (success) {
        // Add the new factory to factories list
        //state.factories = [...state.factories, data.newFactory];
        state.suppliers = state.suppliers ? [...state.suppliers, data] : [data];
        // Handle saqSuppliers if needed and if saqId is provided
        if (
          state.saqSuppliers &&
          Array.isArray(state.saqSuppliers) &&
          action.payload.saqId
        ) {
          state.saqSuppliers = state.saqSuppliers.map((saq) =>
            saq._id === action.payload.saqId
              ? {
                  ...saq,
                  assignedToSupplier: data._id,
                  metadata: {
                    ...saq.metadata,
                    supplierName: data.metadata?.supplierName,
                  },
                }
              : saq
          );
        }
      } else {
        state.error = error;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  setListOfAllSuppliers,
  setSuppliersDataCache,
  clearSuppliers,
  createSupplier,
  setSupplierPageTab,
  getSupplierById,
  setSupplierData,
  updateSupplierData,
  createNewSupplierFromSAQ,
  fetchSAQSuppliers,
  setSAQSuppliers,
  deleteSAQSupplier,
  uploadSupplierFile,
  setUploadSupplierFiles,
  updateUploadProgress,
  updateUploadStatus,
  setSupplierCreationResult,
} = supplierSlice.actions;

export default supplierSlice.reducer;
