import React from "react";
import { Routes, Route } from "react-router-dom";

import SupplierPage from "./supplier.page";

import Risks from "./risk.page";
import Insights from "./insight.page";
import SupplierDetails from "./supplierDetails.page";
import FacilityDetails from "./factoryDetails.page";
import AuditDetails from "./auditDetails.page";
import Factories from "./factory.page";
import CapManagement from "./capManagement.page";
import DueAction from "./dueAction.page";
import ResourcesPage from "./resource.page";
import Questionnaire from "./questionnaire.page";
import InactiveFactories from "./inactiveFactory.page";
import PreSourcingForm from "./preSourcingForm.page";
const IndexPage = () => {
  return (
    <Routes>
      <Route path="/inactive-factories" element={<InactiveFactories />} />
      <Route path="/upstreamfactories" element={<Factories />} />
      <Route path="/factories" element={<Factories />} />
      <Route path="/suppliers" element={<SupplierPage />} />
      <Route path="/resources" element={<ResourcesPage />} />
      <Route path="/suppliers/:supplierId" element={<SupplierDetails />} />
      <Route
        path="/suppliers/:supplierId/factory/:factoryId"
        element={<FacilityDetails />}
      />
      <Route
        path="/suppliers/:supplierId/audit/:auditId"
        element={<AuditDetails />}
      />
      <Route
        path="/suppliers/:supplierId/factory/:factoryId/audit/:auditId"
        element={<AuditDetails />}
      />
      <Route path="/risk" element={<Risks />} />
      <Route path="/dueAction" element={<DueAction />} />
      <Route path="/insights" element={<Insights />} />
      <Route path="/capmanagement" element={<CapManagement />} />
      <Route path="/questionnaires" element={<Questionnaire />} />
      <Route path="/presourcing" element={<PreSourcingForm />} />

      {/* <Route
        path="/capmanagement/audit/:auditId"
        element={<AuditDetails />}
      /> */}
    </Routes>
  );
};

export default IndexPage;
