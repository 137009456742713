import React, { useMemo } from "react";
import { Typography, Grid } from "@mui/material";
import { FacilityStyles } from "../../styles";
import FactoryRiskCard from "../../components/FacilityDetails/factoryRiskCard.component";

const FactoryRiskCardGore = ({ selectedFactory }) => {
  // Define the multipliers
  const multipliers = {
    Extreme: 1.5,
    Critical: 0.9,
    Major: 0.15,
    Moderate: 0.06,
    Observation: 0.03,
  };
  const { enhancedRisk, enhancedRiskScore } = useMemo(() => {
    const categories = [
      {
        label: "Extreme",
        count: parseInt(selectedFactory?.issueDetails?.Extreme || "0", 10) || 0,
      },
      {
        label: "Critical",
        count:
          parseInt(selectedFactory?.issueDetails?.Critical || "0", 10) || 0,
      },
      {
        label: "Major",
        count: parseInt(selectedFactory?.issueDetails?.Major || "0", 10) || 0,
      },
      {
        label: "Moderate",
        count:
          parseInt(selectedFactory?.issueDetails?.Moderate || "0", 10) || 0,
      },
      {
        label: "Observation",
        count:
          parseInt(selectedFactory?.issueDetails?.Observation || "0", 10) || 0,
      },
    ];

    // Calculate the enhancedRiskScore
    const enhancedRiskScore = categories.reduce((acc, category) => {
      return acc + category.count * (multipliers[category.label] || 0);
    }, 0);

    const enhancedRisk = categories.map((category) => ({
      label: category.label,
      subLabel: category.count.toString(),
      value: (category.count * (multipliers[category.label] || 0)).toFixed(2),
    }));

    return { enhancedRisk, enhancedRiskScore };
  }, [selectedFactory]);

  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Risk Score
      </Typography>
      <Grid container spacing={4} style={{ marginTop: -16 }}>
        <Grid item xs={6}>
          <FactoryRiskCard
            key={"inherentRisk"}
            title={"Abstract Risk"}
            riskScore={
              selectedFactory?.metadata?.inherentRisk?.totalScore === ""
                ? "No Data"
                : selectedFactory?.metadata?.inherentRisk?.totalScore ??
                  "No Data"
            }
            details={[
              {
                label: "Country",
                subLabel:
                  selectedFactory?.metadata?.inherentRisk?.country || "-",
                value:
                  selectedFactory?.metadata?.inherentRisk?.countryScore ?? "-",
              },
              {
                label: "Sourcing TML",
                subLabel:
                  selectedFactory?.metadata?.inherentRisk?.sourcingTml || "-",
                value:
                  selectedFactory?.metadata?.inherentRisk?.sourcingTmlScore ??
                  "-",
              },
              {
                label: "Audit Type",
                subLabel:
                  selectedFactory?.metadata?.inherentRisk?.auditType || "-",
                value:
                  selectedFactory?.metadata?.inherentRisk?.auditTypeScore ??
                  "-",
              },
              {
                label: "Frequency of Monitoring",
                subLabel:
                  selectedFactory?.metadata?.inherentRisk?.frequency || "-",
                value:
                  selectedFactory?.metadata?.inherentRisk?.frequencyScore ??
                  "-",
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <FactoryRiskCard
            key={"enhancedRisk"}
            title={"Enhanced Risk"}
            riskScore={
              selectedFactory?.latestAudit?.risk?.auditScore ??
              enhancedRiskScore ??
              "Coming Soon"
            }
            details={enhancedRisk}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryRiskCardGore;
