import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";
const initialState = {
  insightPageTab: "dataAnalytics",
};

const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setInsightPageTab: (state, action) => {
      state.insightPageTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const { setInsightPageTab } = insightsSlice.actions;

export default insightsSlice.reducer;
