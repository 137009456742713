import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityTierMap from "./factoryTierMap.component";
import { useTenant } from "../../context/TenantContext";
import FactoryTierMap from "./factoryTierMap.component";
import FactoryAssociatedSupplierLinks from "../../tenants/stitchfix/factoryAssociatedSupplierLinks.component";
import { capitalizeWords } from "../../utils/lib";

const FactoryInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const { factoryConfig } = useTenant();
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  const fieldToConfigMapping = {
    alias: factoryData?.metadata?.alias || "-",
    associatedAddresses: factoryData?.metadata?.associatedAddress || "-",
    factoryAddress: capitalizeWords(factoryData?.metadata?.address) || "-",
    supplierNames: factoryData,
    region: factoryData?.metadata?.region || "-",
    city: factoryData?.metadata?.city || "-",
    country: factoryData?.metadata?.country || "-",
    factoryId: factoryData?.metadata?.factoryId || "-",
    tier: factoryData?.metadata?.tier || "-",
    productCategories: factoryData?.metadata?.productCategories || "-",
    workerCount: factoryData?.metadata?.workerCount || "-",
    status: factoryData?.metadata?.status || "-",
    agent: factoryData?.metadata?.agent || "-",
    highStrikeRisk: factoryData?.metadata?.highStrikeRisk || "-",
    nextReportDate: factoryData?.metadata?.nextReportDate || "-",
    auditType: factoryData?.metadata?.auditType || "-",
    factoryApprovalDate: factoryData?.metadata?.factoryApprovalDate || "-",
    zipCode: factoryData?.metadata?.zipCode || "-",
    contactPerson: factoryData?.metadata?.contactPerson?.name || "-",
    contactPersonPosition:
      factoryData?.metadata?.contactPerson?.position || "-",
    contactPersonPhone: factoryData?.metadata?.contactPerson?.phone || "-",
    contactPersonEmail: factoryData?.metadata?.contactPerson?.email || "-",
    accountManager: factoryData?.metadata?.productSupport?.name || "-",
    accountManagerEmail: factoryData?.metadata?.productSupport?.email || "-",
    businessUnit: factoryData?.businessUnit || "-",
    tmls: factoryData?.metadata?.tmls || "-",
    influence: factoryData?.metadata?.influence || "-",
    sharedVbu: factoryData?.metadata?.sharedVbu || "-",
    agreementType: factoryData?.metadata?.agreementType || "-",
    cmaConnection: factoryData?.metadata?.cmaConnection || "-",
  };
  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 600, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            {factoryConfig?.generalInformation?.components?.moreInfo?.label ||
              "More Information"}
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {factoryConfig?.generalInformation?.components?.moreInfo?.fields &&
              Object.entries(
                factoryConfig?.generalInformation?.components?.moreInfo?.fields
              ).map(([key, field]) => {
                if (key === "divider") {
                  return <Divider sx={{ my: 4, width: "97%", ml: 2 }} />;
                } else if (key === "additionalContacts") {
                  return (
                    <Grid item xs={field.width}>
                      {" "}
                      {Array.isArray(
                        factoryData?.metadata?.additionalContacts
                      ) &&
                        factoryData?.metadata?.additionalContacts
                          .filter(
                            (contact) => !contact.type || contact.type !== "CAP"
                          )
                          .map((contact, index) => (
                            <>
                              <Box sx={{ mb: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 600 }}
                                >
                                  Contact Person {index + 1}
                                </Typography>
                                <Grid
                                  container
                                  spacing={0}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={6} sx={{ pr: 3 }}>
                                    <Typography sx={ComponentStyles.fieldName}>
                                      Name
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...ComponentStyles.fieldValue,
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {contact?.contactPersonName || "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sx={{ pr: 3 }}>
                                    <Typography sx={ComponentStyles.fieldName}>
                                      Position
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...ComponentStyles.fieldValue,
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {contact?.contactPersonPosition || "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sx={{ pr: 3 }}>
                                    <Typography sx={ComponentStyles.fieldName}>
                                      Phone
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...ComponentStyles.fieldValue,
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {contact?.contactPersonPhone || "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sx={{ pr: 3 }}>
                                    <Typography sx={ComponentStyles.fieldName}>
                                      Email
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...ComponentStyles.fieldValue,
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {contact?.contactPersonEmail || "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Divider sx={{ my: 4, width: "97%", ml: 2 }} />
                            </>
                          ))}
                      {Array.isArray(
                        factoryData?.metadata?.additionalContacts
                      ) &&
                        factoryData?.metadata?.additionalContacts.some(
                          (contact) => contact.type === "CAP"
                        ) && (
                          <>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600 }}
                            >
                              CAP Contact
                            </Typography>
                            {factoryData?.metadata?.additionalContacts
                              .filter((contact) => contact.type === "CAP")
                              .map((contact, index) => (
                                <>
                                  <Box sx={{ mb: 2 }}>
                                    <Grid
                                      container
                                      spacing={0}
                                      justifyContent="space-between"
                                    >
                                      <Grid item xs={6} sx={{ pr: 3 }}>
                                        <Typography
                                          sx={ComponentStyles.fieldName}
                                        >
                                          Email
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...ComponentStyles.fieldValue,
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {contact?.contactPersonEmail || "-"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Divider
                                    sx={{ my: 4, width: "97%", ml: 2 }}
                                  />
                                </>
                              ))}
                          </>
                        )}
                    </Grid>
                  );
                } else if (key === "supplierNames") {
                  return (
                    <Grid item xs={field.width}>
                      <Typography sx={ComponentStyles.fieldName}>
                        {field.fieldName}
                      </Typography>
                      <Typography
                        sx={{
                          ...ComponentStyles.fieldValue,
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        <FactoryAssociatedSupplierLinks
                          factoryData={fieldToConfigMapping[key]}
                        />
                      </Typography>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={field.width}>
                      <Typography sx={ComponentStyles.fieldName}>
                        {field.fieldName}
                      </Typography>
                      <Typography
                        sx={{
                          ...ComponentStyles.fieldValue,
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {fieldToConfigMapping[key] || "-"}
                      </Typography>
                    </Grid>
                  );
                }
              })}
          </Grid>
        </Box>
        {factoryConfig?.generalInformation?.components?.moreInfo?.components
          ?.tierMap?.display && (
          <>
            <Typography
              variant="h6"
              sx={{ mb: 3, textDecoration: "underline" }}
            >
              {
                factoryConfig?.generalInformation?.components?.moreInfo
                  ?.components?.tierMap?.fieldName
              }
            </Typography>
            <FactoryTierMap
              supplierId={factoryData.supplierId}
              currentFactoryId={params.factoryId}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default FactoryInformationDrawer;
