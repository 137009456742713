import { Box, Typography, Tooltip } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const ClientCodeMatchAndPotentialMatch = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          p: 2,
          border: "1px solid #E0E7FF",
          borderRadius: 1,
          bgcolor: "#F5F8FF",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            sx={{
              color: "#1F2937",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            {data.clientCode}
          </Typography>
          <Tooltip title="AI-selected match">
            <AutoAwesomeIcon sx={{ fontSize: 16, color: "#1976d2" }} />
          </Tooltip>
        </Box>
      </Box>

      {data.matches?.filter((match) => match !== data.clientCode)?.length >
        0 && (
        <Box>
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: "14px",
              fontWeight: 500,
              mb: 1.5,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            Other potential matches
          </Typography>
          <Box sx={{ pl: 2 }}>
            {data.matches
              .filter((match) => match !== data.clientCode)
              .map((match, index) => (
                <Typography
                  key={index}
                  sx={{
                    color: "#6B7280",
                    fontSize: "14px",
                    mb: 1,
                  }}
                >
                  {match}
                </Typography>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ClientCodeMatchAndPotentialMatch;
