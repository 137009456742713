const BASE_URL =
  process.env.BASE_URL || "https://s-auditsense-et6rmlvh6q-uc.a.run.app";
const LOCAL = `${BASE_URL}/v1`;
// const LOCAL = `http://localhost:3000/v1`;

export const USER = {
  LOGIN: `${LOCAL}/users/login`,
  GET_USERS_BY_ORGANISATION_ID: `${LOCAL}/users/organisation/<ORGANISATION_ID>`,
};

export const EMAIL = {
  SEND_TO_MENTIONED: `${LOCAL}/emails/sendEmails/mentionedUsers`,
};

export const SUPPLIERS = {
  LIST: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>`,
  SEARCH: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/search`,
  GET_BY_ID: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  GET_LIST_SAQ_BY_ORGANISATION_ID: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/saq`,
  UPDATE_BY_ID: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  CREATE_SUPPLIER: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers`,
  CREATE_NEW_FROM_SAQ: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/create-new`,
  DELETE_SAQ_SUPPLIER: `${LOCAL}/suppliers/organisations/:organisationId/deleteSupplier/:supplierId`,
  UPLOAD_FILE: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/upload`,
};

export const FACTORIES = {
  LIST: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  SEARCH: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/search`,
  GET_LIST_BY_ORGANISATION_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>`,
  GET_LIST_SAQ_BY_ORGANISATION_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/saq`,
  GET_BY_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>`,
  UPDATE_BY_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>`,
  UPLOAD_FILE: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/upload`,
  CREATE_FACTORY: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factory`,
  GET_SIGNED_URL: `${LOCAL}/factories/files/signed-url`,
  CREATE_NEW_FROM_SAQ: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/create-new`,
  GET_DUE_ACTION_CERTIFICATES_DATA: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/dueAction/certificates`,
  EXPORT_FACILITY_DATA: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/export`,
  DELETE_SAQ_FACILITY: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/saqFactories/<FACTORY_ID>`,
  DELETE_FILE: `${LOCAL}/factories/<ORGANISATION_ID>/supplier/<SUPPLIER_ID>/factory/<FACTORY_ID>/file`,
};

export const AUDITS = {
  LIST: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  GET_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>`,
  UPDATE_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>`,
  UPLOAD_REPORT: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/upload`,
  PROCESS_REPORT: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/process`,
  REGENERATE_CAP: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/regenerateCap`,
  DELETE_CAP_DETAILS_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/capdetails/<FINDING_ID>`,
  UPDATE_AUDIT_STATUS: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/updateAuditStatus`,
  GET_LIST_OF_AUDITS_IN_CAP_MANAGEMENT: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/capmanagement`,
  GET_RESOURCES: `${LOCAL}/audits/resources`,
  CREATE_CAP_DETAIL: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/capDetails`,
};
export const CAP_MANAGEMENT = {
  // UPDATE: `${LOCAL}/capmanagements/audits/<AUDIT_ID>/issues/<ISSUE_ID>/brandAction`,
  UPDATE: `${LOCAL}/capmanagements/audits/<AUDIT_ID>/issues`,
  SET_CI_DUE_DATE: `${LOCAL}/capmanagements/audits/<AUDIT_ID>/issues/<ISSUE_ID>/ciDueDate`,
  RESET_CAP_MANAGEMENT_DATA: `${LOCAL}/capmanagements/audits/<AUDIT_ID>/reset`,
  UPLOAD_PROOF: `${LOCAL}/capmanagements/audits/<AUDIT_ID>/issues/<ISSUE_ID>/upload`,
};
export const ORGANISATIONS = {
  GET_RISK_CHART_DATA: `${LOCAL}/organisations/<ORGANISATION_ID>/chart`,
  GET_DUE_CAPS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueCaps`,
  GET_DUE_AUDITS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueAudits`,
  GET_DUE_AUDITS_REFORMATION: `${LOCAL}/organisations/<ORGANISATION_ID>/dueAuditsRef`,
  GET_RESOURCES: `${LOCAL}/organisations/<ORGANISATION_ID>/resources`,
  PIN_RESOURCE: `${LOCAL}/organisations/<ORGANISATION_ID>/resources/<RESOURCE_ID>/pin`,
  GET_NOTIFICATIONS: `${LOCAL}/organisations/<ORGANISATION_ID>/notifications`,
  GET_DUE_IRSS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueIrssRef`,
  GET_DUE_CI_AUDITS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueCapsInCI`,
};

export const ANALYTICS = {
  GET_URL: `${LOCAL}/analytics/organisations/<ORGANISATION_ID>/`,
};

export const NOTIFICATIONS = {
  GET_NOTES: `${LOCAL}/notifications/organisations/<ORGANISATION_ID>/notes`,
  MARK_NOTE_AS_READ: `${LOCAL}/notifications/organisations/<ORGANISATION_ID>/notes/<NOTE_ID>/isRead/<IS_READ>`,
  GET_NOTIFICATIONS: `${LOCAL}/notifications/organisations/<ORGANISATION_ID>`,
  MARK_NOTIFICATION_AS_READ: `${LOCAL}/notifications/<NOTIFICATION_ID>/organisations/<ORGANISATION_ID>/isRead/<IS_READ>`,
};

export const QUESTIONNAIRES = {
  GET_QUESTIONNAIRES: `${LOCAL}/questionnaires/getquestionnaires/<ORGANISATION_ID>`,
  LINK_RESPONSE_TO_FACTORY: `${LOCAL}/questionnaires/organisations/<ORGANISATION_ID>/responses/<RESPONSE_ID>/linkToFactory`,
  GET_FILE_SIGNED_URL: `${LOCAL}/questionnaires/files/signed-url?key=<FILE_PATH>`,
};
