import {
  all,
  put,
  post,
  call,
  take,
  select,
  takeLatest,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
  setListOfAllSuppliers,
  setSupplierData,
  setSuppliersDataCache,
  updateSupplierData,
  createSupplier,
  createNewSupplierFromSAQ,
  fetchSAQSuppliers,
  deleteSAQSupplier,
  uploadSupplierFile,
  setSupplierCreationResult,
  setUploadSupplierFiles,
  setSAQSuppliers,
} from "../reducers/supplier.reducer";
import _ from "lodash";
import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";

import * as API from "../utils/api";
import axios from "axios";
import { logout } from "../reducers/login.reducer";
import { loading, setFilesUploadedIds } from "../reducers/misc.reducer";

function* getListOfSuppliersWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { page, search } = action.payload;

  const requestURL = search
    ? API.SUPPLIERS.SEARCH.replace("<ORGANISATION_ID>", organisationId)
    : API.SUPPLIERS.LIST.replace("<ORGANISATION_ID>", organisationId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = search ? { search } : { limit: 999, offset: 0 };

  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        if (!search) {
          yield put(
            setListOfAllSuppliers({
              suppliers: response.data.results.suppliers,
              supplierCount: response.data.results.supplierCount,
              uiConfig: response.data.results.uiConfig,
            })
          );
        } else {
          yield put(
            setListOfAllSuppliers({
              searchResults: response.data.results.suppliers,
              searchCount: response.data.results.supplierCount,
            })
          );
        }

        yield put(
          setSuppliersDataCache({
            page: search ? search : page,
            results: response.data.results.suppliers,
          })
        );
      }
    } else if (response && response.status === 401) {
      yield put(logout());
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getSupplierByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const requestURL = API.SUPPLIERS.GET_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", action.payload);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setSupplierData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateSupplierDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const requestURL = API.SUPPLIERS.UPDATE_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", action.payload.supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ...action.payload.changes,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setSupplierData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* createSupplierWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.SUPPLIERS.CREATE_SUPPLIER.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  // Helper to build nested object structure
  const buildNestedStructure = (obj) => {
    const result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (key.includes(".")) {
        const parts = key.split(".");
        let current = result;

        parts.forEach((part, index) => {
          if (index === parts.length - 1) {
            current[part] = value;
          } else {
            current[part] = current[part] || {};
            current = current[part];
          }
        });
      } else {
        result[key] = value;
      }
    });

    return result;
  };

  // Build nested structure and extract metadata
  const nestedData = buildNestedStructure(action.payload);
  const { name, organisationId: orgId, ...metadataFields } = nestedData;

  const supplierObj = {
    name: name,
    organisationId: organisationId,
    metadata: metadataFields,
  };

  try {
    const response = yield axios.post(requestURL, supplierObj, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setSupplierData(response.data.results));
        yield put(
          getListOfAllSuppliers({
            limit: 999,
            offset: 0,
          })
        );
      }
    }
  } catch (error) {
    console.error("Failed to create supplier:", error);
    if (error?.response?.status === 401) {
      yield put(logout());
    }
  }
}

function* deleteSAQSupplierWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { supplierId } = action.payload;
  const requestURL = API.SUPPLIERS.DELETE_SAQ_SUPPLIER.replace(
    ":organisationId",
    organisationId
  ).replace(":supplierId", supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    yield put(loading(true));
    const response = yield axios.delete(requestURL, { headers });

    if (response?.status === 200 && response.data?.success) {
      // Refresh the SAQ suppliers list
      yield put(fetchSAQSuppliers());
    }
  } catch (error) {
    console.error("Failed to delete SAQ supplier:", error);
    if (error?.response?.status === 401) {
      yield put(logout());
    }
  } finally {
    yield put(loading(false));
  }
}

export function* createNewSupplierFromSAQWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const supplierId = action.payload.supplierId;
  const { supplierName, metadata } = action.payload;

  const requestURL = API.SUPPLIERS.CREATE_NEW_FROM_SAQ.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    supplierName,
    metadata: metadata,
    supplierId: supplierId,
  };

  try {
    yield put(loading(true));
    const response = yield axios.post(requestURL, body, { headers });
    //const response = yield call(axios.post, requestURL, {}, { headers });
    yield put(loading(false));

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.success
    ) {
      // Set the supplier creation result
      yield put(
        setSupplierCreationResult({
          success: true,
          data: response.data.results,
        })
      );
      return response.data.results._id; // Return the new supplier ID
    } else {
      throw new Error("Failed to create new supplier from SAQ");
    }
  } catch (error) {
    console.error("Failed to create new supplier from SAQ:", error);
    yield put(loading(false));
    // Set the error in the creation result
    yield put(
      setSupplierCreationResult({
        success: false,
        error: error.message || "Unknown error",
      })
    );
    if (error.response && error.response.status === 401) {
      yield put(logout());
    }
    throw error;
  }
}

function* fetchSAQSuppliersWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.SUPPLIERS.GET_LIST_SAQ_BY_ORGANISATION_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = { limit: 999, offset: 0 };

  try {
    yield put(loading(true));
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(loading(false));

        yield put(setSAQSuppliers(response.data.results.suppliers));
        console.log(
          "🚀 saqSuppliers from SAGA:",
          response.data.results.suppliers
        );
      }
    }
  } catch (error) {
    console.error("Failed to fetch SAQ suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    yield put(loading(false));
  }
}
function createUploadFileChannel({
  requestURL,
  formData,
  headers,
  params,
  fileId,
}) {
  return eventChannel((emitter) => {
    const source = axios.CancelToken.source();

    axios
      .post(requestURL, formData, {
        headers,
        params,
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          emitter({ progress, fileId });
        },
      })
      .then((response) => {
        emitter({ success: true, response, fileId });
        emitter(END);
      })
      .catch((error) => {
        emitter({ error, fileId });
        emitter(END);
      });

    // Unsubscribe function
    return () => {
      source.cancel();
    };
  });
}

// Add this worker saga for supplier file uploads
function* uploadSupplierFileWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.SUPPLIERS.UPLOAD_FILE.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", action.payload.supplierId);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };

  if (Array.isArray(action.payload.file)) {
    let fileUpload = {};
    let allUploadedFiles = [];

    for (let file of action.payload.file) {
      const formData = new FormData();
      formData.append("file", file.file);

      try {
        const uploadChannel = yield call(createUploadFileChannel, {
          requestURL,
          formData,
          headers,
          params: { fieldKey: action.payload.fieldKey },
          fileId: file.id, // Pass the file ID
        });

        while (true) {
          const { progress, success, response, error, fileId } = yield take(
            uploadChannel
          );

          if (progress !== undefined) {
            // Dispatch progress update
            yield put({
              type: "supplier/updateUploadProgress",
              payload: {
                fileId,
                progress,
              },
            });
          } else if (success) {
            // Dispatch success status
            yield put({
              type: "supplier/updateUploadStatus",
              payload: {
                fileId,
                status: "success",
              },
            });

            fileUpload[fileId] = true;

            // Get uploaded files from response
            const uploadedFiles = _.get(
              response.data.results,
              action.payload.fieldKey
            );
            if (uploadedFiles) {
              allUploadedFiles = allUploadedFiles.concat(uploadedFiles);

              // Update state immediately after each successful upload
              yield put(
                setUploadSupplierFiles({
                  fieldKey: action.payload.fieldKey,
                  files: uploadedFiles,
                })
              );
            }
            break;
          } else if (error) {
            // Dispatch error status
            yield put({
              type: "supplier/updateUploadStatus",
              payload: {
                fileId,
                status: "error",
              },
            });

            fileUpload[fileId] = false;
            console.error("Failed to upload file:", error);
            break;
          }
        }
      } catch (error) {
        fileUpload[file.id] = false;
        console.error("Failed to upload file:", error);
      }
    }

    // Update upload status
    yield put(setFilesUploadedIds(fileUpload));

    // Call success callback if all files uploaded successfully
    if (Object.values(fileUpload).every((status) => status === true)) {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(allUploadedFiles);
      }
    }
  } else {
    const formData = new FormData();
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
        params: { fieldKey: action.payload.fieldKey },
      });

      if (response && response.status === 200 && response.data.success) {
        const uploadedFiles = _.get(
          response.data.results,
          action.payload.fieldKey
        );

        yield put(
          setUploadSupplierFiles({
            fieldKey: action.payload.fieldKey,
            files: uploadedFiles,
          })
        );

        // Call success callback for single file upload
        if (action.payload.onSuccess) {
          action.payload.onSuccess(uploadedFiles);
        }
      }
    } catch (error) {
      console.error("Failed to upload file:", error);
      if (error?.response?.status === 401) {
        yield put(logout());
      }
    }
  }
}

function* watchSupplierSaga() {
  yield takeLatest(
    [getListOfAllSuppliers.type, getSearchResultOfSuppliers.type],
    getListOfSuppliersWorker
  );
}

function* watchSupplierByIdSaga() {
  yield takeLatest(getSupplierById.type, getSupplierByIdWorker);
}
function* watchUpdateSupplierDataSaga() {
  yield takeLatest(updateSupplierData.type, updateSupplierDataWorker);
}
function* watchCreateSupplierSaga() {
  yield takeLatest(createSupplier.type, createSupplierWorker);
}

function* watchCreateNewSupplierFromSAQSaga() {
  yield takeLatest(
    createNewSupplierFromSAQ.type,
    createNewSupplierFromSAQWorker
  );
}

function* watchFetchSAQSuppliersSaga() {
  yield takeLatest(fetchSAQSuppliers.type, fetchSAQSuppliersWorker);
}
function* watchDeleteSAQSupplierSaga() {
  yield takeLatest(deleteSAQSupplier.type, deleteSAQSupplierWorker);
}

function* watchUploadSupplierFileSaga() {
  yield takeLatest(uploadSupplierFile.type, uploadSupplierFileWorker);
}
export default function* rootSaga() {
  yield all([
    watchSupplierSaga(),
    watchSupplierByIdSaga(),
    watchUpdateSupplierDataSaga(),
    watchCreateSupplierSaga(),
    watchCreateNewSupplierFromSAQSaga(),
    watchDeleteSAQSupplierSaga(),
    watchUploadSupplierFileSaga(),
    watchFetchSAQSuppliersSaga(),
  ]);
}
