import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import {
  selectFactoryList,
  selectFactoryListBySupplierId,
  selectTierCounts,
} from "../../selectors/factory.selector";
import moment from "moment";
import ListCard from "../listCard.component";
import { useTenant } from "../../context/TenantContext";
const FacilityComplianceList = () => {
  const { supplierConfig } = useTenant();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [tierFilter, setTierFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const { tier1, tier2, total } = useSelector(selectTierCounts);
  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#B42318";
      case "critical":
        return "#FF692E";
      case "major":
        return "#EAAA08";
      default:
        return "#667085";
    }
  };

  const facilities = useSelector((state) =>
    selectFactoryListBySupplierId(state, params.supplierId)
  );
  const navigateToFacilityDetails = (facilityId) => {
    dispatch(
      getFactoryById({ supplierId: params.supplierId, factoryId: facilityId })
    );
    navigate(`${location.pathname}/factory/${facilityId}`);
  };

  useEffect(() => {
    dispatch(
      getListOfAllFactories({
        limit: 100,
        offset: 0,
        supplierId: params.supplierId,
        supplierFactoryList: true,
      })
    );
  }, []);

  return (
    <Box mt={2}>
      {(supplierConfig?.tabsSection?.factoryList?.extraInformation?.filterByTier
        ?.display ||
        supplierConfig?.tabsSection?.factoryList?.extraInformation
          ?.filterByStatus?.display) && (
        <Box sx={{ mb: 2 }}>
          {supplierConfig?.tabsSection?.factoryList?.extraInformation
            ?.filterByTier?.display && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="tier-filter-label">Filter by Tier</InputLabel>
              <Select
                labelId="tier-filter-label"
                value={tierFilter}
                label="Filter by Tier"
                onChange={(e) => setTierFilter(e.target.value)}
              >
                <MenuItem value="">All ({total})</MenuItem>
                <MenuItem value={"1"}>Tier 1 ({tier1})</MenuItem>
                <MenuItem value={"2"}>Tier 2 ({tier2})</MenuItem>
              </Select>
            </FormControl>
          )}
          {supplierConfig?.tabsSection?.factoryList?.extraInformation
            ?.filterByStatus?.display && (
            <FormControl fullWidth>
              <InputLabel id="status-filter-label">Filter by Status</InputLabel>
              <Select
                labelId="status-filter-label"
                value={statusFilter}
                label="Filter by Status"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
      )}
      {facilities.length > 0 &&
        facilities.map((facility, index) => (
          <Paper
            key={facility.factoryId}
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              overflowY: "hidden", // Disable vertical scrolling
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            onClick={() => {
              navigateToFacilityDetails(facility.factoryId);
            }}
          >
            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
              <Grid container sx={{ pr: 4, alignItems: "center" }}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 18,
                      color: "#000",
                      display: "inline-block",
                      mr: 2,
                    }}
                  >
                    {facility?.name}
                  </Typography>
                </Grid>
                {supplierConfig?.tabsSection?.factoryList?.extraInformation
                  ?.tier?.display && (
                  <Grid>
                    <Box
                      sx={{
                        textTransform: "none",
                        p: "3px 8px",
                        borderRadius: "6px",
                        border: "1px solid var(--Brand-300, #D0D5DD)",
                        background: "#FFF",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        color: "#344054",
                        fontSize: "12px",
                        fontWeight: 600,
                        fontFamily: "Inter, sans-serif",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        navigateToFacilityDetails(facility.factoryId);
                      }}
                    >
                      {facility.tier?.toString().includes("Tier")
                        ? facility.tier
                        : `Tier ${facility.tier}`}
                    </Box>
                  </Grid>
                )}
                {supplierConfig?.tabsSection?.factoryList?.extraInformation
                  ?.privateLabel?.display &&
                  facility.metadata.privateLabel === "Yes" && (
                    <Box
                      sx={{
                        textTransform: "none",
                        p: "3px 8px",
                        ml: 2,
                        borderRadius: "6px",
                        border: "1px solid var(--Brand-300, #D0D5DD)",
                        background: "#FFF",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        color: "#344054",
                        fontSize: "12px",
                        fontWeight: 600,
                        fontFamily: "Inter, sans-serif",
                      }}
                      size="small"
                      variant="outlined"
                    >
                      Private Label
                    </Box>
                  )}
                {supplierConfig?.tabsSection?.factoryList?.extraInformation
                  ?.factoryStatus?.display &&
                  facility.status !== "active" &&
                  facility.status !== "Active" && (
                    <Box
                      sx={{
                        textTransform: "none",
                        p: "3px 8px",
                        ml: 2,
                        borderRadius: "6px",
                        border: "1px solid var(--Brand-300, #D0D5DD)",
                        background: "#EAAA08",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        color: "#FFF",
                        fontSize: "12px",
                        fontWeight: 600,
                        fontFamily: "Inter, sans-serif",
                      }}
                      size="small"
                      variant="outlined"
                    >
                      Site Status: {facility.status}
                    </Box>
                  )}
              </Grid>
            </Box>

            <ListCard data={facility} type="factory" />
          </Paper>
        ))}
    </Box>
  );
};

export default FacilityComplianceList;
