import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectActionNeededData,
  selectFactoryData,
} from "../../selectors/factory.selector";
import moment from "moment";
import { selectCapListForFactory } from "../../selectors/audit.selector";
import TwoLineCard from "../twoLineCard.component";

import FactoryActionNeeded from "./factoryActionNeeded.component";
import FactoryAuditCadenceCalculation from "./factoryAuditCadenceCalculation.component";
import ComprehensiveRiskDashboard from "./comprehensiveRiskDashboard.component";
import FactoryMostRecentAuditDetails from "./factoryMostRecentAuditDetails.component";
import { useTenant } from "../../context/TenantContext";
import FactoryAuditScheduling from "../../tenants/4imprint/factoryAuditScheduling.component";

import FactoryRiskCardGore from "../../tenants/wlgore/factoryRiskCardGore.component";

const FactoryComplianceStatus = () => {
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;
  const selectFactory = useSelector(selectFactoryData);
  const params = useParams();
  const selectedFactory = useSelector(selectFactoryData);

  const factoryCapData = useSelector((state) =>
    selectCapListForFactory(state, params.factoryId)
  );

  const actioNeededData = useSelector(selectActionNeededData);

  // Filter out items with status "Open"
  const openCapData = factoryCapData.filter((item) => item.status === "Open");

  const nextAuditDate =
    selectedFactory?.metadata?.socialAssessmentInfo?.confirmedAuditDate;

  const availableData = {
    rating: selectedFactory?.latestAudit?.risk?.auditRating || "Coming Soon.",
    venodrRating:
      selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score ===
        100 ||
      !selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score
        ? selectedFactory?.latestAudit?.metadata?.facilityData?.vendor_rating ||
          "N/A"
        : selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score,
    formattedNextAuditDate: nextAuditDate
      ? moment(nextAuditDate).format("MMM. DD, YYYY")
      : "-",
    daysUntilNextAudit: nextAuditDate
      ? moment(nextAuditDate).diff(moment(), "days")
      : null,
    nextAuditType:
      selectedFactory?.metadata?.socialAssessmentInfo?.generalAuditType,
    capStatus: selectedFactory?.latestAudit?.metadata?.CAP?.capStatus || "N/A",
    capDueDate:
      selectedFactory?.latestAudit?.metadata?.CAP?.capDueDate || "N/A",
  };

  return (
    <>
      {/* COMPREHENSIVE RISK DASHBOARD - at the top of the tab (only for configured tenants) */}
      {factoryStatusSection?.showComprehensiveRiskDashboard && (
        <ComprehensiveRiskDashboard selectedFactory={selectedFactory} />
      )}
      
      {/* AUDIT SCHEDULING - live above the most recent audit details */}
      {factoryStatusSection.auditSchedulingSection?.display && (
        <FactoryAuditScheduling selectedFactory={selectedFactory} />
      )}

      {/* RISK SCORE - GORE*/}
      {factoryStatusSection.riskSection?.display && (
        <FactoryRiskCardGore selectedFactory={selectedFactory} />
      )}

      {/* MOST RECENT AUDIT DETAILS */}
      {factoryStatusSection.detailsFromMostRecentAuditSection?.display &&
        (selectedFactory?.latestAudit?.state === "active" ? (
          <FactoryMostRecentAuditDetails selectedFactory={selectedFactory} />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              No audit exist for site status
            </Typography>
          </Box>
        ))}

      <Grid container spacing={4}>
        {factoryStatusSection?.detailsFromMostRecentAuditSection?.componentsAfterTheIssuesCard?.map(
          (components) => (
            <Grid item sx={{ my: 4 }} xs={components.width}>
              <TwoLineCard
                title={components.fieldName}
                nextAuditDate={availableData[components.key]}
              />
            </Grid>
          )
        )}
      </Grid>

      {/* CONFIRMED NEXT AUDIT DATE AND TYPE */}
      {factoryStatusSection?.detailsFromMostRecentAuditSection
        ?.mostRecentAuditDetails?.components?.confirmedNextAuditDateAndType && (
        <Grid item xs={12} sx={{ my: 4 }}>
          <TwoLineCard
            title="ConfirmedNext Audit Date and Type"
            nextAuditDate={
              <>
                {availableData.formattedNextAuditDate}
                {availableData.daysUntilNextAudit !== null && (
                  <Box
                    component="span"
                    sx={{
                      marginLeft: 1,
                      padding: "3px 8px",
                      fontSize: "18px",
                      fontWeight: 500,
                      borderRadius: "4px",
                      border: "1px solid #D0D5DD",
                      background: "white",
                      color: "#344054",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    {availableData.daysUntilNextAudit !== null
                      ? `In ${availableData.daysUntilNextAudit} Days`
                      : ""}
                  </Box>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {` for`}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {selectedFactory?.metadata?.socialAssessmentInfo
                  ?.generalAuditType || "-"}
              </>
            }
          />
        </Grid>
      )}

      {/* CADENCE CALCULATION */}
      {factoryStatusSection?.detailsFromMostRecentAuditSection
        ?.mostRecentAuditDetails?.components?.cadenceCalculation && (
        <FactoryAuditCadenceCalculation selectedFactory={selectedFactory} />
      )}

      {/* ACTION NEEDED */}
      {factoryStatusSection?.detailsFromMostRecentAuditSection
        ?.actionNeededSection?.display && (
        <Grid item sx={{ my: 4 }}>
          {((factoryStatusSection?.detailsFromMostRecentAuditSection
            ?.actionNeededSection?.isReformation &&
            actioNeededData.triggers.overallTriggered) ||
            (!factoryStatusSection?.detailsFromMostRecentAuditSection
              ?.actionNeededSection?.isReformation &&
              openCapData?.length > 0)) && (
            <FactoryActionNeeded
              capDetails={openCapData}
              certificateDetails={actioNeededData.certificateDetails}
              rating={actioNeededData.rating}
              irssDetails={actioNeededData.irssDetails}
              upcomingAuditDate={actioNeededData.upcomingAuditDate}
              nextAssessmentWindowDateStart={
                actioNeededData.nextAssessmentWindowDateStart
              }
              nextAssessmentWindowDateEnd={
                actioNeededData.nextAssessmentWindowDateEnd
              }
              triggersObject={actioNeededData.triggers}
              auditNotConfirmedMessage={
                actioNeededData.auditNotConfirmedMessage
              }
              factoryId={params.factoryId}
              supplierId={params.supplierId}
            />
          )}
          {/* Fallback message if no active audit and no action needed  REFORMATION SPECIFIC*/}
          {factoryStatusSection?.detailsFromMostRecentAuditSection
            ?.actionNeededSection?.isReformation &&
            selectFactory?.latestAudit?.state !== "active" &&
            !actioNeededData.triggers.overallTriggered && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "50px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8B8D97",
                  }}
                >
                  No audit exist for facility status. No action alerts.
                </Typography>
              </Box>
            )}
        </Grid>
      )}

      {/* NEXT DUE DATE */}
      {factoryStatusSection?.detailsFromMostRecentAuditSection
        ?.nextAuditDueDateSection?.display && (
        <Grid item xs={12} xl={4} sx={{ my: 4 }}>
          <TwoLineCard
            title={
              factoryStatusSection?.detailsFromMostRecentAuditSection
                ?.nextAuditDueDateSection.title
            }
            nextAuditDate={
              selectedFactory?.metadata?.status === "inactive"
                ? "Inactive"
                : selectedFactory?.auditCadence.nextAuditDate || "N/A"
            }
            textColor={
              !selectedFactory?.metadata?.status === "inactive" &&
              selectedFactory?.auditCadence?.isPastDue
                ? "#B42318"
                : "inherit"
            }
          />
        </Grid>
      )}

      {/* {factoryStatusSection.actionNeeded.display &&
        actioNeededData.triggers.overallTriggered && (
          <FactoryActionNeededRef
            capDetails={openCapData}
            certificateDetails={actioNeededData.certificateDetails}
            rating={actioNeededData.rating}
            irssDetails={actioNeededData.irssDetails}
            upcomingAuditDate={actioNeededData.upcomingAuditDate}
            nextAssessmentWindowDateStart={
              actioNeededData.nextAssessmentWindowDateStart
            }
            nextAssessmentWindowDateEnd={
              actioNeededData.nextAssessmentWindowDateEnd
            }
            triggersObject={actioNeededData.triggers}
            auditNotConfirmedMessage={actioNeededData.auditNotConfirmedMessage}
            factoryId={params.factoryId}
            supplierId={params.supplierId}
          />
        )} */}
    </>
  );
};

export default FactoryComplianceStatus;
