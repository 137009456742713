import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";

const initialState = {
  audits: [],
  searchResults: [],
  count: null,
  searchCount: null,
  auditPageTab: "issues",
  auditReportPageTab: "issues",
  auditData: null,
  selectedAuditIssueId: null,
  auditIssueCAPData: {},
  regenerateCapResult: null,
  dueActionIRSSData: [],
  capStarted: false,
  resources: [],
  nonActiveAuditCount: null,
  listOfAuditsInCapManagement: [],
  selectedCAPManagementIssueId: null,
};

const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    getListOfAllAudits: (state, action) => {},
    getSearchResultOfAudits: (state) => {},
    getAuditById: (state) => {},
    deleteCapDetailsById: (state, action) => {},
    updateAuditData: (state, action) => {},

    setListOfAllAudits: (state, action) => {
      state.audits = action.payload.audits || state.audits;
      state.searchResults = action.payload.searchResults || state.searchResults;
      state.count = action.payload.auditCount || state.count;
      state.searchCount = action.payload.searchCount || state.searchCount;
      state.nonActiveAuditCount =
        action.payload.nonActiveAuditCount || state.nonActiveAuditCount;
    },

    setAuditData: (state, action) => {
      state.auditData = action.payload;
    },
    setAuditPageTab: (state, action) => {
      state.auditPageTab = action.payload;
    },
    setAuditReportPageTab: (state, action) => {
      state.auditReportPageTab = action.payload;
    },
    setSelectedAuditIssueId: (state, action) => {
      state.selectedAuditIssueId = action.payload;
    },
    setAuditIssueCAPData: (state, action) => {
      state.auditIssueCAPData = action.payload;
    },

    uploadAuditReport: (state, action) => {},
    setUploadAuditReportFiles: (state, action) => {
      state.auditData = { ...state.auditData, files: action.payload };
    },
    processAuditReport: (state, action) => {},
    setProcessAuditReportFiles: (state, action) => {
      state.auditData = { ...state.auditData, processedFiles: action.payload };
    },
    regenerateCap: (state, action) => {},
    setRegenerateCapResult: (state, action) => {
      state.regenerateCapResult = action.payload;
    },
    getDueActionIRSSData: (state) => {},
    setDueActionIRSSData: (state, action) => {
      state.dueActionIRSSData = action.payload;
    },

    getCapStarted: (state, action) => {},
    setCapStarted: (state, action) => {
      state.capStarted = action.payload;
    },

    getListOfAuditsInCapManagement: (state, action) => {},
    setListOfAuditsInCapManagement: (state, action) => {
      state.listOfAuditsInCapManagement = action.payload;
    },
    beginCAPManagement: (state, action) => {},
    setAuditStatus: (state, action) => {
      state.auditData = {
        ...state.auditData,
        capManagementStatus: action.payload,
      };
    },

    updateIssueData: (state, action) => {},
    updateIssueCapData: (state, action) => {
      // if (
      //   action.payload.status === "CLOSED" &&
      //   action.payload.isThisFinalClosure
      // ) {
      //   Object.keys(state.auditIssueCAPData).forEach((issueId) => {
      //     state.auditIssueCAPData[issueId].status = action.payload.status;
      //   });
      // } else {
      //   state.auditIssueCAPData[action.payload.issueId]["status"] =
      //     action.payload.status;
      // }
      // state.auditData.capManagementStatus = action.payload.updateStatus
      //   ? action.payload.updateStatus
      //   : state.auditData.capManagementStatus;

      state.auditIssueCAPData[action.payload.issueId]["actionPlan"] = {
        ...state.auditIssueCAPData[action.payload.issueId]["actionPlan"],
        ...action.payload.actionPlan,
      };
      state.auditIssueCAPData[action.payload.issueId]["brandResponse"] = {
        ...state.auditIssueCAPData[action.payload.issueId]["brandResponse"],
        ...action.payload.brandResponse,
      };
      state.auditIssueCAPData[action.payload.issueId]["capStatus"] = {
        ...state.auditIssueCAPData[action.payload.issueId]["capStatus"],
        ...action.payload.capStatus,
      };

      state.auditData.capManagementStatus =
        action.payload.status === "BRAND_REVIEWING_PLAN"
          ? action.payload.status
          : state.auditData.capManagementStatus;
      state.auditIssueCAPData[action.payload.issueId]["status"] =
        action.payload.status;
      state.auditIssueCAPData[action.payload.issueId]["capManagementStatus"] =
        action.payload.status;
    },
    bulkUpdateCapStatus: (state, action) => {
      Object.keys(state.auditIssueCAPData).forEach((issueId) => {
        state.auditIssueCAPData[issueId].status = action.payload.status;
      });
      state.auditData.capManagementStatus = action.payload.status;
    },
    getResources: (state, action) => {},
    setResources: (state, action) => {
      state.resources = action.payload;
    },

    setCIDueDate: (state, action) => {},
    setCIDueDateResult: (state, action) => {
      state.auditIssueCAPData[action.payload.capIssueId]["ciDueDate"] =
        action.payload.ciDueDate;
    },

    triggerResetCAPManagementData: (state, action) => {},
    resetCAPManagementData: (state, action) => {
      state.auditData = {
        ...state.auditData,
        capManagementStatus: action.payload.capManagementStatus,
      };
    },
    setSelectedCAPManagementIssueId: (state, action) => {
      state.selectedCAPManagementIssueId = action.payload;
    },
    updateIssueProofData: (state, action) => {
      state.auditIssueCAPData[action.payload.issueId]["proofDocuments"] = [
        ...action.payload.proofDocuments,
      ];
      state.auditIssueCAPData[action.payload.issueId]["status"] =
        action.payload.status;
    },
    uploadCapProof: (state, action) => {},

    triggerEmail: (state, action) => {},
    createAuditCapDetail: (state, action) => {},
  },

  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  getListOfAllAudits,
  getSearchResultOfAudits,
  getAuditById,
  setListOfAllAudits,
  setAuditData,
  updateAuditData,
  setAuditPageTab,
  setAuditReportPageTab,
  setSelectedAuditIssueId,
  setAuditIssueCAPData,
  uploadAuditReport,
  setUploadAuditReportFiles,
  processAuditReport,
  setProcessAuditReportFiles,
  regenerateCap,
  setRegenerateCapResult,
  deleteCapDetailsById,
  getListOfAuditsInCapManagement,
  setListOfAuditsInCapManagement,
  beginCAPManagement,
  setAuditStatus,
  updateIssueData,
  updateIssueCapData,
  getResources,
  setResources,
  setCIDueDate,
  setCIDueDateResult,
  triggerResetCAPManagementData,
  resetCAPManagementData,
  triggerEmail,
  getCapStarted,
  setCapStarted,
  getDueActionIRSSData,
  setDueActionIRSSData,
  setSelectedCAPManagementIssueId,
  uploadCapProof,
  bulkUpdateCapStatus,
  updateIssueProofData,
  createAuditCapDetail,
} = auditSlice.actions;

export default auditSlice.reducer;
