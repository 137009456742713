import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  List,
  Divider,
  Typography,
  Badge,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectUserInfo } from "../selectors/login.selector";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
  setLeftSideNavigationSelected,
  setSidebarExpanded,
} from "../reducers/navigation.reducer";
import {
  selectIsSidebarExpanded,
  selectLeftSideNavigationSelected,
} from "../selectors/navigation.selector";
import { useTenant } from "../context/TenantContext";
import { ComponentStyles } from "../styles";
import NavItem from "./navItem.component";
import { selectNumberOfInProgressCAPs } from "../selectors/audit.selector";
import { selectCountOfTotalUnreadNotifications } from "../selectors/notifcation.selector";
import { getNotes } from "../reducers/notification.reducer";
import NotificationsPage from "../pages/notifications.page";

const LeftSideNavbar = () => {
  const { navigation, notificationConfig } = useTenant();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const isExpanded = useSelector(selectIsSidebarExpanded);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    navigate(path);
  };

  useEffect(() => {
    if (params["*"]) {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params, dispatch]);

  const numberOfInProgressCAPs = useSelector(selectNumberOfInProgressCAPs);
  const badgeData = {
    numberOfInProgressCAPs,
  };
  const [notificationModalOpen, setNotificationModalOpen] =
    React.useState(false);

  const handleNotificationModalOpen = () => {
    setNotificationModalOpen(true);
  };

  const handleNotificationModalClose = () => {
    setNotificationModalOpen(false);
  };

  useEffect(() => {
    dispatch(getNotes());
  }, [dispatch]);
  const countOfTotalUnreadNotifications = useSelector(
    selectCountOfTotalUnreadNotifications
  );

  const toggleSidebar = () => {
    dispatch(setSidebarExpanded(!isExpanded));
  };

  const isItemVisible = (itemType) => {
    // Only apply role-based visibility for reformation tenant
    if (navigation?.configuration?.roleBasedAccess) {
      const role = userInfo?.role || "standard";

      if (role === "reporting") {
        return ["suppliers", "facilities", "insights"].includes(itemType);
      }

      if (role === "sourcing") {
        return ["suppliers", "facilities", "questionnaire"].includes(itemType);
      }
    }

    // For all other tenants, show all items
    return true;
  };
  return (
    <Box
      onMouseLeave={() => navigation?.configuration?.dynamic && toggleSidebar()}
      onMouseEnter={() => navigation?.configuration?.dynamic && toggleSidebar()}
      sx={{
        width: isExpanded ? 210 : 72,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: isExpanded ? "space-between" : "center",
          px: "10px",
          py: 2,
        }}
      >
        <img
          src="/Elm_Logo.svg"
          alt="Logo"
          style={{
            ...ComponentStyles.logoImage,
            width: "30px",
          }}
        />
        {isExpanded && notificationConfig?.display && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Supplier Notes">
              <IconButton
                onClick={handleNotificationModalOpen}
                sx={{
                  width: 40,
                  height: 40,
                  padding: 0,
                }}
              >
                {countOfTotalUnreadNotifications &&
                countOfTotalUnreadNotifications > 0 ? (
                  <Badge
                    badgeContent={countOfTotalUnreadNotifications || ""}
                    color="error"
                  >
                    <NotificationsOutlinedIcon
                      style={{ color: "#667085", fontSize: "32px" }}
                    />
                  </Badge>
                ) : (
                  <NotificationsOutlinedIcon
                    style={{ color: "#667085", fontSize: "32px" }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Navigation */}
      <List sx={{ width: "100%" }}>
        {navigation?.fields?.map((item, index) => {
          const itemType = item.path.replace("/", "");
          
          // Render the original nav item
          const navItem = isItemVisible(itemType) && (
            <NavItem
              key={item.path}
              icon={item.icon}
              label={item.label}
              isSelected={selected === item.path}
              isExpanded={isExpanded}
              onClick={() => handleNavigate(item.path)}
              badge={badgeData[item?.badge]}
            />
          );
          
          // Insert Risk item right after Insights
          if (item.path === "/insights") {
            return [
              navItem,
              <NavItem
                key="/risk"
                icon="/resources.svg"
                label="Risk"
                isSelected={selected === "/risk"}
                isExpanded={isExpanded}
                onClick={() => handleNavigate("/risk")}
              />
            ];
          }
          
          // Skip rendering Risk again if it's already in the config
          if (item.path === "/risk") {
            return null;
          }
          
          return navItem;
        })}
      </List>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {!navigation?.configuration?.dynamic && (
        <Box
          sx={{
            display: "flex",
            justifyContent: isExpanded ? "flex-end" : "center",
            mb: 2,
            px: 1,
          }}
        >
          <Tooltip
            title={isExpanded ? "Minimize side bar" : "Expand side bar"}
            placement="right"
          >
            <IconButton
              onClick={toggleSidebar}
              sx={{
                backgroundColor: "white",
                border: "1px solid #cccccc4a",
                "&:hover": {
                  backgroundColor: "white",
                },
                width: 32,
                height: 32,
              }}
            >
              {isExpanded ? (
                <KeyboardArrowLeftIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isExpanded ? "space-between" : "center",
          px: "10px",
          py: 2,
        }}
      >
        {isExpanded && (
          <Box>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {userInfo.name}
            </Typography>
          </Box>
        )}
        <Tooltip title={!isExpanded ? "Logout" : ""} placement="right">
          <IconButton
            edge="end"
            aria-label="Logout"
            onClick={() => handleLogout()}
          >
            <img
              src="/logout.svg"
              alt="Logout"
              style={ComponentStyles.navbarListItemIcon}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <NotificationsPage
        open={notificationModalOpen}
        onClose={handleNotificationModalClose}
      />
    </Box>
  );
};

export default LeftSideNavbar;
