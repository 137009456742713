import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import PastCAP from "./factoryActionNeeded.component";
import FactoryActionNeeded from "./factoryActionNeeded.component";
import TwoLineCard from "../twoLineCard.component";

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  return (
    <>
      <Typography
        sx={{
          color: "#000",
          fontSize: "18px",
          fontWeight: "700",
          my: 3,
        }}
      >
        Details from Most Recent Audit
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {selectedFactory?.metadata?.tier !== "1" ? (
            <Typography
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: "300",
                my: 3,
                textAlign: "center",
              }}
            >
              Tier {selectedFactory?.metadata?.tier} factories are beyond the scope of audits
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FacilityIssuesCard />
              </Grid>
              <Grid item xs={6}>
                <TwoLineCard
                  title="Supplier Rating from Audit"
                  nextAuditDate={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          selectedFactory?.risk?.riskClass === "High risk"
                            ? "rgba(255, 0, 0, 0.1)"
                            : selectedFactory?.risk?.riskClass === "Medium risk"
                            ? "rgba(255, 165, 0, 0.1)"
                            : selectedFactory?.risk?.riskClass === "Low risk"
                            ? "rgba(0, 128, 0, 0.1)"
                            : "rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "34px",
                          fontWeight: "bold",
                          color:
                            selectedFactory?.risk?.riskClass === "High risk"
                              ? "red"
                              : selectedFactory?.risk?.riskClass === "Medium risk"
                              ? "orange"
                              : selectedFactory?.risk?.riskClass === "Low risk"
                              ? "green"
                              : "black",
                          marginRight: "16px",
                        }}
                      >
                        {selectedFactory?.risk?.riskScore}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ marginRight: "16px", backgroundColor: "white" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "normal",
                          color:
                            selectedFactory?.risk?.riskClass === "High risk"
                              ? "red"
                              : selectedFactory?.risk?.riskClass === "Medium risk"
                              ? "orange"
                              : selectedFactory?.risk?.riskClass === "Low risk"
                              ? "green"
                              : "black",
                        }}
                      >
                        {selectedFactory?.risk?.riskClass || "Coming Soon."}
                      </Typography>
                    </Box>
                  }
                />
                <TwoLineCard
                  title="Next Audit Due Date"
                  nextAuditDate={selectedFactory?.metadata?.nextReportDate || "-"}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
