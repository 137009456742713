import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  selectAuditPageTab,
  selectAuditData,
  selectAuditQuickbaseData,
} from "../../selectors/audit.selector";
import { setAuditPageTab } from "../../reducers/audit.reducer";
import { selectFactoryIRSSData } from "../../selectors/factory.selector";

const AuditTabsReformation = () => {
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setAuditPageTab(newValue));
  };
  const selectedAuditData = useSelector(selectAuditData);
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories
    ? true
    : false;
  const value = useSelector(selectAuditPageTab);
  const { isHistoricalAudit, qbInfo } = useSelector(selectAuditQuickbaseData);
  const factoryIRSSData = useSelector(selectFactoryIRSSData);
  console.log("factoryIRSSData", factoryIRSSData);
  // showIRSSTab - override with irssCheck if checked, otherwise use factoryIRSSData.check + risk.auditRating (orange or red)
  const showIRSSTab =
    selectedAuditData?.metadata?.irss?.irssCheck === "Yes"
      ? true
      : selectedAuditData?.risk?.auditRating
          ?.toLowerCase()
          .includes("orange", "red");
  return (
    <Box sx={{ width: "100%", flexGrow: 1, px: 4 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab
          value="issues"
          label="Findings & CAP"
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="document"
          label="Supporting Documents"
          sx={{ textTransform: "none" }}
        />
        {isHistoricalAudit && (
          <Tab
            value="data"
            label="Historical Data (QB)"
            sx={{ textTransform: "none" }}
          />
        )}
        {!isHistoricalAudit && (
          <Tab value="export" label="Export" sx={{ textTransform: "none" }} />
        )}
        {showIRSSTab && (
          <Tab value="irss" label="IRSS" sx={{ textTransform: "none" }} />
        )}
      </Tabs>
    </Box>
  );
};

export default AuditTabsReformation;
