import React, { useState, lazy, Suspense } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ErrorIcon from "@mui/icons-material/Error";
import NoteIcon from "@mui/icons-material/Note";
import CustomChipLabel from "./customChipLabel.component";
import AuditReport from "./auditReport.component";
import AuditReportLeftSideNavigationBar from "./auditReportDetailsLeftSideNavigationBar.component";
import AuditReportTabs from "./auditReportTabs.component";
import AuditReportDetails from "../../pages/auditReportDetails.page";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCapDetailsById,
  setSelectedAuditIssueId,
  updateAuditData,
} from "../../reducers/audit.reducer";
import {
  selectAuditIssueCAPData,
  selectAuditData,
} from "../../selectors/audit.selector";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectEditModeState } from "../../selectors/misc.selector";
import { convertStatusToNormal } from "../../utils/lib";
import { useTenant } from "../../context/TenantContext";
import { selectIsAdmin } from "../../selectors/login.selector";
const StitchFixCreateFindingButton = lazy(() =>
  import("../../tenants/stitchfix/createFindingButton.component")
);

const AuditsTable = () => {
  const { auditConfig } = useTenant();
  const params = useParams();
  const dispatch = useDispatch();

  const auditData = useSelector(selectAuditData);
  const [releaseReport, setReleaseReport] = useState(
    auditData?.metadata?.reportReleasedByBrand ? "Yes" : "No"
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleClick = (id) => {
    dispatch(setSelectedAuditIssueId(id));
  };
  const handleDelete = (capDetailsId) => {
    dispatch(
      deleteCapDetailsById({
        organisationId: params.organisationId,
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
        capDetailsId: capDetailsId,
      })
    );
  };
  const editMode = useSelector(selectEditModeState);
  const issuesData = useSelector(selectAuditIssueCAPData);
  const handleReleaseReportChange = (event) => {
    setReleaseReport(event.target.value);
  };

  const handleSubmitRelease = () => {
    if (releaseReport) {
      try {
        dispatch(
          updateAuditData({
            organisationId: auditData?.organisationId,
            supplierId: auditData?.supplierId,
            factoryId: auditData?.factoryId,
            auditId: auditData?._id,
            changes: {
              reportReleasedByBrand: releaseReport === "Yes",
            },
          })
        );
        setSnackbar({
          open: true,
          message: "Changes saved successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error saving changes:", error);
        setSnackbar({
          open: true,
          message: "Error saving changes",
          severity: "error",
        });
      }
    }
  };

  const selectedAuditData = useSelector(selectAuditData);
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories
    ? true
    : false;
  const adminUser = useSelector(selectIsAdmin);
  return (
    <>
      {auditIsProcessed && (selectedAuditData?.state != "test" || adminUser) ? (
        <Box
          elevation={1}
          sx={{
            p: 3,
            borderTop: "0px",
            px: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {auditConfig?.tabsSection?.findings?.title ||
                "Finding Information"}
            </Typography>
            {auditConfig?.tabsSection?.findings?.buttons?.createNewFindings
              ?.display && (
              <Suspense
                fallback={
                  <Button variant="contained" disabled>
                    Loading...
                  </Button>
                }
              >
                <StitchFixCreateFindingButton />
              </Suspense>
            )}
          </Box>
          <Table aria-label="issues table">
            <TableHead>
              <TableRow>
                {auditConfig?.tabsSection?.findings?.headers?.map((header) => (
                  <TableCell key={header.label}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {issuesData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={
                    index === issuesData.length - 1
                      ? { "&:last-child td, &:last-child th": { border: 0 } }
                      : {}
                  }
                >
                  <TableCell>{row?.clientCodeTitle || index + 1}</TableCell>
                  <TableCell
                    sx={{
                      wordBreak: "break-word",
                      mr: 0,
                      pr: 1,
                      maxWidth: "470px",
                    }}
                  >
                    {row.issue}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "16px",
                      cursor: "pointer",
                      paddingRight: "40px",
                      opacity: 0.5,
                    }}
                    onClick={() => {
                      handleClick(row.id);
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: 0,
                        margin: 0,
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <OpenInFullIcon className="icon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        See detail
                      </Typography>
                    </IconButton>
                  </TableCell>
                  {auditConfig?.tabsSection?.findings?.headers?.find(
                    (header) => header.key === "subType"
                  ) ? (
                    <TableCell sx={{ p: 0 }}>{row.subType}</TableCell>
                  ) : (
                    <TableCell sx={{ p: 0 }}>{row.type}</TableCell>
                  )}
                  <TableCell sx={{ p: 0 }}>
                    <CustomChipLabel severity={row.severity} />
                  </TableCell>
                  <TableCell>
                    {/* TODO: Need to fix the row.statusChipLabel later. It is undefined. Temp change to row.status */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CustomChipLabel
                        cap={row.status}
                        capLabel={convertStatusToNormal(row.status)}
                      />
                      {row.status == "Open" && (
                        <span
                          style={{
                            marginLeft: 8,
                            color: row.dueInDays <= 0 ? "red" : "inherit",
                          }}
                        >
                          {row?.dueInDays
                            ? row.dueInDays <= 0
                              ? `Past Due ${-row.dueInDays?.toString()} Days`
                              : `Due in ${row.dueInDays?.toString()} Days`
                            : ""}
                        </span>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {editMode && (
                      <Button onClick={() => handleDelete(row.id)}>
                        <DeleteIcon sx={{ color: "gray" }} />
                      </Button>
                    )}
                  </TableCell>
                  {/* <TableCell sx={{ width: "8px" }}>
                  {row.note && (
                    <Tooltip title={test}>
                      <IconButton>
                        <NoteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {issuesData.length === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#8B8D97",
                }}
              >
                No issues found
              </Typography>
            </Box>
          )}

          {auditConfig?.tabsSection?.controlSettings?.display && (
            <>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Control Settings
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  my: 3,
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <FormControl sx={{ minWidth: 220 }}>
                  <InputLabel id="release-report-label">
                    Publish Report to Supplier
                  </InputLabel>
                  <Select
                    labelId="release-report-label"
                    value={releaseReport}
                    label="Release Report to Supplier"
                    onChange={handleReleaseReportChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  onClick={handleSubmitRelease}
                  disabled={!releaseReport}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      ) : (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Typography variant="body1">
            Finding and CAP information will be available after audit is
            processed
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AuditsTable;
