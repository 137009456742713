import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Chip,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectDueAudits } from "../../selectors/dueAction.selector";
import { useSelector, useDispatch } from "react-redux";
import { selectFactoryList } from "../../selectors/factory.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import moment from "moment";
import { useTenant } from "../../context/TenantContext";
const DueAuditsDataGrid = () => {
  const { dueActionConfig } = useTenant();
  const navigate = useNavigate();

  // FUNCITON TO CONVERT DAYS to YEARS MONTHS AND DAYs
  //   const convertDaysToYearsMonthsDays = (totalDays) => {
  //     const years = Math.floor(totalDays / 365);
  //     const remainingDaysAfterYears = totalDays % 365;
  //     const months = Math.floor(remainingDaysAfterYears / 30);
  //     const days = remainingDaysAfterYears % 30;

  //     const parts = [];
  //     if (years > 0) parts.push(`${years} ${years === 1 ? 'year' : 'years'}`);
  //     if (months > 0) parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
  //     if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);

  //     return parts.join(', ');
  //   };
  const selectedDueAudits = useSelector(selectDueAudits);

  // TODO: Get the confirmed next audit date from the factory list. Ideally, this data should come from factory not audit. Need to change serverside to support this.
  const factoriesList = useSelector(selectFactoryList);
  const dispatch = useDispatch();
  const organisationId = useSelector(selectOrganisationId);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  // Map through selectedDueAudits and attach confirmedNextAuditDate from matching factory
  const auditsWithConfirmedDates = selectedDueAudits?.map((audit) => {
    // Find matching factory using factoryId
    const matchingFactory = factoriesList?.find(
      (factory) => factory.factoryId === audit.factoryId
    );

    // Return audit with confirmedNextAuditDate added from factory if it exists
    return {
      ...audit,
      confirmedNextAuditDate: matchingFactory?.confirmedNextAuditDate || null,
    };
  });

  const enhancedDueAudits = auditsWithConfirmedDates || selectedDueAudits;

  const rows = enhancedDueAudits?.map((audit, index) => ({
    id: `${audit?.auditId}-${index}`,
    factoryName: audit?.factoryName,
    auditDate: audit?.auditEndDate,
    auditScore: audit?.auditScore,
    factoryId: audit?.factoryId,
    supplierId: audit?.supplierId,
    daysSinceAudit: audit?.daysSinceAudit,
    daysUntilDue: audit?.daysUntilDue,
    dueStatus:
      audit?.daysUntilDue > 0
        ? `in ${audit?.daysUntilDue} Days`
        : `Past Due ${Math.abs(audit?.daysUntilDue)} Days`,
    dueDateForNextAudit: audit?.initialPresetDueDate,
    nextAuditDueDate: audit?.nextAuditDueDate || "",
    confirmedNextAuditDate: audit?.confirmedNextAuditDate
      ? moment(audit?.confirmedNextAuditDate).format("MMM. D, YYYY")
      : "",
    severityCategories: audit?.severityCategories,
    issueDetails: audit?.issueDetails,
    openIssues: audit?.groupedDataForDueAction?.open,
    pastDueIssues: audit?.groupedDataForDueAction?.pastDue,
    auditFrequency: audit?.auditFrequency,
    scheduleStatus: audit?.scheduleStatus,
  }));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);
    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;

      if (row?.daysSinceAudit) {
        const daysSinceAudit = row?.daysSinceAudit;

        return newFilters.some((filter) => {
          switch (filter) {
            case "pastDue":
              return daysSinceAudit > 0;
            case "due1to20":
              return daysSinceAudit <= 0 && daysSinceAudit >= -20;
            case "due20to60":
              return daysSinceAudit < -20 && daysSinceAudit >= -60;
            case "due60plus":
              return daysSinceAudit < -60;
            default:
              return true;
          }
        });
      }
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [selectedDueAudits]);

  const columns = dueActionConfig?.audit?.dataGrid?.columns?.map((col) => {
    if (col?.renderCell === "factoryName") {
      return {
        ...col,
        renderCell: (params) => (
          <Typography
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              padding: "8px 0px 8px 8px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        ),
      };
    } else if (col?.renderCell === "dueStatus") {
      return {
        ...col,
        renderCell: (params) => (
          <Chip
            label={params.row.dueStatus}
            sx={{
              borderRadius: "8px",
              backgroundColor:
                params.row.daysUntilDue < 0 ? "#DC6803" : "#EAECF0",
              color: params.row.daysUntilDue < 0 ? "#FFF" : "#172B4D",
              fontWeight: "500",
            }}
          />
        ),
      };
    } else if (col?.renderCell === "details") {
      return {
        ...col,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => {
              window.history.pushState(null, "", "/dueAction");

              navigate(
                `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}`,
                { replace: true }
              );
            }}
            sx={{
              backgroundColor: "#4B68D4",
              color: "white",
              "&:hover": {
                backgroundColor: "#3A54B6",
              },
              textTransform: "none",
              width: "79px",
              height: "40px",
              ml: 4,

              borderRadius: "8px",
            }}
            size="small"
          >
            To Site
          </Button>
        ),
      };
    }
    return col;
  });

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Audits Due
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGrid
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 70}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container

                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",

                            "& .MuiDataGrid-cell": {
                              // Target each cell

                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueAuditsDataGrid;
