import React from "react";
import { Box, Divider, Typography } from "@mui/material";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { PageStyles } from "../styles";

import InsightDashboard from "../components/InsightDetails/insightDashboard.component";
import RiskAnalytics from "../components/InsightDetails/riskAnalytics.component";
import { useTenant } from "../context/TenantContext";
import { selectInsightPageTab } from "../selectors/insights.selector";
import { useSelector } from "react-redux";
import InsightsTabs from "../components/InsightDetails/insightTabs.component";
import FactoryInsightsMetaDataGrid from "../components/InsightDetails/factoryInsightsMetaDataGrid.component";
import SupplierInsightsMetaDataGrid from "../components/InsightDetails/supplierInsightsMetaDataGrid.component";

const Insights = () => {
  const { insightsConfig } = useTenant();
  const selectedTab = useSelector(selectInsightPageTab);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflowY: "hidden" }}>
        <Box
          sx={{
            ...PageStyles.containerBoxExceptNavbar,
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white", // Assuming a white background, adjust if needed
          }}
        >
          <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
            <TopBreadcrumbs />
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
              {insightsConfig?.header?.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
            >
              {insightsConfig?.header?.description}
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Box sx={{ flex: 1, pt: 3 }}>
          <InsightsTabs />

          <Box>
            {selectedTab === "dataAnalytics" ? (
              <InsightDashboard />
            ) : selectedTab === "risk" ? (
              <RiskAnalytics />
            ) : selectedTab === "supplierDataTable" ? (
              <SupplierInsightsMetaDataGrid />
            ) : (
              <FactoryInsightsMetaDataGrid />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Insights;
