import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { Box, Typography, Button, Divider, Chip, Drawer } from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";

import {
  getListOfAllSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useTenant } from "../../context/TenantContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { selectSupplierList } from "../../selectors/supplier.selector";

import CreateSupplierForm from "../createSupplier.component";
import {
  selectOrganisationId,
  selectUserInfo,
} from "../../selectors/login.selector";
import { copyToClipboard } from "../../utils/lib";

const SupplierTableDataGrid = ({
  navigateToSupplierDetails,
  organisationId,
}) => {
  const { supplierConfig, nomenclature } = useTenant();
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const userInfo = useSelector(selectUserInfo);

  const rows = suppliersList
    ?.map((supplier) => {
      if (!supplier) return null;

      return {
        id: supplier?.supplierId || "",
        supplierName: supplier?.name || "",
        supplierIdFromMetadata: supplier?.metadata?.uniqueSupplierId || "–",
        country: supplier?.country || "",
        totalFactoryCount: supplier?.factoryData?.totalFactories
          ? isNaN(Number(supplier.factoryData.totalFactories))
            ? null
            : Number(supplier.factoryData.totalFactories)
          : null,
        tier1: supplier?.factoryData?.tier1
          ? isNaN(Number(supplier.factoryData.tier1))
            ? null
            : Number(supplier.factoryData.tier1)
          : null,
        tier2: supplier?.factoryData?.tier2
          ? isNaN(Number(supplier.factoryData.tier2))
            ? null
            : Number(supplier.factoryData.tier2)
          : null,
        tier1Factories: supplier?.factoryData?.tier1Factories || "-",
        tier2Factories: supplier?.factoryData?.tier2Factories || "-",
        businessUnit: supplier?.businessUnit,
        vendorStatus: supplier?.metadata?.vendorStatus,
        status: supplier?.status || "-",
        actionAlert: supplier?.actionAlert,
        primaryContact:
          supplier?.metadata?.primarySupplierContactPerson?.name || "-",
        primaryContactEmail:
          supplier?.metadata?.primarySupplierContactPerson?.email || "-",
      };
    })
    .filter(Boolean); // Remove any null rows
  const formatters = {
    defaultFormatter: (value) =>
      value === null || value === undefined ? "-" : value,
    numberFormatter: (value) => (value ? value.toLocaleString() : "-"),
    uppercaseFormatter: (value) => (value ? String(value).toUpperCase() : "-"),
  };
  const columns = supplierConfig.dataGrid.columns.map((col) => {
    if (typeof col?.valueFormatter === "string") {
      return {
        ...col,
        valueFormatter:
          formatters[col.valueFormatter] || formatters.defaultFormatter,
      };
    }
    if (col?.renderCell === "statusChip") {
      return {
        ...col,
        renderCell: (params) => {
          // Handle empty or missing values
          if (!params.value || params.value === "-") {
            return (
              <Chip
                label="Deactivated"
                sx={{
                  borderRadius: "16px",
                  fontWeight: 500,
                  fontSize: 12,
                  backgroundColor: "#F2F4F7", // Gray for deactivated/empty
                  color: "#667085", // Gray text
                  textTransform: "capitalize",
                }}
              />
            );
          }
          
          // Check for both compliant and active statuses
          const status = params.value?.toLowerCase() || "";
          const isCompliant = status === "compliant";
          const isActive = status === "active";
          
          // Set colors based on status
          let backgroundColor = "#FEF0C7"; // Default yellow for non-compliant/non-active
          let textColor = "#B54708"; // Default amber text
          
          if (isCompliant || isActive) {
            backgroundColor = "#E6F4EA"; // Green for compliant/active
            textColor = "#1E8E3E"; // Green text
          }
          
          return (
            <Chip
              label={params.value}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor: backgroundColor,
                color: textColor,
                textTransform: "capitalize",
              }}
            />
          );
        },
      };
    } else if (col?.renderCell === "vendorStatus") {
      return {
        ...col,
        renderCell: (params) => {
          return (
            <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              <Typography
                sx={{
                  color: params?.row?.vendorStatus?.includes("Approved")
                    ? "green"
                    : "inherit",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
              >
                {params.row.vendorStatus}
              </Typography>
            </div>
          );
        },
      };
    } else if (col?.renderCell === "actionAlert") {
      return {
        ...col,
        renderCell: (params) => (
          <Chip
            label={params.row.actionAlert}
            sx={{
              borderRadius: "16px",
              fontWeight: 500,
              fontSize: 12,
              backgroundColor:
                params.row.actionAlert === "Yes" ? "#FECDCA" : "#F2F4F7",
              color: params.row.actionAlert === "Yes" ? "#B42318" : "default",
              cursor: "pointer",
            }}
          />
        ),
      };
    } else if (col?.renderCell === "tieredFactories") {
      return {
        ...col,
        renderCell: (params) => {
          // Check if we have tier1Factories as an array
          const factories =
            col?.field === "tier1Factories"
              ? params.row.tier1Factories
              : params.row.tier2Factories;

          if (!factories || factories === "-" || factories.length === 0) {
            return "";
          }

          // If we have factories, display them as links
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {factories?.map((factory) => (
                <Box
                  key={factory.factoryId}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    href={`/suppliers/${params.row.id}/factory/${factory.factoryId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#4C5ED9",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {factory.name}
                  </a>
                  <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
                </Box>
              ))}
            </Box>
          );
        },
      };
    } else if (col?.renderCell === "details") {
      return {
        ...col,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => navigateToSupplierDetails(params.row.id)}
            sx={{
              backgroundColor: "#6172F3",
              color: "white",
              "&:hover": {
                backgroundColor: "#5667e2",
                fontWeight: "bold",
              },
              textTransform: "none",
              width: "79px",
              height: "40px",
              borderRadius: "8px",
            }}
            size="small"
            disabled={
              col?.disabledCondition
                ? userInfo?.role === "sourcing" ||
                  userInfo?.role === "reporting"
                : false
            }
          >
            Details
          </Button>
        ),
      };
    }
    return col;
  });

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getListOfAllSuppliers({ page: 1, limit: 999, offset: 0 }));
  }, [dispatch]);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            {nomenclature.supplier.fieldNamePlural}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your {nomenclature.supplier.fieldNamePlural} here
          </Typography>
        </div>
        <div>
          {supplierConfig?.buttons?.presourcingForm?.display && (
            <Button
              variant="outlined"
              onClick={() => copyToClipboard(organisationId, false, true)}
              sx={{
                color: "#6172F3",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#5667e2",
                },
                textTransform: "none",
                width: "230px",
                height: "40px",
                borderRadius: "8px",
                mr: "24px",
              }}
            >
              {supplierConfig?.buttons?.presourcingForm?.label}
              <img
                style={{ paddingLeft: 8 }}
                src={supplierConfig?.buttons?.presourcingForm?.icon}
                alt="arrow icon"
              />
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setIsDrawerOpen(true);
            }}
            sx={{
              color: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "200px",
              height: "40px",
              borderRadius: "8px",
              mr: "48px",
            }}
          >
            {`Create New ${nomenclature.supplier.fieldNameSingular}`}
          </Button>
        </div>
      </div>

      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            getRowHeight={() => "auto"}
            sx={{
              borderRadius: "8px",
              padding: "16px",
              "& .MuiDataGrid-cell": {
                padding: "8px",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f0f0f0", // Light gray background
                color: "#333", // Dark text color
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "16px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateSupplierForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default SupplierTableDataGrid;
