import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@mui/material";
import { ComponentStyles } from "../../styles";
import { useTenant } from "../../context/TenantContext";

const OpenCAPCard = ({ capDetails = [] }) => {
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;
  const getBackgroundColor = (dueInDays) => {
    if (
      factoryStatusSection?.detailsFromMostRecentAuditSection?.openCapCard
        .customColors
    ) {
      if (dueInDays > 30)
        return factoryStatusSection?.detailsFromMostRecentAuditSection
          ?.openCapCard.colors.high;
      if (dueInDays > 0)
        return factoryStatusSection?.detailsFromMostRecentAuditSection
          ?.openCapCard.colors.medium;
      return factoryStatusSection?.detailsFromMostRecentAuditSection
        ?.openCapCard.colors.low;
    }
    return dueInDays > 30 ? "darkgray" : dueInDays > 0 ? "#FF692E" : "#B42318";
  };

  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        background: "#FFF",
        mb: 4,
        boxShadow: factoryStatusSection?.detailsFromMostRecentAuditSection
          ?.openCapCard.enableBoxShadow
          ? "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)"
          : "none",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Open CAP
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table>
        <TableBody>
          {capDetails.length > 0 ? (
            capDetails.map((cap, index) => (
              <TableRow key={index}>
                <TableCell sx={{ borderBottom: "none", pl: 0 }}>
                  <Typography sx={ComponentStyles.fieldName}>Status</Typography>
                  <Box
                    sx={{
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      background: getBackgroundColor(cap.dueInDays),
                      color: factoryStatusSection
                        ?.detailsFromMostRecentAuditSection?.openCapCard
                        .customColors
                        ? "white"
                        : "#FFF",
                      width: "fit-content",
                    }}
                  >
                    {cap.dueInDays > 0
                      ? `In ${Math.ceil(cap.dueInDays)} days`
                      : `Past Due ${Math.ceil(-cap.dueInDays)} days`}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    ...ComponentStyles.fieldName,
                    borderBottom: "none",
                  }}
                >
                  <Typography sx={ComponentStyles.fieldName}>
                    Severity
                  </Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {cap.severity || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    ...ComponentStyles.fieldName,
                    borderBottom: "none",
                  }}
                >
                  <Typography sx={ComponentStyles.fieldName}>
                    Findings
                  </Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {cap.issueSummary
                      ? cap.issueSummary.length > 70
                        ? `${cap.issueSummary.substring(0, 70)}...`
                        : cap.issueSummary
                      : "No issues reported"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                {factoryStatusSection?.detailsFromMostRecentAuditSection
                  ?.openCapCard.emptyStateText || "No actions needed."}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OpenCAPCard;
