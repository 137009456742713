import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./components/login.component";
import IndexPage from "./pages/index.page";
import FacilityDetails from "./pages/factoryDetails.page";
import { TenantProvider } from "./context/TenantContext"; // Tenant Context
import theme from "./utils/theme";
import { selectLoggedIn } from "./selectors/login.selector";
import { loginSuccess } from "./reducers/login.reducer";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "fb9ee2a0afec9d75cd64230695492848Tz0xMDExMDYsRT0xNzYyMTA3MzQwMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLoggedIn);
  const [loading, setLoading] = useState(true);

  // Check login state on mount
  useEffect(() => {
    const storedState = localStorage.getItem("loginState");
    if (storedState) {
      const state = JSON.parse(storedState);
      if (state && state._id && state.token) {
        dispatch(loginSuccess(state));
      }
    }
    setLoading(false); // Stop loading after checking login state
  }, [dispatch]);

  // Render a loading indicator while app determines login state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ToastContainer position="bottom-left" theme="dark" />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <TenantAwareRoutes isLoggedIn={isLoggedIn} />
        </Router>
      </ThemeProvider>
    </>
  );
}

// Wrap tenant-aware routes in a TenantProvider
const TenantAwareRoutes = ({ isLoggedIn }) => {
  const tenantId = window.location.hostname.split(".")[0];

  return (
    <TenantProvider tenantId={tenantId}>
      <Routes>
        {/* Protected Route */}
        <Route
          path="/organisations/:organisationId/suppliers/:supplierId/factories/:factoryId"
          element={
            isLoggedIn ? <FacilityDetails /> : <Navigate to="/" replace />
          }
        />
        {/* Public Route */}
        <Route
          path="/"
          element={
            !isLoggedIn ? <Login /> : <Navigate to="/factories" replace />
          }
        />
        {/* Fallback Route */}
        <Route
          path="*"
          element={isLoggedIn ? <IndexPage /> : <Navigate to="/" replace />}
        />
      </Routes>
    </TenantProvider>
  );
};

export default App;
