import { Box, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const FactoryAssociatedSupplierLinks = ({
  factoryData,
  isLoadingSuppliers,
}) => {
  if (!factoryData?.suppliers || !factoryData?.supplierNames) return null;
  if (isLoadingSuppliers) {
    return <CircularProgress size={20} />;
  }

  return (
    <Box component="ul" sx={{ padding: 0, margin: 0, listStyle: "none" }}>
      {factoryData.suppliers.map((id, index) => {
        const name = factoryData.supplierNames[index];
        if (!name) return null;

        return (
          <Box component="li" key={id} sx={{ marginBottom: "1px" }}>
            <Link
              to={`/suppliers/${id}`}
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: "#3538CD",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              {name}
              <img
                style={{ paddingLeft: 8 }}
                src="/ne_arrow.svg"
                alt="arrow icon"
              />
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default FactoryAssociatedSupplierLinks;
