import React from "react";

import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";

import CustomChipLabel from "./customChipLabel.component";
import { useSelector } from "react-redux";

import {
  selectAuditCAPStatus,
  selectAuditIssueDetails,
} from "../../selectors/audit.selector";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { AuditStyles } from "../../styles";

import AuditCapDetailsStatusOpen from "./auditCapDetailsStatusOpen.component";

import { useTenant } from "../../context/TenantContext";
import InternalNote from "./ineternalNote.component";
import ClientCodeMatchAndPotentialMatch from "../../tenants/stitchfix/clientCodeMatchAndPotentialMatch.component";

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);

const AuditReport = () => {
  const { auditConfig } = useTenant();

  const data = useSelector(selectAuditIssueDetails);

  const mappingOfCAPStatusToStep = {
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    SITE_ADDED_ACTION_PLAN: 3,
    BRAND_APPROVED_PLAN: 4,
    SITE_ADDING_PROOF: 5,
    COMPLETED: 6,
  };
  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  const currentStep = mappingOfCAPStatusToStep[auditCAPStatus];

  const fieldsToIdentifierMapping = {
    issueSummary: data?.issueSummary || "-",
    clientCodeTitle:
      data?.clientCodeTitle && data?.clientCodeTitle.trim().length > 0
        ? data?.clientCodeTitle
        : "-",
    category: data?.type || "-",
    subType: data?.subType || "-",
    repeatFinding: data?.repeatedIssue || "-",
    findingDetails: data?.issueDetail || "-",
    auditBasis: data?.auditBasis || "-",
    clientCode: data?.clientCode || "-",
    severity: data?.severity || "-",
    remediationTimeline: data?.timeline || "-",
    ratingExplanation: data?.ratingExplanation || "-",
  };
  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ p: 2 }}>
        {/* Header Section */}
        <Typography
          sx={{
            ...AuditStyles.auditReportTitle,
          }}
          gutterBottom
        >
          {auditConfig?.findingDetailsSection?.fields?.header?.fieldName}
        </Typography>
        {auditConfig?.findingDetailsSection?.fields?.header?.displayValue && (
          <Typography
            sx={{
              ...AuditStyles.auditReportTitle24,
            }}
          >
            {
              fieldsToIdentifierMapping[
                auditConfig?.findingDetailsSection?.fields?.header?.identifier
              ]
            }
          </Typography>
        )}

        {/* Details Section */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {auditConfig?.findingDetailsSection?.fields?.issueLevelFields?.map(
            (field) => (
              <Grid item xs={field.width}>
                <Typography sx={AuditStyles.fieldTitle}>
                  {field.fieldName}
                </Typography>
                {field.isStitchfix ? (
                  <ClientCodeMatchAndPotentialMatch data={data} />
                ) : (
                  <Typography sx={AuditStyles.auditReportFieldValue}>
                    {fieldsToIdentifierMapping[field.identifier]}
                  </Typography>
                )}
              </Grid>
            )
          )}
        </Grid>

        <Grid container spacing={2} sx={{ my: 2 }}>
          {auditConfig?.findingDetailsSection?.fields?.aiGeneratedFieldsAfterBrandCodeMatch?.map(
            (field) => (
              <Grid item xs={field.width} sx={{ mb: 2 }}>
                <AiGeneratedField label={field.fieldName}>
                  <Typography>
                    {field.renderComponent === "severity" ? (
                      <CustomChipLabel severity={data.severity} />
                    ) : field.renderComponent === "remediationTimeline" ? (
                      <Typography
                        sx={{
                          ...AuditStyles.auditReportFieldValue,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          mr: 2,
                        }}
                      >
                        {data?.timeline || "-"}
                      </Typography>
                    ) : field.renderComponent === "ratingExplanation" ? (
                      <Typography
                        sx={{
                          color: "#45464E",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {data?.ratingExplanation || "-"}
                      </Typography>
                    ) : field.renderComponent === "internalNote" ? (
                      <InternalNote />
                    ) : (
                      <></>
                    )}
                  </Typography>
                </AiGeneratedField>
              </Grid>
            )
          )}
        </Grid>

        <AuditCapDetailsStatusOpen data={data} currentStep={currentStep} />
      </Box>
    </Container>
  );
};

export default AuditReport;
