import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
  Chip,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import CustomChipLabel from "../../../components/customChipLable.component";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAuditIssueCAPData,
  selectAuditData,
} from "../../../selectors/audit.selector";
import { convertStatusToNormal } from "../../../utils/lib";
import { setSelectedCAPManagementIssueId } from "../../../reducers/audit.reducer";
import { ComponentStyles } from "../../../styles";
import { updateAuditData } from "../../../reducers/audit.reducer";
import moment from "moment";

const CapManagementTable = () => {
  const dispatch = useDispatch();
  const auditData = useSelector(selectAuditData);
  const issuesData = useSelector(selectAuditIssueCAPData);
  const [entireAuditCAPStatus, setEntireAuditCAPStatus] = useState(
    auditData?.capManagementStatus || "OPEN"
  );
  const [capManagedByAuditFirm, setCapManagedByAuditFirm] = useState(
    auditData?.metadata.capManagedByAuditFirm || false
  );
  const [capStartDate, setCapStartDate] = useState(
    auditData?.metadata.capStartDate
      ? moment(auditData.metadata.capStartDate)
      : null
  );
  const [capClosureDate, setCapClosureDate] = useState(
    auditData?.metadata.capClosureDate
      ? moment(auditData.metadata.capClosureDate)
      : null
  );
  const [capNote, setCapNote] = useState(auditData?.metadata.capNote || "");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    if (auditData?.metadata) {
      setCapManagedByAuditFirm(
        auditData?.metadata.capManagedByAuditFirm || false
      );
      setCapStartDate(
        auditData?.metadata.capStartDate
          ? moment(auditData.metadata.capStartDate)
          : null
      );
      setCapClosureDate(
        auditData?.metadata.capClosureDate
          ? moment(auditData.metadata.capClosureDate)
          : null
      );
      setCapNote(auditData?.metadata.capNote || "");
      setEntireAuditCAPStatus(auditData?.capManagementStatus || "OPEN");
    }
  }, [auditData]);

  const handleEntireAuditCAPStatusChange = (event) => {
    const newStatus = event.target.value;
    setEntireAuditCAPStatus(newStatus);

    let messages = [];

    // Set current date as CAP Start Date if changing to IN_PROGRESS or CLOSED and no start date exists
    if (
      (newStatus === "IN_PROGRESS" || newStatus === "CLOSED") &&
      !capStartDate
    ) {
      setCapStartDate(moment());
      messages.push("CAP Start Date has been automatically set to today");
    }

    // Set current date as CAP Closure Date if changing to CLOSED and no closure date exists
    if (newStatus === "CLOSED" && !capClosureDate) {
      setCapClosureDate(moment());
      messages.push("CAP Completion Date has been automatically set to today");
    }

    // Show snackbar if any dates were auto-populated
    if (messages.length > 0) {
      setSnackbar({
        open: true,
        message: messages.join(". "),
        severity: "info",
      });
    }
  };

  const handleCapManagedByAuditFirmChange = (event) => {
    setCapManagedByAuditFirm(event.target.value === "Yes");
  };

  const handleCapStartDateChange = (newDate) => {
    setCapStartDate(newDate);
  };

  const handleCapClosureDateChange = (newDate) => {
    setCapClosureDate(newDate);
  };

  const handleCapNoteChange = (event) => {
    setCapNote(event.target.value);
  };

  const handleSubmitEntireAuditCAPStatus = () => {
    if (entireAuditCAPStatus === "OPEN") {
      setCapManagedByAuditFirm(false);
    }

    try {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes: {
            capManagementStatus: entireAuditCAPStatus,
            capManagedByAuditFirm: capManagedByAuditFirm,
            capStartDate: capStartDate,
            capClosureDate: capClosureDate,
            capNote: capNote,
          },
        })
      );
      setSnackbar({
        open: true,
        message: "Changes saved successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error saving changes:", error);
      setSnackbar({
        open: true,
        message: "Error saving changes",
        severity: "error",
      });
    }
  };

  const handleClick = (id) => {
    dispatch(setSelectedCAPManagementIssueId(id));
  };

  const renderStatusCell = (params) => {
    const status = params.value;
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomChipLabel cap={status} capLabel={status} />
      </div>
    );
  };

  const renderCheckmark = (params) => {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleOutlineIcon
          sx={{
            color: params.value ? "#039855" : "#D0D5DD",
            fontSize: "24px",
          }}
        />
      </div>
    );
  };

  const renderDetailsButton = (params) => {
    return (
      <IconButton
        className="details-button"
        onClick={() => handleClick(params.row.id)}
        sx={{
          backgroundColor: "white",
          padding: 0,
          margin: 0,
          height: "100%",
          width: "100%",
          visibility: "hidden",
        }}
      >
        <OpenInBrowserIcon className="icon" />
        {/* <Typography variant="caption" sx={{ ml: 1 }}>
          See detail
        </Typography> */}
      </IconButton>
    );
  };

  const columns = [
    {
      field: "issue",
      headerName: "Finding",
      minWidth: 200,
      flex: 1.2,
      sortable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", lineHeight: "1.5" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "detailsButton",
      headerName: "",
      minWidth: 30,
      renderCell: renderDetailsButton,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) =>
        renderStatusCell({
          value: convertStatusToNormal(params.value || "Open"),
        }),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "dueInDays",
      headerName: "Timeline",
      minWidth: 130,
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {params.row.status === "Closed"
            ? "-"
            : params.value <= 0
            ? `Past Due ${-params.value} Days`
            : `Due in ${params.value} Days`}
        </div>
      ),
    },
    {
      field: "supplierFillInCAP",
      headerName: "Supplier CAP Entry",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) =>
        renderCheckmark({ value: params.row.capStatus?.isSupplierFillinCAP }),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "brandApproveCAP",
      headerName: "4imprint CAP Entry Approval",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) =>
        renderCheckmark({ value: params.row.capStatus?.isBrandApproveCAP }),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "supplierProvideCapProof",
      headerName: "Supplier CAP Proof",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) =>
        renderCheckmark({
          value: params.row.capStatus?.isSupplierProvideCapProof,
        }),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "brandApproveCapProof",
      headerName: "4imprint CAP Proof Approval",
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) =>
        renderCheckmark({
          value: params.row.capStatus?.isBrandApproveCAPProof,
        }),
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
  ];

  return (
    <Box
      elevation={1}
      sx={{
        borderTop: "0px",
        px: 5,
        my: 10,
      }}
    >
      {auditData?.capManagementStatus === "OPEN" ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              CAP Management Process
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Card
              variant="outlined"
              style={{
                ...ComponentStyles.twoLineThreeLineCard,
                width: "100%",
                // maxWidth: '500px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "24px",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                The CAP process has not been started
              </Typography>
            </Card>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 4, mb: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              CAP Management Process
            </Typography>
            {auditData?.capManagementStatus === "CLOSED" && (
              <Chip
                label="CAP Completed"
                sx={{
                  backgroundColor: "#ECFDF3",
                  border: "1px solid #027A48",
                  borderColor: "#027A48",
                  color: "#027A48",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "8px 16px",
                  height: "auto",
                  "& .MuiChip-label": {
                    padding: "4px",
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              overflow: "hidden",
              mb: 6,
            }}
          >
            <Box
              sx={{
                width: "100%",
                overflowX: "auto",
              }}
            >
              <DataGrid
                rows={issuesData}
                columns={columns}
                getRowHeight={() => "auto"}
                hideFooter
                autoHeight
                disableColumnMenu
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnReorder
                disableColumnResize
                disableRowSelectionOnClick
                sortingMode="none"
                sx={{
                  border: "none",
                  minWidth: "1400px",
                  "& .MuiDataGrid-cell": {
                    borderRight: "none",
                    borderBottom: "0.5px solid #E0E0E0",
                    padding: "16px",
                    whiteSpace: "normal",
                    alignItems: "center",
                  },
                  "& .MuiDataGrid-main": {
                    width: "100%",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    overflow: "unset",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#FFFFFF",
                    borderBottom: "0.5px solid #E0E0E0",
                    whiteSpace: "normal",
                    "& .MuiDataGrid-columnHeader": {
                      borderRight: "none",
                      cursor: "default",
                      height: "unset !important",
                      maxHeight: "none !important",
                      padding: "16px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-iconButtonContainer": {
                        display: "none",
                      },
                      "& .MuiDataGrid-sortIcon": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitleContainer": {
                        padding: 0,
                        whiteSpace: "normal",
                        lineHeight: 1.3,
                        justifyContent: "center",
                        textAlign: "center",
                      },
                    },
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: 600,
                    color: "#344054",
                    whiteSpace: "normal",
                    lineHeight: 1.3,
                    textAlign: "center",
                  },
                  "& .MuiDataGrid-row": {
                    minHeight: "80px !important",
                    maxHeight: "unset !important",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "& .details-button": {
                        visibility: "visible",
                        animation: "fadeIn 0.2s ease-in",
                      },
                    },
                    "&.Mui-selected": {
                      backgroundColor: "transparent !important",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "transparent !important",
                    },
                  },
                  "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
                    {
                      outline: "none",
                    },
                  "@keyframes fadeIn": {
                    "0%": {
                      opacity: 0,
                    },
                    "100%": {
                      opacity: 1,
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}
      {auditData?.capManagementStatus === "OPEN" && <Divider sx={{ my: 3 }} />}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
        Control Settings
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 6,
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel id="entire-audit-cap-status-label">
            Audit CAP Status
          </InputLabel>
          <Select
            labelId="entire-audit-cap-status-label"
            value={entireAuditCAPStatus}
            label="Audit CAP Status"
            onChange={handleEntireAuditCAPStatusChange}
          >
            <MenuItem value="OPEN">Not Started</MenuItem>
            <MenuItem value="IN_PROGRESS">In-progress</MenuItem>
            <MenuItem value="CLOSED">Completed</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel id="cap-managed-by-audit-firm-label">
            CAP Managed By Audit Firm
          </InputLabel>
          <Select
            labelId="cap-managed-by-audit-firm-label"
            value={capManagedByAuditFirm ? "Yes" : "No"}
            label="CAP Managed By Audit Firm"
            onChange={handleCapManagedByAuditFirmChange}
            disabled={entireAuditCAPStatus === "OPEN"}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="CAP Start Date"
            value={capStartDate}
            onChange={handleCapStartDateChange}
            disabled={entireAuditCAPStatus === "OPEN"}
            slotProps={{
              textField: {
                sx: { minWidth: 220 },
              },
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="CAP Complete Date"
            value={capClosureDate}
            onChange={handleCapClosureDateChange}
            disabled={entireAuditCAPStatus !== "CLOSED"}
            slotProps={{
              textField: {
                sx: { minWidth: 220 },
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{ mt: 3, mb: 4 }}>
        <TextField
          fullWidth
          label="CAP Note"
          multiline
          rows={4}
          value={capNote}
          onChange={handleCapNoteChange}
          placeholder="Add any notes or comments about the CAP process"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "8px",
              },
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 2, mb: 3 }}>
        <Button
          variant="contained"
          onClick={handleSubmitEntireAuditCAPStatus}
          disabled={!entireAuditCAPStatus}
          sx={{
            backgroundColor: "#585aeb",
            textTransform: "none",
          }}
        >
          Save
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CapManagementTable;
