import React from "react";
import { Box, Tabs, Tab } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { setInsightPageTab } from "../../reducers/insights.reducer";
import { ComponentStyles } from "../../styles";
import { useTenant } from "../../context/TenantContext";
import { selectInsightPageTab } from "../../selectors/insights.selector";

const InsightsTabs = () => {
  const { insightsConfig } = useTenant();
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    dispatch(setInsightPageTab(newValue));
  };
  const selectedTab = useSelector(selectInsightPageTab);

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="insight tabs"
        variant="fullWidth"
        sx={ComponentStyles.tabBody}
      >
        {insightsConfig?.tabs?.map((tab) => (
          <Tab key={tab.tab} label={tab.label} value={tab.tab} />
        ))}
      </Tabs>
    </Box>
  );
};

export default InsightsTabs;
