import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryList } from "../../selectors/factory.selector";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import { ScatterChart } from "@mui/x-charts/ScatterChart";

const RiskScatterPlot = () => {
  const dispatch = useDispatch();
  const facilityList = useSelector(selectFactoryList);

  useEffect(() => {
    dispatch(getListOfAllFactories({}));
  }, []); 

  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [selectedSupplierId, setSeletectedSupplierId] = useState("");

  // Generate random spend values once and store them by facility ID
  const randomSpendValues = useMemo(() => {
    const values = {};
    // Function to generate random spend between 5 and 100 million dollars
    const generateRandomSpend = () => {
      return Math.floor(Math.random() * (100000000 - 5000000 + 1)) + 5000000;
    };
    
    // Pre-generate random values for all facilities
    facilityList.forEach(facility => {
      if (facility?.factoryId && !values[facility.factoryId]) {
        values[facility.factoryId] = generateRandomSpend();
      }
    });
    
    return values;
  }, [facilityList.length]); // Only regenerate if the number of facilities changes

  const data = facilityList.map((facility) => ({
    id: facility?.factoryId,
    x: facility?.auditScore,
    y: Number(((facility?.spend || randomSpendValues[facility?.factoryId] || 5000000) / 1000000).toFixed(2)),
    supplierId: facility?.supplierId,
    facilityName: facility?.name,
  }));

  const filteredData = data.filter((item) => item.x !== 0);

  const handleClick = (event, itemData) => {
    event.preventDefault();
    const facilityId = filteredData[itemData.dataIndex].id;
    const supplierId = filteredData[itemData.dataIndex].supplierId;
    setSelectedFacilityId(facilityId);
    setSeletectedSupplierId(supplierId);
  };

  useEffect(() => {
    dispatch(
      getFactoryById({
        supplierId: selectedSupplierId,
        factoryId: selectedFacilityId,
      })
    );
  }, [selectedSupplierId, selectedFacilityId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxHeight: "60vh",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#182230",
          fontWeight: 500,
          fontSize: "36px",
        }}
      >
        Site Risk Map
      </Typography>
      <Typography
        variant="caption"
        sx={{ color: "#667085", fontStyle: "italic", mb: 1 }}
      >
        Spend data is randomly generated
      </Typography>
      <Box sx={{ flex: 1, maxheight: "60vh", mb: 2 }}>
        <ScatterChart
          series={[
            {
              id: "facility",
              data: filteredData.map((facility) => ({
                x: facility.x,
                y: facility.y,
                id: facility.id,
                facilityName: facility.facilityName,
              })),
              valueFormatter: (item) => {
                return `${item.facilityName} | Audit Score: ${item.x} | Spend: ${item.y}`;
              },
              highlightScope: {
                highlighted: "item",
                faded: "global",
              },
              color: "#3538CD", // Changed the color of the dot to 3538CD
            },
          ]}
          grid={{ vertical: true, horizontal: true }}
          xAxis={[
            {
              label: "Audit Score",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: 100,
            },
          ]}
          yAxis={[
            {
              label: "Spend (Million)",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: 100, // Changed max to 100 million to accommodate the larger range
            },
          ]}
          sx={{
            ".MuiChartsAxis-left .MuiChartsAxis-line": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            ".MuiChartsAxis-left .MuiChartsAxis-tick": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onItemClick={(event, itemData) => handleClick(event, itemData)}
        />
      </Box>
    </Box>
  );
};

export default RiskScatterPlot;
