import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  selectAdditionalFactoryInformation,
  selectFactoryData,
} from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { FacilityStyles, PageStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTenant } from "../../context/TenantContext";

const AdditionalFactoryInformation = () => {
  const dataMigrationMode = false;
  const { factoryConfig } = useTenant();
  const [expanded, setExpanded] = React.useState(true);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const { factoryInfo, auditExists, auditProcessingDate } = useSelector(
    (state) =>
      selectAdditionalFactoryInformation(
        state,
        factoryConfig?.additionalInformation?.isReformation ?? false
      )
  );
  const selectedFactoryData = useSelector(selectFactoryData);
  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };

  const getColor = (color) => {
    if (!auditProcessingDate) {
      return "inherit";
    }

    const auditDate = new Date(auditProcessingDate);
    const dec12Date = new Date("2024-12-12");
    const dec19Date = new Date("2024-12-19");
    const jan6Date = new Date("2025-01-06");

    switch (color) {
      case "normal":
        return auditExists ? "#6566db" : "inherit";
      case "dec12":
        return auditExists && auditDate > dec12Date ? "#6566db" : "inherit";
      case "dec19":
        return auditExists && auditDate > dec19Date ? "#6566db" : "inherit";
      case "jan6":
        return auditExists && auditDate > jan6Date ? "#6566db" : "inherit";
      case "intake":
      default:
        return "inherit";
    }
  };

  return (
    <Card variant="outlined" sx={FacilityStyles.additionalInformationCard}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{ display: "inline" }}
            >
              {factoryConfig?.additionalInformation?.title}
            </Typography>
            <Tooltip
              title={`These data came from the latest audit on ${selectedFactoryData?.latestAudit?.metadata?.facilityData?.audit_end}`}
            >
              <InfoOutlinedIcon
                sx={{
                  marginLeft: "8px",
                  color: "#A0A5AD",
                  verticalAlign: "middle",
                }}
              />
            </Tooltip>
            {factoryConfig?.additionalInformation?.tabs.length > 0 && (
              <>
                <Box
                  sx={PageStyles.tierAndPrivateLabel}
                  size="small"
                  variant="outlined"
                >
                  <Typography variant="caption" color="#6566db">
                    Data from latest audit
                  </Typography>
                  {/* <Typography variant="caption" color="textSecondary">
                {auditExists ? "Data from latest audit" : "Data from intake form"}
                </Typography> */}
                </Box>
                <Box
                  sx={PageStyles.tierAndPrivateLabel}
                  size="small"
                  variant="outlined"
                >
                  <Typography variant="caption" color="textSecondary">
                    Data from intake form
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Button onClick={handleToggleExpand} size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Box>
        {expanded && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {dataMigrationMode ? (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Data Migration and cleaning in progress. This section is
                  temporarily disabled.
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ display: "inline-flex" }}
                    gap={2}
                  >
                    {factoryInfo
                      .filter(
                        (section) =>
                          factoryConfig?.additionalInformation?.checker[
                            section.key
                          ]
                      )
                      .map((section, index) => (
                        <Box
                          key={index}
                          variant="outlined"
                          sx={
                            FacilityStyles.additionalInformationSectionHeading
                          }
                        >
                          {section.heading}
                        </Box>
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    {factoryInfo
                      .filter(
                        (section) =>
                          factoryConfig?.additionalInformation?.checker[
                            section.key
                          ]
                      )
                      .map((section, sectionIndex) =>
                        section.values.map((item, itemIndex) => (
                          <Grid
                            item
                            xs={3}
                            key={`${sectionIndex}-${itemIndex}`}
                          >
                            <Typography
                              variant="body2"
                              color={
                                factoryConfig?.additionalInformation?.tabs
                                  .length > 0
                                  ? getColor(item.color)
                                  : "textSecondary"
                              }
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                            >
                              {factoryConfig?.additionalInformation?.tabs
                                .length > 0
                                ? formatValue(item.value)
                                : item.value}
                            </Typography>
                          </Grid>
                        ))
                      )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AdditionalFactoryInformation;
