import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, CircularProgress, Alert } from "@mui/material";
import { getQuestionnaires } from "../../../reducers/questionnaire.reducer";
import QuestionnaireAccordion from "./questionnaireAccordion.component";
import {
  selectQuestionnaires,
  selectQuestionnaireLoading,
  selectQuestionnaireError,
} from "../../../selectors/questionnaire.selector";

const QuestionnaireTable = () => {
  const dispatch = useDispatch();
  const questionnaires = useSelector(selectQuestionnaires);
  const loading = useSelector(selectQuestionnaireLoading);
  const error = useSelector(selectQuestionnaireError);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    dispatch(getQuestionnaires());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [loading, isInitialLoad]);

  const showLoading = loading || isInitialLoad;

  if (showLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "60vh",
          width: "100%",
        }}
      >
        <CircularProgress size={40} thickness={4} sx={{ color: "#6172F3" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%", mt: 2 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", mt: 2, mb: 4 }}>
      {questionnaires && questionnaires.length > 0 ? (
        questionnaires.map((questionnaire) => (
          <QuestionnaireAccordion
            key={questionnaire.id || questionnaire._id}
            questionnaire={questionnaire}
          />
        ))
      ) : (
        <Box
          py={4}
          textAlign="center"
          sx={{
            bgcolor: "#F9FAFB",
            borderRadius: "8px",
            border: "1px solid #EAECF0",
            p: 4,
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No questionnaires found.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Create your first questionnaire to get started.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default QuestionnaireTable;
