import React from 'react';
import { Card, CardContent, Typography, Box, Tooltip } from '@mui/material';

const CountryRiskCard = ({ country }) => {
  // Function to get color based on risk score
  const getScoreColor = (score, type) => {
    if (type === 'lrs') {
      if (score >= 8) return "#F04438"; // High risk - red
      if (score >= 6) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'cs') {
      if (score >= 7) return "#F04438"; // High risk - red
      if (score >= 5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'bs') {
      if (score >= 7) return "#F04438"; // High risk - red
      if (score >= 5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'fls') {
      if (score >= 2) return "#F04438"; // High risk - red
      if (score >= 1.5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    }
    return "#12B76A"; // Default green
  };

  const getRiskLevel = (score, type) => {
    if (type === 'lrs') {
      if (score >= 8) return "High Risk";
      if (score >= 6) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'cs') {
      if (score >= 7) return "High Risk";
      if (score >= 5) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'bs') {
      if (score >= 7) return "High Risk";
      if (score >= 5) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'fls') {
      if (score >= 2) return "High Risk";
      if (score >= 1.5) return "Medium Risk";
      return "Low Risk";
    }
    return "Low Risk"; // Default
  };

  const riskTypes = [
    { key: 'lrs', label: 'Labor Rights Score', description: 'Based on labor rights compliance and regulations' },
    { key: 'cs', label: 'Corruption Score', description: 'Based on corruption perception and governance indicators' },
    { key: 'bs', label: 'Bribery Score', description: 'Based on bribery risk matrix and business ethics' },
    { key: 'fls', label: 'Forced Labor Score', description: 'Based on global slavery index and human trafficking indicators' }
  ];

  return (
    <Card 
      sx={{ 
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        height: '100%'
      }}
    >
      <CardContent>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600, 
            mb: 2,
            color: "#101828"
          }}
        >
          {country.Country}
        </Typography>

        {riskTypes.map((riskType) => (
          country[riskType.key] && country[riskType.key] !== "" && (
            <Box key={riskType.key} sx={{ mb: 1.5 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Tooltip title={riskType.description} placement="top">
                  <Typography variant="body2" sx={{ color: "#667085" }}>
                    {riskType.label}
                  </Typography>
                </Tooltip>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontWeight: 600, 
                    color: getScoreColor(country[riskType.key], riskType.key) 
                  }}
                >
                  {getRiskLevel(country[riskType.key], riskType.key)}
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  height: 8, 
                  width: '100%', 
                  bgcolor: '#F2F4F7', 
                  borderRadius: 1,
                  mt: 0.5
                }}
              >
                <Box 
                  sx={{ 
                    height: '100%', 
                    width: `${(country[riskType.key] / 10) * 100}%`, 
                    bgcolor: getScoreColor(country[riskType.key], riskType.key), 
                    borderRadius: 1 
                  }} 
                />
              </Box>
              <Typography variant="caption" sx={{ color: "#667085", display: 'block', mt: 0.5 }}>
                Score: {country[riskType.key]}
              </Typography>
            </Box>
          )
        ))}
      </CardContent>
    </Card>
  );
};

export default CountryRiskCard;
