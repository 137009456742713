import loginReducer from "./login.reducer";
import supplierReducer from "./supplier.reducer";
import factoryReducer from "./factory.reducer";
import navigationReducer from "./navigation.reducer";
import auditReducer from "./audit.reducer";
import miscReducer from "./misc.reducer";
import riskReducer from "./risk.reducer";
import analyticsReducer from "./analytics.reducer";
import dueActionReducer from "./dueAction.reducer";
import userReducer from "./user.reducer";
import emailReducer from "./email.reducer";
import notificationReducer from "./notification.reducer";
import insightsReducer from "./insights.reducer";
import questionnaireReducer from "./questionnaire.reducer";

const rootReducer = {
  login: loginReducer,
  supplier: supplierReducer,
  factory: factoryReducer,
  navigation: navigationReducer,
  audit: auditReducer,
  misc: miscReducer,
  risk: riskReducer,
  analytics: analyticsReducer,
  dueAction: dueActionReducer,
  user: userReducer,
  email: emailReducer,
  notification: notificationReducer,
  insights: insightsReducer,
  questionnaire: questionnaireReducer,
};

export default rootReducer;
