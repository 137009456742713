import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierPageTab } from "../../selectors/supplier.selector";
import { setSupplierPageTab } from "../../reducers/supplier.reducer";
import { useTenant } from "../../context/TenantContext";

const SupplierTabs = () => {
  const { supplierConfig } = useTenant();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setSupplierPageTab(newValue));
  };
  const value = useSelector(selectSupplierPageTab);

  return (
    <Box sx={{ width: "100%", mt: 1, flexGrow: 1 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        {supplierConfig?.tabs?.map(
          (tab) =>
            tab.display && (
              <Tab
                key={tab.tab}
                value={tab.tab}
                label={tab.label}
                sx={{ textTransform: "none" }}
              />
            )
        )}
      </Tabs>
    </Box>
  );
};

export default SupplierTabs;
