import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import FactoryTableDataGrid from "../components/factoryTableDataGrid.component";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganisationId } from "../selectors/login.selector";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../reducers/factory.reducer";
import { getListOfAuditsInCapManagement } from "../reducers/audit.reducer";
import { useNavigate } from "react-router-dom";
import { selectActionNeededData } from "../selectors/factory.selector";

const InactiveFactories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organisationId = useSelector(selectOrganisationId);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({
          page: 1,
          limit: 999,
          offset: 0,
          organisationId,
        })
      );
      dispatch(getListOfAuditsInCapManagement({ organisationId }));
    }
  }, [organisationId, dispatch]);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const getActionNeededData = (factory) => {
    return selectActionNeededData.resultFunc({ factoryData: factory });
  };
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <FactoryTableDataGrid
          displayInactiveFactories={true}
          navigateToFactoryDetails={navigateToFactoryDetails}
          getActionNeededData={getActionNeededData}
          organisationId={organisationId}
        />
      </Box>
    </Box>
  );
};

export default InactiveFactories;
