import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Chip, Drawer, Skeleton } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useTenant } from "../../context/TenantContext";
import { selectFactoryList } from "../../selectors/factory.selector";
import { selectOrganisationId } from "../../selectors/login.selector";
import TopBreadcrumbs from "../topBreadcrumbs.component";
import { AuditStyles } from "../../styles";
import moment from "moment";
import { selectLoadingState } from "../../selectors/misc.selector";
import countryRiskScores from "../../data/country_risk_scores.json";

const FactoryRiskGrid = ({
  navigateToFactoryDetails,
  getActionNeededData,
  organisationId,
}) => {
  const { factoryConfig, nomenclature } = useTenant();
  const location = useLocation();
  const isLoading = useSelector(selectLoadingState);

  let factoriesList = useSelector(selectFactoryList);

  // Function to get color based on risk score
  const getScoreColor = (score, type) => {
    if (type === 'lrs') {
      if (score >= 8) return "#F04438"; // High risk - red
      if (score >= 6) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'cs') {
      if (score >= 7) return "#F04438"; // High risk - red
      if (score >= 5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'bs') {
      if (score >= 7) return "#F04438"; // High risk - red
      if (score >= 5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'fls') {
      if (score >= 2) return "#F04438"; // High risk - red
      if (score >= 1.5) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    } else if (type === 'combined') {
      if (score >= 6) return "#F04438"; // High risk - red
      if (score >= 4) return "#F79009"; // Medium risk - orange
      return "#12B76A"; // Low risk - green
    }
    return "#12B76A"; // Default green
  };

  const getRiskLevel = (score, type) => {
    if (type === 'lrs') {
      if (score >= 8) return "High Risk";
      if (score >= 6) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'cs') {
      if (score >= 7) return "High Risk";
      if (score >= 5) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'bs') {
      if (score >= 7) return "High Risk";
      if (score >= 5) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'fls') {
      if (score >= 2) return "High Risk";
      if (score >= 1.5) return "Medium Risk";
      return "Low Risk";
    } else if (type === 'combined') {
      if (score >= 6) return "High Risk";
      if (score >= 4) return "Medium Risk";
      return "Low Risk";
    }
    return "Low Risk"; // Default
  };

  // Filter and transform factory data
  let rows = factoriesList.map((factory) => {
    const actionNeededData = getActionNeededData ? getActionNeededData(factory) : {};
    const actionAlertTrigger = actionNeededData.triggers?.overallTriggered
      ? "Yes"
      : "No";
    
    // Find country risk data
    const countryName = factory?.location || "";
    const countryData = countryRiskScores.find(
      (country) => country.Country.toLowerCase() === countryName.toLowerCase()
    ) || {};
    
    // Risk rating cells
    const lrsRating = countryData.lrs || "-";
    const csRating = countryData.cs || "-";
    const bsRating = countryData.bs || "-";
    const flsRating = countryData.fls || "-";
    
    // Calculate combined risk score (average of all other scores)
    let combinedRiskScore = "-";
    
    // Only calculate if we have at least one valid score
    const validScores = [lrsRating, csRating, bsRating, flsRating].filter(score => score !== "-");
    if (validScores.length > 0) {
      const sum = validScores.reduce((acc, score) => acc + parseFloat(score), 0);
      combinedRiskScore = (sum / validScores.length).toFixed(1);
    }

    return {
      id: factory?.factoryId,
      factoryName: factory?.name,
      factoryIdFromMetadata: factory?.factoryIdFromMetadata || "-",
      country: factory?.location || "-",
      tier: factory?.tier || "-",
      supplierId: factory?.supplierId || "-",
      supplierName: factory?.supplierName || "-",
      supplierIdFromMetadata: factory?.supplierIdFromMetadata || "-",
      complianceStatus: factory?.metadata?.complianceStatus || "-",
      // Risk ratings
      lrs: lrsRating,
      cs: csRating,
      bs: bsRating,
      fls: flsRating,
      combinedRisk: combinedRiskScore,
      // Other fields
      businessIntensity:
        factory?.metadata?.processInfo?.businessIntensity || "Not Available",
      actionAlert: factory?.metadata?.actionAlert || "-",
      status: factory?.status || "-",
      productionStatus:
        factory?.metadata?.processInfo?.productionContractType || "-",
      vendorRating: factory?.metadata?.vendorRating || "Unrated",
      actionAlertTrigger: actionAlertTrigger,
      lastAuditDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate
        ? moment(
            factory.metadata.socialAssessmentInfo.lastAssessmentDate
          ).format("MMM. DD, YYYY")
        : "-",
      nextReportDate: factory?.metadata?.auditCadence?.nextAuditDate
        ? moment(factory.metadata.auditCadence.nextAuditDate).format(
            "YYYY-MM-DD"
          )
        : factory?.metadata?.nextReportDate
        ? moment(factory.metadata.nextReportDate).format("YYYY-MM-DD")
        : "N/A",
    };
  });

  // Risk score cell renderer
  const renderRiskScoreCell = (params, riskType) => {
    const value = params.value;
    if (value === "-") return <Typography>-</Typography>;
    
    // Only color code the combined risk column
    if (riskType === 'combined') {
      const color = getScoreColor(value, riskType);
      const riskLevel = getRiskLevel(value, riskType);
      
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: color,
              mr: 1
            }}
          />
          <Typography 
            variant="body2" 
            sx={{ 
              fontWeight: 600, 
              color: color 
            }}
          >
            {value} ({riskLevel})
          </Typography>
        </Box>
      );
    } else {
      // For other risk types, just show the value without color coding
      return (
        <Typography variant="body2">
          {value}
        </Typography>
      );
    }
  };

  // Define table columns with risk rating columns
  const columns = [
    {
      field: "factoryName",
      headerName: nomenclature?.factory?.fieldNameSingular || "Site",
      flex: 2,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            fontWeight: "600",
            color: "#101828",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: "#667085",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "lrs",
      headerName: "Labor Rights Score",
      flex: 1.5,
      renderCell: (params) => renderRiskScoreCell(params, 'lrs'),
    },
    {
      field: "cs",
      headerName: "Corruption Score",
      flex: 1,
      renderCell: (params) => renderRiskScoreCell(params, 'cs'),
    },
    {
      field: "bs",
      headerName: "Bribery Score",
      flex: 1,
      renderCell: (params) => renderRiskScoreCell(params, 'bs'),
    },
    {
      field: "fls",
      headerName: "Forced Labor Score",
      flex: 1,
      renderCell: (params) => renderRiskScoreCell(params, 'fls'),
    },
    {
      field: "combinedRisk",
      headerName: "Combined Risk",
      flex: 1.5,
      renderCell: (params) => renderRiskScoreCell(params, 'combined'),
    },
    {
      field: "details",
      headerName: "",
      flex: 0.8,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            if (navigateToFactoryDetails) {
              navigateToFactoryDetails(params.row.supplierId, params.row.id);
            }
          }}
          sx={{
            textTransform: "none",
            borderRadius: "8px",
            color: "#6172F3",
            borderColor: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
              borderColor: "#5667e2",
            },
          }}
        >
          Details
        </Button>
      ),
    }
  ];

  return (
    <Box sx={{ width: "100%", padding: "12px 0px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: "#101828",
          }}
        >
          Site Risk Grid
        </Typography>
      </Box>

      <Box
        sx={{
          height: "calc(100vh - 300px)",
          width: "100%",
          overflowX: "auto",
        }}
      >
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <DataGridPro
            rows={rows}
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: 'lrs', sort: 'desc' }],
              },
            }}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            getRowHeight={() => "auto"}
            sx={{
              borderRadius: "8px",
              padding: "16px",
              "& .MuiDataGrid-cell": {
                padding: "8px",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important",
                cursor: "pointer",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f0f0f0", // Light gray background
                color: "#333", // Dark text color
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "16px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
            onRowClick={(params) => {
              if (navigateToFactoryDetails) {
                navigateToFactoryDetails(params.row.supplierId, params.row.id);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default FactoryRiskGrid;
