import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography, Chip, Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectFactoryList } from "../../selectors/factory.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import moment from "moment";
import { selectDueCICaps } from "../../selectors/dueAction.selector";
import { getDueCICaps } from "../../reducers/dueAction.reducer";
import { useTenant } from "../../context/TenantContext";
const DueCIAuditsDataGrid = () => {
  const { dueActionConfig } = useTenant();
  const navigate = useNavigate();

  // FUNCITON TO CONVERT DAYS to YEARS MONTHS AND DAYs
  //   const convertDaysToYearsMonthsDays = (totalDays) => {
  //     const years = Math.floor(totalDays / 365);
  //     const remainingDaysAfterYears = totalDays % 365;
  //     const months = Math.floor(remainingDaysAfterYears / 30);
  //     const days = remainingDaysAfterYears % 30;

  //     const parts = [];
  //     if (years > 0) parts.push(`${years} ${years === 1 ? 'year' : 'years'}`);
  //     if (months > 0) parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
  //     if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);

  //     return parts.join(', ');
  //   };
  const selectedDueCICaps = useSelector(selectDueCICaps);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedDueCICaps) {
      dispatch(getDueCICaps());
    }
  }, [selectedDueCICaps]);

  const [severityCategories, setSeverityCategories] = useState(
    selectedDueCICaps?.[0]?.severityCategories
  );
  const rows = selectedDueCICaps?.map((cap) => {
    if (!severityCategories && cap.severityCategories) {
      setSeverityCategories(cap.severityCategories);
    }
    return {
      id: cap?.id,
      auditId: cap?.auditId,
      factoryName: cap?.factoryName,
      auditDate: cap?.auditDate,
      factoryId: cap?.factoryId,
      supplierId: cap?.supplierId,
      daysSinceAudit: cap?.daysSinceAudit,
      dueStatus:
        cap?.daysToCIDueDate > 0
          ? `Past Due ${cap?.daysToCIDueDate} Days`
          : `in ${cap?.daysToCIDueDate.toString().replace("-", "")} Days`,
      lastAuditDate: cap?.auditDate,
      type: cap?.type,
      subType: cap?.subType,
      issueTitle: cap?.issueTitle,
      severity: cap?.severity,
      timeline: cap?.timeline,
      ciDueDate: cap?.ciDueDate,
    };
  });

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);
    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;

      if (row?.daysSinceAudit) {
        const daysSinceAudit = row?.daysSinceAudit;

        return newFilters.some((filter) => {
          switch (filter) {
            case "pastDue":
              return daysSinceAudit > 0;
            case "due1to20":
              return daysSinceAudit <= 0 && daysSinceAudit >= -20;
            case "due20to60":
              return daysSinceAudit < -20 && daysSinceAudit >= -60;
            case "due60plus":
              return daysSinceAudit < -60;
            default:
              return true;
          }
        });
      }
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [selectedDueCICaps]);

  const columns = dueActionConfig?.ciAudit?.dataGrid?.columns?.map((col) => {
    if (col?.renderCell === "issueTitle") {
      return {
        ...col,
        renderCell: (params) => (
          <Typography
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              padding: "8px 0px 8px 8px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        ),
      };
    } else if (col?.renderCell === "dueStatus") {
      return {
        ...col,
        renderCell: (params) => (
          <Chip
            label={params.row.dueStatus}
            sx={{
              borderRadius: "8px",
              backgroundColor:
                params.row.daysSinceAudit > 0 ? "#DC6803" : "#EAECF0",
              color: params.row.daysSinceAudit > 0 ? "#FFF" : "#172B4D",
              fontWeight: "500",
            }}
          />
        ),
      };
    } else if (col?.renderCell === "severity") {
      return {
        ...col,
        renderCell: (params) => (
          <Typography
            sx={{
              color: severityCategories?.[params.value] || "#8B8D97",
              whiteSpace: "normal",
              wordWrap: "break-word",
              padding: "8px 0px 8px 16px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.value}
          </Typography>
        ),
      };
    } else if (col?.renderCell === "details") {
      return {
        ...col,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => {
              window.history.pushState(null, "", "/dueAction");

              navigate(
                `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}/audit/${params.row.auditId}`,
                { replace: true }
              );
            }}
            sx={{
              backgroundColor: "#4B68D4",
              color: "white",
              "&:hover": {
                backgroundColor: "#3A54B6",
              },
              textTransform: "none",
              width: "79px",
              height: "40px",
              ml: 4,

              borderRadius: "8px",
            }}
            size="small"
          >
            To Audit
          </Button>
        ),
      };
    }
    return col;
  });

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Audits Due
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGrid
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 70}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container

                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",

                            "& .MuiDataGrid-cell": {
                              // Target each cell

                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueCIAuditsDataGrid;
