import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@mui/material";
import { ComponentStyles, AuditStyles } from "../../styles";

const getVendorRatingColor = (rating) => {
  if (!rating) return { background: "#F0F0F0", text: "#000" }; // Default colors
  const lowerRating = rating.toLowerCase();
  if (lowerRating.includes("green"))
    return { background: AuditStyles.auditRatingColors.green };
  if (lowerRating.includes("yellow"))
    return { background: AuditStyles.auditRatingColors.yellow };
  if (lowerRating.includes("orange"))
    return { background: AuditStyles.auditRatingColors.orange };
  if (lowerRating.includes("red"))
    return { background: AuditStyles.auditRatingColors.red };
  return { background: "#F0F0F0", text: "#000" }; // Default colors
};

const FactoryStatusCard = ({ factoryData }) => {
  const vendorRating = factoryData?.metadata?.vendorRating || "-";
  const { background: vendorRatingBackground, text: vendorRatingText } =
    getVendorRatingColor(vendorRating);

  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        background: "#FFF",
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Facility Status
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table sx={{ flex: 1, alignItems: "flex-start" }}>
        <TableBody>
          <TableRow
            sx={{ verticalAlign: "top", "& > td": { pt: 0.5, pr: 0, pl: 0 } }}
          >
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Vendor Rating
              </Typography>
              <Box
                sx={{
                  background: vendorRatingBackground,
                  color: vendorRatingText,
                  textTransform: "none",
                  padding: "3px 8px",
                  fontSize: "12px",
                  fontWeight: 500,
                  borderRadius: "6px",
                  border: "1px solid #D0D5DD",
                  width: "fit-content",
                }}
              >
                {vendorRating}
              </Box>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Supply Chain Status
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.supplyChainStatus || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Compliance Status
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.complianceStatus || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Date of Authorization
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.miscDates
                  ?.complianceAuthorizationDate || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Facility Onboarding Status
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.onboardingStatus || "-"}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default FactoryStatusCard;
