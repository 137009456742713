import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  CardContent,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeWords, getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";
import {
  selectSupplierList,
  selectSupplierNameFromId,
} from "../../../selectors/supplier.selector";
import { ComponentStyles, FacilityStyles } from "../../../styles";
import { useTenant } from "../../../context/TenantContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getListOfAllSuppliers } from "../../../reducers/supplier.reducer";
import { countries } from "../../../utils/countryList";
import EditModalSwitchCases from "../../SupplierDetails/EditModals/editModalSwitchCases.component";

// import { updateSupplier } from '../../actions/supplierActions'; // Ensure you have this action
// import CommentSection from "../../commentSection.component";

const EditFactoryInformation = ({ open = false, onClose, internalData }) => {
  const { factoryConfig } = useTenant();
  const dispatch = useDispatch();

  const getDefaultDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? new Date().toISOString().split("T")[0]
      : date.toISOString().split("T")[0];
  };
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, internalData?.supplierId)
  );
  const suppliers = useSelector(selectSupplierList);
  useEffect(() => {
    if (!suppliers || suppliers.length === 0) {
      dispatch(
        getListOfAllSuppliers({
          limit: 999,
          offset: 0,
        })
      );
    }
  }, [dispatch]);
  const initData = {
    supplierName: supplierName || "-",

    tier: internalData?.metadata?.tier
      ? typeof internalData.metadata.tier === "number"
        ? internalData.metadata.tier
        : internalData.metadata.tier.split(" ")[1]
      : "-",
    alias: getValidData(internalData?.metadata?.alias),
    factoryId: internalData.metadata?.factoryId || "-",
    factoryType: internalData?.metadata?.processInfo?.supplierType || "-",
    streetAddress: internalData?.metadata?.streetAddress || "-",
    stateProvince: getValidData(internalData?.metadata?.stateProvince),

    status: getValidData(internalData?.metadata?.status) || "-",
    addressLine1: internalData?.metadata?.addressLine1 || "-",
    zipCode: internalData?.metadata?.zipCode || "-",
    additionalContacts: Array.isArray(
      internalData?.metadata?.additionalContacts
    )
      ? internalData?.metadata?.additionalContacts
      : [],
    factorySizeToolTip: `Orginal data from audit is: ${
      internalData?.latestAudit?.metadata?.facilityData?.site_size || "-"
    }`,
    factorySize: internalData?.latestAudit?.metadata?.facilityData
      ? `${internalData.latestAudit.metadata.facilityData.standardized_site_size} ft²`
      : "-",
    factoryIdFromMetadata: internalData?.metadata?.factoryId || "-",
    reporting: internalData?.metadata?.reportingCheck === true ? "Yes" : "No",
    domesticOrInternational:
      internalData?.metadata?.domesticOrInternational || "-",
    deMinimisCheck: internalData?.metadata?.deMinimis?.deMinimisCheck || "-",
    deMinimisYear: internalData?.metadata?.deMinimis?.deMinimisYear || "-",
    subcontractorUsed:
      internalData?.metadata?.processInfo?.productionContractType?.includes(
        "Subcontractor"
      )
        ? "Yes"
        : "No",
    subcontractorDetails:
      internalData?.metadata?.processInfo?.subcontractorDetails || "-",

    contactName:
      internalData?.metadata?.primaryFacilityContactPerson?.name || "-",
    contactPosition:
      internalData?.metadata?.primaryFacilityContactPerson?.position || "-",
    contactPersonPhone: internalData?.metadata?.contactPerson?.phone || "-",
    contactPersonEmail: internalData?.metadata?.contactPerson?.email || "-",
    contactPersonPosition:
      internalData?.metadata?.contactPerson?.position || "-",
    contactPhone:
      internalData?.metadata?.primaryFacilityContactPerson?.phone || "-",
    contactEmail:
      internalData?.metadata?.primaryFacilityContactPerson?.email || "-",
    contactPerson:
      internalData?.metadata?.contactPerson?.name ||
      internalData?.metadata?.contactPerson ||
      "-",
    agentName: internalData?.metadata?.agentContact?.name || "N/A",
    agentEmail: internalData?.metadata?.agentContact?.email || "-",
    agentPhone: internalData?.metadata?.agentContact?.phone || "-",
    agentAddress: internalData?.metadata?.agentContact?.address || "-",

    supplyChainContact: internalData?.metadata?.supplyChainContact || "-",
    cityStateCountry: `${internalData?.metadata?.city || "-"}, ${
      internalData?.metadata?.stateProvince || "-"
    }, ${internalData?.metadata?.country || "-"}`,
    lastSocialAssessmentPayer:
      internalData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer ||
      "-",
    lastSocialAssessmentType:
      internalData?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType ||
      "-",
    lastSocialAssessmentDate:
      internalData?.metadata?.socialAssessmentInfo?.lastAssessmentDate || "-",
    lastSocialAssessmentFirm:
      internalData?.metadata?.socialAssessmentInfo?.lastAssessmentFirm || "-",
    nextVisitType:
      internalData?.metadata?.socialAssessmentInfo?.nextVisitType || "-",
    nextVisitDate:
      internalData?.metadata?.socialAssessmentInfo?.nextVisitDate || "-",
    nextVisitServiceProvider:
      internalData?.metadata?.socialAssessmentInfo?.nextVisitServiceProvider ||
      "-",
    agreementType: internalData?.metadata?.agreementType || "N/A",
    accountManager: internalData?.metadata?.productSupport?.name || "N/A",
    city: internalData?.metadata?.city || "N/A",
    country: getValidData(internalData?.metadata?.country) || "N/A",
    reporting: internalData?.metadata?.reportingCheck === true ? "Yes" : "No",
    domesticOrInternational:
      internalData?.metadata?.domesticOrInternational || "-",
    primatyFactoryContactPhone:
      internalData?.metadata?.primaryFacilityContactPerson?.phone || "-",
    primaryFactoryContactEmail:
      internalData?.metadata?.primaryFacilityContactPerson?.email || "-",
    confirmedNextAuditDate: getValidData(
      internalData?.metadata?.confirmedNextAuditDate
    ),
    presetAuditDueDate: internalData?.metadata?.initialPresetDueDate
      ? new Date(
          internalData?.metadata?.initialPresetDueDate.split("/").join("-")
        )
          .toISOString()
          .split("T")[0]
      : "-",
    productCategories: getValidData(internalData?.metadata?.productCategories),
    productProduced: getValidData(internalData?.metadata?.productProduced),
    productSubcategories: getValidData(
      internalData?.metadata?.productSubcategories
    ),
    privateLabel: getValidData(internalData?.metadata?.privateLabel),
    contractor: getValidData(internalData?.metadata?.contractor, false),
    smallFactory: getValidData(internalData?.metadata?.smallFactory, false),
    spend: getValidData(internalData?.metadata?.spend),
    address: capitalizeWords(
      getValidData(internalData?.metadata?.address, "No Address Provided")
    ),
    agent: getValidData(internalData?.metadata?.agent, ""),
    supplierIds: [
      internalData?.supplierId, // Include the main supplierId
      ...(internalData?.supplierIds || []), // Include additional supplierIds if they exist
    ].filter((id, index, self) => id && self.indexOf(id) === index), // Remove duplicates and null values
    nextAuditDate: getDefaultDate(internalData?.metadata?.nextReportDate),
    businessUnit: getValidData(internalData?.businessUnit, ""),
    factoryApprovalDate: internalData?.metadata?.factoryApprovalDate
      ? new Date(internalData.metadata.factoryApprovalDate)
          .toISOString()
          .split("T")[0]
      : "",
    tier: getValidData(internalData?.metadata?.tier, ""),
  };
  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [internalData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: internalData?.supplierId,
          factoryId: internalData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = [...formData.additionalContacts];
    newContacts[index] = {
      ...newContacts[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  const addContact = () => {
    const newContacts = Array.isArray(formData.additionalContacts)
      ? formData.additionalContacts
      : [];
    setFormData({
      ...formData,
      additionalContacts: [
        ...newContacts,
        {
          contactPersonName: "",
          contactPersonPosition: "",
          contactPersonPhone: "",
          contactPersonEmail: "",
        },
      ],
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={FacilityStyles.editModalBox}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {factoryConfig?.editMode?.generalInformation?.display && (
              <Grid
                item
                xs={12}
                md={factoryConfig?.editMode?.generalInformation?.width || 7}
              >
                <Card sx={FacilityStyles.editModalCard}>
                  <>
                    {" "}
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: 700,
                            fontSize: 16,
                            color: "#344054",
                            display: "inline-block",
                          }}
                        >
                          Edit general site information
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      {Object.entries(
                        factoryConfig?.editMode?.generalInformation?.fields
                      ).map(([field, value]) => (
                        <EditModalSwitchCases
                          field={field}
                          value={value}
                          formData={formData}
                          setFormData={setFormData}
                          addContact={addContact}
                          handleChange={handleChange}
                          initData={initData}
                          suppliers={suppliers}
                          countries={countries}
                          supplierName={supplierName}
                        />
                      ))}
                    </Grid>
                  </>

                  {factoryConfig?.editMode?.addNewContacts?.display && (
                    <>
                      <Divider sx={{ mt: 3, mb: 3 }} />
                      {formData?.additionalContacts &&
                        formData?.additionalContacts?.map((contact, index) => (
                          <EditModalSwitchCases
                            field={null}
                            value={null}
                            formData={formData}
                            setFormData={setFormData}
                            addContact={addContact}
                            handleChange={handleChange}
                            initData={initData}
                            suppliers={suppliers}
                            countries={countries}
                            supplierName={supplierName}
                            additionalContact={true}
                            contact={contact}
                            index={index}
                            handleContactChange={handleContactChange}
                          />
                        ))}
                      <Grid item xs={12}>
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={addContact}
                          sx={{
                            color: "#585aeb",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "rgba(88, 90, 235, 0.04)",
                            },
                          }}
                        >
                          Add Another Contact
                        </Button>
                      </Grid>
                    </>
                  )}

                  {factoryConfig?.editMode?.operationInformation?.display && (
                    <>
                      {" "}
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#344054",
                              display: "inline-block",
                            }}
                          >
                            Operational information
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        {Object.entries(
                          factoryConfig?.editMode?.operationInformation?.fields
                        ).map(([field, value]) => (
                          <EditModalSwitchCases
                            field={field}
                            value={value}
                            formData={formData}
                            setFormData={setFormData}
                            addContact={addContact}
                            handleChange={handleChange}
                            initData={initData}
                            suppliers={suppliers}
                            countries={countries}
                            supplierName={supplierName}
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                </Card>
              </Grid>
            )}

            {factoryConfig?.editMode?.confidentialInformation?.display && (
              <Grid
                item
                xs={12}
                md={
                  factoryConfig?.editMode?.confidentialInformation?.width || 5
                }
              >
                <Card sx={FacilityStyles.editModalCard}>
                  <>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: 700,
                            fontSize: 16,
                            color: "#344054",
                            display: "inline-block",
                          }}
                        >
                          Edit confidential business information
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      {Object.entries(
                        factoryConfig?.editMode?.confidentialInformation?.fields
                      ).map(([field, value]) => (
                        <EditModalSwitchCases
                          field={field}
                          value={value}
                          formData={formData}
                          setFormData={setFormData}
                          addContact={addContact}
                          handleChange={handleChange}
                          initData={initData}
                          suppliers={suppliers}
                          countries={countries}
                          supplierName={supplierName}
                        />
                      ))}
                    </Grid>
                  </>

                  {factoryConfig?.editMode?.additionalInformation?.display && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              color: "#344054",
                              display: "inline-block",
                            }}
                          >
                            Edit additional information
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        {Object.entries(
                          factoryConfig?.editMode?.additionalInformation?.fields
                        ).map(([field, value]) => {
                          if (value.isDropdown) {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  select
                                  fullWidth
                                  label={value.fieldName}
                                  name={field}
                                  variant="outlined"
                                  value={formData[field]}
                                  onChange={handleChange}
                                  margin="normal"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                >
                                  {value?.options?.map((option) => (
                                    <option value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                            );
                          } else if (value.isDisabled) {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label="Tier"
                                  name="tier"
                                  variant="outlined"
                                  value={formData.tier}
                                  onChange={handleChange}
                                  margin="normal"
                                  disabled
                                  SelectProps={{
                                    native: true,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          } else if (value.identifier === "spend") {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label="Spend"
                                  name="spend"
                                  variant="outlined"
                                  value={formData.spend || 0}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setFormData({
                                      ...formData,
                                      spend: value,
                                    });
                                  }}
                                  margin="normal"
                                  InputProps={{
                                    startAdornment: <span>$</span>,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid item xs={value.width}>
                                <TextField
                                  fullWidth
                                  label={value.fieldName}
                                  name={field}
                                  variant="outlined"
                                  value={formData[field]}
                                  onChange={handleChange}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderRadius: "8px", // Set the border-radius here
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </>
                  )}
                </Card>
              </Grid>
            )}
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditFactoryInformation;
