import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";

const ListCard = ({ data, type }) => {
  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#D92D20";
      case "critical":
        return "#FF692E";
      case "major":
        return "#FDB022";
      default:
        return "#45464E";
    }
  };

  return (
    <Table sx={{ px: 0, mx: 0, tableLayout: "auto" }}>
      <TableBody>
        <TableRow sx={{ borderBottom: "none" }}>
          {type === "factory" && (
            <TableCell
              sx={{
                borderBottom: "none",
                pl: 0,
                py: 0,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#8B8D97",
                }}
              >
                Location
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#45464E",
                }}
              >
                {data?.location || "-"}
              </Typography>
            </TableCell>
          )}
          <TableCell
            sx={{
              borderBottom: "none",
              py: 0,
              pl: type === "factory" ? 2 : 0,
              whiteSpace: "nowrap",
            }}
          >
            {type !== "factory" && (
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8B8D97",
                  }}
                >
                  Audit Date
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                  }}
                >
                  {data?.lastAuditDate
                    ? moment(data?.lastAuditDate).format("MMM. DD, YYYY")
                    : "-"}
                </Typography>
              </>
            )}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: "none",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              py: 0,
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              {type === "factory" ? (data?.metadata?.tier && data?.metadata?.tier !== "1" ? "Factory Tier" : "Next Audit In") : "Audit Type"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: type === "factory" && data?.nextReportDate && moment(data?.nextReportDate).diff(moment(), 'days') < 0 ? "red" : "#45464E",
              }}
              color={issuesColorScheme(data?.status)}
            >
              {type === "factory"
                ? data?.metadata?.tier && data?.metadata?.tier !== "1"
                  ? `Tier ${data.metadata.tier}`
                  : data?.nextReportDate
                  ? `${moment(data?.nextReportDate).diff(moment(), 'days')} Day`
                  : "-"
                : data?.auditType
                ? data?.auditType
                : "-"}
            </Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", py: 0, whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Issues
            </Typography>
            <Box
              variant="outlined"
              sx={{
                borderRadius: "6px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                padding: "3px 8px",
                color: "#344054",
                fontSize: "12px",
                fontWeight: 500,
                width: "fit-content",
              }}
            >
              {[
                parseInt(data?.ztCount || 0, 10),
                parseInt(data?.criticalCount || 0, 10),
                parseInt(data?.majorCount || 0, 10),
                parseInt(data?.minorCount || 0, 10),
              ].reduce((acc, count) => acc + count, 0).toString() || "-"}
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", py: 0, whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              ZT
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: issuesColorScheme(data?.ztCount ? "zt" : ""),
              }}
            >
              {data?.ztCount?.toString() || "-"}
            </Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", py: 0, whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Critical
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: issuesColorScheme(data?.criticalCount ? "critical" : ""),
              }}
            >
              {data?.criticalCount?.toString() || "-"}
            </Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", py: 0, whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Major
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: issuesColorScheme(data?.majorCount ? "major" : ""),
              }}
            >
              {data?.majorCount?.toString() || "-"}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: "none",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              py: 0,
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Minor
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: issuesColorScheme(data?.minorCount ? "minor" : ""),
              }}
            >
              {data?.minorCount?.toString() || "-"}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              borderBottom: "none",
              py: 0,
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Open CAP
            </Typography>

            <Box
              variant="contained"
              sx={{
                borderRadius: "6px",
                background: "#EAECF0",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                padding: "3px 8px",
                color: issuesColorScheme(data?.status),
                fontSize: "12px",
                fontWeight: 500,
                width: "fit-content",
              }}
            >
              {`${data?.openCAP}` || "-"}
            </Box>
          </TableCell>

          <TableCell sx={{ borderBottom: "none", py: 0, whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              Past Due CAP
            </Typography>

            <Box
              variant="contained"
              sx={{
                borderRadius: "6px",
                background: "#EAECF0",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                padding: "3px 8px",
                color: issuesColorScheme(data?.status),
                fontSize: "12px",
                fontWeight: 500,
                width: "fit-content",
              }}
            >
              {/* // TODO: Remove hardcoded stuff */}
              {"0"}
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default ListCard;
