import React, { createContext, useContext, useEffect, useState } from "react";
import tenantMetadata from "../common/constants/tenantMetadata.json";

const TenantContext = createContext();

export const TenantProvider = ({ tenantId, children }) => {
  const [tenantConfig, setTenantConfig] = useState(null);

  useEffect(() => {
    const config = tenantMetadata[tenantId] || tenantMetadata.default;
    setTenantConfig(config);
  }, [tenantId]);

  if (!tenantConfig) {
    return <div>Loading tenant configuration...</div>;
  }

  return (
    <TenantContext.Provider value={tenantConfig}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
