import React from "react";
import { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Card,
  Drawer,
  Modal,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectAuditPageTab,
  selectSelectedAuditIssueId,
  selectAuditData,
  selectSelectedCAPManagementIssueId,
  selectAuditQuickbaseData,
} from "../selectors/audit.selector";
import AuditInformation from "../components/AuditDetails/auditInformation.component";
import ListOfAuditDocuments from "../components/AuditDetails/listOfAuditDocuments.component";
import AuditTabs from "../components/AuditDetails/auditTabs.component";

import EditToggleSwitch from "../components/editToggleSwitch.component";
import AuditsTable from "../components/AuditDetails/auditsTable.component";

import AuditIssuesTable from "../components/AuditDetails/auditIssuesTable.component";
import AuditReportDetails from "./auditReportDetails.page";

import { toggleEditModeForAudit } from "../reducers/misc.reducer";
import { AuditStyles, PageStyles } from "../styles";
import {
  getAuditById,
  regenerateCap,
  setSelectedAuditIssueId,
  setSelectedCAPManagementIssueId,
} from "../reducers/audit.reducer";

import { selectSaveStatus } from "../selectors/misc.selector";
import { selectSupplierData } from "../selectors/supplier.selector";
import { getSupplierById } from "../reducers/supplier.reducer";
import { useTenant } from "../context/TenantContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import CapManagementTable from "../tenants/4imprint/capManagement/capManagementTable.component";
import CapManagementDetails from "../tenants/4imprint/capManagement/capMangementDetails.component";
import AuditDocumentDrop from "../components/AuditDetails/auditDocumentDrop.component";
import AuditSupportingDocuments from "../components/AuditDetails/auditSupportingDocuments.component";
import { selectFactoryData } from "../selectors/factory.selector";
import { selectIsAdmin } from "../selectors/login.selector";
import HistoricalAuditDataTab from "../tenants/reformation/historicalDataTab.component";
import IRSSTab from "../tenants/reformation/irssTab.component";
import AuditTabsReformation from "../tenants/reformation/auditTabsReformation.component";

const AuditDetails = () => {
  const { auditConfig } = useTenant();
  const params = useParams();
  const dispatch = useDispatch();
  // Drawer state management
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isCapDrawerOpen, setIsCapDrawerOpen] = React.useState(false);
  const adminUser = useSelector(selectIsAdmin);
  const isSomethingUnsaved = useSelector(selectSaveStatus);

  // Update the drawer based on the selectedAuditIssueId
  const selectedAuditIssueId = useSelector(selectSelectedAuditIssueId);
  const selectedFactoryData = useSelector(selectFactoryData);
  React.useEffect(() => {
    // Ensure the drawer opens only if there is a valid, non-null `selectedAuditIssueId`
    if (selectedAuditIssueId) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedAuditIssueId]);

  // Handle close drawer
  const handleDrawerClose = () => {
    if (isSomethingUnsaved === "updated") {
      alert(
        "You have unsaved changes on the page. Please save or discard them before closing."
      );
    } else {
      dispatch(setSelectedAuditIssueId(null));
      dispatch(toggleEditModeForAudit(false));
      setIsDrawerOpen(false);
    }
  };

  // CAP Management Drawer state management
  const selectedCAPManagementIssueId = useSelector(
    selectSelectedCAPManagementIssueId
  );

  React.useEffect(() => {
    // Ensure the drawer opens only if there is a valid, non-null selectedCAPManagementIssueId
    if (selectedCAPManagementIssueId) {
      setIsCapDrawerOpen(true);
    } else {
      setIsCapDrawerOpen(false);
    }
  }, [selectedCAPManagementIssueId]);

  // Handle close CAP drawer
  const handleCapDrawerClose = () => {
    dispatch(setSelectedCAPManagementIssueId(null));
    setIsCapDrawerOpen(false);
  };

  const selectedAuditData = useSelector(selectAuditData);

  const selectedTab = useSelector(selectAuditPageTab);
  // Define organisationId, auditId, supplierId, and factoryId
  const organisationId = params.organisationId;
  const auditId = params.auditId;
  const supplierId = params.supplierId;
  const factoryId = params.factoryId;
  const supplierData = useSelector(selectSupplierData);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);

  React.useEffect(() => {
    if (selectedAuditData == null) {
      dispatch(
        getAuditById({
          supplierId: supplierId,
          factoryId: factoryId,
          auditId: auditId,
        })
      );
    }
  }, []);

  // Handle regenerate export
  const handleRegenerateExport = () => {
    dispatch(regenerateCap({ organisationId, auditId, supplierId, factoryId }));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Check if the audit is processed. Hack for Ref.
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories
    ? true
    : false;

  const { isHistoricalAudit, qbInfo } = useSelector(selectAuditQuickbaseData);
  const showIRSSTab =
    selectedAuditData?.metadata?.irss?.irssCheck === "Yes"
      ? true
      : selectedAuditData?.risk?.auditRating
          ?.toLowerCase()
          .includes("orange", "red");
  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography
                gutterBottom
                sx={{
                  ...PageStyles.name,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <span>
                  {selectedAuditData?.metadata?.facilityData?.auditing_standard}{" "}
                  (
                  {moment(
                    selectedAuditData?.metadata?.facilityData?.audit_end
                  ).format("MMM. DD, YYYY")}
                  )
                </span>
                {auditConfig?.titleSection?.smallSite?.display &&
                  selectedFactoryData?.metadata?.smallFactory && (
                    <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                      Small Site
                    </Box>
                  )}
                <Box sx={{ display: "flex", gap: 1 }}>
                  {auditConfig?.titleSection?.reportReleasedByBrand
                    ?.display && (
                    <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                      {selectedAuditData?.metadata?.reportReleasedByBrand
                        ? "Published"
                        : "Not Published"}
                    </Box>
                  )}
                  {auditConfig?.titleSection?.capManagementStatus?.display &&
                    selectedAuditData?.capManagementStatus && (
                      <Box
                        sx={{
                          ...PageStyles.tierAndPrivateLabel,
                          ...(selectedAuditData.capManagementStatus ===
                            "CLOSED" && {
                            backgroundColor: "#ECFDF3",
                            border: "1px solid #027A48",
                            borderColor: "#027A48",
                            color: "#027A48",
                          }),
                        }}
                        size="small"
                        variant="outlined"
                      >
                        {selectedAuditData.capManagementStatus === "OPEN" &&
                          "CAP not started"}
                        {selectedAuditData.capManagementStatus ===
                          "IN_PROGRESS" && "CAP In-progress"}
                        {selectedAuditData.capManagementStatus === "CLOSED" &&
                          "CAP Completed"}
                      </Box>
                    )}
                </Box>
              </Typography>
              {selectedAuditData?.state &&
                selectedAuditData.state === "test" && (
                  <Typography color="red" sx={{ fontSize: "10px", mb: 1 }}>
                    Internal - Not Approved
                  </Typography>
                )}

              {auditConfig?.titleSection
                ?.displayEmailSupportForFeedbackButton && (
                <Button
                  variant="outlined"
                  sx={{ fontSize: "12px", color: "#3538CD", mt: 1 }}
                  href={`mailto:advait@elm-ai.com,eesha@elm-ai.com?subject=Stitchfix Audit Issue&body=Audit ID: ${auditId}%0D%0A%0D%0A---%0D%0Adescribe the issue here`}
                >
                  Email support for feedback or issues
                </Button>
              )}
            </Grid>
            {auditConfig?.editMode?.toggle && (
              <Grid item>
                <EditToggleSwitch />
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12}>
            <Card sx={PageStyles.auditContainerCard}>
              {selectedAuditData?.state === "deleted" ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={AuditStyles.deletedAuditBox}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "bold" }}
                      >
                        Platform Message
                      </Typography>
                      <Typography sx={AuditStyles.deletedAuditBanner}>
                        You have successfully delete this audit from the
                        platform. AuditSense AI will temporarily store the
                        deleted data for 30 days before permanently deleting it.
                        If you wish to recover the data or to permanently delete
                        the data immediately, please contact the Elm AI team.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  {(!auditIsProcessed ||
                    (selectedAuditData?.state == "test" && !adminUser)) && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#f0f0f0",
                          padding: "16px",
                          pl: "32px",
                          borderRadius: "4px",
                          marginBottom: "16px",
                        }}
                      >
                        <Typography sx={{ color: "#666", fontSize: "20px" }}>
                          The files for this audit have been uploaded but not
                          processed for AI result.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={
                      auditConfig?.generalInformation?.components
                        ?.auditInformation?.width || 7
                    }
                    xl={
                      auditConfig?.generalInformation?.components
                        ?.auditInformation?.width || 7
                    }
                  >
                    <AuditInformation />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={
                      auditConfig?.generalInformation?.components
                        ?.auditIssuesTable?.width || 5
                    }
                    xl={
                      auditConfig?.generalInformation?.components
                        ?.auditIssuesTable?.width || 5
                    }
                    sx={{ p: 2 }}
                  >
                    <AuditIssuesTable />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <AuditCapProgressStepper auditData={selectedAuditData} />
                </Grid> */}
                  <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                    {auditConfig.isReformation ? (
                      <AuditTabsReformation />
                    ) : (
                      <AuditTabs />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {selectedTab === "issues" ? (
                      <AuditsTable />
                    ) : selectedTab === "document" ? (
                      <AuditSupportingDocuments
                        organisationId={organisationId}
                        supplierId={params.supplierId}
                        factoryId={params.factoryId}
                        auditId={auditId}
                      />
                    ) : selectedTab === "cap" ? (
                      <CapManagementTable />
                    ) : selectedTab === "data" && isHistoricalAudit ? (
                      <HistoricalAuditDataTab qbInfo={qbInfo} />
                    ) : selectedTab === "irss" && showIRSSTab ? (
                      <IRSSTab
                        facilityName={selectedFactoryData?.name}
                        supplierName={
                          selectedFactoryData?.metadata?.supplierName
                        }
                      />
                    ) : (
                      <>
                        <Box sx={AuditStyles.regenerateExportBox}>
                          <Typography sx={AuditStyles.regenerateExportBanner}>
                            If the audit report is edited, click the button to
                            regenerate for the latest data.
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleRegenerateExport}
                            sx={AuditStyles.regenerateExportButton}
                          >
                            Regenerate Export
                          </Button>
                        </Box>
                        <ListOfAuditDocuments includeType="export" />
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={AuditStyles.auditFindingsDrawer}
      >
        <AuditReportDetails handleDrawerClose={handleDrawerClose} />
      </Drawer>

      {/* New CAP Details Drawer */}
      <Drawer
        anchor="bottom"
        open={isCapDrawerOpen}
        onClose={handleCapDrawerClose}
        sx={AuditStyles.auditFindingsDrawer}
      >
        <CapManagementDetails handleDrawerClose={handleCapDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default AuditDetails;
