import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  MenuItem,
  Select,
} from "@mui/material";

import moment from "moment";
import { ComponentStyles } from "../../styles";

const computeDueInDays = (date) => {
  return moment(date).diff(moment(), "days");
};

const getIrssColor = (irssEnrollmentTriggered, irssProgramEndTriggered) => {
  if (irssEnrollmentTriggered || irssProgramEndTriggered) return "#B42318"; // RED for triggered state
  return "#D0D5DD"; // GREY for non-triggered
};

const getIrssStatusText = (irssEnrollmentTriggered, irssProgramEndDate) => {
  if (irssEnrollmentTriggered) {
    return "Waiting to be enrolled";
  } else {
    const daysLeft = computeDueInDays(irssProgramEndDate);
    if (daysLeft > 21) return "Currently enrolled";
    if (daysLeft <= 0) return "Program ended";
    if (daysLeft < 21) return "Program ending soon";
  }
};

const getIrssAlertText = (irssEnrollmentTriggered, irssProgramEndDate) => {
  if (irssEnrollmentTriggered) {
    return "The facility needs to be enrolled in IRSS.";
  } else {
    const daysLeft = computeDueInDays(irssProgramEndDate);
    const programEndFromatted = moment(irssProgramEndDate).format("MMM. DD, Y");
    if (daysLeft > 21)
      return `The facility is currently in IRSS. Program end date: ${
        programEndFromatted || "N/A"
      }`;
    if (daysLeft <= 0) return "The program had ended.";
    if (daysLeft < 21)
      return `The program is ending soon. Program end date: ${
        programEndFromatted || "N/A"
      }`;
  }
};

const getLicenseColor = (dueInDays) => {
  if (dueInDays <= 0) return "#B42318"; // RED if expired
  if (dueInDays <= 21) return "#FF692E"; // ORANGE if expiring in <= 21 days
  return "#D0D5DD"; // GREY if not triggered
};

const getLicenseStatusText = (dueInDays) => {
  if (dueInDays <= 0) return `Expired ${Math.ceil(-dueInDays)} Days Ago`;
  return `Expiring In ${Math.ceil(dueInDays)} Days`;
};

const getLicenseAlertText = (dueInDays, licenseType) => {
  if (dueInDays <= 0) return `${licenseType} has expired.`;
  return `${licenseType} is expiring soon.`;
};

const getAuditColor = (dueInDays) => {
  return dueInDays <= 5 ? "#B42318" : "#D0D5DD"; // RED if audit <= 5 days away, GREY otherwise
};

const getAuditStatusText = (dueInDays) => {
  if (dueInDays <= 0) return `Was ${Math.ceil(-dueInDays)} Days Ago`;
  return `In ${Math.ceil(dueInDays)} Days`;
};

const getAuditAlertText = (dueInDays, nextAuditDate) => {
  if (dueInDays <= 0)
    return `OVERDUE. The audit was scheduled for ${nextAuditDate}. Please update.`;
  return `The next audit is scheduled for ${nextAuditDate}`;
};

const SimpleActionCard = ({
  type,
  title,
  rows,
  handleIrssChange,
  irssEnrollmentTriggered,
  irssProgramEndTriggered,
  irssProgramEndDate,
}) => {
  return (
    <Box
      elevation={1}
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        background: "#FFF",
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        mb: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table sx={{ flex: 1, alignItems: "flex-start" }}>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                verticalAlign: "top", // Align content to top
                "& > td": { pt: 1 }, // Add some padding to top of cells
              }}
            >
              <TableCell sx={{ borderBottom: "none", pl: 0, py: 0 }}>
                <Typography sx={ComponentStyles.fieldName}>Status</Typography>

                {/* IRSS Block */}
                {type === "irss" && (
                  <Box
                    sx={{
                      background: getIrssColor(
                        irssEnrollmentTriggered,
                        irssProgramEndTriggered
                      ),
                      color: "#FFF",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      width: "fit-content",
                    }}
                  >
                    {getIrssStatusText(
                      irssEnrollmentTriggered,
                      irssProgramEndDate
                    )}
                  </Box>
                  //   <Select
                  //     value={selectedIrssStatus}
                  //     onChange={(e) => handleIrssChange(e.target.value)}
                  //     displayEmpty
                  //     sx={{
                  //       background: getIrssColor(selectedIrssStatus, irssProgramEndDate, irssTriggered),
                  //       color: "#FFF",
                  //       padding: "3px 8px",
                  //       fontSize: "12px",
                  //       fontWeight: 500,
                  //       borderRadius: "6px",
                  //       border: "1px solid #D0D5DD",
                  //       width: "210px",
                  //       height: "28px",
                  //     }}
                  //   >
                  //     {irssOptions.map((option) => (
                  //       <MenuItem key={option} value={option}>
                  //         {option}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                )}

                {/* License Block */}
                {type === "license" && (
                  <Box
                    sx={{
                      background: getLicenseColor(row.dueInDays),
                      color: "#FFF",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      width: "fit-content",
                    }}
                  >
                    {getLicenseStatusText(row.dueInDays)}
                  </Box>
                )}

                {/* Upcoming Audit Block */}
                {type === "upcomingAudit" && (
                  <Box
                    sx={{
                      background: getAuditColor(row.dueInDays),
                      color: "#FFF",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      width: "fit-content",
                    }}
                  >
                    {getAuditStatusText(row.dueInDays)}
                  </Box>
                )}

                {/* Audit Not Confirmed Block */}
                {type === "auditNotConfirmed" && (
                  <Box
                    sx={{
                      background: "#B42318", // RED color to indicate urgent action
                      color: "#FFF",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      width: "fit-content",
                    }}
                  >
                    Window starts in {row.dueInDays} Days
                  </Box>
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...ComponentStyles.fieldName,
                  borderBottom: "none",
                  pb: 0,
                  width: "75%",
                  verticalAlign: "top", // Align content to top
                }}
              >
                <Typography sx={ComponentStyles.fieldName}>Alert</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {type === "irss" &&
                    getIrssAlertText(
                      irssEnrollmentTriggered,
                      irssProgramEndDate
                    )}
                  {type === "license" &&
                    getLicenseAlertText(row.dueInDays, row.alert)}
                  {type === "upcomingAudit" &&
                    getAuditAlertText(row.dueInDays, row.alert)}
                  {type === "auditNotConfirmed" && row.alert}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SimpleActionCard;
