import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuditInformationEditModal from "./EditModals/editAuditInformation.component";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTenant } from "../../context/TenantContext";
import { selectIsAdmin } from "../../selectors/login.selector";

const AuditInformation = () => {
  const { auditConfig } = useTenant();
  const params = useParams();
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);

  useEffect(() => {
    if (
      selectedAuditData &&
      ((selectedFactoryData?._id &&
        selectedAuditData?.factoryId !== selectedFactoryData?._id) ||
        !selectedFactoryData)
    ) {
      dispatch(
        getFactoryById({
          supplierId: params.supplierId,
          factoryId: selectedAuditData.factoryId,
        })
      );
    }
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData, selectedAuditData, dispatch, params.supplierId]);
  const adminUser = useSelector(selectIsAdmin);
  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };
  const getDisplayValue = (value) => {
    if (selectedAuditData?.state === "test" && !adminUser) {
      return "-";
    }
    return value || "-";
  };

  const auditDataKeyValuePairs = {
    auditStartDate: selectedAuditData?.metadata?.facilityData?.audit_start
      ? selectedAuditData?.metadata?.facilityData?.audit_start
      : selectedAuditData?.metadata?.auditStartDate
      ? moment(selectedAuditData.metadata.auditStartDate).format("MM/DD/YYYY")
      : "-",
    auditStartDateReformation: getDisplayValue(
      selectedAuditData?.metadata?.auditStartDate
        ? moment(selectedAuditData?.metadata?.auditStartDate).format(
            "MMM. DD, YYYY"
          )
        : "-"
    ),
    auditEndDate: selectedAuditData?.metadata?.facilityData?.audit_end
      ? selectedAuditData?.metadata?.facilityData?.audit_end
      : selectedAuditData?.metadata?.auditEndDate
      ? moment(selectedAuditData.metadata.auditEndDate).format("MM/DD/YYYY")
      : "-",
    auditEndDateReformation: getDisplayValue(
      selectedAuditData?.metadata?.auditEndDate
        ? `${moment(selectedAuditData?.metadata?.auditEndDate).format(
            "MMM. DD, YYYY"
          )}`
        : "-"
    ),
    auditorName: selectedAuditData?.metadata?.facilityData?.auditor_name
      ? selectedAuditData?.metadata?.facilityData?.auditor_name
      : selectedAuditData?.auditor,
    auditorNameReformation: getDisplayValue(
      selectedAuditData?.auditor || selectedAuditData?.metadata?.auditorsPrimary
    ),
    languageManagement: selectedAuditData?.metadata?.facilityData
      ?.language_management
      ? selectedAuditData?.metadata?.facilityData?.language_management
      : selectedAuditData?.metadata?.primaryLanguage,
    languageWorkers: selectedAuditData?.metadata?.facilityData?.language_workers
      ? selectedAuditData?.metadata?.facilityData?.language_workers
      : selectedAuditData?.metadata?.workersLanguage,
    auditFunder: selectedAuditData?.metadata?.auditFunder || "-",
    auditScheme:
      selectedAuditData?.metadata?.facilityData?.auditing_standard || "-",
    auditorFirm: selectedAuditData?.metadata?.facilityData?.auditing_firm
      ? selectedAuditData?.metadata?.facilityData?.auditing_firm
      : selectedAuditData?.metadata?.auditFirm || "-",
    address: selectedAuditData?.metadata?.facilityData?.facility_address,
    auditUploadDate: selectedAuditData?.createdAt,
    publishDate: selectedAuditData?.metadata?.reportReleasedDate,
    capStartDate:
      selectedAuditData?.metadata?.facilityData?.cap_start_date ||
      selectedAuditData?.createdAt,
    capManagedByAuditFirm:
      selectedAuditData?.metadata?.capManagedByAuditFirm || "-",
    capStartDateForPastDueCalculation: selectedAuditData?.metadata?.facilityData
      ?.cap_start_date
      ? moment(
          selectedAuditData?.metadata?.facilityData?.cap_start_date
        ).format("MMM. DD, YYYY")
      : `${moment(selectedAuditData?.metadata?.auditEndDate).format(
          "MMM. DD, YYYY"
        )} (Default as Audit Date)`,
    auditScore: selectedAuditData?.risk?.auditScore || "-",
    auditRating: selectedAuditData?.isHistoricalAudit
      ? selectedAuditData?.auditRatingClean ||
        selectedAuditData?.auditRating ||
        "-"
      : selectedAuditData?.risk?.auditRating || "-",

    vendorRating:
      selectedAuditData?.risk?.auditRatingAfterCAP ||
      selectedAuditData?.risk?.auditRating ||
      "-",
    auditScoreColorCondition: selectedAuditData?.risk?.auditScore
      ? selectedAuditData.risk.auditScore >= 81
        ? "#027A48" // Green
        : selectedAuditData.risk.auditScore >= 61
        ? "#B54708" // Yellow/Orange
        : "#B42318" // Red
      : "#101828", // Default color for "-"
    auditScoreRoots:
      selectedAuditData?.metadata?.facilityData?.audit_score === 100 ||
      !selectedAuditData?.metadata?.facilityData?.audit_score
        ? selectedAuditData?.risk?.auditRating || "-"
        : selectedAuditData?.metadata?.facilityData?.audit_score,
    siteDescription:
      selectedAuditData?.metadata?.facilityData?.site_description || "-",
    auditType: getDisplayValue(selectedAuditData?.metadata?.auditType),
    auditTypeReformation: getDisplayValue(
      selectedAuditData?.metadata?.auditTypeFrequency ||
        selectedAuditData?.metadata?.auditRange
    ),
    auditFunderReformation: getDisplayValue(
      selectedAuditData?.metadata?.auditFunder ||
        selectedAuditData?.metadata?.reportOwnerAndPayee
    ),
    mra: getDisplayValue(selectedAuditData?.metadata?.mra),
    irss: getDisplayValue(selectedAuditData?.metadata?.irss?.irssCheck),
    originalAuditScore: getDisplayValue(
      selectedAuditData?.metadata?.originalAuditScore
    ),
    lastAuditScheme: getDisplayValue(
      selectedFactoryData?.metadata?.last4Audits?.last4AuditTypes?.length > 1
        ? selectedFactoryData?.metadata?.last4Audits?.last4AuditTypes?.[
            (selectedFactoryData?.metadata?.last4Audits?.last4AuditTypes
              ?.length || 0) - 2
          ]
        : "-"
    ),
    lastAuditDate: getDisplayValue(
      selectedFactoryData?.metadata?.last4Audits?.last4AuditEndDates?.length > 1
        ? selectedFactoryData?.metadata?.last4Audits?.last4AuditEndDates?.[
            (selectedFactoryData?.metadata?.last4Audits?.last4AuditEndDates
              ?.length || 0) - 2
          ]
        : "-"
    ),
  };
  return (
    factoryData &&
    (!editMode ? (
      <Box sx={AuditStyles.containerBox}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={AuditStyles.containerGrid}
        >
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography
                sx={{
                  ...AuditStyles.headingsNameBold700,
                  display: "inline-block",
                  alignSelf: "center",
                }}
              >
                Audit Information
              </Typography>
              {auditConfig?.generalInformation?.components?.importedAuditTile
                ?.display &&
                selectedAuditData?.isHistoricalAudit && (
                  <Typography
                    sx={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #666",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: "0.875rem",
                      color: "#666",
                    }}
                  >
                    Imported Audit
                  </Typography>
                )}
            </Box>
          </Grid>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}
          <Grid item>
            {auditConfig?.generalInformation?.auditScore?.display && (
              <Card variant="outlined" style={AuditStyles.editModeCard}>
                <CardContent
                  style={{
                    padding: "4px",
                    paddingLeft: "32px",
                    paddingRight: selectedAuditData?.metadata
                      ?.originalAuditScore
                      ? "18px"
                      : "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography style={AuditStyles.headingsNameBold700}>
                    {auditConfig?.generalInformation?.auditScore?.fieldName ||
                      "Audit Score"}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "16px 16px" }}
                  />
                  <Typography
                    style={{
                      ...AuditStyles.bigHeadingFont30,
                      fontSize:
                        auditConfig?.generalInformation?.auditScore?.fontSize ||
                        "30px",
                      color: auditConfig?.generalInformation?.auditScore?.color
                        ? auditDataKeyValuePairs?.[
                            auditConfig?.generalInformation?.auditScore
                              ?.colorCondition
                          ]
                        : "inherit",
                    }}
                  >
                    {auditDataKeyValuePairs?.[
                      auditConfig?.generalInformation?.auditScore?.identifier
                    ] || "-"}
                  </Typography>
                  {selectedAuditData?.metadata?.originalAuditScore && (
                    <Tooltip
                      title={`The original score on the audit is ${selectedAuditData?.metadata?.originalAuditScore}`}
                    >
                      <InfoOutlinedIcon
                        sx={{ marginLeft: "8px", color: "#A0A5AD" }}
                      />
                    </Tooltip>
                  )}
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {auditConfig?.generalInformation?.components?.vendorScore
            ?.display && (
            <Grid item xs={6}>
              <Card variant="outlined" style={AuditStyles.editModeCard}>
                <CardContent
                  style={{
                    padding: "4px",
                    paddingLeft: "32px",
                    paddingRight: auditDataKeyValuePairs[
                      auditConfig?.generalInformation?.components?.vendorScore
                        ?.identifier
                    ]
                      ? "18px"
                      : "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography style={AuditStyles.headingsNameBold700}>
                    {auditConfig?.generalInformation?.components?.vendorScore
                      ?.fieldName || "Vendor Score"}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "16px 16px" }}
                  />
                  <Typography style={AuditStyles.headingsNameBold700}>
                    <Tooltip
                      title={`Numerical Score: ${
                        selectedAuditData?.risk?.auditScoreAfterCAP ||
                        selectedAuditData?.risk?.auditScore ||
                        "-"
                      }`}
                    >
                      <span>
                        {getDisplayValue(
                          auditDataKeyValuePairs[
                            auditConfig?.generalInformation?.components
                              ?.vendorScore?.identifier
                          ]
                        )}
                      </span>
                    </Tooltip>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}

          {auditConfig?.generalInformation?.components?.reformationAuditScore
            ?.display && (
            <Grid item xs={6}>
              <Card variant="outlined" style={AuditStyles.editModeCard}>
                <CardContent
                  style={{
                    padding: "4px",
                    paddingLeft: "32px",
                    paddingRight: selectedAuditData?.metadata
                      ?.originalAuditScore
                      ? "18px"
                      : "32px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography style={AuditStyles.headingsNameBold700}>
                    {auditConfig?.generalInformation?.components
                      ?.reformationAuditScore?.fieldName ||
                      "Reformation Audit Score"}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "16px 16px" }}
                  />
                  <Typography style={AuditStyles.headingsNameBold700}>
                    <Tooltip
                      title={`Numerical Score: ${
                        selectedAuditData?.risk?.auditScore ?? "-"
                      }`}
                    >
                      <span>
                        {getDisplayValue(
                          auditDataKeyValuePairs[
                            auditConfig?.generalInformation?.components
                              ?.reformationAuditScore?.identifier
                          ]
                        )}
                      </span>
                    </Tooltip>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* Company Address */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          {Object.entries(auditConfig?.generalInformation?.fields)
            .filter(([_, value]) => value.display)
            .map(([field, value]) => {
              if (field === "divider") {
                return (
                  <Grid item xs={value.width}>
                    <Divider />
                  </Grid>
                );
              }
              return (
                <Grid item xs={value.width}>
                  <Box display="flex" alignItems="center">
                    <Typography sx={ComponentStyles.fieldName}>
                      {value.fieldName}
                    </Typography>
                    {field === "factorySize" && value.tooltipIdentifier && (
                      <Tooltip
                        title={auditDataKeyValuePairs[value.tooltipIdentifier]}
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "8px",
                            color: "#A0A5AD",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Typography sx={ComponentStyles.fieldValue}>
                    {auditDataKeyValuePairs[field]}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    ) : (
      <AuditInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        factoryData={selectedFactoryData}
        auditData={selectedAuditData}
      />
    ))
  );
};

export default AuditInformation;
