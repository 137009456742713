import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import moment from "moment";

import { getSupplierById } from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import { selectFactoryList } from "../../selectors/factory.selector";

import { useTenant } from "../../context/TenantContext";

const FactoryInsightsMetaDataGrid = () => {
  const { insightsConfig } = useTenant();
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const rows = factoriesList.map((factory) => {
    return {
      id: factory?.factoryId ?? "", // Unique ID for the grid's row key
      factoryIdFromMetadata: factory?.factoryIdFromMetadata ?? "",
      factoryName: factory?.name ?? "",
      associatedSupplier: factory?.supplierName ?? "",
      status: factory?.metadata?.status ?? "",
      statusOfCompliance: factory?.metadata?.complianceStatus ?? "",
      tier: factory?.tier ?? "",
      completeAddress: factory?.metadata?.address ?? "",
      factoryAddressLine1: factory?.metadata?.addressLine1 ?? "",
      factoryCountry: factory?.metadata?.country ?? "",
      apparelNonApparel: factory?.metadata?.productCategories ?? "",
      factoryCity: factory?.metadata?.city ?? "",
      factoryState: factory?.metadata?.state ?? "",
      // reportingCheck: factory?.metadata?.reportingCheck ?? "",
      doNotReportCheck: factory?.metadata?.doNotReportCheck ?? "",
      deMinimisCheck:
        factory?.metadata?.deMinimis?.deMinimisCheck === false
          ? ""
          : factory?.metadata?.deMinimis?.deMinimisCheck ?? "",
      deMinimisYear: factory?.metadata?.deMinimis?.deMinimisYear ?? "",
      domesticOrInternational: factory?.metadata?.domesticOrInternational ?? "",
      vendorRating: factory?.metadata?.vendorRating ?? "",
      complianceAuthorizationDate: factory?.metadata?.miscDates
        ?.complianceAuthorizationDate
        ? moment(
            factory?.metadata?.miscDates?.complianceAuthorizationDate
          ).format("MMMM D, YYYY")
        : "",
      supplyChainStatus: factory?.metadata?.supplyChainStatus ?? "",
      supplyChainPriority: factory?.metadata?.supplyChainPriority ?? "",
      potentialInitialPODate: factory?.metadata?.potentialInitialPODate
        ? moment(factory?.metadata?.potentialInitialPODate).format(
            "MMMM D, YYYY"
          )
        : "",
      irssCheck: factory?.metadata?.irss?.check ?? "",

      lastAssessmentDate: factory?.metadata?.socialAssessmentInfo
        ?.lastAssessmentDate
        ? moment(
            factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate
          ).format("MMMM D, YYYY")
        : "",

      lastSocialAssessmentType:
        factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType ?? "",
      lastSocialAssessmentFirm:
        factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentFirm ?? "",
      lastSocialAssessmentPayer:
        factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer ??
        "",
      nextAssessmentWindowDateStart: factory?.metadata?.socialAssessmentInfo
        ?.nextAssessmentWindowDateStart
        ? moment(
            factory?.metadata?.socialAssessmentInfo
              ?.nextAssessmentWindowDateStart
          ).format("MMMM D, YYYY")
        : "",
      nextAssessmentWindowDateEnd: factory?.metadata?.socialAssessmentInfo
        ?.nextAssessmentWindowDateEnd
        ? moment(
            factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateEnd
          ).format("MMMM D, YYYY")
        : "",
      generalAuditType:
        factory?.metadata?.socialAssessmentInfo?.generalAuditType ?? "",
      generalAuditFirm:
        factory?.metadata?.socialAssessmentInfo?.generalAuditFirm ?? "",
      confirmedAuditDate: factory?.metadata?.socialAssessmentInfo
        ?.confirmedAuditDate
        ? moment(
            factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate
          ).format("MMMM D, YYYY")
        : "",
      socialAssessmentPayer:
        factory?.metadata?.socialAssessmentInfo?.socialAssessmentPayer ?? "",
      socialResponsibilitySignOff:
        factory?.metadata?.socialResponsibilitySignOff ?? "",
      environmentalResponsibilitySignOff:
        factory?.metadata?.environmentalResponsibilitySignOff ?? "",
      productCategories: factory?.metadata?.productCategories ?? "",
      productionProcesses:
        factory?.metadata?.processInfo?.productionProcesses ?? "",
      productionContractType:
        factory?.metadata?.processInfo?.productionContractType ?? "",
      supplyChainTeam: factory?.metadata?.supplyChainTeam ?? "",
      supplyChainContact: factory?.metadata?.supplyChainContact ?? "",
      facilityAffiliation: factory?.metadata?.facilityAffiliation ?? "",
      existingClients: factory?.metadata?.existingClients ?? "",
      peakSeason: factory?.metadata?.productionSeasonalityPeak ?? "",
      lowSeason: factory?.metadata?.productionSeasonalityLow ?? "",
      minOrderQty: factory?.metadata?.minOrderQty ?? "",
      avgLeadTime: factory?.metadata?.avgLeadTime ?? "",
      machineCount: factory?.metadata?.machineCount ?? "",
      avgUnitsProducedPerWeek: factory?.metadata?.avgUnitsProducedPerWeek ?? "",
      size: factory?.metadata?.size ?? "",
      domesticMigrantWorkers: factory?.metadata?.domesticMigrantWorkers ?? "",
      domesticMigrantWorkers: factory?.factoryInfofromLatestAudit
        ?.domestic_migrant_workers
        ? parseInt(factory.factoryInfofromLatestAudit.domestic_migrant_workers)
        : "",
      foreignMigrantWorkers: factory?.metadata?.foreignMigrantWorkers ?? "",
      foreignMigrantWorkers: factory?.factoryInfofromLatestAudit
        ?.foreign_migrant_workers
        ? parseInt(factory.factoryInfofromLatestAudit.foreign_migrant_workers)
        : "",
      homeWorkers: factory?.metadata?.homeWorkers ?? "",
      productionSeasonalityLow:
        factory?.metadata?.productionSeasonalityLow ?? "",
      productionSeasonalityLow:
        factory?.factoryInfofromLatestAudit?.production_seasonality_low ?? "",
      productionSeasonalityPeak:
        factory?.metadata?.productionSeasonalityPeak ?? "",

      homeWorkers: factory?.factoryInfofromLatestAudit?.home_workers
        ? parseInt(factory.factoryInfofromLatestAudit.home_workers)
        : "",

      productionSeasonalityPeak:
        factory?.factoryInfofromLatestAudit?.production_seasonality_peak ?? "",
      siteSize: factory?.metadata?.siteSize ?? "",
      workerCountTotal: factory?.metadata?.workerCountTotal ?? "",
      workerGenderFemale: factory?.metadata?.workerGenderFemale ?? "",
      workerGenderMale: factory?.metadata?.workerGenderMale ?? "",
      workerGenderOther: factory?.metadata?.workerGenderOther ?? "",
      youngWorkers: factory?.metadata?.youngWorkers ?? "",

      irssProgramEndDate: factory?.metadata?.irss?.programEndDate
        ? moment(factory?.metadata?.irss?.programEndDate).format("MMMM D, YYYY")
        : "",
      irssStatus: factory?.metadata?.irss?.status ?? "",
      supplierType: factory?.metadata?.processInfo?.supplierType ?? "",
      unionPresent: factory?.metadata?.freedomOfAssoc?.unionCheck ?? "",
      cbaPresent: factory?.metadata?.freedomOfAssoc?.cbaCheck ?? "",
      osId: factory?.metadata?.ids?.osId ?? "",
      factoryApprovalDate: factory?.metadata?.factoryApprovalDate
        ? moment(factory.metadata.factoryApprovalDate).format("YYYY-MM-DD")
        : "",
      materialProduced: factory?.metadata?.materialProduced ?? "",

      highStrikeRisk: factory?.metadata?.highStrikeRisk ?? "",
      agent: factory?.metadata?.agent ?? "",

      businessLine: factory?.businessUnit ?? "",
      brandType: factory?.metadata?.productCategories ?? "",
      countryOfOrigin: factory?.metadata?.country ?? "",
      factoryNickname: factory?.metadata?.alias ?? "",
      factoryAlias: factory?.metadata?.alias ?? "",
      factoryLegalName: factory?.name ?? "",
      factoryStatus: factory?.metadata?.status ?? "",
      vendorLegalName: factory?.supplierName ?? "",
      vendorStatus: factory?.metadata?.status ?? "",
      auditScore: factory?.riskScore ?? "",
      highRiskStrike: factory?.metadata?.highStrikeRisk ?? "",
      nextAudit: factory?.metadata?.nextReportDate
        ? moment(factory.metadata.nextReportDate).format("MM-YY")
        : "",
      auditStandard:
        factory?.factoryInfofromLatestAudit?.auditing_standard ||
        factory?.metadata?.auditType ||
        "",
      noOfWorkers: factory?.metadata?.workerCount ?? "",
      address1: factory?.metadata?.address ?? "",
      contractor: factory?.metadata?.contractor === true ? "Yes" : "",
      smallFactory: factory?.metadata?.smallFactory === true ? "Yes" : "",
      factoryAddress: factory?.address ?? "",
      associatedAddresses: factory?.metadata?.associatedAddress ?? "",
      contactName: factory?.contactPerson?.name ?? "",
      contactPosition: factory?.contactPerson?.position ?? "",
      contactPhone: factory?.contactPerson?.phone ?? "",
      contactEmail: factory?.contactPerson?.email ?? "",
      secondaryEmail: factory?.metadata?.additionalContacts
        ? factory?.metadata?.additionalContacts[0]?.contactPersonEmail
        : "",
      thirdEmail: factory?.metadata?.additionalContacts
        ? factory?.metadata?.additionalContacts[1]?.contactPersonEmail
        : "",
      fourthEmail: factory?.metadata?.additionalContacts
        ? factory?.metadata?.additionalContacts[2]?.contactPersonEmail
        : "",
      fifthEmail: factory?.metadata?.additionalContacts
        ? factory?.metadata?.additionalContacts[3]?.contactPersonEmail
        : "",

      privateLabel: factory?.metadata?.privateLabel === "Yes" ? "Yes" : "No",
      spend2023: factory?.metadata?.spend
        ? Number(factory.metadata.spend).toFixed(2)
        : "",
      formattedSpend2023: factory?.metadata?.spend
        ? `$${Number(factory.metadata.spend).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "",
      spend2024: factory?.metadata?.spend2024
        ? Number(factory.metadata.spend2024).toFixed(2)
        : "",
      formattedSpend2024: factory?.metadata?.spend2024
        ? `$${Number(factory.metadata.spend2024).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : "",
      nextAuditDueDate: factory?.metadata?.initialPresetDueDate
        ? moment(factory.metadata.initialPresetDueDate).format("YYYY-MM-DD")
        : "",
      calculatedNextAuditDueDate: factory?.auditCadence?.nextAuditDate
        ? moment(factory.auditCadence.nextAuditDate).format("YYYY-MM-DD")
        : "",
      lastAuditDate: factory?.lastAuditDatefromLatestAudit
        ? moment(factory.lastAuditDatefromLatestAudit).format("YYYY-MM-DD")
        : "",
      confirmedNextAuditDate: factory?.metadata?.confirmedNextAuditDate
        ? moment(factory.metadata.confirmedNextAuditDate).format("YYYY-MM-DD")
        : "",
      auditScore: factory?.auditScore ?? "",
      openFindings: factory?.issueDetails?.Open ?? "",
      pastDueFindings: factory?.issueDetails?.PastDue ?? "",
      criticalFindings: factory?.issueDetails?.Critical ?? "",
      majorFindings: factory?.issueDetails?.Major ?? "",
      minorFindings: factory?.issueDetails?.Minor ?? "",
      ztFindings: factory?.issueDetails?.ZT ?? "",
      closedFindings: factory?.issueDetails?.Closed ?? "",

      originalSiteSize: factory?.factoryInfofromLatestAudit?.site_size ?? "",
      standardizedSiteSize: factory?.factoryInfofromLatestAudit
        ?.standardized_site_size
        ? parseInt(factory.factoryInfofromLatestAudit.standardized_site_size)
        : "",
      workerCountAgency: factory?.factoryInfofromLatestAudit
        ?.worker_count_agency
        ? parseInt(factory.factoryInfofromLatestAudit.worker_count_agency)
        : "",
      workerCountPermanent: factory?.factoryInfofromLatestAudit
        ?.worker_count_permanent
        ? parseInt(factory.factoryInfofromLatestAudit.worker_count_permanent)
        : "",
      workerCountTemporary: factory?.factoryInfofromLatestAudit
        ?.worker_count_temporary
        ? parseInt(factory.factoryInfofromLatestAudit.worker_count_temporary)
        : "",
      workerCountTotal: factory?.factoryInfofromLatestAudit?.worker_count_total
        ? parseInt(factory.factoryInfofromLatestAudit.worker_count_total)
        : "",
      workerGenderFemale: factory?.factoryInfofromLatestAudit
        ?.worker_gender_female
        ? parseInt(factory.factoryInfofromLatestAudit.worker_gender_female)
        : "",
      workerGenderMale: factory?.factoryInfofromLatestAudit?.worker_gender_male
        ? parseInt(factory.factoryInfofromLatestAudit.worker_gender_male)
        : "",
      workerGenderOther: factory?.factoryInfofromLatestAudit
        ?.worker_gender_other
        ? parseInt(factory.factoryInfofromLatestAudit.worker_gender_other)
        : "",
      youngWorkers: factory?.factoryInfofromLatestAudit?.young_workers
        ? parseInt(factory.factoryInfofromLatestAudit.young_workers)
        : "",
      status: factory?.status ?? "",
      country: factory?.metadata?.country ?? "",
      zipCode: factory?.metadata?.zipCode ?? "",
      stateProvince: factory?.metadata?.stateProvince ?? "",
      streetAddress: factory?.metadata?.streetAddress ?? "",
      managementLanguage:
        factory?.factoryInfofromLatestAudit?.language_management ?? "",
      workerLanguage:
        factory?.factoryInfofromLatestAudit?.language_workers ?? "",
      supplierId: factory?.supplierId ?? "",
      factoryName: factory?.name ?? "",
      region: factory?.metadata?.region ?? "",
      city: factory?.metadata?.city ?? "",
      spend: factory?.metadata?.spend ?? "",
      formattedSpend: factory?.metadata?.spend
        ? `$${Number(factory.metadata.spend).toLocaleString("en-US")}`
        : "",
      lastAuditDate: factory?.lastAuditDateTimestamp
        ? moment(factory.lastAuditDateTimestamp).format("MMMM D, YYYY")
        : "",

      agreementType: factory?.metadata?.agreementType ?? "",
      productSupportName: factory?.productSupport?.name ?? "",
      productSupportEmail: factory?.productSupport?.email ?? "",
      businessUnit: factory?.businessUnit ?? "",
      tmls: factory?.tmls ?? "",
      sharedVBU: factory?.metadata?.sharedVBU ?? "",
      cmaConnection: factory?.metadata?.cmaConnection ?? "",
      influence: factory?.metadata?.influence ?? "",
    };
  });

  const columns = insightsConfig?.factoryDataGrid?.columns?.map((col) => {
    if (col?.renderCell === "click") {
      return {
        ...col,
        renderCell: (params) => (
          <Button
            variant="contained"
            onClick={() => {
              navigateToFactoryDetails(params.row.supplierId, params.row.id);
            }}
            sx={{
              backgroundColor: "#6172F3",
              color: "white",
              "&:hover": {
                backgroundColor: "#5667e2",
                fontWeight: "bold",
              },
              textTransform: "none",
              minWidth: "79px",
              height: "40px",
              borderRadius: "8px",
            }}
            size="small"
          >
            Details
          </Button>
        ),
      };
    } else if (col?.renderCell === "status") {
      return {
        ...col,
        valueFormatter: (params) =>
          params
            ? params.charAt(0).toUpperCase() + params.slice(1).toLowerCase()
            : "‑",
        renderCell: (params) =>
          params.value
            ? params.value.charAt(0).toUpperCase() +
              params.value.slice(1).toLowerCase()
            : "-",
      };
    } else if (col?.renderCell === "factoryIdFromMetadata") {
      return {
        ...col,
        valueFormatter: (params) => (params ? `${params}\u200B` : "‑"),
        renderCell: (params) => (params.value ? params.value : "-"),
      };
    }
    return col;
  });

  const dataGridFormat = {
    columns,
    rows,
  };

  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  // Add this new state for column visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // Add this state to track if we're in filtered view
  const [isFiltered, setIsFiltered] = useState(false);

  // Add this state for filter model
  const [filterModel, setFilterModel] = useState({
    items:
      insightsConfig?.factoryDataGrid?.factoryTableInitialState?.filter
        ?.filterModel?.items,
  });

  // Modify the function to toggle between views
  const toggleBasicColumns = () => {
    if (isFiltered) {
      // Reset both column visibility and filters
      setColumnVisibilityModel({});
      setFilterModel({
        items: [],
        logicOperator: "and",
      });
      setIsFiltered(false);
    } else {
      // Hide all columns first
      const hideAllColumns = columns.reduce((acc, column) => {
        acc[column.field] = false;
        return acc;
      }, {});

      // Set visible columns
      setColumnVisibilityModel({
        ...hideAllColumns,
        factoryCountry: true,
        associatedSupplier: true,
        factoryName: true,
        factoryAddress: true,
        factoryCity: true,
        supplierType: true,
        deMinimisCheck: true,
        deMinimisYear: true,
        productCategories: true,
        workerCountTotal: true,
        unionPresent: true,
        cbaPresent: true,
        osId: true,
        lastAssessmentDate: true,
      });

      // Set compliance status filter - modified to match the working example
      setFilterModel({
        items: [
          {
            field: "tier",
            operator: "doesNotContain",
            value: "Agent",
          },
          {
            field: "statusOfCompliance",
            operator: "isAnyOf",
            value: ["AUTHORIZED", "PROBATIONARY PERIOD"],
          },
        ],
        logicOperator: "and",
      });

      setIsFiltered(true);
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 250px)",
        width: "100%",
        padding: "24px 80px",
      }}
    >
      {insightsConfig?.factoryDataGrid?.components?.filterForFLA?.display && (
        <Button
          variant={isFiltered ? "contained" : "outlined"}
          color="primary"
          onClick={toggleBasicColumns}
          sx={{ mb: 2 }}
        >
          {insightsConfig?.factoryDataGrid?.components?.filterForFLA?.label ??
            "Filter for FLA facility list reporting"}
        </Button>
      )}

      <DataGridPro
        {...dataGridFormat}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        initialState={insightsConfig?.factoryDataGrid?.factoryTableInitialState}
        checkboxSelection
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default FactoryInsightsMetaDataGrid;
