import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Tooltip,
  Chip,
} from "@mui/material";
import { ComponentStyles } from "../../styles";

const FactoryAuditCadenceCalculation = ({ selectedFactory }) => {
  return (
    <Grid item xs={12} sx={{ my: 4 }}>
      <Card variant="outlined" style={ComponentStyles.twoLineThreeLineCard}>
        <CardContent style={{ padding: "16px 32px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#344054",
                display: "flex",
                alignItems: "center",
              }}
            >
              Audit Cadence Calculation
            </Typography>
            <Tooltip title="The Audit cadence calculuation is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
              <Chip
                label="Beta"
                size="small"
                sx={{
                  backgroundColor: "#4C5ED9",
                  color: "white",
                  fontWeight: 500,
                  fontSize: "12px",
                  height: "24px",
                }}
              />
            </Tooltip>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Last Audit Date
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.latestAudit?.metadata?.auditEndDate || "-"}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Next Audit Date
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.nextAuditDate || "-"}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Audit Cadence
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData
                    ?.auditCadence || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Next Audit Scheme
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData
                    ?.nextAuditType || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>Location</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData?.location ||
                    "-"}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Country Risk
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData
                    ?.countryRisk || "-"}{" "}
                  ({selectedFactory?.metadata?.country || "-"})
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  ZT/Critical Issues
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData
                    ?.hasZTorCritical
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              {/* 
                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Original Audit Grade
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.grade || "-"}
                    </Typography>
                  </Grid> */}

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Vendor Rating
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.auditCadence?.supplementaryData
                    ?.updatedAuditGrade || "-"}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Authorization Status
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.metadata?.complianceStatus || "-"}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={4} lg={3} sx={{ pr: 1 }}>
                <Typography sx={ComponentStyles.fieldName}>
                  Last Audit Scheme
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {selectedFactory?.latestAudit?.metadata?.auditType || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FactoryAuditCadenceCalculation;
