import React from "react";
import { Grid, Typography, Divider } from "@mui/material";

import moment from "moment";
import TwoRowCard from "../../components/twoRowCard.component";

import FactoryIssuesCardReformation from "./factoryIssuesCardReformation.component";
const getNextAuditType = (factoryData) => {
  const nextAuditScheme =
    factoryData?.metadata?.auditCadence?.supplementaryData?.nextAuditType;
  const generalAuditType =
    factoryData?.metadata?.socialAssessmentInfo?.generalAuditType?.toLowerCase();
  const latestAuditType = factoryData?.latestAudit?.metadata?.auditType;
  const latestAuditEndDate = factoryData?.latestAudit?.metadata?.auditEndDate;

  if (
    nextAuditScheme &&
    (nextAuditScheme.includes("labor law") || nextAuditScheme.includes("ll"))
  ) {
    return "Labor Law";
  }
  // } else if (generalAuditType && (generalAuditType.includes("labor law") || generalAuditType.includes("ll"))) {
  //   return "Labor Law";
  // }

  if (latestAuditType && latestAuditEndDate) {
    return "Annual Audit";
  }

  return "Initial Audit";
};

const getNextAuditFirm = (factoryData) => {
  const nextAuditFirm =
    factoryData?.metadata?.auditCadence?.supplementaryData?.nextAuditFirm;
  const generalAuditFirm =
    factoryData?.metadata?.socialAssessmentInfo?.generalAuditFirm;
  return nextAuditFirm || generalAuditFirm || "-";
};

const formatDate = (dateString) => {
  return dateString
    ? moment(dateString, ["YYYY-MM", "MMM, YYYY"]).format("MMM, YYYY")
    : "-";
};

const FactoryLatestAuditInfo = ({ factoryData }) => {
  const nextAuditType = getNextAuditType(factoryData);
  const nextAuditFirm = getNextAuditFirm(factoryData);
  const recommendedNextAuditMonth = formatDate(
    factoryData?.metadata?.auditCadence?.overrideNextAuditDate ||
      factoryData?.metadata?.auditCadence?.nextAuditDate
  );
  const auditExists = !!factoryData?.latestAudit;
  const isHistoricalAudit = factoryData?.latestAudit?.isHistoricalAudit;

  if (!auditExists) {
    return (
      <>
        <Typography
          sx={{ fontSize: 18, fontWeight: 700, color: "#344054" }}
          align="center"
          color="textSecondary"
          style={{ marginBottom: "10px", width: "100%" }}
        >
          No findings and latest audit data available as no audit exists.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TwoRowCard
              title="Next Audit"
              row1={{
                label1: "Audit Scheme",
                value1:
                  factoryData?.metadata?.auditCadence?.supplementaryData
                    ?.nextAuditType ||
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.generalAuditType ||
                  "-",
                label2: "Audit Firm",
                value2: nextAuditFirm,
              }}
              row2={{
                label1: "Audit Type",
                value1: nextAuditType || "-",
                label2: "Next Confirmed Assessment Date Window: Start - End",
                value2: `${
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.nextAssessmentWindowDateStart || "-"
                } to ${
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.nextAssessmentWindowDateEnd || "-"
                }`,
              }}
              row3={{
                label1: "Recommended Next Audit Month",
                value1: recommendedNextAuditMonth || "-",
                label2: "Confirmed Audit Date",
                value2:
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.confirmedAuditDate || "-",
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      {isHistoricalAudit ? (
        <>
          <Typography
            sx={{ fontSize: 18, fontWeight: 700, color: "#344054" }}
            align="center"
            color="textSecondary"
            style={{ marginBottom: "10px", width: "100%" }}
          >
            Only historical audit exists, no findings data.
          </Typography>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700, color: "#344054", ml: 2 }}
            color="textSecondary"
            style={{ marginBottom: "10px", width: "100%" }}
          >
            Latest Audit Info
          </Typography>
          <Grid item xs={12} md={6}>
            <TwoRowCard
              title="Latest Audit"
              row1={{
                label1: "Audit Scheme",
                value1: factoryData?.latestAudit?.metadata?.auditType || "-",
                label2: "Audit Date",
                value2: factoryData?.latestAudit?.metadata?.auditEndDate || "-",
              }}
              row2={{
                label1: "Audit Type",
                value1:
                  factoryData?.latestAudit?.metadata?.auditTypeFrequency || "-",
                label2: "MRA",
                value2: factoryData?.latestAudit?.metadata?.mra || "-",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TwoRowCard
              title="Next Audit"
              row1={{
                label1: "Audit Scheme",
                value1:
                  factoryData?.metadata?.auditCadence?.supplementaryData
                    ?.nextAuditType ||
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.generalAuditType ||
                  "-",
                label2: "Audit Firm",
                value2: nextAuditFirm,
              }}
              row2={{
                label1: "Audit Type",
                value1: nextAuditType || "-",
                label2: "Next Confirmed Assessment Date Window: Start - End",
                value2: `${
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.nextAssessmentWindowDateStart || "-"
                } to ${
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.nextAssessmentWindowDateEnd || "-"
                }`,
              }}
              row3={{
                label1: "Recommended Next Audit Month",
                value1: recommendedNextAuditMonth || "-",
                label2: "Confirmed Audit Date",
                value2:
                  factoryData?.metadata?.socialAssessmentInfo
                    ?.confirmedAuditDate || "-",
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Typography
            sx={{ fontSize: 20, fontWeight: 700, color: "#344054", ml: 2 }}
            color="textSecondary"
            style={{ marginBottom: "10px", width: "100%" }}
          >
            Latest Audit Info
          </Typography>
          <Grid item xs={12} md={6}>
            <FactoryIssuesCardReformation
              latestAuditData={factoryData?.latestAudit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TwoRowCard
                  title="Latest Audit"
                  row1={{
                    label1: "Audit Scheme",
                    value1:
                      factoryData?.latestAudit?.metadata?.auditType || "-",
                    label2: "Audit Date",
                    value2:
                      factoryData?.latestAudit?.metadata?.auditEndDate || "-",
                  }}
                  row2={{
                    label1: "Audit Type",
                    value1:
                      factoryData?.latestAudit?.metadata?.auditTypeFrequency ||
                      "-",
                    label2: "MRA",
                    value2: factoryData?.latestAudit?.metadata?.mra || "-",
                  }}
                />
              </Grid>
              <Grid item>
                <TwoRowCard
                  title="Next Audit"
                  row1={{
                    label1: "Audit Scheme",
                    value1:
                      factoryData?.metadata?.auditCadence?.supplementaryData
                        ?.nextAuditType ||
                      factoryData?.metadata?.socialAssessmentInfo
                        ?.generalAuditType ||
                      "-",
                    label2: "Audit Firm",
                    value2: nextAuditFirm,
                  }}
                  row2={{
                    label1: "Audit Type",
                    value1: nextAuditType || "-",
                    label2:
                      "Next Confirmed Assessment Date Window: Start - End",
                    value2: `${
                      factoryData?.metadata?.socialAssessmentInfo
                        ?.nextAssessmentWindowDateStart || "-"
                    } to ${
                      factoryData?.metadata?.socialAssessmentInfo
                        ?.nextAssessmentWindowDateEnd || "-"
                    }`,
                  }}
                  row3={{
                    label1: "Recommended Next Audit Month",
                    value1: recommendedNextAuditMonth || "-",
                    label2: "Confirmed Audit Date",
                    value2:
                      factoryData?.metadata?.socialAssessmentInfo
                        ?.confirmedAuditDate || "-",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FactoryLatestAuditInfo;
