import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Link,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../../selectors/supplier.selector";
import SupplierInformationEditModal from "./EditModals/editSupplierInformation.component";
import EditIcon from "@mui/icons-material/Edit";
import { selectEditModeState } from "../../selectors/misc.selector";
import SupplierInformationDrawer from "./supplierInformationDrawer.component";
import { ComponentStyles, SupplierStyles } from "../../styles";
import SupplierTierMapDrawer from "./supplierTierMapDrawer.component";
import { useTenant } from "../../context/TenantContext";
import SupplierReminderButton from "../../tenants/stitchfix/supplierReminderButton.component";
import SupplierAssociatedFactoryLinks from "../../tenants/reformation/supplierAssociatedFactoryLinks.component";

const SupplierInformation = () => {
  const { supplierConfig } = useTenant();
  const supplierData = useSelector(selectSupplierData);

  const [isEditing, setIsEditing] = useState(false);
  const editMode = useSelector(selectEditModeState);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  const formatAddress = (metadata) => {
    if (!metadata) return "";
    const addressParts = [];
    // Add street line 1 if it exists
    if (metadata.streetLine1 || metadata.addressLine1) {
      addressParts.push(metadata.streetLine1 || metadata.addressLine1);
    }
    // Add street line 2 if it exists
    if (metadata.streetLine2 || metadata.addressLine2) {
      addressParts.push(metadata.streetLine2 || metadata.addressLine2);
    }
    // Create city-state-zip group with proper commas
    const cityStateGroup = [];
    if (metadata.city) {
      cityStateGroup.push(metadata.city);
    }
    if (metadata.state) {
      cityStateGroup.push(metadata.state);
    }

    // Add the city-state group if not empty
    if (cityStateGroup.length > 0) {
      addressParts.push(cityStateGroup.join(", "));
    }
    // Join all parts with commas and return
    return addressParts.join(", ");
  };

  const supplierDataKeyValuePairs = {
    supplierName: supplierData?.metadata?.companyName || "-",
    supplierId: supplierData?.metadata?.uniqueSupplierId || "-",
    companyAddress: supplierData?.metadata?.companyAddress || "-",
    contactPerson: supplierData?.metadata?.contactPerson?.name || "-",
    contactPersonPosition:
      supplierData?.metadata?.contactPerson?.position || "-",
    contactPersonPhone: supplierData?.metadata?.contactPerson?.phone || "-",
    contactPersonEmail: supplierData?.metadata?.contactPerson?.email || "-",
    contactPersonNameReformation:
      supplierData?.metadata?.primarySupplierContactPerson?.name || "-",
    contactPersonPositionReformation:
      supplierData?.metadata?.primarySupplierContactPerson?.position || "-",
    contactPersonPhoneReformation:
      supplierData?.metadata?.primarySupplierContactPerson?.phone || "-",
    contactPersonEmailReformation:
      supplierData?.metadata?.primarySupplierContactPerson?.email || "-",
    supplyChainContact: supplierData?.metadata?.supplyChainContact || "-",
    supplyChainTeam: supplierData?.metadata?.supplyChainTeam || "-",
    tier1Factories: supplierData?.factoryData?.tier1Factories,
    tier2Factories: supplierData?.factoryData?.tier2Factories,
    streetAddress: supplierData?.metadata?.streetAddress || "-",
    cityStateCountry: `${supplierData?.metadata?.city || "-"}, ${
      supplierData?.metadata?.stateProvince || "-"
    }, ${supplierData?.metadata?.country || "-"}`,
    address: supplierData?.metadata?.addressLine1 || "-",
    zipCode: supplierData?.metadata?.zipCode || "-",
    factoryId: supplierData?.metadata?.factoryId || "-",
    factoryType: supplierData?.metadata?.processInfo?.supplierType || "-",
    reporting: supplierData?.metadata?.reportingCheck === true ? "Yes" : "No",
    domesticOrInternational:
      supplierData?.metadata?.domesticOrInternational || "-",
    contactNote: supplierData?.metadata?.contactPerson?.contactNote
      ? supplierData.metadata.contactPerson.contactNote
      : null,
    vendorStatus:
      supplierData?.metadata?.status ||
      supplierData?.metadata?.vendorStatus ||
      "-",
    totalFactories: supplierData?.factoryData?.totalFactories || "-",
    tier: supplierData?.metadata?.tier
      ? supplierData?.metadata?.tier?.toString().includes("Tier")
        ? supplierData?.metadata?.tier
        : `Tier ${supplierData?.metadata?.tier}`
      : "-",
    country: supplierData?.metadata?.country || "-",
    companyAddress: supplierData?.metadata?.companyAddress || "-",
    companyAddressFormatted: formatAddress(supplierData?.metadata) || "-",
    supplyChainContact: supplierData?.metadata?.supplyChainContact || "-",
    supplyChainTeam: supplierData?.metadata?.supplyChainTeam || "-",
  };
  return (
    <Card style={SupplierStyles.supplierInformationCard}>
      <SupplierInformationEditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        supplierData={supplierData}
      />

      {supplierData && (
        <CardContent sx={{ minHeight: "305px" }}>
          <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#344054",
                  display: "inline-block",
                }}
              >
                {supplierConfig?.generalInformation?.sectionTitle}
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEdit}
                />
              )}
            </Grid>

            <Grid item>
              {supplierConfig?.generalInformation?.components?.moreInfo
                ?.display && (
                <Button
                  variant="outlined"
                  onClick={toggleDrawer(true)}
                  sx={{
                    color: "#6172F3",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#5667e2",
                    },
                    textTransform: "none",
                    width: "150px",
                    height: "40px",
                    borderRadius: "8px",
                    mr: 1,
                  }}
                >
                  More Information
                </Button>
              )}

              {supplierConfig?.generalInformation?.components?.tierMap
                ?.display && (
                <Button
                  variant="outlined"
                  onClick={toggleTierMapDrawer(true)}
                  sx={{
                    color: "#6172F3",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#5667e2",
                    },
                    textTransform: "none",
                    width: "100px",
                    height: "40px",
                    borderRadius: "8px",
                    mr: "-24px",
                  }}
                >
                  Tier Map
                </Button>
              )}
              {supplierConfig?.generalInformation?.components?.sendReminder
                ?.display && <SupplierReminderButton supplier={supplierData} />}
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            {Object.entries(supplierConfig?.generalInformation?.fields)
              .filter(([_, value]) => value?.display)
              .map(([field, value]) => {
                if (field === "contactNote") {
                  return (
                    <Grid item xs={value.width}>
                      <Typography sx={ComponentStyles.fieldName}>
                        {value.fieldName}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          color: "#45464E",
                          wordBreak: "break-word",
                          whiteSpace: "pre-line", // Ensures new lines are rendered
                          maxHeight: showMore ? "none" : "100px", // Fixed height with show more option
                          overflow: "hidden",
                        }}
                      >
                        {supplierDataKeyValuePairs[field]
                          ? supplierDataKeyValuePairs[field]
                              .split("\n")
                              .map((line, index) => (
                                <span key={index}>
                                  {line.includes("Name:") && (
                                    <strong>Name:</strong>
                                  )}
                                  {line.includes("Function:") && (
                                    <strong>Function:</strong>
                                  )}
                                  {line.includes("Email:") && (
                                    <strong>Email:</strong>
                                  )}
                                  {line.replace(
                                    /(Name:|Function:|Email:)/g,
                                    ""
                                  )}
                                  <br />
                                </span>
                              ))
                          : "No contact information available"}
                      </Typography>
                      {supplierConfig?.generalInformation?.components
                        ?.showMoreContacts?.display && (
                        <Button onClick={toggleShowMore} sx={{ mt: 1 }}>
                          {showMore ? "Show Less" : "Show More Contacts"}
                        </Button>
                      )}
                    </Grid>
                  );
                } else if (value.identifier === "tieredFactories") {
                  console.log("inside here");
                  return (
                    <Grid item xs={value.width}>
                      <Typography sx={ComponentStyles.fieldName}>
                        {value.fieldName}
                      </Typography>
                      <Typography sx={ComponentStyles.fieldValue}>
                        <SupplierAssociatedFactoryLinks
                          factories={supplierDataKeyValuePairs[field]}
                          tier={field === "tier1Factories" ? "1" : "2"}
                          supplierId={supplierData?._id}
                        />
                      </Typography>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={value.width}>
                    <Typography sx={ComponentStyles.fieldName}>
                      {value.fieldName}
                    </Typography>

                    <Typography sx={ComponentStyles.fieldValue}>
                      {supplierDataKeyValuePairs[field]}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      )}
      <SupplierInformationDrawer
        supplierData={supplierData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <SupplierTierMapDrawer
        supplierData={supplierData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </Card>
  );
};

export default SupplierInformation;
