import React from "react";
import { Box, Tabs, Tab, Tooltip, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierPageTab } from "../../selectors/supplier.selector";
import { setSupplierPageTab } from "../../reducers/supplier.reducer";
import { setFactoryPageTab } from "../../reducers/factory.reducer";
import { selectFacilityPageTab } from "../../selectors/factory.selector";
import { useTenant } from "../../context/TenantContext";

const FactoryTabs = () => {
  const dispatch = useDispatch();
  const { factoryConfig } = useTenant();
  const handleChange = (event, newValue) => {
    dispatch(setFactoryPageTab(newValue));
  };
  const value = useSelector(selectFacilityPageTab);

  return (
    <Box sx={{ width: "100%", mt: 1, flexGrow: 1 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        {factoryConfig?.tabs?.map(
          (tab) =>
            tab.display &&
            (tab.tooltipIdentifier ? (
              <Tab
                value={tab.tab}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span>{tab.label}</span>

                    <Tooltip title={tab.tooltipIdentifier}>
                      <Chip
                        label="Beta"
                        size="small"
                        sx={{
                          backgroundColor: "#4C5ED9",
                          color: "white",
                          fontWeight: 500,
                          fontSize: "12px",
                          height: "24px",
                          ml: 1,
                        }}
                      />
                    </Tooltip>
                  </Box>
                }
                sx={{ textTransform: "none" }}
              />
            ) : (
              <Tab
                value={tab.tab}
                label={tab.label}
                sx={{ textTransform: "none" }}
              />
            ))
        )}
      </Tabs>
    </Box>
  );
};

export default FactoryTabs;
