import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@mui/material";
import { ComponentStyles } from "../../styles";

const FactoryIRSSCard = ({ factoryData }) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        background: "#FFF",
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        IRSS Details
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table sx={{ flex: 1, alignItems: "flex-start" }}>
        <TableBody>
          <TableRow
            sx={{ verticalAlign: "top", "& > td": { pt: 0.5, pr: 0, pl: 0 } }}
          >
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Facility Currently in IRSS ?
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.latestAudit?.metadata?.irss?.irssCheck || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                IRSS Program Status
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.latestAudit?.metadata?.irss?.status || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                IRSS Program Start Date
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.latestAudit?.metadata?.irss?.startDate || "-"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none", py: 0 }}>
              <Typography sx={ComponentStyles.fieldName}>
                IRSS Program End Date
              </Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.latestAudit?.metadata?.irss?.endDate || "-"}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default FactoryIRSSCard;
