import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Grid } from "@mui/material";

import { useTenant } from "../../context/TenantContext";
import moment from "moment";
import SimpleActionCard from "../../tenants/reformation/simpleActionCard.component";
import { updateFactoryData } from "../../reducers/factory.reducer";
import OpenCAPCard from "./openCAPCard.component";

const computeDueInDays = (date) => moment(date).diff(moment(), "days");

const FactoryActionNeeded = ({
  capDetails = [],
  certificateDetails,
  irssDetails,
  upcomingAuditDate,
  nextAssessmentWindowDateStart,
  auditNotConfirmedMessage,
  factoryId,
  supplierId,
  triggersObject,
}) => {
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;

  const {
    showIRSSCard,
    showCertificatesAndLicenseCard,
    showUpcomingAuditCard,
    showAuditNotConfirmedCard,
    showCAPTable,
  } =
    factoryStatusSection?.detailsFromMostRecentAuditSection?.actionNeededSection
      ?.components;

  const dispatch = useDispatch();
  const [irssStatus, setIrssStatus] = useState(
    irssDetails?.status || "Pending"
  );

  const handleStatusChange = (newStatus) => {
    setIrssStatus(newStatus);
    dispatch(
      updateFactoryData({
        supplierId,
        factoryId,
        changes: { irssStatus: newStatus },
      })
    );
  };

  return (
    <>
      <Typography sx={{ fontWeight: 700, fontSize: 18, color: "#000", my: 3 }}>
        Action Needed
      </Typography>

      <Grid container spacing={2}>
        {/* IRSS Card */}
        {showIRSSCard &&
          (triggersObject.irssEnrollmentTriggered ||
            triggersObject.irssProgramEndTriggered) && (
            <Grid item xs={12} md={6}>
              <SimpleActionCard
                type="irss"
                title="IRSS"
                rows={[
                  {
                    dueInDays: irssStatus,
                    alert: "The facility will enroll in the IRSS program",
                  },
                ]}
                handleIrssChange={handleStatusChange}
                irssEnrollmentTriggered={triggersObject.irssEnrollmentTriggered}
                irssProgramEndTriggered={triggersObject.irssProgramEndTriggered}
                irssProgramEndDate={irssDetails?.programEndDate}
              />
            </Grid>
          )}

        {/* Certificate and License Card */}
        {showCertificatesAndLicenseCard &&
          triggersObject.certificationTriggered &&
          (() => {
            const rows = [];
            if (triggersObject.wccTriggered) {
              rows.push({
                dueInDays: computeDueInDays(certificateDetails.wcc?.wccExpDate),
                alert: "WCC",
              });
            }
            if (triggersObject.licenseTriggered) {
              rows.push({
                dueInDays: computeDueInDays(
                  certificateDetails.businessLicenseInfo?.licenseExpDate
                ),
                alert: "Business license",
              });
            }
            return (
              <Grid item xs={12} md={6}>
                <SimpleActionCard
                  type="license"
                  title="Certificate and License"
                  rows={rows}
                  triggered={triggersObject.certificationTriggered}
                />
              </Grid>
            );
          })()}

        {/* Upcoming Audit Card */}
        {showUpcomingAuditCard && triggersObject.auditTriggered && (
          <Grid item xs={12} md={6}>
            <SimpleActionCard
              type="upcomingAudit"
              title="Upcoming Audit"
              rows={[
                {
                  dueInDays: computeDueInDays(upcomingAuditDate),
                  alert: moment(upcomingAuditDate).format("MMM. D, YYYY"),
                },
              ]}
              triggered={triggersObject.auditTriggered}
            />
          </Grid>
        )}

        {/* Audit Not Confirmed Card */}
        {showAuditNotConfirmedCard &&
          triggersObject.auditNotConfirmedTriggered && (
            <Grid item xs={12} md={6}>
              <SimpleActionCard
                type="auditNotConfirmed"
                title="No Confirmed Audit Date"
                rows={[
                  {
                    dueInDays: computeDueInDays(nextAssessmentWindowDateStart),
                    alert: auditNotConfirmedMessage,
                  },
                ]}
                triggered={triggersObject.auditNotConfirmedTriggered}
              />
            </Grid>
          )}

        {/* CAP Table */}
        {((factoryStatusSection?.detailsFromMostRecentAuditSection
          ?.actionNeededSection?.isReformation &&
          triggersObject.capTriggered) ||
          (!factoryStatusSection?.detailsFromMostRecentAuditSection
            ?.actionNeededSection?.isReformation &&
            showCAPTable)) && (
          <Grid item xs={12}>
            <OpenCAPCard capDetails={capDetails} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FactoryActionNeeded;
