import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Divider,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { updateAuditData } from "../../reducers/audit.reducer";

import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles, AuditStyles } from "../../styles";
import moment from "moment";
import { useTenant } from "../../context/TenantContext";

import FactoryIssuesCard from "./factoryIssuesCard.component";
import FactoryIssuesCardStitchfix from "../../tenants/stitchfix/factoryIssuesCardStitchfix.component";
import FactoryIssuesCardGore from "../../tenants/wlgore/factoryIssuesCardGore.component";
import FactoryIssuesCardRoots from "../../tenants/roots/factoryIssuesCardRoots.component";
const getColorForRating = (rating) => {
  switch (rating) {
    case "Green (A)":
      return AuditStyles.auditRatingColors.green;
    case "Yellow (B)":
      return AuditStyles.auditRatingColors.yellow;
    case "Orange (C)":
      return AuditStyles.auditRatingColors.orange;
    case "Red (D)":
      return AuditStyles.auditRatingColors.red;
    default:
      return "black";
  }
};

const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  const { factoryConfig } = useTenant();
  const factoryStatusSection = factoryConfig?.tabsSection?.factoryStatusSection;

  const dispatch = useDispatch();
  const auditEndDate = selectedFactory?.latestAudit?.metadata?.auditEndDate;
  const nextAuditDate =
    selectedFactory?.metadata?.socialAssessmentInfo?.confirmedAuditDate;

  const availableData = {
    rating: selectedFactory?.latestAudit?.risk?.auditRating || "Coming Soon.",
    venodrRating:
      selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score ===
        100 ||
      !selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score
        ? selectedFactory?.latestAudit?.metadata?.facilityData?.vendor_rating ||
          "N/A"
        : selectedFactory?.latestAudit?.metadata?.facilityData?.audit_score,
    formattedNextAuditDate: nextAuditDate
      ? moment(nextAuditDate).format("MMM. DD, YYYY")
      : "-",
    daysUntilNextAudit: nextAuditDate
      ? moment(nextAuditDate).diff(moment(), "days")
      : null,
    nextAuditType:
      selectedFactory?.metadata?.socialAssessmentInfo?.generalAuditType,
    auditScore:
      selectedFactory?.latestAudit?.risk?.auditScore ||
      selectedFactory?.risk?.riskScore ||
      "Coming Soon.",
    auditScoreColorCondition: selectedFactory?.latestAudit?.risk?.auditScore
      ? selectedFactory?.latestAudit?.risk?.auditScore >= 81
        ? "#027A48" // Green
        : selectedFactory?.latestAudit?.risk?.auditScore >= 61
        ? "#B54708" // Yellow/Orange
        : "#B42318" // Red
      : "#101828",
    totalVendorScore: selectedFactory?.vendorScoreForRoots?.totalScore || "N/A",
    capPerformanceScore:
      selectedFactory?.vendorScoreForRoots?.capPerformanceScore,
    nextAuditDate: selectedFactory?.metadata?.auditCadence?.nextAuditDate
      ? moment(selectedFactory.metadata.auditCadence.nextAuditDate).format(
          "DD-MMM-YYYY"
        )
      : selectedFactory?.metadata?.nextReportDate || "-",

    auditScoreColorConditionStitchfix: () => {
      const auditScore = parseInt(
        selectedFactory?.latestAudit?.risk?.auditScore ??
          selectedFactory?.risk?.riskScore,
        10
      );
      if (isNaN(auditScore)) return "black";
      if (auditScore === 1) return "red";
      if (auditScore >= 2 && auditScore <= 3) return "orange";
      if (auditScore >= 4 && auditScore <= 5) return "green";
      return "black";
    },
    auditScoreBackgroundColorConditionStitchfix: () => {
      const auditScore = parseInt(
        selectedFactory?.latestAudit?.risk?.auditScore ??
          selectedFactory?.risk?.riskScore,
        10
      );
      if (isNaN(auditScore)) return "rgba(0, 0, 0, 0.1)";
      if (auditScore === 1) return "rgba(255, 0, 0, 0.1)";
      if (auditScore >= 2 && auditScore <= 3) return "rgba(255, 165, 0, 0.1)";
      if (auditScore >= 4 && auditScore <= 5) return "rgba(0, 128, 0, 0.1)";
      return "rgba(0, 0, 0, 0.1)";
    },
    auditScoreVerboseDisplayConditionStitchfix: () => {
      const auditScore = parseInt(
        selectedFactory?.latestAudit?.risk?.auditScore ??
          selectedFactory?.risk?.riskScore,
        10
      );
      if (isNaN(auditScore)) return "Coming Soon.";
      if (auditScore === 1) return "High risk";
      if (auditScore >= 2 && auditScore <= 3) return "Medium risk";
      if (auditScore >= 4 && auditScore <= 5) return "Low risk";
      return "Coming Soon.";
    },
  };
  // Determine the order of grid items based on tenant config
  const issuesCardMd =
    factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard?.layout
      ?.issuesCard === "left"
      ? 8
      : factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard
          ?.layout?.issuesCard === "full"
      ? 12
      : 4;
  const twoLineCardsMd =
    factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard?.layout
      ?.issuesCard === "left"
      ? 4
      : 8;
  const issuesCardOrder =
    factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard?.layout
      ?.issuesCard === "left"
      ? 1
      : 2;
  const twoLineCardsOrder =
    factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard?.layout
      ?.issuesCard === "left"
      ? 2
      : 1;

  const [capSubmissionTime, setCapSubmissionTime] = useState(
    selectedFactory?.latestAudit?.metadata?.capSubmissionTime || ""
  );

  useEffect(() => {
    dispatch(
      updateAuditData({
        organisationId: selectedFactory?.organisationId,
        supplierId: selectedFactory?.supplierId,
        factoryId: selectedFactory?._id,
        auditId: selectedFactory?.latestAudit?._id,
        changes: { capSubmissionTime },
      })
    );
  }, [capSubmissionTime]);
  return (
    <Box sx={{ mb: 4 }}>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        {factoryStatusSection?.detailsFromMostRecentAuditSection?.title ||
          `Details from Most Recent Audit on ${auditEndDate}`}
      </Typography>

      {/* TWO LINE CARDS that live with the findings table */}
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={twoLineCardsMd}
          container
          direction="column"
          spacing={2}
          sx={{ height: "100%", order: { xs: 1, md: twoLineCardsOrder } }}
        >
          {factoryStatusSection?.detailsFromMostRecentAuditSection?.componentsBesideTheIssuesCard?.map(
            (component) => {
              return (
                <Grid item sx={{ flexGrow: 1.1 }}>
                  <TwoLineCard
                    title={component.fieldName}
                    nextAuditDate={
                      component.displayInCustomBox ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                              availableData[
                                component.auditScoreBackgroundColorCondition
                              ](),
                            borderRadius: "8px",
                            padding: "16px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "34px",
                              fontWeight: "bold",
                              color: availableData[component.colorCondition](),
                              marginRight: "16px",
                            }}
                          >
                            {availableData[component.key]}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              marginRight: "16px",
                              backgroundColor: "white",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "24px",
                              fontWeight: "normal",
                              color: availableData[component.colorCondition](),
                            }}
                          >
                            {availableData[
                              component.auditScoreVerboseDisplayCondition
                            ]()}
                          </Typography>
                        </Box>
                      ) : (
                        availableData[component.key]
                      )
                    }
                    textColor={
                      component.color
                        ? component.colorCondition
                          ? availableData[component.colorCondition]
                          : getColorForRating(availableData.rating)
                        : "inherit"
                    }
                  />
                  {availableData[component.key] &&
                    availableData[component.dropdown] && (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Submitted CAP in</InputLabel>
                        <Select
                          value={capSubmissionTime}
                          onChange={(e) => setCapSubmissionTime(e.target.value)}
                          label="Submitted CAP in"
                        >
                          <MenuItem value="within14">within 14 days</MenuItem>
                          <MenuItem value="within30">within 30 days</MenuItem>
                          <MenuItem value="after30">after 30 days</MenuItem>
                          <MenuItem value="notSubmitted">
                            Did not submit CAP
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                </Grid>
              );
            }
          )}
        </Grid>

        {/* ISSUES CARD */}
        {factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard
          ?.display && (
          <Grid
            item
            sm={12}
            md={issuesCardMd}
            sx={{ order: { xs: 2, md: issuesCardOrder } }}
          >
            {factoryStatusSection?.detailsFromMostRecentAuditSection?.issuesCard
              ?.org === "Stitchfix" ? (
              <FactoryIssuesCardStitchfix
                latestAuditData={selectedFactory.latestAudit}
              />
            ) : factoryStatusSection?.detailsFromMostRecentAuditSection
                ?.issuesCard?.org === "Gore" ? (
              <FactoryIssuesCardGore
                latestAuditData={selectedFactory.latestAudit}
              />
            ) : factoryStatusSection?.detailsFromMostRecentAuditSection
                ?.issuesCard?.org === "Roots" ? (
              <FactoryIssuesCardRoots
                latestAuditData={selectedFactory.latestAudit}
              />
            ) : (
              <FactoryIssuesCard
                latestAuditData={selectedFactory.latestAudit}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FactoryMostRecentAuditDetails;
