import React, { useEffect } from "react";
import { Box } from "@mui/material";

import SupplierTableDataGrid from "../components/SupplierDetails/supplierTableDataGrid.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectOrganisationId } from "../selectors/login.selector";
import {
  getListOfAllSuppliers,
  getSupplierById,
} from "../reducers/supplier.reducer";

const Suppliers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const organisationId = useSelector(selectOrganisationId);

  useEffect(() => {
    if (organisationId) {
      dispatch(getListOfAllSuppliers({ organisationId }));
    }
  }, [organisationId, dispatch]);

  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    navigate(`${location.pathname}/${supplierId}`);
  };
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <SupplierTableDataGrid
          navigateToSupplierDetails={navigateToSupplierDetails}
          organisationId={organisationId}
        />
      </Box>
    </Box>
  );
};

export default Suppliers;
